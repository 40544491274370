$dropdown-item__width                     : 100% !default;
$dropdown-item__bg-color                  : $white !default;
$dropdown-item__border-radius             : 0 !default;
$dropdown-item__outline                   : none !default;
$dropdown-item__font-size                 : $font-size-base !default;
$dropdown-item__transition-height         : height 0.3s !default;
$dropdown-item__transition                : $transition-base !default;

// List item
$dropdown-item__item-padding              : 13px 0 !default;
$dropdown-item__item-color                : $color-primary !default;
$dropdown-item__item-color--open          : $color-primary !default;
$dropdown-item__item-color-hover          : $color-primary !default;
$dropdown-item__item-bg-color             : $color-light !default;
$dropdown-item__item-bg-color--open       : $color-light !default;
$dropdown-item__item-bg-color-hover       : $color-light !default;
$dropdown-item__item-font-weight          : $font-weight-base !default;

// List icon
$dropdown-item__icon-width                : 16px !default;
$dropdown-item__icon-height               : 16px !default;
$dropdown-item__icon-fill                 : $color-primary !default;
$dropdown-item__icon-fill-hover           : $color-primary !default;
$dropdown-item__icon-fill--open           : $color-primary !default;
$dropdown-item__icon-rotate--open         : rotate(180deg) !default;

// screen-m list variables
$dropdown-item__content-margin--screen-m  : $spacer $spacer--medium !default;

$self: '.dropdown-item';


#{$self} {
    width: $dropdown-item__width;
    background-color: $dropdown-item__bg-color;
    position: relative;

    &__list {
        display: block;
        width: $dropdown-item__width;
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    &__icon {
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        width: $dropdown-item__icon-width;
        height: $dropdown-item__icon-height;
        margin: auto;
        fill: $dropdown-item__icon-fill;
        transition: $dropdown-item__transition;
    }

    &__item {
        display: block;
        width: 100%;
        padding: 0;
        font-size: $dropdown-item__font-size;

        &--collapse {

            .dropdown-item__label {
                padding-right: 30px;
            }
        }
    }

    &__label {
        display: block;
        position: relative;
        width: 100%;
        background-color: $dropdown-item__item-bg-color;
        padding: $dropdown-item__item-padding;
        margin: 0;
        border: 0;
        border-radius: $dropdown-item__border-radius;
        text-decoration: none;
        text-align: left;
        cursor: pointer;
        transition: $dropdown-item__transition;
        color: $dropdown-item__item-color;
        font-weight: $dropdown-item__item-font-weight;

        &:hover,
        &:focus {
            color: $dropdown-item__item-color-hover;
            background-color: $dropdown-item__item-bg-color-hover;
            text-decoration: underline;
            outline: $dropdown-item__outline;

            & > .dropdown-item__icon {
                fill: $dropdown-item__icon-fill-hover;
            }
        }

        &[aria-expanded="true"] {
            color: $dropdown-item__item-color--open;
            background-color: $dropdown-item__item-bg-color--open;

            & > .dropdown-item__icon {
                fill: $dropdown-item__icon-fill--open;
                transform: $dropdown-item__icon-rotate--open;
            }
        }
    }
  
    &__content {
        overflow: hidden;
        transition: $dropdown-item__transition-height;
        &[aria-hidden="true"] {
            display: none;
        }
    }
}
