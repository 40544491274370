$cross-sell-products-background-\@large         :   $gray-lightest !default;

#cart-crosssells {
    padding: 0;

    @include mq($screen-l) {
        padding: 10px 20px 0 0;
    }
}

.cart-cross-sell-placeholder {
    @include mq($screen-l) {
        margin-bottom: 700px;
    }
}

.cross-sell-products {
    &.carousel.glider {
        margin-left: 0;
        width: 100%;
    }
}

.glider-contain {
    &.cart-glider {
        .glider {
            width: 100%;
            padding-left: 7px;
            .glider-track {
                min-width: 100%;
            }
        }
        .glider-slide {
            @include mq($screen-l) {
                min-width: calc((100% / 4) - (32px*(4 - 1) / 4)) !important;
                a {
                    min-height: 63px;
                }
            }
            margin: 0 12px 10px;
            strong {
                margin-bottom: 20px;
            }
        }
        .glider-prev {
            left: 20px;
            &.hide {
                display: none;
            }
        }
        .glider-next {
            right: 26px;
            &.hide {
                display: none;
            }
        }
    }
    &.products.wrapper.grid.products-grid {
        margin: unset;
        &.accessories {
            margin: 0 0 32px 0;
        }
        max-height: unset;
        .glider-slide {
            padding: 20px 10px 20px 10px;
            button svg {
                display: block;
            }
            @include mq($screen-l) {
                .cross-sell-product-item__cta {
                    flex-basis: 44%;
                    align-self: center;
                }
                .price-box {
                    flex-basis: 56%;
                }
            }
            .price.price--decimal .price__value:not(.price__value--old):not(.price__value--gross) .price__prefix {
                font-size: 0.6em;
                margin-right: 0;
            }
            .add-to-cart-button__primary-button {
                height: 46px;
                width: 100%;
                padding: 10px 14px;
            }
        }
        .glider-track {
            max-width: 100%;
        }
    }
}

.cross-sell-products {
    display: flex;
    flex-flow: row nowrap;
    overflow-x: scroll;

    @include mq($screen-l) {
        flex-wrap: wrap;
        overflow-x: hidden;
        max-width: 100%;
        padding: 20px 12px 20px 20px;
        background: $cross-sell-products-background-\@large;
    }

    &__title {
        flex-basis: 100%;
        color: $color-primary;
        margin-top:5px;

        @include mq($screen-l) {
            margin-top:0;
            margin-bottom: 15px;
        }
    }

    &__cross-sell-product-item {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 100%;
        margin: 0 10px 15px 0;

        &:last-child {
            margin-right: 0;
        }

        @include mq($screen-s) {
            width: calc(100% / 2 - #{$spacer--medium});
            margin-right: 10px;
        }

        @include mq($screen-m) {
            width: calc(100% / 3 - #{$spacer--medium});
        }

        .e-label {
            margin: 5px 0;
            display: block;
            min-height: 27px;
            .product-elabel-block {
                margin: 0;
                img.product-elabel {
                    width: 50px;
                    height: auto;
                    margin: unset;
                }
            }
        }
    }
}
