$rating__size                   : 100px !default;
$rating__item-size              : $rating__size / 5 !default;
$rating__size--rate             : 250px !default;
$rating__margin--rate           : $spacer--medium !default;
$rating__item-size--rate        : $rating__size--rate / 5 !default;
$icon__active-color             : $orange !default;
$icon__active-color--with-border: $orange !default;
$icon__inactive-color           : $gray-light !default;
$rating__error-margin-bottom    : $spacer !default;
$rating__error-color            : $red !default;

.rating {
    width: $rating__size;

    &:hover,
    &.focus-visible {
        .rating__rate-item span:before {
            display: block;
        }
    }

    &__rate-item {
        position: relative;
        flex: 1 0 $rating__item-size;
        overflow: hidden;

        &:hover,
        &.focus-visible {
            ~ .rating__rate-item span:before {
                display: none;
            }
        }
    }
    &__star {
        position: relative;
        height: $rating__item-size;
        width: $rating__size;
        margin: 0;

        &:before { // inactive
            content: '';
            position: absolute;
            display: block;
            bottom: 0;
            left: 0;
            top: 0;
            right: 0;
            width: $rating__size;
            height: $rating__item-size;
            background-image: svg-uri("<svg viewBox='0 0 20 19' xmlns='http://www.w3.org/2000/svg'><path fill='none' d='M-2-2h24v24H-2z'/><path fill='#{$icon__inactive-color}' d='M20 7.24l-7.19-.62L10 0 7.19 6.63 0 7.24l5.46 4.73L3.82 19 10 15.27 16.18 19l-1.63-7.03L20 7.24zM10 13.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L10 4.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L10 13.4z'/></svg>");
            background-repeat: repeat-x;
            background-position: left center;
            background-size: $rating__item-size;
            text-indent: 10000px;

            @include isIE() {
                height: 100%;
                background-size: $rating__item-size 100%;
            }
        }
        &--single {
            width: $rating__item-size;
        }
    }
    &__indicator {
        left: 0;
        top: 0;
        display: block;
        height: $rating__item-size;
        width: $rating__size;
        overflow: hidden;
        text-indent: -10000px;
        &:before { // active
            content: '';
            position: absolute;
            display: block;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: auto;
            height: $rating__item-size;
            background-image: svg-uri("<svg viewBox='0 0 20 19' xmlns='http://www.w3.org/2000/svg'><path fill='none' d='M-2-2h24v24H-2z'/><path d='M10 15.27L16.18 19l-1.64-7.03L20 7.24l-7.19-.61L10 0 7.19 6.63 0 7.24l5.46 4.73L3.82 19 10 15.27z' fill='#{$icon__active-color}'/></svg>");
            background-repeat: repeat-x;
            background-position: left center;
            background-size: $rating__item-size;
            text-indent: 10000px;

            @include isIE() {
                width: 100%;
                height: 100%;
                background-size: $rating__item-size 100%;
            }

            .rating__star--rate & {
                display: none;
            }
            .rating__star--rate:hover &,
            .rating__star--rate.focus-visible &,
            .rating__rate-item--active & {
                display: block;
            }
        }
        &:after { //active star with border
            content: '';
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: $rating__item-size;
            background-image: svg-uri("<svg viewBox='0 0 20 19' xmlns='http://www.w3.org/2000/svg'><path fill='none' d='M-2-2h24v24H-2z'/><path d='M10 15.27L16.18 19l-1.64-7.03L20 7.24l-7.19-.61L10 0 7.19 6.63 0 7.24l5.46 4.73L3.82 19 10 15.27z' fill='#{$icon__active-color--with-border}'/></svg>");
            background-repeat: repeat-x;
            background-size: $rating__item-size;
            background-position: left center;

            @include isIE() {
                width: 100%;
                height: 100%;
                background-size: $rating__item-size 100%;
            }

            .rating__star--rate & {
                display: none;
                text-indent: 10000px;
            }
        }
    }

    &__error {
        display: none;
        color: $rating__error-color;
        margin-bottom: $rating__error-margin-bottom;

        &--visible {
            display: block;
        }
    }

    .mage-error {
        display: none !important; // sass-lint:disable-line no-important
    }
    &--rate {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $rating__size--rate;
        margin-bottom: $rating__margin--rate;

        .rating__rate-item {
            flex: 1 0 $rating__item-size--rate;
        }

        .rating__star {
            height: $rating__item-size--rate;
            width: $rating__item-size--rate;
            border: 0;
            background-color: transparent;
            cursor: pointer;

            &:before {
                width: $rating__item-size--rate;
                height: $rating__item-size--rate;
                background-size: $rating__item-size--rate - 10;
                background-position: center;
                background-repeat: no-repeat;

                @include isIE() {
                    background-size: $rating__item-size--rate - 10 100%;
                }
            }

            &--single {
                width: $rating__item-size--rate;
            }
        }
        .radio__field {
            width: 1px;
            height: 1px;
            bottom: 0;
            &.focus-visible {
                opacity: 1;
                height: 0;
                outline: none;
                appearance: none;
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -$rating__item-size--rate;
                    width: $rating__item-size--rate;
                    height: $rating__item-size--rate;
                    border: $border-focus;
                }
            }
        }

        .rating__indicator {
            height: $rating__item-size--rate;
            width: $rating__item-size--rate;

            &:before {
                height: $rating__item-size--rate;
                background-size: $rating__item-size--rate - 10;
                background-position: center;
                background-repeat: no-repeat;
            }

            &:after {
                height: $rating__item-size--rate;
                background-size: $rating__item-size--rate - 10;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
}
