.trustpilot-star-rating {

    .tp-stars {
        display: inline-block;

        .tp-star {
            width: 17px;
            height: 17px;

            .tp-star__canvas {
                fill: #d6d6d6;
            }

            .tp-star__corner {
                fill: darken(#d6d6d6, 25%);
            }
        }
    }
}
