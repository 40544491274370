$home-background-color                  : $gray-lightest !default;
$home-background-color--accent          : $color-light !default;
$home__banner-margin                    : 20px auto !default;
$home__banner-margin-\@large            : 40px auto !default;
$home__static-block-padding              : 50px 0 20px !default;
$home__featured-categories-border       : $base-fat-border !default;

$self : '.home';

#{$self} {
    background: $home-background-color;

    @include mq($screen-m) {
        background: $home-background-color--accent;
    }

    .usp-bar {
        display: block;

        @include mq($screen-m) {
            display: none;
        }
    }

    &__banner-content {
        margin: $home__banner-margin;

        @include mq($screen-m) {
            margin: $home__banner-margin-\@large;
        }
    }

    &__static-block {
        display: none;

        @include mq($screen-m, $screen-l) {
            border-bottom: 1px solid $gray-light;
        }

        @include mq($screen-m) {
            display: block;
            padding: $home__static-block-padding;
        }
    }

    &__cms-block-title {
        display: none;

        @include mq($screen-m) {
            display: block;
            color: $static__text-heading-color;
        }
    }

    &__link-list {
        display: block;

        @include mq($screen-m) {
            display: none;
        }
    }

    &__featured-categories {
        background: $home-background-color--accent;
        border-top: $home__featured-categories-border;
        border-bottom: $home__featured-categories-border;
        padding: 30px 0;
        width: 100%;

        @include mq($screen-m) {
            background: $home-background-color;
        }
    }

    &__payment-logos,
    &__need-help {
        display: none;
        margin-bottom: 20px;

        @include mq($screen-m) {
            display: block;
            padding: 0 0 0 $spacer--container;

            .need-help {
                border: $border-base;
                width: 100%;
            }
        }

        @include mq($screen-l) {

            .need-help {
                padding: 30px;
            }
        }
    }

    // ---------------- Extends to style content based on migrated data. ---------------- //

    .featured-categories-wrapper {
        @extend #{$self}__featured-categories;
        width: 100vw;
        margin: 0 -#{$global-container-margin};

        @include mq(#{$max-content-width + 2 * $global-container-margin}) {
            margin: 0 calc((100vw - #{$max-content-width}) / -2);
        }
    }

    .page-content {
        display: none;

        @include mq($screen-m) {
            display: block;
            padding: 10px 0 20px;
        }
    }

    .container.ambanners {
        padding-top: 25px;
        margin-left: 0;
        margin-right: 0;
    }


    .ambanner-11 {
        img {
            margin-bottom: 20px;
        }
    }
}
