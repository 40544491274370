.paypal-review {
  &__organisation-type {
    margin-bottom: 20px;
  }

  &__shipping-address {
    padding: 20px 30px;
    margin-bottom: 20px;
    border: $border-base;
    border-radius: $border-radius;

    @include mq($screen-m) {
      width: 400px;
    }

    span {
      display: block;
      margin: 5px 0;
    }
  }

  &__edit-link {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }

  &__shipping-method {
    margin-top: 20px;
    padding: 30px 0;
    border-top: $border-base;
    border-bottom: $border-base;
  }

  .opc-progress-bar > hr {
    width: 45%;

    @include mq($screen-m) {
      width: 80%;
    }
  }
}
