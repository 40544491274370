$newsletter__field-width                        : 310px !default;
$newsletter__field-height                       : 50px !default;
$newsletter__field-border                       : none !default;
$newsletter__field-border-radius                : 5px !default;
$newsletter__field-padding                      : 0 $spacer--input !default;
$newsletter__line-height                        : 1.2 !default;
$newsletter__input-background                   : $white !default;
$newsletter__input-color                        : $gray !default;
$newsletter__input-color--placeholder           : $gray !default;
$newsletter__heading-color                      : $white !default;
$newsletter__controls-height                    : 32px !default;
$newsletter__bottom-gap                         : $spacer--medium !default;
$newsletter__button-padding                     : 21px !default;
$newsletter__button-width                       : 153px !default;
$newsletter__button-height                      : 50px !default;
$newsletter__button-color                       : none !default;
$newsletter__button-border-bottom               : none !default;
$newsletter__heading-margin                     : 0 20px 35px 0 !default;
$newsletter__heading-font-weight                : $font-weight-semi-bold !default;
$newsletter__heading-font-size                  : $font-size-large !default;

$newsletter--sidebar__heading-color             : $color-text-accent !default;
$newsletter--sidebar__heading-color2            : $color-light !default;
$newsletter--sidebar__heading-background        : $color-primary !default;
$newsletter--sidebar__font-size                 : $font-size-small !default;
$newsletter--sidebar__font-size\@large          : $font-size-base !default;
$newsletter--sidebar__font-weight               : $font-weight-bold !default;

$self : '.newsletter';

#{$self} {
    &__heading {
        line-height: $newsletter__line-height;
        color: $newsletter__heading-color;
        height: auto;
        text-align: left;
        margin: $newsletter__heading-margin;

        &--third-level {
            color: $newsletter__heading-color;
            font-weight: $newsletter__heading-font-weight;
            font-size: $newsletter__heading-font-size;
        }
    }
    &__controls {
        position: relative;
        height: auto;
        margin-bottom: $newsletter__bottom-gap;
        font-size: 0;
    }
    &__button {
        vertical-align: top;
        min-width: $newsletter__button-width;
        height: $newsletter__button-height;
        background-color: $newsletter__button-color;
        border-bottom: $newsletter__button-border-bottom;

        &:hover {
            cursor: pointer;
            outline: none;
        }
        &:focus {
            @include focus();
        }
    }
    &__input {
        margin: 0 5px 0 0;
        display: inline-block;
        font-size: $font-size-base;
    }
    &__label {
        @include visually-hidden;
    }
    &__field {
        background: $newsletter__input-background;
        padding: $newsletter__field-padding;
        margin: 0;
        border: $newsletter__field-border;
        border-radius: $newsletter__field-border-radius;
        width: $newsletter__field-width;
        height: $newsletter__field-height;
        color: $newsletter__input-color;
        line-height: $newsletter__field-height;
        &::placeholder {
            color: $newsletter__input-color--placeholder;
        }
        &:focus {
            @include focus-input();
        }
    }

    &--sidebar {
        padding: 0 $spacer--medium;

        @include mq($screen-xl) {
            padding: 0 $spacer--container;
        }

        #{$self}__input {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        #{$self}__heading {
            display: flex;
            flex-flow: row wrap;
            margin-bottom: 10px;
            color: $newsletter--sidebar__heading-color;

            &--third-level {
                flex-basis: 100%;
                line-height: 35px;
                padding: 0 $spacer--medium;
                color: $newsletter--sidebar__heading-color2;
                background: $newsletter--sidebar__heading-background;
                font-size: $newsletter--sidebar__font-size\@large;
                font-weight: $newsletter--sidebar__font-weight;
            }

            span {
                display: inline-block;
                flex-basis: 100%;
                padding: $spacer--medium;
                font-size: $newsletter--sidebar__font-size;

                @include mq($screen-xl) {
                    font-size: $newsletter--sidebar__font-size\@large;
                }
            }
            
        }

        #{$self}__field {
            border: $border-base;
            border-radius: 5px 0 0 5px;
            height: 45px;
            width: 80%;

            @include mq($screen-xl) {
                width: 85%;
            }
        }

        #{$self}__button {
            min-width: 0;
            width: 20%;
            height: 45px;
            border-radius: 0 5px 5px 0;

            @include mq($screen-xl) {
                width: 15%;
            }
        }
    }
}
