$responsive-video__padding        : 10px !default;
$responsive-video__padding\@large : 20px !default;

$responsive-video__floats: (left: right, right: left);
$responsive-video__widths: 25, 50, 75, 100;
$responsive-video__aspect-ratios: (4_3: (3/4), 16_9: (9/16), 21_9: (9/21));
.responsive-video {
  margin-bottom: $responsive-video__padding;

  @include mq($screen-l) {
    margin-bottom: $responsive-video__padding\@large;
  }

  &.lazy-youtube-video {
    position: relative;

    width: 100%;

    @each $size, $paddingTop in $responsive-video__aspect-ratios {
      &.aspect-#{$size} {
        padding-top: percentage($paddingTop);
      }
    }

    @include mq($screen-l) {
      @each $width in $responsive-video__widths {
        &.width-#{$width} {
          width: percentage($width / 100);

          @each $size, $paddingTop in $responsive-video__aspect-ratios {
            &.aspect-#{$size} {
              padding-top: percentage($paddingTop * ($width / 100));
            }
          }
        }
      }

      @each $float, $marginPosition in $responsive-video__floats {
        &.float-#{$float} {
          float: #{$float};
          margin-#{$marginPosition}: $responsive-video__padding\@large;
        }
      }
    }

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
