$dashboard-actions-toolbar__margin             : 0 !default;
$dashboard-actions-toolbar__item-margin        : $spacer 0 0 0 !default;
$dashboard-actions-toolbar__item-margin\@medium: 0 $spacer 0 0 !default;

.dashboard-actions-toolbar {
    display: flex;
    flex-direction: column;
    margin: $dashboard-actions-toolbar__margin;

    @include mq($screen-m) {
        flex-direction: row;
    }

    &__item {
        margin: $dashboard-actions-toolbar__item-margin;

        @include mq($screen-m) {
            margin: $dashboard-actions-toolbar__item-margin\@medium;
        }
    }
}
