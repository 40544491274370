$email-to-friend__max-width                   : 500px !default;
$email-to-friend__sender-wrapper-margin-bottom: $spacer--medium !default;
$email-to-friend__fieldset-margin-bottom      : $spacer--large !default;
$email-to-friend__inner-fieldset-margin-top   : $spacer--large !default;
$email-to-friend__remove-margin-bottom        : $spacer--medium !default;
$email-to-friend__back-margin-left            : $spacer--medium !default;
$email-to-friend__submit-margin-bottom        : $spacer--large !default;

.email-to-friend {
    max-width: $email-to-friend__max-width;

    &__sender-wrapper {
        margin-bottom: $email-to-friend__sender-wrapper-margin-bottom;
    }

    &__fieldset {
        margin-bottom: $email-to-friend__fieldset-margin-bottom;
    }

    &__inner-fieldset {
        margin-top: $email-to-friend__inner-fieldset-margin-top;
    }

    &__options {
        .fields {
            &:not(.additional) {
                .email-to-friend__inner-fieldset {
                    margin-top: 0;
                }
            }
        }
    }

    &__message {
        resize: vertical;
    }

    &__remove {
        margin-bottom: $email-to-friend__remove-margin-bottom;
    }

    &__back {
        margin-left: $email-to-friend__back-margin-left;
        cursor: pointer;
    }

    &__submit {
        margin-bottom: $email-to-friend__submit-margin-bottom;
    }
}
