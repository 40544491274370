$self : '.static-text';

#{$self} {

    & h1 {
        color: $static__text-heading-color;
        font-weight: $static__text-heading-font-weight;
        font-size: 20px;
    }

    & h2 {
        color: $static__text-heading-color;
        font-weight: $static__text-heading-font-weight;
        font-size: 18px;
    }

    & h3 {
        color: $static__text-heading-color;
        font-weight: $static__text-heading-font-weight;
        font-size: 16px;
        margin-top: $global-container-margin;
    }

    & h4 {
        color: $static__text-heading-color;
        font-weight: $static__text-heading-font-weight;
    }

    & a:not(.button) {
        font-weight: $static__text-link-font-weight;

        &:hover,
        &:active,
        &:focus {
            color: $font-color-link-default;
            background-color: transparent;
            text-decoration: underline;
            font-weight: $static__text-link-font-weight;
        }
    }

    & hr {
        margin: 10px -#{$global-container-margin};
        border: none;
        border-top: 1px solid $gray-light;
    }

    & ul {
        list-style: none;
        padding: 0;

        & li {
            line-height: 22px;

            &:before {
                content: '';
                float: left;
                background: svg-uri($ios-checkmark-primary-svg) center no-repeat;
                margin: 0;
                width: 26px;
                height: 21px;
            }
        }
    }

    & button {
        @extend .button;
    }

    & table {
        border-collapse: collapse;
    }
}

// ---------------- Extends to style content based on migrated data. ---------------- //

.page-content {
    @extend #{$self};
}

.cms-page-view:not([class*="-fixtures"]) {
    .page-main.container {
        @extend #{$self};
    }

    .block-static-block:not(&.row) {

        [class*='col-xs-'],
        [class*='col-sm-'],
        [class*='col-md-'],
        [class*='col-lg-'],
        [class*='col-xl-'] {
            display: inline-block;
        }

        .col-xs-12 {
            width: 100%;
        }

        @include mq($screen-s) {
            .col-sm-4 {
                width: calc((100% / 3) - 3px);
            }
        }
    }
}
