.cms-no-route {

    .main {

        img {
            float: left;
            width: 115px;
            margin: 40px 40px 0 0;
        }

        .box {
            display: block;
            padding-left: 200px;
            width: calc(100% - 117px);

            h1 {
                font-size: 26px;
                font-weight: $font-weight-bold;
                color: $color-primary--accent-5;
            }

            p {
                margin-bottom: 20px;
                color: $color-primary--accent-5;
            }

            ul {
                list-style: none;
                margin-bottom: 20px;
                color: $color-primary--accent-5;
            }
        }
    }
}
