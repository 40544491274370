$featured-info__margin            : 8px 16px !default;
$featured-info__title-font-size   : $font-size-small !default;
$featured-info__title-color       : $font-color-base !default;
$featured-info__subtitle-font-size: 10px !default;
$featured-info__subtitle-color    : $gray !default;
$featured-info__icon-color        : $color-primary !default;

.featured-info {
    display: flex;
    margin: $featured-info__margin;
    color: $featured-info__title-color;
    text-decoration: none;
    &.focus-visible,
    &:hover {
        text-decoration: none;
    }

    &__icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 8px;

        &-svg {
            width: 24px;
            height: 24px;
            fill: $featured-info__icon-color;
            &--delivery-truck {
                width: 35px;
                height: 20px;
            }
        }
    }

    &__data {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-title {
            margin-bottom: 4px;
            font-weight: $font-weight-bold;
            font-size: $featured-info__title-font-size;
            line-height: $featured-info__title-font-size;
        }

        &-subtitle {
            color: $featured-info__subtitle-color;
            font-size: $featured-info__subtitle-font-size;
            line-height: $featured-info__subtitle-font-size;
        }
    }
}
