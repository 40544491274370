$dashboard-nav-padding: 15px !default;
$dashboard-nav-margin : 15px !default;

$dashboard-nav-link-item-color: $color-text-accent !default;

.dashboard-nav {

    @include mq(false, $screen-m) {
        display: none;

        &.mobile-open {
            display: block;
            position: fixed;
            left: 0;
            bottom: 75px;
            width: 100vw;
            z-index: 20;
            margin-bottom: 0;
            padding: 20px;
            background: #fff;
            border-top: 2px solid $color-primary;

            + .mobile-account-menu {

                &__fade {
                    display: block;
                }
            }
        }

        &__list {
            margin-bottom: 4px !important;
        }

        &__head {
            margin: 2px 0;
        }
    }

    margin-bottom: $dashboard-nav-margin;

    &__content {
        display: none;
        color: $color-primary;
        padding: $dashboard-nav-padding;
        margin-bottom: $dashboard-nav-margin;

        &--visible {
            display: block;
        }

        @include mq($screen-m) {
            display: block;
        }
    }

    &__list {
        display: none;
        height: 0;
        margin: 0;
        padding-left: 20px;
        overflow: hidden;
        transition: height 0.25s cubic-bezier(0.4, 0, 1, 1);

        li {
            &:hover {
                span {
                    color: $color-primary;
                }
            }
            &.active {
                span {
                    color: $color-primary;
                }
            }
        }
    }

    &__head {
        padding: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: $color-text-accent;

        &:hover {
            color: $color-primary;
        }

        &.active {
            font-weight: bold;
            border-left: 2px solid $color-primary;
            color: $color-primary;
        }

        svg {
            width: 25px;
            height: 25px;
            margin-right: 10px;
            filter: $dashboard-nav-link-item-filter;
        }

        &.pro {
            svg {
                width: 35px;
                margin-left: -10px;
                filter: none;
            }

            &.active {
                svg {
                    margin-left: 0;
                }
            }
        }

        &.logout {
            margin-top: 5px;
        }
    }

    &__item {
        padding: 0;
        transition: $transition-base;
    }

    &__title {
        .account-page & {
            color: #fff;
        }
        background-color: $color-primary;
        font-weight: normal;
        font-size: 14px;
        padding: 7px 12px;
        border-radius: 5px;
        margin: 0 0 7px;
    }

    &__item > a,
    &__link {
        display: block;
        padding: 7px;
        margin-left: 5px;
        color: $dashboard-nav-link-item-color;
    }

    // This class is changing by Magento
    .current {
        position: relative;
        padding: 5px;
        margin-left: 7px;
        color: $color-primary;

        &:hover {
            background-color: initial;
        }

        & > a {
            padding: 0;
        }
    }

    // This clases to disabled links module
    .nav.item {
        @extend .list__item;
        @extend .dashboard-nav__item;

        a[href*="/downloadable/customer/products/"] {
            display: none;
        }
    }

    .nav.item.current {
        @extend .current;
    }
}

.mobile-account-menu {
    position: fixed;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    padding: 15px 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    background: #fff;
    border-top: 1px solid #b9b9b9;

    @include mq($screen-m) {
        display: none;
    }

    &__item {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        flex-basis: 25%;
        gap: 4px;
        padding: 0 8px;
        border-right: $border-base;

        &:last-child {
            border-right: none;
        }

        svg {
            width: 25px;
            height: 25px;
            padding: 0;
            color: $color-primary;
            filter: $dashboard-nav-link-item-filter;
        }

        span {
            flex-basis: 100%;
            text-align: center;
            font-size: 10px;
            color: $color-text-accent;
        }

        a {
            color: $color-text-accent;
        }
    }

    &__fade {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 8;
        background: #000;
        opacity: 0.5;
    }
}