$forgot-password__max-width                    : 500px !default;
$forgot-password__margin-bottom                : $spacer--extra-large !default;
$forgot-password__field-margin-bottom          : $spacer--medium !default;
$forgot-password__captcha-field-width          : 50% !default;
$forgot-password__captcha-wrapper-margin-bottom: $spacer--medium !default;
$forgot-password__image-width                  : auto !default;
$forgot-password__image-max-width              : 300px !default;
$forgot-password__image-padding-right          : $spacer !default;
$forgot-password__strength-meter-margin-bottom : $spacer--medium !default;

.customer-account-forgotpassword {

    .heading--page {
        text-transform: none;
    }
}

.forgot-password {
    @extend .account-page;

    max-width: $forgot-password__max-width;
    margin-bottom: $forgot-password__margin-bottom;

    &__email,
    &__captcha-field {
        margin-bottom: $forgot-password__field-margin-bottom;
    }

    &__captcha-field {
        width: $forgot-password__captcha-field-width;
    }

    &__captcha-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: $forgot-password__captcha-wrapper-margin-bottom;
    }

    &__image {
        padding-right: $forgot-password__image-padding-right;
        width: $forgot-password__image-width;
        max-width: $forgot-password__image-max-width;
    }

    &__strength-meter {
        margin-bottom: $forgot-password__strength-meter-margin-bottom;
    }
}
