.einvoice-profile-edit,
.customer-account-create {
    .account-page .e-invoice-form {
        .e-invoicing-checkbox {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            input[type="checkbox"] {
                height: 20px;
                width: unset;
                margin-right: 5px;
                position: relative;
                top: 1px;
            }

            label {
                margin-bottom: 0;
                font-size: 14px;
            }

            .tooltip {
                flex-basis: 100%;
                margin-top: 10px;
            }
        }

        .e-invoice-field-group {
            .note {
                display: none;
            }
        }

        .legend > span {
            color: $color-text-default;
            font-weight: 600;
            line-height: 1.5;
            margin: 10px 0;
            font-size: 21px;
            display: block;
        }
    }
}
.sales-order-view {
    .account-page {
        #my-orders-table {
            .dispatch-status {
                display: block;

                + .button {
                    display: inline-block;
                    vertical-align: middle;
                    height: auto;
                    padding: 6px 10px;
                    font-weight: 600;
                    font-size: 12px;
                    margin-left: 5px;

                    @include mq($screen-m) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}