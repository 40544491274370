$category-links-border-radius       :   22px !default;
$category-links-border              :   none !default;
$category-links-background          :   $gray-light !default;
$category-links-background--hover   :   $gray-light !default;
$category-links-color--hover        :   $link-_color-hover !default;
$category-links-padding             :   12px 15px !default;

.category-links {
    margin: 10px 0 25px 0;
    color: $color-text-accent;

    &--with-fade {
        @include mq(false, $screen-m) {
            position: relative;

            &:before {
                z-index: 100;
                right: -2px;
                top: 0;
                height: 100%;
                content: ' ';
                position: absolute;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 2%, #ffffff);
                width: 50px;
                pointer-events: none;
            }
        }
    }

    &--no-margin {
        margin: 0;
    }

    &__heading {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 900;
    }

    &__item {
        list-style: none;
        font-size: 14px;
        white-space: nowrap;

        &:not(:last-child) {
            margin-right: 10px;
        }

        &--slider {
            flex-basis: 204px;
            flex-grow: 0;
            flex-shrink: 0;

            @include mq(false, $screen-m) {
                border: 1px solid $gray-light;
                border-radius: 5px;
                padding: 10px;

                &:last-child {
                    margin-right: 30px;
                }
            }
        }
    }

    &__image {
        width: auto;
        height: auto;
        max-height: 120px;
        max-width: inherit;
        margin: 0 auto;
        margin-bottom: 5px;

        @include mq(false, $screen-m) {
            max-height: 85px;
        }
    }

    &.category-slider {
        position: relative;
        @include mq(false, $screen-m){
            display: none;
        }

        @include mq($screen-m) {
            .glider-prev,
            .glider-next {
                top: 50%;
                padding: 20px 10px;
                background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16"><path fill-rule="evenodd" fill="#{$white}" d="M2.33 0L.45 1.88 6.55 8l-6.1 6.12L2.33 16l8-8z"/></svg>') no-repeat center;
                background-size: 11px auto;
                background-color: $color-primary;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            .glider-prev {
                left: 0;
                transform: translateY(-50%) rotate(180deg);
            }

            .glider-next {
                right: 0;
                transform: translateY(-50%);
            }
        }
    }
}

.product-view .category-links {
    &__list {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0 0 8px;
        column-gap: 32px;
        border-bottom: 1px solid #ccc;
        float: left;
        width: 100%;

        &.glider {
            @include mq($screen-m) {
                margin: 0 25px 10px 25px;
            }
        }
    }

    &__link {
        padding: 0;
        text-decoration: none;

        &:hover {
            background: none;
            color: $color-primary;
            text-decoration: underline;
            text-decoration-thickness: from-font;
        }

        &--full-width {
            display: block;
            text-align: center;
            padding: 8px 15px;
            overflow: hidden;

            @include mq(false, $screen-m) {
                padding: 0;
                background-color: inherit;
                border: none;
            }
        }
    }
}

.category-view .category-links {
    &__list {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 10px 0;
        margin: 0 0 10px;

        &.glider {
            @include mq($screen-m) {
                margin: 0 25px 10px 25px;
            }
        }
    }
    &__link {
        padding: $category-links-padding;
        background-color: $category-links-background;
        border-radius: $category-links-border-radius;
        border: $category-links-border;
        color: $color-text-accent;
        text-decoration: none;

        &:hover {
            background: $category-links-background--hover;
            color: $category-links-color--hover;
        }

        &--full-width {
            display: block;
            text-align: center;
            padding: 8px 15px;
            overflow: hidden;

            @include mq(false, $screen-m) {
                padding: 0;
                background-color: inherit;
                border: none;
            }
        }
    }
}
