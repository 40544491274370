.row {
    margin: 0 auto;

    &--space-between {
        justify-content: space-between;
    }
}

[class*='col-xs-'],
[class*='col-sm-'],
[class*='col-md-'],
[class*='col-lg-'],
[class*='col-xl-'] {
    padding-left: 0;
    padding-right: 0;
}

@include mq(768px) {
    .show-sm {
        display: none !important;
    }
}

@include mq(false, $screen-m) {
    .show-md {
        display: none !important;
    }
}

@include mq(false, $screen-l) {
    .show-lg {
        display: none !important;
    }
}

@include mq($screen-m) {
    .hide-md {
        display: none !important;
    }
}

@include mq($screen-l) {
    .hide-lg {
        display: none !important;
    }
}
