$featured-categories-items-per-row                          : 1 !default;
$featured-categories-items-per-row-\@medium                 : 3 !default;
$featured-categories-item-margin                            : 0 0 20px 0 !default;
$featured-categories-item-margin-\@medium                   : 0 10px 20px 10px !default;
$featured-categories-item-padding                           : 0 !default;
$featured-categories-item-wrapper-padding                   : 5px;
$featured-categories-item-wrapper-padding-\@medium          : 10px;
$featured-categories-item-wrapper-padding-\@large           : 20px;
$featured-categories-item-link-color                        : $font-color-link-accent;
$featured-categories-item-link-color-hover                  : $font-color-link-accent;
$featured-categories-item-image-size                        : 52px !default;
$featured-categories-item-image-size-\@large                : 90px !default;
$featured-categories-item-image-margin-left-right           : 11px !default;
$featured-categories-text-font-weight                       : $font-weight-semi-bold !default;
$featured-categories-text-font-size                         : 14px !default;
$featured-categories-text-font-size-\@medium                : 12px !default;
$featured-categories-text-font-size-\@large                 : 14px !default;
$featured-categories-text-icon-fill                         : $color-primary !default;
$featured-categories-text-icon-size                         : 13px !default;

.featured-categories {

    .list {
        display: flex;
        flex-flow: row wrap;
    }

    .list__item {
        flex-basis: 100%;
        margin: $featured-categories-item-margin;
        padding: $featured-categories-item-padding;

        &:last-child {
            margin-bottom: 0;
        }

        @include mq($screen-m) {
            margin: $featured-categories-item-margin-\@medium;
        }

        @include mq($screen-m, #{$screen-l - 1}) {
            $num-row-m : $featured-categories-items-per-row;
            flex-basis: calc((100% / #{$num-row-m}) - (#{$featured-categories-item-wrapper-padding-\@large} * (#{$num-row-m} - 1) / #{$num-row-m} ));

            &:nth-child(1),
            &:nth-child(#{$num-row-m}n + #{$num-row-m + 1}) {
                margin-left: 0;
            }

            &:nth-child(#{$num-row-m}n + #{$num-row-m}) {
                margin-right: 0;
            }
        }

        @include mq($screen-l) {
            $num-row-l : $featured-categories-items-per-row-\@medium;
            flex-basis: calc((100% / #{$num-row-l}) - (#{$featured-categories-item-wrapper-padding-\@large} * (#{$num-row-l} - 1) / #{$num-row-l} ));
            margin-bottom: 0;

            &:nth-child(1),
            &:nth-child(#{$num-row-l}n + #{$num-row-l + 1}) {
                margin-left: 0;
            }

            &:nth-child(#{$num-row-l}n + #{$num-row-l}) {
                margin-right: 0;
            }
        }
    }
}

// ---------------- Extends to style content based on migrated data. ---------------- //

.featured-categories {
    @extend .list;
    display: flex;
    flex-flow: row wrap;
    max-width: $max-content-width;
    width: 100%;
    margin: 0 auto;
    padding: 0 $global-container-margin;

    @include mq(#{$max-content-width + 2 * $global-container-margin}) {
        padding: 0;
    }

    li {
        @extend .list__item;
        @extend .featured-category;

        &.orange {
            @extend .featured-category--cta;
        }
    }

    a {
        @extend .featured-category__content;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            right: -40px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border: 2px solid $color-light;
            color: $color-light;
            border-radius: 50%;
            background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16"><path fill-rule="evenodd" fill="#fff" d="M2.33 0L.45 1.88 6.55 8l-6.1 6.12L2.33 16l8-8z"/></svg>') no-repeat center;
        }
    }

    .title {
        @extend h2;
    }
}
