//
//  Responsive Classes
//  _____________________________________________

@include mq($max-screen: $screen-m - 1) {
    [data-content-type='row'][data-appearance='contained'] {
        [data-element='inner'] {
            background-attachment: scroll !important; // sass-lint:disable-line no-important
        }
    }
}

//
//  Responsive Classes
//  _____________________________________________

@include mq($max-screen: $screen-m - 1) {
    [data-content-type='row'][data-appearance='full-bleed'] {
        background-attachment: scroll !important; // sass-lint:disable-line no-important
    }
}

//
//  Full Width row appearance styles
//  ________________________________

[data-content-type='row'][data-appearance='full-width'] {
    > .row-full-width-inner {
        margin-left: auto;
        margin-right: auto;
        max-width: $max-content-width;
        width: 100%;
    }
}

//
//  Responsive Classes
//  _____________________________________________

@include mq($max-screen: $screen-m - 1) {
    [data-content-type='row'][data-appearance='full-width'] {
        background-attachment: scroll !important; // sass-lint:disable-line no-important
    }
}

