$search-form__height            : 34px !default;
$search-form__width             : 100% !default;
$search-form__width--extra-large: 70% !default;
$search-form__alignment         : flex-end !default;
$search-form__margin            : 0 !default;
$search-form__border            : 1px solid $gray-light !default;
$search-form__font-size         : $font-size-small !default;

$search-form__input-height      : 32px !default;

$search-form__button-size       : 18px !default;
$search-form__button-margin     : 0 !default;
$search-form__button-border     : none !default;
$search-form__button-background : transparent !default;
$search-form__box-shadow        : none !default;
$search-form__button-icon-color : $gray-dark !default;
$search-form__button-fill-hover : $color-primary !default;
$search-form__top               : -1px !default;
$search-form__right             : 0 !default;

$search-form__placeholder-color : $color-text-accent-2 !default;

.search-form {
    position: relative;
    width: $search-form__width;
    height: $search-form__height;
    align-self: $search-form__alignment;
    margin: $search-form__margin;
    border: $search-form__border;
    border-radius: $search-form__height;
    z-index: 1;

    &__input-wrapper {
        position: relative;
    }

    &__input {
        width: 100%;
        height: $search-form__input-height;
        border: none;
        border-radius: $search-form__height;
        font-size: $search-form__font-size;

        &::placeholder {
            color: $search-form__placeholder-color;
            font-size: $search-form__font-size;
        }
    }

    &__button {
        position: absolute;
        top: $search-form__top;
        right: $search-form__right;
        height: calc(#{$search-form__input-height} + 2px);
        margin: $search-form__button-margin;
        border: $search-form__button-border;
        border-radius: $search-form__height;
        background-color: $search-form__button-background;
        box-shadow: $search-form__box-shadow;
        z-index: 1;

        &:focus,
        &:hover {
            background-color: $search-form__button-background;

            .button__icon {
                fill: $search-form__button-fill-hover;
            }
        }
    }

    .button__icon {
        width: $search-form__button-size;
        height: $search-form__button-size;
        fill: $search-form__button-icon-color;
    }
}
