$wishlist__item-margin-bottom    : $spacer--medium !default;
$wishlist__item-padding          : 0 $spacer--medium !default;
$wishlist__price-padding         : 0 $spacer $spacer !default;
$wishlist__price-margin          : 0 !default;
$wishlist__price-font-weight     : $font-weight-bold !default;
$wishlist__name-padding          : $spacer $spacer 0 $spacer !default;
$wishlist__name-margin           : 0 !default;
$wishlist__name-link-color       : $gray-darkest !default;
$wishlist__name-link-font-size   : $font-size-small !default;
$wishlist__name-link-line-height : 1.5 !default;
$wishlist__options-margin        : 0 $spacer !default;
$wishlist__inner-padding         : $spacer 0 !default;
$wishlist__inner-padding\@medium : $spacer--medium 0 !default;
$wishlist__label-margin          : 0 $spacer 0 0 !default;
$wishlist__qty-min-width         : 120px !default;
$wishlist__qty-max-width         : 200px !default;
$wishlist__field-qty-margin-right: $spacer !default;
$wishlist__field-qty-text-align  : right !default;
$wishlist__actions-margin-bottom : $spacer !default;
$wishlist__to-cart-flex-basis    : 100% !default;
$wishlist__to-cart-padding-top   : $spacer !default;
$wishlist__button-width          : 100% !default;
$wishlist__button-margin-bottom  : 0% !default;
$wishlist__remove-margin-left    : $spacer !default;
$wishlist__comment-margin        : 0 !default;
$wishlist__action-padding-top    : $spacer--medium !default;

.wishlist {
    &__grid {
        justify-content: flex-start;
    }

    &__item {
        margin-bottom: $wishlist__item-margin-bottom;
        @include mq($screen-s) {
            padding: $wishlist__item-padding;
        }

        .price-box {
            padding: $wishlist__price-padding;
            font-weight: $wishlist__price-font-weight;
        }

        .price-as-configured {
            margin: $wishlist__price-margin;
            font-weight: $wishlist__price-font-weight;
        }
    }

    &__link {
        display: block;
    }

    &__item-name {
        display: block;
        padding: $wishlist__name-padding;
        margin: $wishlist__name-margin;
    }

    &__item-name-link {
        display: block;
        color: $wishlist__name-link-color;
        font-size: $wishlist__name-link-font-size;
        line-height: $wishlist__name-link-line-height;
    }

    &__options {
        margin: $wishlist__options-margin;
    }

    &__item-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: $wishlist__inner-padding;

        @include mq($screen-m) {
            padding: $wishlist__inner-padding\@medium;
        }
    }

    &__label {
        margin: $wishlist__label-margin;
    }

    &__qty {
        flex: 1;
        min-width: $wishlist__qty-min-width;
        max-width: $wishlist__qty-max-width;
    }

    &__field-qty {
        margin-right: $wishlist__field-qty-margin-right;
        text-align: $wishlist__field-qty-text-align;
    }

    &__item-actions {
        order: 2;
        margin-bottom: $wishlist__actions-margin-bottom;

        &--to-cart {
            order: 3;
            flex-basis: $wishlist__to-cart-flex-basis;
            padding-top: $wishlist__to-cart-padding-top;
        }
    }

    &__button {
        width: $wishlist__button-width;
        margin-bottom: $wishlist__button-margin-bottom;
    }

    &__remove {
        margin-left: $wishlist__remove-margin-left;
    }

    &__comment {
        margin: $wishlist__comment-margin;
    }

    &__action {
        padding-top: $wishlist__action-padding-top;
    }
}
