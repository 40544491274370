$cross-sell-product-item-border            : $border-base !default;
$cross-sell-product-item-background        : $color-light !default;
$cross-sell-product-item__image-width      : 115px !default;

.cross-sell-product-item {
    padding: 20px;
    border-radius: $border-radius;
    background: $cross-sell-product-item-background;
    border: $cross-sell-product-item-border;

    &__image {
        margin: 0 auto 10px;
        width: $cross-sell-product-item__image-width;
    }

    &__name {
        flex-basis: 100%;
    }

    &__link {
        color: $color-text-accent;
        font-weight: $font-weight-semi-bold;

        &:hover,
        &:active,
        &:focus {
            color: $color-text-accent;
            font-weight: $font-weight-semi-bold;
        }
    }

    &__details {
        display: flex;
        flex-flow: row wrap;

        .price-box {
            padding: 5px 0;

            @include mq($screen-m) {
                padding: 15px 0;
            }
        }
    }

    &__cta {
        flex-basis: 100%;

        .add-to-cart-button__primary-button {
            width: 100%;

            @include mq(false, $screen-l) {
                & span {
                    display: none;
                }
            }
        }
    }
}
