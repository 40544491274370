$sticky-header-top                      : 100px !default; // Header height minus 1 px. -> changed to match the PNG logo
$sticky-product-header-height           : 80px !default;
$sticky-product-header-background       : $color-light !default;
$sticky-product-header-box-shadow       : 0 2px 10px -6px rgba(0, 0, 0, 0.5) !default;
$sticky-product-header-box-shadow\ie    : 0 2px 10px -3px rgba(0, 0, 0, 0.5) !default;

.sticky-product-header {
    position: absolute;
    transition: all 0.3s ease-in-out;
    transform: translateY(-100%);
    opacity: 0;
    z-index: -1;
    width: 100%;
    background: $sticky-product-header-background;
    box-shadow: $sticky-product-header-box-shadow;

    &--fixed {
        opacity: 1;
        transform: translateY(0);
    }

    &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        max-height: 80px;
    }

    &__product {
        flex-basis: 50%;

        @include mq($screen-l) {
            flex-basis: 40%;
        }

        .inline-product-item {
            padding: 5px 0;
        }

        .related-product-item {
            border: none;
        }

        .price-wrapper {
            height: 25px;

            .price {
                line-height: 25px;
            }
        }
    }

    &__actions {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        flex-basis: 32%;

        .quantity-update {
            margin-right: $spacer--container;
        }

        .add-to-cart-button {
            flex-basis: 100%;
        }
    }
}
