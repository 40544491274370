$link__color              : $font-color-link-default !default;
$link__font-family        : $font-family-base !default;
$link__font-weight        : $font-weight-normal !default;
$link__color-hover        : $color-primary--accent !default;
$link__line-height        : $font-line-height !default;
$link__transition         : $transition-base !default;
$link__text-decoration    : none !default;
$link__color--invert      : $gray-darker !default;
$link__color-hover--invert: $font-color-link-default !default;
$link-icon__hover-color   : $color-primary !default;
$link-icon-icon__fill     : $color-primary !default;

.link {
    color: $link__color;
    transition: $link__transition;
    font-family: $link__font-family;
    font-weight: $link__font-weight;
    line-height: $link__line-height;
    text-decoration: $link__text-decoration;
    cursor: pointer;

    &:hover {
        color: $link__color-hover;
        text-decoration: $link__text-decoration-hover;
    }

    &.button:hover {
        text-decoration: $link__text-decoration;
    }

    &--invert {
        color: $link__color--invert;

        &:hover {
            color: $link__color-hover--invert;
        }
    }

    &--secondary {
        color: $gray-lighter;

        &:hover {
            color: $color-primary;
        }
    }

    &--icon {
        display: flex;
        align-items: center;

        &:hover,
        &:focus,
        &:active {
            background: initial;
            text-decoration: initial;
            color: $link-icon__hover-color;
        }

        .icon {
            display: inline-block;
            margin-right: 5px;
            width: 16px;
            height: 16px;
            fill: $link-icon-icon__fill;
        }
    }

    &--underline {
        text-decoration: underline;
    }

    // visible only for screen readers and when focused
    &--visually-hidden {
        @include visually-hidden($usePseudo: true);
    }
}

a {
    @extend .link;
}
