$quotation-input-border: 1px solid $gray-light !default;
$field-primary-background: $color-primary--accent-2 !default;
$field-primary-font-color: $color-primary !default;

.modal-popup.modal-slide .modal-footer {
    text-align: center;
}

.quotation-request {
    .modal-inner-wrap {
        max-width: 800px;
        border-radius: 5px;

        .modal-content {
            padding-bottom: 10px;
        }
    }

    fieldset {
        border: 0;
    }

    .quantity-update {
        background: #ffffff;
        max-width: 100px;
        margin: 0 auto;
    }

    .field {
        margin-bottom: 20px;

        &--primary {
            padding: 5px 20px;
            background: $field-primary-background;
            border-radius: 15px;
            text-align: center;
            margin: 0 auto;
            max-width: 360px;
            margin-bottom: 20px;

            label {
                color: $field-primary-font-color;
                margin-bottom: 14px;
            }
        }

        &._required,
        &.required {
            & > {
                label,
                .label {
                    &:after {
                        content: '*';
                        font-size: 1.2rem;
                        margin: 0 0 0 3px;
                        color: red;
                    }
                }
            }
        }
    }

    label {
        color: $color-text-accent;
        line-height: 1;
        font-weight: 600;
        font-size: 14px;
        margin: 10px 0 5px;
        width: 100%;
    }

    input.input-text {
        height: 45px;
        line-height: 45px;
        padding: 0 25px 0 10px;
    }

    textarea.input-text {
        padding: 10px 25px 10px 10px;
        line-height: 1.6;
        height: 213px;
    }

    .input-text {
        border-radius: 5px;
        border: $quotation-input-border;
        width: 100%;
    }

    .heading--page {
        color: $color-text-accent;
        font-weight: 700;
        font-size: 18px;
        text-transform: none;
    }

    &__entry-point {
        position: fixed;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        right: -95px;
        top: 50%;
        width: 220px;
        transform: rotateZ(-90deg);
        padding: 12px;
        z-index: 400;
        border-radius: 4px 4px 0 0;
        background: $color-primary;
        color: $color-light;
        font-weight: $font-weight-bold;

        &:hover,
        &:focus {
            color: $color-light;
        }

        @include mq($screen-m) {
            top: initial;
            right: initial;
            bottom: 0;
            left: 15%;
            transform: none;
            width: 250px;
        }

        .entry-point {

            &__count {
                text-align: center;
                border-radius: 50%;
                padding: 2px;
                flex-basis: 25px;
                margin-right: 12px;
                background: $color-tertiary;

                @include mq($screen-m) {
                    padding: 1px;
                    flex-basis: 26px;
                    margin-right: 12px;
                    font-size: $font-size-medium;
                }
            }
        }

        svg {
            padding: 5px;
            margin: 0 0 0 auto;
            fill: $color-light;
        }
    }
}
