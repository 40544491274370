.modal-cart-add-success {
    text-align: center;
    margin: 0 auto;
    width: 90%;
    height: 330px;

    &__icon {
        content: '';
        display: inline-block;
        width: 40px;
        height: 40px;
        margin: 0 0 20px 0;
        background-image: svg-uri($cart-check-svg);
        background-size: contain;
        background-repeat: no-repeat;
    }

    .button {
        margin: $spacer--medium 0;

        &__icon {
            fill: $color-primary !important;
            width: 40px;
            height: 40px;
        }
    }

    &.modal-popup.modal-slide {
        @include mq($screen-m) {
            width: 40%;
            top: 50% !important;
        }
    }

    &.modal-popup,
    &.modal-slide {
        overflow-y: hidden !important;
        left: 0 !important;
        top: inherit !important;
        bottom: -150px;

        .modal-inner-wrap {
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.35);
            overflow-y: hidden !important;
        }
    }
}

.modal-alternative-request,
.modal-wishlist-pdp {

    label {
        display: block;
        font-weight: bold;
        text-align: left;
        color: $color-text-accent;
    }

    textarea {
        border-color: $gray-light;
        padding: 8px;
        margin: 8px 0;
    }

    .actions-wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        button {
            flex-basis: calc(50% - 8px);
        }
    }
}

.modal-alternative-success {
    h2, p {
        font-weight: 600;
        color: $color-alt;
    }
}

.modal-wishlist-pdp,
.modal-wishlist-quote-request {
    &.modal-popup.modal-slide .modal-inner-wrap {
        height: 100%;
        border-radius: 5px;
    }
}

.modal-wishlist-pdp {

    .icon {
        display: block;
        margin: 0 auto 10px auto;
        fill: $color-primary;
    }

    .heading {
        font-weight: bold;
        font-size: 20px;
    }

    label {
        padding-top: 10px;
        margin: 10px 0;
        font-weight: 600;
        font-size: 16px;
        color: $color-text-accent;
    }

    input {
        margin: 10px 0;
    }

    select {
        position: relative;
        appearance: none;
        background: #fff;
        margin: 10px 0;
        padding: 0 20px;
        border: $border-base;
        border-radius: 5px;
        width: 100%;
        height: 45px;
        font-size: 16px;

        option {
            &:last-child {
                background: $color-primary--accent-2;
                color: $color-primary;
            }
        }
    }
}

.modal-wishlist-pdp-success,
.modal-wishlist-request-quote-success, {

    .modal-inner-wrap {
        border-radius: 5px;
    }

    @include mq(false, $screen-m) {
        &.modal-slide.modal-popup {
            height: 330px;
            top: inherit !important;
            bottom: -150px;
        }
    }
}