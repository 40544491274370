div[name='address_finder'] {
    display: none;

    .display-address-autocomplete & {
        display: block;
    }
}

.address-autocomplete {
    display: none;

    .display-address-autocomplete & {
        display: block;
        width: calc(100% - 8px);
    }

    &__summary {
        display: none;

        .address-autocomplete--active & {
            display: flex;
            flex-flow: column wrap;
            align-items: flex-start;
            padding: 10px 20px;
            background: $osc_checkout__step-title-background;
        }

        .button {
            align-self: flex-end;
        }

        p {
            &:empty {
                display: none;
            }
        }
    }

    &__warning {
        display: none;

        .address-autocomplete--warning & {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
            justify-content: space-between;
            padding: 10px;
            margin: 10px 0;
            background: #ffeca8;

            svg {
                margin-top: 5px;
                border: 1px solid #000;
                border-radius: 50%;
                width: 20px;
                height: 20px;
            }
        }
    }

    &__warning-messages {
        flex-basis: calc(100% - 40px);

        &--street,
        &--number,
        &--postal-incorrect,
        &--postal-missing,
        &--city {
            display: none;

            &.active {
                display: block;
            }
        }

        p:not(:first-child) {
            position: relative;
            line-height: 1;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: -15px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #000;
                transform: translateY(-50%);
            }
        }
    }
}

.display-address-autocomplete {

    .field[name="shippingAddress.city"],
    .field[name="shippingAddress.postcode"],
    .field[name="shippingAddress.street.0"],
    .field[name="shippingAddress.street.1"] {
        display: none;
    }

    &--warning {
        .field[name="shippingAddress.city"],
        .field[name="shippingAddress.postcode"],
        .field[name="shippingAddress.street.0"],
        .field[name="shippingAddress.street.1"] {
            display: inline-block;
        }
    }
}

.pac-icon {
    display: none;
}

.pac-item {
    font-size: 14px;
    margin-left: 20px;
    cursor: pointer;
}

.pac-item-query {
    font-size: 14px;
    line-height: 1.5;
}

.pac-item > span:last-child {
    display: block;
    line-height: 1.5;
}

div[name='shippingAddress.address_finder']{
    .ui-autocomplete {
        padding: 0;
        /*display: block !important;*/
        border-radius: 5px;
        max-height: 301px;
        overflow: auto;
        box-sizing: border-box;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);

        li.ui-menu-item:not(.multi-step-checkout__back-to-cart):not(.multi-step-checkout__proceed):not(.adyen-checkout__dropdown__element) {
            margin-bottom: 0 !important;
            list-style: none;
            padding:  17px 20px;
            box-shadow: none;

            &:hover {
                background-color: #e7f6ff;
                cursor: pointer;
            }

            &.loqate_container {
                box-sizing: border-box;
                border-right: inset 20px transparent;
                background-repeat: no-repeat;
                background-position: right;
                background-image: url("data:image/svg+xml,%3Csvg version='1.0' xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 64.000000 64.000000' preserveAspectRatio='xMidYMid meet'%3E%3Cg transform='translate(0.000000,64.000000) scale(0.100000,-0.100000)'%0Afill='%23000000' stroke='none'%3E%3Cpath d='M158 623 c-34 -40 -32 -42 98 -174 l128 -129 -129 -130 -129 -130 20%0A-27 c12 -16 29 -30 39 -31 20 -4 325 294 325 318 0 19 -302 320 -321 320 -9 0%0A-23 -8 -31 -17z'/%3E%3C/g%3E%3C/svg%3E");
            }
        }

        &::-webkit-scrollbar-track {
            border-radius: 5px;
            background-color: $gray-light;
        }

        &::-webkit-scrollbar {
            width: 8px;
            background-color: $gray-light;
            box-sizing: border-box;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: $color-text-accent-3;
            box-sizing: border-box;
        }
    }

    #address_finder_results_container {
        position: absolute;
        z-index: 1;
    }

    .bold-text{
        font-weight: bold;
    }
}