$pager__list-padding                        : $spacer !default;
$pager__item-margin                         : 0 $spacer !default;
$pager__item-after-height--current          : $spacer !default;
$pager__item-after-background-color--current: $color-primary !default;
$pager__item-after-transition--current      : $transition-base  !default;
$pager__item-size                           : $spacer--extra-large !default;
$pager__item--current                       : $font-color-base !default;
$pager__item-text-decoration--current       : none !default;
$pager__icon-width                          : $spacer--medium !default;
$pager__icon-height                         : $spacer--medium !default;
$pager__link-fill-disabled                  : $gray !default;
$pager__link-fill                           : $color-primary !default;

$algolia-pagination-color: $color-primary !default;
$algolia-pagination-color-selected: $white !default;
$algolia-pagination-background: $white !default;
$algolia-pagination-background-selected: $color-primary !default;

.pager {
    width: 100%;
    text-align: center;

    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
        list-style-type: none;
        padding: $pager__list-padding;
        margin: 0;
    }

    &__item {
        display: inline-block;
        padding: 3px;
        margin: 0 8px;
        background-color: $algolia-pagination-background;
        color: $algolia-pagination-color;
        line-height: 1.5;
        text-decoration: none;
        cursor: pointer;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &--current {
            width: 26px;
            height: 26px;
            font-weight: bolder;
            color: $algolia-pagination-color-selected;
            background: $algolia-pagination-background-selected;
            border-radius: 50%;
            padding: 2px;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: $pager__item-size;
        text-decoration: none;

        &[disabled] {
            fill: $pager__link-fill-disabled;
            pointer-events: none;
            cursor: default;
        }

        &--prev,
        &--next {
            fill: $pager__link-fill;
        }
    }

    &__icon {
        width: $pager__icon-width;
        height: $pager__icon-height;
        fill: $algolia-pagination-color;
    }
}
