.contact-index-index {
    .heading--page {
        text-transform: capitalize;
    }
}

.contact {
    order: -1;

    &__form {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin-bottom: 25px;
    }

    &__field {
        flex-basis: 100%;
    }

    &__field-half {
        flex-basis: calc(50% - 8px);
    }

    &__submit {
        margin: $spacer--medium 0;
    }

    button {
        @extend .button;
    }
}
