@mixin focus($mode: '') {
    outline-offset: -1px;
    z-index: 1;
    @if $mode == 'dark' {
        outline: $border-focus-dark;
    }
    @else {
        outline: $border-focus;
    }
}

@mixin focus-inline($mode: '') {
    outline: $outline-focus-inline;
    text-decoration: $text-decoration-focus-inline;
    @if $mode == 'dark' {
        color: $color-focus-inline-dark;
        background-color: $bg-focus-inline-dark;
    }
    @else {
        color: $color-focus-inline;
        background-color: $bg-focus-inline;
    }
}

@mixin focus-input($mode: '') {
    outline: $outline-base;
    @if $mode == 'dark' {
        box-shadow: none;
        border: 2px solid $color-primary;
    }
    @else {
        border-color: $form-elements-border-color-focus;
        box-shadow: $form-input-box-shadow-focus;
    }
}
