body {
    .webforms {
        .fieldset {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: space-between;
            padding: 0;

            .field {
                padding: 0;
            }
        }

        .webforms-gdpr {
            padding: 0;
        }

        .checkbox[type=checkbox] {
            display: inline;
        }

        .actions-toolbar {
            padding: 0;

            .btn-primary {
                @extend .button;
                @extend .button--cta;
                width: auto;
            }
        }
    }
}

@media screen and (min-width: 20em) {
    .wf-sm-row{
        clear:left;
    }
    .wf-sm-1,
    .wf-sm-1-1,
    .wf-sm-1-2,
    .wf-sm-1-3,
    .wf-sm-2-3,
    .wf-sm-1-4,
    .wf-sm-3-4,
    .wf-sm-1-6,
    .wf-sm-1-5,
    .wf-sm-2-5,
    .wf-sm-3-5,
    .wf-sm-4-5,
    .wf-sm-5-6,
    .wf-sm-1-12,
    .wf-sm-2-12,
    .wf-sm-3-12,
    .wf-sm-4-12,
    .wf-sm-5-12,
    .wf-sm-6-12,
    .wf-sm-7-12,
    .wf-sm-8-12,
    .wf-sm-9-12,
    .wf-sm-10-12,
    .wf-sm-11-12,
    .wf-sm-12-12 {
        display: inline-block;
        zoom: 1;
        letter-spacing: normal;
        word-spacing: normal;
        vertical-align: top;
        text-rendering: auto;
        float:left;

    }

    .wf-sm-1-12 {
        width: 8.3333%;
    }

    .wf-sm-1-6,
    .wf-sm-2-12 {
        width: 16.6667%;
    }

    .wf-sm-1-5 {
        width: 20%;
    }

    .wf-sm-1-4,
    .wf-sm-3-12 {
        width: 25%;
    }

    .wf-sm-1-3,
    .wf-sm-4-12 {
        width: 33.3333%;
    }

    .wf-sm-2-5 {
        width: 40%;
    }

    .wf-sm-5-12 {
        width: 41.6667%;
    }

    .wf-sm-1-2,
    .wf-sm-6-12 {
        width: calc(50% - 8px);
    }

    .wf-sm-7-12 {
        width: 58.3333%;
    }

    .wf-sm-3-5 {
        width: 60%;
    }

    .wf-sm-2-3,
    .wf-sm-8-12 {
        width: 66.6667%;
    }

    .wf-sm-3-4,
    .wf-sm-9-12 {
        width: 75%;
    }

    .wf-sm-4-5 {
        width: 80%;
    }

    .wf-sm-5-6,
    .wf-sm-10-12 {
        width: 83.3333%;
    }

    .wf-sm-11-12 {
        width: 91.6667%;
    }

    .wf-sm-1,
    .wf-sm-1-1,
    .wf-sm-12-12 {
        width: 100%;
    }
}

@media screen and (min-width: 48em) {
    .wf-md-row{
        clear:left;
    }
    .wf-sm-row{
        clear:unset;
    }
    .wf-md-1,
    .wf-md-1-1,
    .wf-md-1-2,
    .wf-md-1-3,
    .wf-md-2-3,
    .wf-md-1-4,
    .wf-md-3-4,
    .wf-md-1-6,
    .wf-md-1-5,
    .wf-md-2-5,
    .wf-md-3-5,
    .wf-md-4-5,
    .wf-md-5-6,
    .wf-md-1-12,
    .wf-md-2-12,
    .wf-md-3-12,
    .wf-md-4-12,
    .wf-md-5-12,
    .wf-md-6-12,
    .wf-md-7-12,
    .wf-md-8-12,
    .wf-md-9-12,
    .wf-md-10-12,
    .wf-md-11-12,
    .wf-md-12-12 {
        display: inline-block;
        zoom: 1;
        letter-spacing: normal;
        word-spacing: normal;
        vertical-align: top;
        text-rendering: auto;
        float:left;

    }

    .wf-md-1-12 {
        width: 8.3333%;
    }

    .wf-md-1-6,
    .wf-md-2-12 {
        width: 16.6667%;
    }

    .wf-md-1-5 {
        width: 20%;
    }

    .wf-md-1-4,
    .wf-md-3-12 {
        width: 25%;
    }

    .wf-md-1-3,
    .wf-md-4-12 {
        width: 33.3333%;
    }

    .wf-md-2-5 {
        width: 40%;
    }

    .wf-md-5-12 {
        width: 41.6667%;
    }

    .wf-md-1-2,
    .wf-md-6-12 {
        width: calc(50% - 8px);
    }

    .wf-md-7-12 {
        width: 58.3333%;
    }

    .wf-md-3-5 {
        width: 60%;
    }

    .wf-md-2-3,
    .wf-md-8-12 {
        width: 66.6667%;
    }

    .wf-md-3-4,
    .wf-md-9-12 {
        width: 75%;
    }

    .wf-md-4-5 {
        width: 80%;
    }

    .wf-md-5-6,
    .wf-md-10-12 {
        width: 83.3333%;
    }

    .wf-md-11-12 {
        width: 91.6667%;
    }

    .wf-md-1,
    .wf-md-1-1,
    .wf-md-12-12 {
        width: 100%;
    }
}

@media screen and (min-width: 64em) {
    .wf-lg-row{
        clear:left !important;
    }
    .wf-md-row, .wf-sm-row{
        clear:unset;
    }
    .wf-lg-1,
    .wf-lg-1-1,
    .wf-lg-1-2,
    .wf-lg-1-3,
    .wf-lg-2-3,
    .wf-lg-1-4,
    .wf-lg-3-4,
    .wf-lg-1-5,
    .wf-lg-2-5,
    .wf-lg-3-5,
    .wf-lg-4-5,
    .wf-lg-1-6,
    .wf-lg-5-6,
    .wf-lg-1-12,
    .wf-lg-2-12,
    .wf-lg-3-12,
    .wf-lg-4-12,
    .wf-lg-5-12,
    .wf-lg-6-12,
    .wf-lg-7-12,
    .wf-lg-8-12,
    .wf-lg-9-12,
    .wf-lg-10-12,
    .wf-lg-11-12,
    .wf-lg-12-12 {
        display: inline-block;
        zoom: 1;
        letter-spacing: normal;
        word-spacing: normal;
        vertical-align: top;
        text-rendering: auto;
        float:left;

    }

    .wf-lg-1-12 {
        width: 8.3333%;
    }

    .wf-lg-1-6,
    .wf-lg-2-12 {
        width: 16.6667%;
    }

    .wf-lg-1-5 {
        width: 20%;
    }

    .wf-lg-1-4,
    .wf-lg-3-12 {
        width: 25%;
    }

    .wf-lg-1-3,
    .wf-lg-4-12 {
        width: 33.3333%;
    }

    .wf-lg-2-5 {
        width: 40%;
    }

    .wf-lg-5-12 {
        width: 41.6667%;
    }

    .wf-lg-1-2,
    .wf-lg-6-12 {
        width: calc(50% - 8px);
    }

    .wf-lg-7-12 {
        width: 58.3333%;
    }

    .wf-lg-3-5 {
        width: 60%;
    }

    .wf-lg-2-3,
    .wf-lg-8-12 {
        width: 66.6667%;
    }

    .wf-lg-3-4,
    .wf-lg-9-12 {
        width: 75%;
    }

    .wf-lg-4-5 {
        width: 80%;
    }

    .wf-lg-5-6,
    .wf-lg-10-12 {
        width: 83.3333%;
    }

    .wf-lg-11-12 {
        width: 91.6667%;
    }

    .wf-lg-1,
    .wf-lg-1-1,
    .wf-lg-12-12 {
        width: 100%;
    }
}