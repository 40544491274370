div[data-content-type="row"] div[data-element="inner"] {
  margin-bottom: 15px;
}

.pagebuilder-column-group {
  margin-left: -15px;
  margin-right: -15px;
}

.pagebuilder-column {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

figure[data-content-type='image'] figcaption {
  font-size: 10px;
}

ul[style="list-style-type: checkmark;"] {
  list-style-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 400 400' fill='%230b92e0'%3E%3Cpath d='M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z'/%3E%3C/svg%3E");
}

figure {
  margin: unset;
}

div[data-content-type='divider'] hr {
  border: none;
  border-top: 1px solid #ebebeb;
}

div[data-content-type='buttons'] .button {
  margin: 0 10px 10px 0;
}

.pagebuilder-collapsible-heading {
  position: relative;

  .pagebuilder-collapsible-heading__title-wrapper {
    position: relative;
    cursor: pointer;
  }

  .pagebuilder-collapsible-heading__content {
    display: none;
  }

  .pagebuilder-collapsible-heading__icon {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 7px;
    transition: all .3s ease-in-out,outline-offset 1ms;
    display: none;
  }

  &.active {
    .pagebuilder-collapsible-heading__icon {
      transform: translate(0, -50%) rotate(180deg);
    }

    .pagebuilder-collapsible-heading__content {
      display: block;
    }

  }

  &[data-place-arrows="arrow-left"] {
    .pagebuilder-collapsible-heading__title {
      padding-left: 30px;
    }
    .pagebuilder-collapsible-heading__icon {
      display: block;
      left: 7px;
    }
  }
  &[data-place-arrows="arrow-right"] {
    .pagebuilder-collapsible-heading__title {
      padding-right: 30px;
    }
    .pagebuilder-collapsible-heading__icon {
      display: block;
      right: 7px;
    }
  }
}

.savings-calculator {
  .field {
    margin-bottom: 20px;
    .label, label {
      font-weight: 600;
      color: #2d2d2d;
    }
    .control {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        margin-left: 10px;
      }
    }
  }
  .results {
    table {
      padding: 0;
      border-radius: 5px;
      margin-bottom: 15px;
      @media screen and (max-width: 1200px) {
        font-size: 85%;
      }
      tr {
        td, th {
          text-align: right;
          width: 33.3333%;
          min-width: 70px;
        }
      }
      &.totals {
        border: none;
        border-spacing: 2px;
        tr {
          td, th {
            border-radius: 5px;
            background: #e7ffee;
            color: #47ad4c;
            &#total-savings {
              background: #47ad4c;
              color: #fff;
              font-size: 120%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .pagebuilder-column-line {
    display: block !important;
  }
  .pagebuilder-column {
    width: 90% !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
}