.wwl-pdp-align {

  @include mq($screen-m) {

    &--atp-alternative {

      .wwl-align-anchor-1 {
        margin-top: -80px;
      }
    }

    &--recommends {

      .wwl-align-anchor-1 {
        margin-top: -275px;
      }
    }

    &--atp {

      .wwl-align-anchor-2 {
        margin-top: -140px;
      }
    }
  }
}
