$link-list__width                     : 100% !default;
$link-list__border-radius             : 0 !default;
$link-list__outline                   : none !default;
$link-list__font-size                 : $font-size-base !default;
$link-list__transition-height         : height 0.3s !default;
$link-list__transition                : $transition-base !default;

// List item
$link-list__item-padding              : $spacer--medium 0 !default;
$link-list__item-line-height          : 2.14 !default;
$link-list__item-color                : $color-light !default;
$link-list__item-color-\@large        : $color-light !default;
$link-list__item-color--open          : $color-light !default;
$link-list__item-color-hover          : $color-light !default;
$link-list__item-font-weight          : $font-weight-semi-bold !default;

$link-list__link-hover-decoration     : underline !default;
$link-list__item-margin--inline       : 0 10px !default;
$link-list__item-margin--inline-first : 0 10px 0 0 !default;
$link-list__item-separator--inline    : '|' !default;

.link-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: $link-list__width;

    &__list {
        display: block;
        list-style-type: none;
        padding: 0;
        margin: 0;

        &--inline {
            margin: 0 auto;
        }
    }

    &__item {
        padding: 0;
        line-height: $link-list__item-line-height;
        font-size: $link-list__font-size;

        &--inline {
            display: inline-block;

            &:not(:last-child):after {
                content: $link-list__item-separator--inline;

                @include mq($screen-l) {
                    content: none;
                }
            }

            @include mq($screen-l) {
                margin: $link-list__item-margin--inline;

                &:first-child {
                    margin: $link-list__item-margin--inline-first;
                }
            }
        }
    }

    &__link {
        color: $link-list__item-color;

        &:hover {
            color: $link-list__item-color;
            text-decoration: $link-list__link-hover-decoration;
        }

        @include mq($screen-m) {
            color: $link-list__item-color-\@large;

            &:hover {
                color: $link-list__item-color-\@large;
            }
        }
    }

    &__label {
        display: block;
        padding: $link-list__item-padding;
        transition: $link-list__transition;
        color: $link-list__item-color;
        font-weight: $link-list__item-font-weight;

        @include mq($screen-m) {
            color: $link-list__item-color-\@large;
        }
    }
}
