$button__height                     : 48px !default;
$button__width                      : 48px !default;
$button__padding                    : 0 $spacer--medium !default;
$button__border                     : none !default;
$button__border-radius              : 100px !default;
$button__border-hover               : none !default;
$button__background                 : $color-primary !default;
$button__background-hover           : $color-primary--accent !default;
$button__font-family                : $font-family-base !default;
$button__font-weight                : $font-weight-bold !default;
$button__font-size                  : $font-size-medium !default;
$button__font-color                 : $white !default;
$button__font-color-hover           : $white !default;
$button__transition                 : $transition-base !default;
$button__box-shadow                 : inset 0 1px 0 0 rgba(0, 0, 0, 0.5) !default;

// Secondary
$button__border--secondary          : 2px solid $color-primary !default;
$button__border-hover--secondary    : $color-primary !default;
$button__background--secondary      : $white !default;
$button__background-hover--secondary: $color-primary !default;
$button__font-color--secondary      : $gray-darker !default;
$button__font-color-hover--secondary: $gray-dark !default;

// CTA
$button__color--cta                 : $button__font-color !default;
$button__background--cta            : $color-cta !default;
$button__background-hover--cta      : $color-cta--accent !default;
$button__box-shadow--cta            : none !default;
$button__box-shadow-active--cta     : $button__box-shadow !default;
$button__font-weight--cta           : $font-weight-semi-bold;
$button__font-color-hover--cta      : $button__font-color-hover !default;

// CTA Secondary
$button__color--cta-secondary            : $button__font-color !default;
$button__background--cta-secondary       : $color-cta !default;
$button__background-hover--cta-secondary : darken($color-cta, 5%) !default;
$button__box-shadow--cta-secondary       : none !default;
$button__font-weight--cta-secondary      : $font-weight-semi-bold;
$button__font-color-hover--cta-secondary : $button__font-color-hover !default;

// Alt
$button__background--alt            : $color-alt !default;
$button__background-hover--alt      : $color-alt--accent !default;
$button__box-shadow--alt            : none !default;
$button__box-shadow-active--alt     : $button__box-shadow !default;

// Light
$button__background--light            : $color-light !default;
$button__background-hover--light      : $color-light--accent !default;
$button__box-shadow--light            : inset 0 2px 0 0 rgba(0, 0, 0, 0.15) !default;
$button__font-color--light            : $color-primary !default;
$button__font-color-hover--light      : $color-primary !default;
$button__font-weight--light           : $font-weight-normal !default;
$button__border-color--light          : $gray-light !default;

// Light secondary
$button__background--light-secondary       : $color-light !default;
$button__background-hover--light-secondary : $color-primary--accent-2 !default;
$button__box-shadow--light-secondary       : none !default;
$button__font-color--light-secondary       : $color-primary !default;
$button__font-color-hover--light-secondary : $color-primary !default;
$button__font-weight--light-secondary      : $font-weight-semi-bold !default;
$button__border-color--light-secondary     : transparent !default;

//Light secondary inverted
$button__background--light-secondary-inverted       : $color-primary--accent-2 !default;
$button__background-hover--light-secondary-inverted : $color-light !default;
$button__box-shadow--light-secondary-inverted       : none !default;
$button__font-color--light-secondary-inverted       : $color-primary !default;
$button__font-weight--light-secondary-inverted      : $font-weight-semi-bold !default;
$button__border-color--light-secondary-inverted     : transparent !default;
$button__border-color-hover--light-secondary-inverted : $color-primary !default;

// Icon
$button__padding--icon                    : 0 !default;
$button__size--icon                       : 20px !default;
$button__border-width--icon               : 3px !default;
$button__border--icon                     : $button__border-width--icon solid $color-primary !default;
$button__border-hover--icon               : $gray-dark !default;
$button__fill--icon                       : $white !default;
$button__fill-hover--icon                 : $white !default;
$button__background--icon                 : $color-primary !default;
$button__background-hover--icon           : $color-primary--accent !default;
$button__fill--icon--cta                  : $button__fill--icon !default;
$button__fill--icon--cta-secondary        : $button__fill--icon !default;
$button__fill-hover--icon--cta            : $button__fill--icon !default;
$button__fill-hover--icon--cta-secondary  : $button__fill--icon !default;

// Fluid
$button__width--fluid               : 100% !default;

// Icon with text
$button__margin-right--icon-with-text: 15px !default;

$self : '.button';

.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: $button__height;
    min-width: $button__width;
    padding: $button__padding;
    border: $button__border;
    border-radius: $button__border-radius;
    background: $button__background;
    font-family: $button__font-family;
    font-size: $button__font-size;
    color: $button__font-color;
    transition: $button__transition;
    font-weight: $button__font-weight;

    &:hover {
        background: $button__background-hover;
        border-color: $button__border-hover;
        color: $button__font-color-hover;
    }

    &:active {
        box-shadow: $button__box-shadow;
        background-color: lighten($button__background, 20%);
    }

    @include isIE() {
        align-items: center;
    }

    &--icon {
        align-items: center;
        padding: $button__padding--icon;
        border: $button__border--icon;
        background: $button__background--icon;

        &:hover {
            background: $button__background-hover--icon;

            .button__icon,
            .icon {
                fill: $button__fill-hover--icon;
            }
        }
    }

    &--fluid {
        width: $button__width--fluid;
    }

    &--link {
        align-items: center;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: $button__font-color-hover;
        }
    }

    &--cta {
        border: none;
        color: $button__color--cta;
        background-color: $button__background--cta;
        font-weight: $button__font-weight--cta;
        box-shadow: $button__box-shadow--cta;
        @include gradient-directional($button__background--cta, $button__background-hover--cta, 281deg);

        #{$self}__icon {
            fill: $button__fill--icon--cta;
        }

        &:hover {
            color: $button__font-color-hover--cta;
            @include gradient-directional(darken($button__background--cta, 20%), darken($button__background-hover--cta, 20%), 281deg);

            .button__icon,
            .icon {
                fill: $button__fill-hover--icon--cta;
            }
        }

        &:active {
            box-shadow: $button__box-shadow-active--cta;
            color: $button__font-color-hover--cta-secondary;
            @include gradient-directional(lighten($button__background--cta, 20%), lighten($button__background-hover--cta, 20%), 281deg);
        }
    }

    &:disabled,
    &--disabled {
        cursor: initial;
        background: lighten($button__background--cta, 30%);

        &:not(.loading) {
            :hover,
            &:focus,
            &:active {
                background: lighten($button__background--cta, 30%);
            }
        }
    }

    &--cta-secondary {
        border: none;
        color: $button__color--cta-secondary;
        background-color: $button__background--cta-secondary;
        font-weight: $button__font-weight--cta-secondary;

        #{$self}__icon {
            fill: $button__fill--icon--cta-secondary;
        }

        &:hover {
            background-color: $button__background-hover--cta-secondary;
            font-weight: $button__font-weight--cta-secondary;

            .button__icon,
            .icon {
                fill: $button__fill-hover--icon--cta-secondary;
            }
        }

        &:active {
            box-shadow: $button__box-shadow--cta-secondary;
            background-color: $button__background-hover--cta-secondary;
            font-weight: $button__font-weight--cta-secondary;
        }
    }

    &--alt {
        border: none;
        background-color: $button__background--alt;
        box-shadow: $button__box-shadow--alt;

        &:hover {
            background-color: darken($button__background-hover--alt, 20%);
        }

        &:active {
            box-shadow: $button__box-shadow-active--alt;
            background-color: $button__background-hover--alt;
        }
    }

    &--light {
        background-color: $button__background--light;
        color: $button__font-color--light;
        border: 1px solid $button__border-color--light;
        font-weight: $button__font-weight--light;

        &:hover {
            background-color: $button__background-hover--light;
            color: $button__font-color-hover--light;
            font-weight: $button__font-weight--light;
        }

        &:active {
            box-shadow: $button__box-shadow--light;
            background-color: $button__background-hover--light;
            color: $button__font-color-hover--light;
            font-weight: $button__font-weight--light;
        }
    }

    &--light-secondary {
        background-color: $button__background--light-secondary;
        color: $button__font-color--light-secondary;
        border: 1px solid $button__border-color--light-secondary;
        font-weight: $button__font-weight--light-secondary;

        &:hover {
            background-color: $button__background-hover--light-secondary;
            color: $button__font-color-hover--light-secondary;
            font-weight: $button__font-weight--light-secondary;
        }

        &:active {
            box-shadow: $button__box-shadow--light-secondary;
            background-color: $button__background-hover--light-secondary;
            color: $button__font-color-hover--light-secondary;
            font-weight: $button__font-weight--light-secondary;
        }
    }

    &--light-secondary-no-background {
        background-color: $button__background--light;
        color: $button__font-color--light-secondary;
        border: 1px solid $button__border-color--light-secondary;
        font-weight: $button__font-weight--light-secondary;

        &:hover {
            background-color: $button__background--light;
            color: $button__font-color-hover--light-secondary;
            font-weight: $button__font-weight--light-secondary;
        }

        &:active {
            box-shadow: $button__box-shadow--light-secondary;
            background-color: $button__background-hover--light-secondary;
            color: $button__font-color-hover--light-secondary;
            font-weight: $button__font-weight--light-secondary;
        }
    }

    &--light-secondary-inverted {
        background-color: $button__background--light-secondary-inverted;
        color: $button__font-color--light-secondary-inverted;
        border: 1px solid $button__border-color--light-secondary-inverted;
        font-weight: $button__font-weight--light-secondary-inverted;
        box-shadow: $button__box-shadow--light-secondary-inverted;

        &:hover {
            background-color: $button__background-hover--light-secondary-inverted;
            color: $button__font-color--light-secondary-inverted;
            font-weight: $button__font-weight--light-secondary-inverted;
            box-shadow: $button__box-shadow--light-secondary-inverted;
            border: 1px solid $button__border-color-hover--light-secondary-inverted;
        }

        &:active {
            box-shadow: $button__box-shadow--light-secondary-inverted;
            background-color: $button__background-hover--light-secondary-inverted;
            color: $button__font-color--light-secondary-inverted;
            font-weight: $button__font-weight--light-secondary-inverted;
            box-shadow: $button__box-shadow--light-secondary-inverted;
        }
    }

    &--secondary {
        border: $button__border--secondary;
        color: $button__font-color--secondary;
        background: $button__background--secondary;

        &:hover {
            border-color: $button__border-hover--secondary;
            color: $button__font-color-hover--secondary;
            background: $button__background-hover--secondary;
        }
    }

    &__icon {
        width: $button__size--icon;
        height: $button__size--icon;
        fill: $button__fill--icon;
        transition: $button__transition;
    }
}
