.input-wrap {
    position: relative;
}

.input {
    position: relative;

    .input-wrap,
    .control {
        position: relative;

        input + span.validation-feedback:after {
            top: 20px;
        }
    }

    input {
        & + span.validation-feedback {
            display: none;

            &:after {
                content: " ";
                background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQxNiIgdmlld0JveD0iMCAwIDQxNiA0MTYiIHdpZHRoPSI0MTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTIwOCAwYy0xMTQuNjkgMC0yMDggOTMuMzEtMjA4IDIwOHM5My4zMSAyMDggMjA4IDIwOCAyMDgtOTMuMzEgMjA4LTIwOC05My4zMS0yMDgtMjA4LTIwOHptMTA4LjI1IDEzOC4yOS0xMzQuNCAxNjBjLTIuOTgzMDI0IDMuNTUzMDM3LTcuMzYxMzQ5IDUuNjM2MzktMTIgNS43MWgtLjI3Yy00LjUzNTM3NCAwLTguODU2OTY5LTEuOTI4MDA2LTExLjg5LTUuM2wtNTcuNi02NGMtMy45ODIxMDIzLTQuMjIzNTY4LTUuMzY0MzEyOS0xMC4yNzI2MDYtMy42MTE4MDc0LTE1LjgwNjUzNiAxLjc1MjUwNTUtNS41MzM5MzEgNi4zNjQ3OTk0LTkuNjg0NjA3IDEyLjA1MjIyODQtMTAuODQ1OTkyczExLjU1Nzc2Ni44NDg3MTcgMTUuMzM5NTc5IDUuMjUyNTI4bDQ1LjI5IDUwLjMyIDEyMi41OS0xNDUuOTFjNS43MjE3ODItNi42MTUyNDYgMTUuNjk1MjQ4LTcuNDA5OTc4IDIyLjM5MjQ0Mi0xLjc4NDMzNXM3LjYzNTg4OCAxNS41ODY1OSAyLjEwNzU1OCAyMi4zNjQzMzV6IiBmaWxsPSIjNGJiMDRjIi8+PC9zdmc+");
                background-size: contain;
                position: absolute;
                width: 18px;
                height: 18px;
                right: 10px;
                top: 23px;
                transform: translateY(-50%);
            }
        }
    }

    input[placeholder]:not(:placeholder-shown):not(.invoice-email.untested):not(.discount-code__field):not(.algolia-search-input):not([aria-invalid="true"]):focus,
    input[placeholder]:not(:placeholder-shown):not(.invoice-email.untested):not(.discount-code__field):not(.algolia-search-input):not(.mage-error):focus {
        border: 1px solid #4bb14d;
        box-shadow: 0 0 2px 0 #4bb14d;
    }

    input[placeholder]:not(:placeholder-shown):not(.invoice-email.untested):not(.discount-code__field):not(.algolia-search-input):not([aria-invalid="true"]),
    input[placeholder]:not(:placeholder-shown):not(.invoice-email.untested):not(.discount-code__field):not(.algolia-search-input):not(.mage-error) {
        padding-right: 34px;

        + .mage-error[style*="display: none"] + span.validation-feedback,
        & + span.validation-feedback {
            display: block;
            &:after {
                content: " ";
                background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQxNiIgdmlld0JveD0iMCAwIDQxNiA0MTYiIHdpZHRoPSI0MTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTIwOCAwYy0xMTQuNjkgMC0yMDggOTMuMzEtMjA4IDIwOHM5My4zMSAyMDggMjA4IDIwOCAyMDgtOTMuMzEgMjA4LTIwOC05My4zMS0yMDgtMjA4LTIwOHptMTA4LjI1IDEzOC4yOS0xMzQuNCAxNjBjLTIuOTgzMDI0IDMuNTUzMDM3LTcuMzYxMzQ5IDUuNjM2MzktMTIgNS43MWgtLjI3Yy00LjUzNTM3NCAwLTguODU2OTY5LTEuOTI4MDA2LTExLjg5LTUuM2wtNTcuNi02NGMtMy45ODIxMDIzLTQuMjIzNTY4LTUuMzY0MzEyOS0xMC4yNzI2MDYtMy42MTE4MDc0LTE1LjgwNjUzNiAxLjc1MjUwNTUtNS41MzM5MzEgNi4zNjQ3OTk0LTkuNjg0NjA3IDEyLjA1MjIyODQtMTAuODQ1OTkyczExLjU1Nzc2Ni44NDg3MTcgMTUuMzM5NTc5IDUuMjUyNTI4bDQ1LjI5IDUwLjMyIDEyMi41OS0xNDUuOTFjNS43MjE3ODItNi42MTUyNDYgMTUuNjk1MjQ4LTcuNDA5OTc4IDIyLjM5MjQ0Mi0xLjc4NDMzNXM3LjYzNTg4OCAxNS41ODY1OSAyLjEwNzU1OCAyMi4zNjQzMzV6IiBmaWxsPSIjNGJiMDRjIi8+PC9zdmc+");
                background-size: contain;
                position: absolute;
                width: 18px;
                height: 18px;
                right: 10px;
                top: 23px;
                transform: translateY(-50%);
            }
        }

    }
}
.customer-account-login,
.customer-account-create  {
    span.validation-feedback {
        &:after {
            top: 20px !important;
        }
    }
}
.contact__form {
    span.validation-feedback {
        &:after {
            top: 49px !important;
        }
    }
}

div.mage-error:not([style*='display: none']) + span.validation-feedback {
    display: block;

    &:after {
        content: " ";
        background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQxNiIgdmlld0JveD0iMCAwIDQxNiA0MTYiIHdpZHRoPSI0MTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTIwOCAwYy0xMTQuNjkgMC0yMDggOTMuMzEtMjA4IDIwOHM5My4zMSAyMDggMjA4IDIwOCAyMDgtOTMuMzEgMjA4LTIwOC05My4zMS0yMDgtMjA4LTIwOHptMCAzMTkuOTFjLTExLjA0NTY5NSAwLTIwLTguOTU0MzA1LTIwLTIwczguOTU0MzA1LTIwIDIwLTIwIDIwIDguOTU0MzA1IDIwIDIwLTguOTU0MzA1IDIwLTIwIDIwem0yMS43Mi0yMDEuMTUtNS43NCAxMjJjMCA4LjgzNjU1Ni03LjE2MzQ0NCAxNi0xNiAxNnMtMTYtNy4xNjM0NDQtMTYtMTZsLTUuNzQtMTIxLjk0di0uMDVjLS4zNDI2MjYtNy45ODIxNTcgMy43MjA2MTUtMTUuNTA5MDcgMTAuNTgxOTAzLTE5LjYwMjM0OTMgNi44NjEyODktNC4wOTMyNzk2IDE1LjQxNDkwNS00LjA5MzI3OTYgMjIuMjc2MTk0IDAgNi44NjEyODggNC4wOTMyNzkzIDEwLjkyNDUyOSAxMS42MjAxOTIzIDEwLjU4MTkwMyAxOS42MDIzNDkzeiIgZmlsbD0iI2Y4OGQwZiIvPjwvc3ZnPg==");
        background-size: contain;
        position: absolute;
        width: 18px;
        height: 18px;
        right: 10px;
        top: 23px;
        transform: translateY(-50%);
    }
}

.field._error, .field._vat-warning {
    input {

        &:focus {
            border: 1px solid #fdd4a0;
            box-shadow: 0 0 2px 0 #fdd4a0;
        }

        & + span.validation-feedback {
            display: block;

            &:after {
                content: " ";
                background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQxNiIgdmlld0JveD0iMCAwIDQxNiA0MTYiIHdpZHRoPSI0MTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTIwOCAwYy0xMTQuNjkgMC0yMDggOTMuMzEtMjA4IDIwOHM5My4zMSAyMDggMjA4IDIwOCAyMDgtOTMuMzEgMjA4LTIwOC05My4zMS0yMDgtMjA4LTIwOHptMCAzMTkuOTFjLTExLjA0NTY5NSAwLTIwLTguOTU0MzA1LTIwLTIwczguOTU0MzA1LTIwIDIwLTIwIDIwIDguOTU0MzA1IDIwIDIwLTguOTU0MzA1IDIwLTIwIDIwem0yMS43Mi0yMDEuMTUtNS43NCAxMjJjMCA4LjgzNjU1Ni03LjE2MzQ0NCAxNi0xNiAxNnMtMTYtNy4xNjM0NDQtMTYtMTZsLTUuNzQtMTIxLjk0di0uMDVjLS4zNDI2MjYtNy45ODIxNTcgMy43MjA2MTUtMTUuNTA5MDcgMTAuNTgxOTAzLTE5LjYwMjM0OTMgNi44NjEyODktNC4wOTMyNzk2IDE1LjQxNDkwNS00LjA5MzI3OTYgMjIuMjc2MTk0IDAgNi44NjEyODggNC4wOTMyNzkzIDEwLjkyNDUyOSAxMS42MjAxOTIzIDEwLjU4MTkwMyAxOS42MDIzNDkzeiIgZmlsbD0iI2Y4OGQwZiIvPjwvc3ZnPg==") !important;
                background-size: contain;
                position: absolute;
                width: 18px;
                height: 18px;
                right: 10px;
                top: 23px;
                transform: translateY(-50%);
            }
        }
    }
}


.service-request-form .input input[placeholder]:not(:placeholder-shown):not(.discount-code__field):not(.algolia-search-input):not(.mage-error) {
  padding-right: 10px;
}

input[name='taxvat'][style*='display: none'] ~ span.validation-feedback {
    display: none!important;
}


