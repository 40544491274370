.gift-card {
    margin-bottom: 15px;
    padding: 0 15px;
    &.active {
        .gift-card__title-icon {
            transform: rotate(180deg);
        }
    }

    &__title {
        display: flex;
        align-items: center;
        padding: 10px 0;
        cursor: pointer;

        &-text {
            margin-right: 15px;
            font-weight: 500;
        }

        &-icon {
            width: 14px;
            height: 14px;
            transition: $transition-base;
        }
    }

    &__form {
        display: block;
    }

    &__content {
        display: none;
    }

    &__fields {
        margin-bottom: 5px;
    }
}
