.add-to-cart-button {

    &__primary-button {
        height: $add-to-cart-button-height;
        width: $add-to-cart-button-width;

        @include mq($screen-l) {
            height: $add-to-cart-button-height\@medium;
            width: $add-to-cart-button-width\@medium;
            padding: $add-to-cart-button-padding\@medium;
        }

        .icon {
            width: 24px;
            height: 24px;
        }

        span {
            display: none;
        }

        @include mq($screen-l) {

            .icon {
                margin-right: 7px;
            }

            span {
                display: inline-block;
            }
        }
    }

    &--wide {

        @include mq($screen-xs) {

            .add-to-cart-button__primary-button {
                width: 100%;

                .icon {
                    margin-right: 7px;
                }

                span {
                    display: inline-block;
                }
            }
        }
    }

    &--compact {

        .add-to-cart-button__primary-button {
            max-width: 70px;

            @include mq($screen-l) {

                .icon {
                    margin: 0;
                }

                span {
                    display: none;
                }
            }
        }
    }
}
