//sass-lint:disable no-ids

$tp-green                           : #01b679 !default;
$tp-star-1-c                        : #ff3722 !default;
$tp-star-2-c                        : #fb963a !default;
$tp-star-3-c                        : #f9cd16 !default;
$tp-star-4-c                        : #73cf11 !default;
$tp-star-5-c                        : #00c47c !default;

.tp-widget-wrapper {
    display: none;
    position: relative;
    transition: all 1s ease;
    color: #1b1b21;

    strong {
        font-weight: 600;
    }

    .tp-widget-summary__description {
        display: block;
    }

    .modal {
        position: relative;
    }

    .modal::before {
        background-color: #fff;
        border-right: 1px solid #d6d6d6;
        border-top: 1px solid #d6d6d6;
        content: '';
        display: block;
        height: 16px;
        left: 50%;
        position: absolute;
        top: -13px;
        transform: rotate(-34deg) translateX(-50%) skewX(26deg);
        transform-origin: center;
        width: 16px;
    }

    #fallback-logo {
        display: none;
    }

    .no-svg #fallback-logo {
        display: inline-block;
    }

    &.visible {
        display: block;
    }

    .tp-widget-summary__rating {
        display: none;
    }

    .tp-widget-summary {
        .tp-stars {
            .tp-star {
                width: 31px;
                height: 31px;
            }
        }
    }

    .tp-widget-loader,
    .tp-widget-fallback {
        max-width: 50%;
        opacity: 0;
        text-align: center;
        width: 400vh;
    }

    .tp-widget-loader svg,
    .tp-widget-fallback svg {
        width: 100%;
    }

    @media screen and (min-width: 768px) {
        .tp-widget-loader,
        .tp-widget-fallback {
            max-width: 200px;
        }
    }

    .tp-widget-loader--loaded {
        opacity: 1;
    }

    .bold-underline {
        border-bottom: 1px solid rgba(25, 25, 25, 0.6);
    }

    .bold-underline:hover {
        border-color: #191919;
    }

    .tp-widget-review__rating__label {
        align-items: center;
        display: flex;
    }

    .tp-widget-review__rating__label::after {
        border-color: $tp-green transparent transparent transparent;
        border-style: solid;
        border-width: 4px 4px 0;
        content: '';
        display: block;
        height: 0;
        margin: 0 0 0 6px;
        width: 0;
    }

    .progress-bar {
        background-color: #e5e5e5;
        border-radius: 2px;
        height: 12px;
        position: relative;
    }

    .progress-bar__status {
        background-color: #5c5c70;
        border-radius: 2px;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: background-color 0.2s ease;
    }

    .tp-widget-checkbox {
        position: relative;
    }

    .tp-widget-checkbox__label {
        border: 1px solid #d6d6d6;
        border-radius: 2px;
        cursor: pointer;
        display: block;
        height: 16px;
        position: relative;
        transition: background-color 0.2s ease;
        width: 16px;
    }

    .tp-widget-checkbox__input {
        height: 100%;
        left: 0;
        margin: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .tp-widget-scorebar--disabled {
        opacity: 0.4;
    }

    .tp-widget-scorebar--disabled .tp-widget-checkbox__label {
        cursor: default;
    }

    .tp-widget-scorebar--active.tp-widget-scorebar--1 .progress-bar__status {
        background-color: $tp-star-1-c;
    }

    .tp-widget-scorebar--active.tp-widget-scorebar--2 .progress-bar__status {
        background-color: $tp-star-2-c;
    }

    .tp-widget-scorebar--active.tp-widget-scorebar--3 .progress-bar__status {
        background-color: $tp-star-3-c;
    }

    .tp-widget-scorebar--active.tp-widget-scorebar--4 .progress-bar__status {
        background-color: $tp-star-4-c;
    }

    .tp-widget-scorebar--active.tp-widget-scorebar--5 .progress-bar__status {
        background-color: $tp-green;
    }

    .tp-widget-scorebar--active .tp-widget-checkbox__label {
        background-color: $tp-green;
        border-color: $tp-green;
    }

    .tp-widget-scorebar--active .tp-widget-checkbox__label::after {
        border-bottom: 2px solid #fff;
        border-left: 2px solid #fff;
        content: '';
        display: block;
        height: 5px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: rotate(-45deg) translate(-10%, -130%);
        width: 10px;
    }

    .tp-widget-summary {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        padding: 0 0 24px;

        @include mq(false, $screen-m) {
            .page-product-grouped & {
                display: none;
            }
        }
    }

    .tp-widget-summary__information {
        display: flex;
        flex-direction: column;
    }

    .tp-widget-summary__stars {
        margin: 0 0 12px;

        img {
            width: 100%;
        }
    }

    .tp-widget-summary__rating {
        align-items: center;
        color: #5c5c70;
        display: flex;
        font-size: 14px;
    }

    .tp-widget-summary__rating {
        display: none;
    }


    .tp-widget-summary__rating .rating {
        color: #1b1b21;
        font-size: 20px;
        font-weight: 700;
        margin: 0 6px 0 0;
    }

    .tp-widget-summary__rating .separator {
        margin: 0 12px 0 8px;
    }

    .tp-widget-summary__count {
        color: #1b1b21;
    }

    @media screen and (min-width: 768px) {
        .tp-widget-summary {
            flex-direction: row;
            justify-content: space-between;
        }
        .tp-widget-summary__rating .rating {
            font-size: 24px;
            margin: 0 8px 0 0;
        }
    }

    .tp-widget-wrapper-layout {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .tp-widget-wrapper-layout::after {
        content: '';
        display: block;
        height: 12px;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transition: opacity 0.2s ease;
        width: 100%;
        z-index: 20;
    }

    .tp-widget-wrapper-layout::after {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(182, 182, 182, 0.19) 100%);
        bottom: 0;
    }

    .tp-widget-wrapper-layout.content-shadow--show::after {
        opacity: 1;
    }

    .tp-widget-summary {
        position: relative;
    }

    .tp-widget-summary::after {
        content: '';
        display: block;
        height: 12px;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transition: opacity 0.2s ease;
        width: 100%;
        z-index: 20;
    }

    .tp-widget-summary::after {
        background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(182, 182, 182, 0.19) 100%);
        top: 100%;
    }

    .tp-widget-summary.content-shadow--show::after {
        opacity: 1;
    }

    .tp-widget-content {
        padding: 0;
    }

    .tp-widget-empty-vertical {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .tp-widget-empty-vertical__title {
        font-size: 18px;
        margin: 0 0 12px;
        text-align: center;
    }

    .tp-widget-empty-vertical__stars {
        margin: 0 0 8px;
        width: 150px;
    }

    .tp-widget-empty-vertical__subtitle-wrapper {
        display: flex;
        flex-direction: row;
    }

    .tp-widget-empty-vertical__subtitle {
        align-items: center;
        display: flex;
        font-size: 14px;
        text-align: center;
    }

    .tp-widget-empty-vertical__logo {
        margin: 0 0 5px 5px;
        width: 90px;
    }

    .tp-widget-empty-horizontal {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: center;
    }

    .tp-widget-empty-horizontal__title {
        font-size: 14px;
        text-align: center;
    }

    .tp-widget-empty-horizontal__logo {
        margin: 0 0 5px 5px;
        width: 90px;
    }

    @media screen and (max-width: 768px) {
        .tp-widget-empty-vertical__subtitle-wrapper {
            align-items: center;
            display: flex;
            flex-direction: column;
        }
        .tp-widget-empty-vertical__logo {
            margin: 5px 0 0;
        }
        .tp-widget-empty-horizontal {
            flex-direction: column;
        }
        .tp-widget-empty-horizontal__logo {
            margin: 0;
        }
        .tp-widget-summary {
            border: none;
        }
    }

    .tp-widget-reviews__sphere {
        .trustpilot-star-rating.trustpilot-star-filter {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-items: flex-start;

            .tp-stars {
                display: block;
                cursor: pointer;
                padding: 5px;

                &.active {
                    background-color: #eee;
                }
            }
        }

        .filter-progress-wrapper {
            display: inline-block;
            width: 150px;
            position: relative;
            border: 1px solid #d6d6d6;
            background-color: #e5e5e5;
            height: 8px;
            border-radius: 5px;
            margin: 3px 0;

            .filter-progress-bar {
                position: absolute;
                left: 0;
                top: 0;
                background-color: $tp-green;
                height: 100%;
            }
        }

        .tp-widget-reviews-inner {
            position: relative;

            &::after {
                position: absolute;
                display: none;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.7);
            }

            &.is-loading::after {
                display: block;
            }
        }

        .tp-widget-review__content {
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            .tp-widget-review__text {
                padding-left: 10px;
            }
        }

        .trustpilot-review-item{
            .tp-widget-review__flag {
                flex-grow: 0;
                flex-shrink: 0;
                width: 20px;
                height: 20px;
                fill: #fff;
            }
        }

        .tp-widget-reviews__pagination {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 14px;

            a {
                padding: 5px;

                &.disabled {
                    pointer-events: none;
                    opacity: 0.5;
                }
            }
        }
    }
}
