.modal-request-callback {

    .heading {

        .icon {
            margin: 5px auto;
        }

        h4 {
            margin-bottom: 0.5em;
        }

        a {
            text-decoration: underline;
        }
    }

    .request {

        &__form {

            .input__field {
                height: 45px;
                border-radius: $border-radius;
            }

            label {
                text-align: left;
                color: $color-text-accent;
                font-weight: $font-weight-semi-bold;

                &.error {
                    margin-top: 5px;
                    color: $message__color--error;
                }
            }
        }

        &__actions {

            .button {
                margin-top: 16px;
            }
        }
    }
}

aside {
    .installer-contact-pdp__radios {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        @include mq($screen-l) {
            justify-content: space-between;
            flex-wrap: nowrap;
        }
    }

    .installer-contact-pdp__radio {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: 0 8px 8px;
        width: auto;

        @include mq($screen-l) {
            margin: 0 8px;
            width: 100%;
        }

        label {
            margin: 0 0 0 8px;

            &[generated="true"] {
                position: absolute;
                top: -37px;
                left: 130px;
            }
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin: 0;
        }
    }

    .heading-icon-envelope {
        &:before {
            content: '';
            display: block;
            margin: 0 auto 8px;
            width: 24px;
            height: 25px;
            background: svg-uri($envelope-icon-svg) no-repeat;
        }
    }

    .heading-icon-writing {
        &:before {
            content: '';
            display: block;
            margin: 0 auto 8px;
            width: 24px;
            height: 25px;
            background: svg-uri($envelope-icon-svg) no-repeat;
        }
    }
}

.modal-attachment-download {

    &.modal-popup.modal-slide {
        @include mq(false, $screen-m) {
            height: auto;
        }
        .modal-inner-wrap {
            @include mq($screen-m) {
                width: 360px;
            }
        }
    }

    svg {
        display: inline-block;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        fill: $color-primary;
    }

    .checkbox__label--checkbox {
        padding-left: 30px;
        cursor: pointer;

        span {
            margin-top: 25px;
            transform: translateY(-25%);
            font-weight: normal;
        }
    }

    .request__actions .button {
        margin: 10px 0;
        width: 100%;
    }

    #download-now-link {
        display: block;
        text-align: left;
        padding-top: 10px;
    }
}