$mega-menu__margin                           : 0 !default;
$mega-menu__border-bottom                    : 1px solid $gray-light !default;
$mega-menu__font-color                       : $gray-darker !default;
$mega-menu__font-weight                      : $font-weight-bold !default;
$mega-menu__font-size                        : $font-size-base !default;
$mega-menu__font-size--large                 : 16px !default;
$mega-menu__font-size--small                 : $font-size-small !default;
$mega-menu__background-color                 : $white !default;

$mega-menu__list-padding                     : 0 !default;
$mega-menu__list-justify-content             : flex-start !default;
$mega-menu__list-background-color            : $white !default;

$mega-menu__inner-list-padding               : 0 !default;
$mega-menu__inner-list-background-color      : $white !default;
$mega-menu__inner-list-box-shadow--level1    : 0 4px 6px 0 rgba(51, 51, 51, 0.3) !default;
$mega-menu__inner-list-border--level1        : 1px solid $gray-light !default;

$mega-menu__inner-item-padding--parent       : 16px 0 16px 16px !default;
$mega-menu__inner-item-padding--parent-child : 0 !default;
$mega-menu__inner-item-maargin--parent-child : 0 0 $spacer--medium !default;

$mega-menu__inner-item-padding--image        : 0 !default;
$mega-menu__inner-item-border--image         : 1px solid $gray-light !default;
$mega-menu__inner-item-top--image            : 66px;
$mega-menu__inner-item-right--image          : 16px;
$mega-menu__inner-item-max-width--image      : 210px;

$mega-menu__inner-item-icon-color            : $color-primary !default;
$mega-menu__inner-item-icon-size             : 14px !default;

$mega-menu__link-padding                     : 0px $spacer--medium !default;
$mega-menu__link-margin                      : 0px $spacer--medium !default;
$mega-menu__link-color                       : $gray-darker !default;
$mega-menu__link-color--active               : $color-primary !default;
$mega-menu__link-text-decoration             : none !default;

$mega-menu__inner-link-margin                : 0 !default;
$mega-menu__inner-link-padding               : 0 !default;
$mega-menu__inner-link-font-weight           : $font-weight-normal !default;
$mega-menu__inner-link-text-decoration       : none !default;
$mega-menu__inner-link-color                 : $gray-darker !default;
$mega-menu__inner-link-color--active         : $gray-darker !default;
$mega-menu__inner-link-color--image-focus    : $font-color-base !default;
$mega-menu__inner-link-text-decoration--active : underline !default;
$mega-menu-after-icon-fill                   : $color-primary !default;
$mega-menu-height                            : 3.5em !default;

$side-menu__trigger-wrapper-border      : 1px solid $gray-light !default;
$side-menu__trigger-wrapper-background  : $gray-lightest !default;
$side-menu__trigger-size                : 41px !default;
$side-menu__trigger-margin              : 0 !default;
$side-menu__trigger-padding             : 8px !default;
$side-menu__trigger-border              : 1px solid $gray-light !default;
$side-menu__trigger-border-radius       : $border-radius;
$side-menu__trigger-background-color    : $white !default;
$side-menu__trigger-box-shadow          : none !default;
$side-menu__trigger-icon-size           : 18px !default;
$side-menu__trigger-icon-color          : $color-primary !default;
$side-menu__trigger-icon-size--close    : 42px !default;
$side-menu__title-font-size             : $font-size-medium !default;
$side-menu__title-font-weight           : $font-weight-bold !default;

body:not(.new-menu) {
    .mega-menu {
        display: none;
        background: $mega-menu__background-color;
        color: $mega-menu__font-color;
        position: relative;
        margin: $mega-menu__margin;
        border-bottom: $mega-menu__border-bottom;
        @include mq($screen-m) {
            display: block;
            z-index: 45;
        }

        &__side-menu {
            order: 4;
            flex: 0 auto;
            margin-top: 7px;

            @include mq($screen-m) {
                display: none;
            }
        }

        &__wrapper {
            display: flex;
        }

        &__list {
            display: flex;
            margin: 0;
            flex-wrap: nowrap;
            justify-content: $mega-menu__list-justify-content;
            padding: $mega-menu__list-padding;
            list-style: none;
            background-color: $mega-menu__list-background-color;
            width: 80%;

            @include isiOSDevice {
                @include mq($screen-m) {
                    width: 70%;
                }
            }
        }

        &__list-secondary {
            position: relative;
            display: flex;
            margin: 0;
            flex-wrap: nowrap;
            justify-content: $mega-menu__list-justify-content;
            padding: $mega-menu__list-padding;
            list-style: none;
            background-color: $mega-menu__list-background-color;
            width: 20%;

            @include isiOSDevice {
                @include mq($screen-m) {
                    width: 30%;
                }
            }
        }

        &__inner-list {
            padding: $mega-menu__inner-list-padding;
            background-color: $mega-menu__inner-list-background-color;
            list-style: none;

            &--level1 {
                display: none;
                position: absolute;
                flex-wrap: wrap;
                top: 100%;
                left: 50%;
                z-index: 4;
                width: 100%;
                max-width: $max-content-width;
                transform: translate(-50%, 0);
                border-top: $mega-menu__inner-list-border--level1;
                box-shadow: $mega-menu__inner-list-box-shadow--level1;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
        }

        &__item {
            &--image {
                position: absolute;
                top: $mega-menu__inner-item-top--image;
                right: calc(((100% - #{$max-content-width}) / 2) + 38px);
                max-width: $mega-menu__inner-item-max-width--image;
                border: $mega-menu__inner-item-border--image;
                padding: $mega-menu__inner-item-padding--image;
                z-index: 4;

                .mega-menu__inner-link {
                    display: block;

                    &:focus {
                        @include focus();
                        background-color: transparent;
                        color: $mega-menu__inner-link-color--image-focus;
                    }
                }

                img {
                    width: auto;
                }
            }
        }

        &__inner-item {
            &--parent {
                padding: $mega-menu__inner-item-padding--parent;
                flex-basis: 20%;

                &:nth-child(4) {
                    flex-basis: 40%;
                    padding-right: 20%;
                }

                & > .mega-menu__inner-link {
                    display: flex;
                    margin: $mega-menu__inner-item-maargin--parent-child;
                    padding: $mega-menu__inner-item-padding--parent-child;
                    font-size: $mega-menu__font-size;
                    font-weight: $mega-menu__font-weight;

                    &:after {
                        content: '';
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16"><path fill-rule="evenodd" fill="#{$mega-menu-after-icon-fill}" d="M2.33 0L.45 1.88 6.55 8l-6.1 6.12L2.33 16l8-8z"/></svg>') no-repeat center;
                        background-size: contain;
                        margin-left: 2px;
                        margin-top: 6px;
                    }
                }
            }
        }

        &__inner-item-icon {
            fill: $mega-menu__inner-item-icon-color;
            width: $mega-menu__inner-item-icon-size;
            height: $mega-menu__inner-item-icon-size;
        }

        &__link {
            display: table-cell;
            padding: 0 15px;
            font-size: $mega-menu__font-size--large;
            font-weight: $mega-menu__font-weight;
            color: $mega-menu__link-color;
            text-decoration: $mega-menu__link-text-decoration;
            height: $mega-menu-height;
            vertical-align: middle;
            text-align: center;

            &:hover {
                text-decoration: $mega-menu__link-text-decoration;
                color: $mega-menu__link-color--active;
            }

            @media(max-width: 1280px) {
                font-size: $mega-menu__font-size--small;
            }
        }

        &__inner-link {
            margin: $mega-menu__inner-link-margin;
            padding: $mega-menu__inner-link-padding;
            font-size: $mega-menu__font-size;
            font-weight: $mega-menu__inner-link-font-weight;
            color: $mega-menu__inner-link-color;
            text-decoration: $mega-menu__inner-link-text-decoration;

            &:hover {
                text-decoration: $mega-menu__inner-link-text-decoration--active;
                color: $mega-menu__inner-link-color--active;
            }
        }

        &__inner-link-icon {
            align-self: baseline;
            margin-top: 4px;
        }

        &__column-hidden {
            > .mega-menu__inner-link {
                display: none;
            }
        }

        &__trigger-wrapper {
            display: flex;
            justify-content: flex-start;
            border-bottom: $side-menu__trigger-wrapper-border;
            background: $side-menu__trigger-wrapper-background;
        }

        &__trigger {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            height: $side-menu__trigger-size;
            min-width: $side-menu__trigger-size;
            margin: $side-menu__trigger-margin;
            padding: $side-menu__trigger-padding;
            border: $side-menu__trigger-border;
            border-radius: $side-menu__trigger-border-radius;
        }

        &__trigger-icon {
            width: $side-menu__trigger-icon-size;
            height: $side-menu__trigger-icon-size;
            fill: $side-menu__trigger-icon-color;
        }

        &__trigger--close {
            padding: 0;
            border: 0;
            background-color: transparent;
            align-self: center;

            .mega-menu__trigger-icon {
                width: $side-menu__trigger-icon-size--close;
                height: $side-menu__trigger-icon-size--close;
            }

            &.back {
                margin-left: 11px;

                .mega-menu__trigger-icon {
                    width: 22px;
                    height: 22px;
                }
            }
        }

        &__trigger-text {
            font-size: 12px;
            font-weight: 600;
            margin: 0 0 0 4px;
            color: $color-primary;
        }

        &__title {
            text-align: center;
            padding: 25px 0;
            width: calc(100% - (2 * #{$side-menu__trigger-size}));
            font-size: $side-menu__title-font-size;
            font-weight: $side-menu__title-font-weight;
        }

        &__heading {
            display: block;
            font-weight: bold;
            padding: 15px 0;
            color: $color-primary;

            @include mq($screen-m) {
                color: $color-text-accent;
                font-weight: bold;
                margin: 0 20px;
                padding: 5px;
            }

            &--link {
                padding: 0;
                margin: 0;
            }
        }
    }
}
