.rating {
    &__summary {
        display: block;
        position: relative;
        overflow: hidden;
        width: 100px;
    }

    &__results {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
    }

    &__icon {
        width: 100px;
        height: 30px;
        fill: $gray-lighter;

        &--results {
            fill: $yellow;
        }
    }
}
