$minicart-content__font-size                : $font-size-base !default;
$minicart-content__border                   : 1px solid $gray-light !default;

$minicart-content__top-bar-height           : 8px !default;
$minicart-content__top-bar-background       : $gray-light !default;
$minicart-content__top-bar-active-width     : 64px !default;
$minicart-content__top-bar-active-background: $color-primary !default;

$minicart-content__total-margin             : $spacer--medium 0 !default;
$minicart-content__total-padding            : 0 $spacer--medium !default;

$minicart-content__summary-font-weight      : $font-weight-bold !default;
$minicart-content__summary-color            : $color-secondary !default;

$minicart-content__actions-padding          : 10px $spacer--medium (2 * $spacer--medium) $spacer--medium !default;

$minicart-content__edit-padding             : $spacer--medium !default;
$minicart-content__edit-font-weight         : $font-weight-bold !default;
$minicart-content__edit-text-decoration     : none !default;

$minicart-content__products-list-padding    : 0 0 10px 0 !default;
$minicart-content__products-list-margin     : $spacer 0 0 0 !default;

.minicart-content {
    height: auto !important; // sass-lint:disable-line no-important
    max-height: calc(100vh - 90px);
    font-size: $minicart-content__font-size;
    overflow-y: auto;
    @include mq($screen-l) {
        max-height: calc(100vh - 120px);
    }

    &__bar {
        position: relative;
        height: $minicart-content__top-bar-height;
        width: 100%;
        background-color: $minicart-content__top-bar-background;

        &:after {
            position: absolute;
            top: 0;
            right: 0;
            content: '';
            height: $minicart-content__top-bar-height;
            width: $minicart-content__top-bar-active-width;
            background-color: $minicart-content__top-bar-active-background;
        }
    }

    &__total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        margin: $minicart-content__total-margin;
        padding: $minicart-content__total-padding;

        &-counter {
            align-self: flex-start;
        }
    }

    &__summary {
        text-align: right;
    }

    &__summary-value {
        font-weight: $minicart-content__summary-font-weight;
        color: $minicart-content__summary-color;
    }

    &__actions {
        display: flex;
        padding: $minicart-content__actions-padding;
    }

    &__edit {
        display: block;
        padding: $minicart-content__edit-padding;
        font-weight: $minicart-content__edit-font-weight;
        text-align: center;
        text-decoration: $minicart-content__edit-text-decoration;
        &:focus,
        &:hover {
            text-decoration: $minicart-content__edit-text-decoration;
        }
    }

    &__products-list {
        margin: $minicart-content__products-list-margin;
        padding: $minicart-content__products-list-padding;
        border-bottom: $minicart-content__border;
        max-height: 280px;
        overflow-y: auto;

        @include mq($screen-l) {
            max-height: 360px;
        }

        @media (max-height: 800px) {
            max-height: 240px;
        }

        @media (max-height: 650px) {
            max-height: 120px;
        }
    }

    &__checkout {
        width: calc(50% - 20px);
        flex: 1 auto;
        
        &:first-child {
            margin-right: 10px;
        }
    }

    &__totals {
        display: flex;
        flex-direction: column;
    }

    &__totals-block {
        display: flex;
        flex-direction: column;
        padding: 8px 0;

        &:first-child {
            padding-top: 12px;
        }

        &--separator {
            border-top: $border-base;
            padding: 0 0 $spacer 0;
            margin-top: $spacer;
        }

        &--discount {
            color: #47ad4c;
            font-weight: 600;
        }

        &--subtotals {
            position: relative;
            .has-gradient & {
                &:before {
                    top: -60px;
                    height: 50px;
                    content: ' ';
                    position: absolute;
                    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 2%, #ffffff);
                    width: 100%;
                    pointer-events: none;
                }
                &:after {
                    content: '';
                    height: 9px;
                    width: 100%;
                    background: #fff;
                    top: -10px;
                    position: absolute;
                }
            }
        }

        &--grand-totals {
            border-bottom: none;
        }
        .tooltip--shipping .tooltip__icon {
            display: block;
            line-height: 20px;
            font-weight: bold;
            margin: 0 10px 0 0;
            background: $product-tooltip-background-color;
            border: $product-tooltip-border;
            color: $product-tooltip-border;
        }
        .tooltip__content .price {
            font-size: 14px;
            font-weight: normal;
        }
    }

    &__totals-row {
        display: flex;
        flex-direction: row;
        padding: 0 (2 * $spacer--medium);
        &.shipping-notice {
            display: block;
            padding-top:10px;
            text-align: center;
            font-size: 14px;
            .price, strong {
                font-size: 14px;
            }
        }
    }

    &__total-label {
        flex: 1 50%;
        &--grand-total {
            font-weight: $font-weight-bold;
        }
    }

    &__total-label-subtitle {
        font-weight: normal;
        font-size: $font-size-small;
    }

    &__total-value {
        flex: 1 50%;
        text-align: right;

        .price {
            font-size: inherit;
            font-weight: inherit;
        }

        &--grand-total {
            font-weight: $font-weight-bold;
        }

    }


}

#minicart-feedback {
    border-bottom: $border-base;

    &:empty {
        display: none;
    }
}

.minicart-feedback-success {
    color: $color-alt;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin: 0;
    padding: 10px 15px 5px;

    svg {
        width: 30px;
        fill: $color-alt;
    }
}

.minicart-open .minicart-content::before {
    content: ' ';
    display: block;
    width: 54px;
    height: 40px;
    background: #fff;
    position: absolute;
    right: 0;
    top: -28px;
    z-index: -1;
}

.minicart-open{
    .header-button__icon--minicart-arrow{
        transform: rotate(180deg);
    }
}

#cart-is-empty + dialog {
    border-radius: 5px;
    transform: translateY(80%);

    .subtitle.empty {
        padding: 15px;
    }
}
