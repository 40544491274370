.quote-offer-view-page {
  [data-ui-id^="page-title-wrapper"] {
    text-transform: none;
    color: $color-primary;
    font-weight: 700;
  }

  .heading--page {
    padding-top: 10px;
  }

  &__quote-offer-assistance {
    justify-content: center;
    @include mq($screen-m) {
      flex-wrap: nowrap;
    }

    &__avatar {
      padding: 20px;
      z-index: 1;
      margin-bottom: -30px;

      @include mq($screen-m) {
        margin-bottom: unset;
      }

      img {
        width: 75px;
        height: auto;
        border-radius: 100%;
      }
    }

    &__details {
      background-color: $color-primary--accent-2;
      padding: 20px;
      border-radius: 5px;

      @include mq($screen-m) {
        flex: 0 1 100%;
      }

      .info {
        display: flex;
        align-items: center;

        svg {
          margin-right: 5px;
        }
      }
    }
  }

  &__quote-offer-items {
    &__title {
      color: $color-primary;
      font-weight: 600;
      font-size: 18px;
    }

    &__header {
      display: none;

      @include mq($screen-m) {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 10px 0;
        font-weight: bold;
      }

      &__product {
        flex-basis: 60%;
      }

      &__details {
        display: flex;
        flex-basis: 40%;
        justify-content: space-between;
        text-align: center;

        &__column {
          flex-basis: 10%;
          min-width: 100px;

          &:last-of-type {
            text-align: right;
          }
        }
      }
    }

    &__item {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      border-top: $border-base;

      &:last-child {
        border-bottom: $border-base;
      }

      @include mq($screen-m) {
        flex-flow: row nowrap;
      }
    }

    .quote-offer-item {
      &__details {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        flex-basis: 100%;

        img {
          @include mq(false, $screen-m) {
            width: 75px;
            height: 75px;
          }

          @include mq($screen-m) {
            flex-basis: 10%;
            margin-right: 20px;
          }
        }

        @include mq($screen-m) {
          flex-basis: 50%;
        }
      }

      &__text {
        display: flex;
        flex-flow: row wrap;
        align-self: stretch;
        flex-basis: 75%;
        padding: 5px 0;

        @include mq($screen-m) {
          flex-basis: 90%;
        }

        span:first-child {
          align-self: self-end;
        }

        span:last-child {
          color: #9d9d9d;
          padding-top: 5px;
        }

        span {
          flex-basis: 100%;
        }
      }

      &__totals {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        flex-basis: 100%;

        @include mq($screen-m) {
          flex-basis: 40%;
        }

        &__column {
          flex-basis: 10%;
          text-align: center;
          min-width: 100px;

          &:last-of-type {
            text-align: right;
          }
        }
      }
    }
  }

  &__totals {
    background-color: $color-primary--accent-2;
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;

    &__subtotal, &__subtotal_without_discount, &__discount, &__shipping, &__ecotax, &__total_excl, &__tax, &__total_incl {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      &__column:last-child {
        text-align: right;
      }

      &__column {
        padding-top: 15px;
        flex-basis: 50%;
      }
    }

    &__discount__column:last-of-type {
      color: green;
    }

    &__total_excl {
      margin-top: 10px;
      border-top: 2px solid $color-light--accent;
    }

    &__total_excl, &__total_incl {
      font-weight: bold;
    }

    &__button {
      padding-top: 20px;

      > .button {
        color: $color-primary;
        background-color: $white;
        border: none;
        font-weight: 600;
        &--cta {
          color: $white;
          background-color: $color-cta;

          &:hover {
            font-weight: 600;
            background-color: $button__background-hover--cta-secondary;
          }
        }
      }

    }


    &__terms {
      padding-top: 10px
    }
  }
}