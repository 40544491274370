//
//  Default appearance styles
//  _____________________________________________

figure[data-content-type='image'] {
    > [data-element='link'],
    > [data-element='link'] img {
        border-radius: inherit;
    }

    figcaption {
        word-wrap: break-word;
    }
}
