$cart-summary__padding                   : 29px !default;
$cart-summary__margin                    : 0 -#{$global-container-margin} !default;
$cart-summary__background                : $color-primary--accent-2 !default;
$cart-summary__item-padding              : 5px 0 !default;

#cart-summary-container {
    @include mq($screen-l) {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.cart-summary {
    margin: $cart-summary__margin;
    padding: $cart-summary__padding;
    background-color: $cart-summary__background;
    border-radius: 5px;

    .paypal-express-in-context-mini-cart-container {
        position: relative;
        z-index: 1;

        .paypal-button {
            overflow: visible !important; // Have to override inline styles from PayPal
        }
    }

    @include mq($screen-l) {
        margin: 0;
    }

    &__item {
        position: relative;
        display: block;
        flex: 1 0 100%;
        padding: $cart-summary__item-padding;

        .braintree-paypal-logo {
            position: relative;
            z-index: 1;
        }
    }

    &__checkout-button {
        display: flex;
        flex-flow: row nowrap;
        margin-bottom: 0;
        width: 100%;

        span {
            line-height: $font-size-base;
        }

        .icon {
            order: 2;
            width: 16px;
            height: 16px;
            padding: 3px;
            margin: 0 -4px 0 4px;
        }
    }
}
