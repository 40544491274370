$layout-home-column-width: 100% !default;
$layout-sidebar-width: 25% !default;
$layout-content-width: calc(100% - #{$layout-sidebar-width}) !default;
$layout-sidebar-additional-display: block !default;
$layout-columns-justify-content: flex-start !default;

.page-layout-2columns-left,
.page-layout-2columns-right {
    .columns {
        display: flex;
        flex-direction: column;
        justify-content: $layout-columns-justify-content;

        @include mq($screen-m) {
            flex-flow: row wrap;

            .ambanners.ambanner-11 {
                flex-basis: 100%;
                margin: 10px 0 25px;
            }

            .home {
                width: $layout-home-column-width;
            }
        }
    }

    .main {
        @include mq($screen-m) {
            order: 1;
            flex: 1 0 $layout-content-width;
            max-width: $layout-content-width;
        }
    }

    .sidebar {
        @include mq($screen-m) {
            flex: 1 0 $layout-sidebar-width;
            max-width: $layout-sidebar-width;
        }

        &-additional {
            display: $layout-sidebar-additional-display;
        }
    }
}

.page-layout-2columns-right {
    .main {
        @include mq($screen-m) {
            order: 0;
        }
    }
}
