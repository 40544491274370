//
//  Default appearance styles
//  _____________________________________________

[data-content-type='button-item'] {
    max-width: 100%;

    [data-element='link'],
    [data-element='empty_link'] {
        max-width: 100%;
        word-wrap: break-word;
    }

    [data-element='empty_link'] {
        cursor: default;
    }
}

a,
button,
div {
    &.pagebuilder-button-link {
        @extend .button;
        @extend .button--link;

        display: inline-flex !important; // sass-lint:disable-line no-important
        box-shadow: none;
    }

    &.pagebuilder-button-primary {
        @extend .button;

        display: inline-flex !important; // sass-lint:disable-line no-important
        box-shadow: none;
        text-decoration: none;
    }

    &.pagebuilder-button-secondary {
        @extend .button;
        @extend .button--secondary;

        display: inline-flex !important; // sass-lint:disable-line no-important
        box-shadow: none;
        text-decoration: none;
    }
}
