$self : '.product-view';

#{$self}--grouped {
    flex-wrap: wrap;

    .grouped-info-header {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: $border-base;

        #algolia_instant_selector {
            @include mq(false, $screen-l) {
                width: 100%;
                margin: 10px 0;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;

                .grouped-filter-overlay__trigger {
                    height: 45px;
                    margin-right: 5px;
                }

                .ais-SortBy-select {
                    margin: 0;
                }
            }

            .ais-SortBy {
                position: relative;
                display: flex;
                align-items: center;
                width: 49%;
                height: 45px;

                @include mq($screen-l) {
                    width: 100%;
                }

                &-select {
                    @include mq(false, $screen-l) {
                        font-size: 14px;
                        width: 100%;
                    }
                }

                &:after {
                    @include mq(false, $screen-l) {
                        top: unset;
                        right: 10px;
                    }
                }
            }

            label {
                display: none;

                @include mq($screen-l) {
                    display: inline-block;
                    margin: 0;
                    color: $color-text-accent;
                }
            }

            select.ais-SortBy-select {
                @include mq($screen-l) {
                    margin-left: 8px;
                }
            }
        }
    }

    #{$self}__wrapper {
        margin: $spacer--medium 0 0 0;

        @include mq($screen-m) {
            margin: 0 0 20px 0;
        }
    }

    #{$self}__main-details {
        display: block;
        position: relative;
        float: right;
        border: none;
        width: 100%;
        order: $product-view__order-main-details;
        margin-bottom: $product-view__main-details-bottom-spacing;
        border-radius: 0;
        box-shadow: none;
        padding: inherit;
    }

    #{$self}__secondary-details {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        order: $product-view__order-secondary-details;

        .product-view__product-name {
            display: flex;
            flex-basis: 100%;
            font-size: 24px;
            font-weight: bold;
        }

        @include mq($screen-m) {
            .product-view__product-name {
                margin-top: 15px;
            }

            .product-view__trustpilot-summary {
                margin-bottom: 30px;
            }
        }

        .register-wrapper {
            display: flex;
            flex-basis: 100%;
            flex-direction: column;

            @include mq(false, $screen-m) {
                display: block;
            }
        }

    }

    #instant-search-facets-container {
        @include mq($screen-m) {
            margin-top: 27px;
        }
    }

    #{$self}__filter {
        margin: 20px 0;
    }

    #{$self}__trustpilot-summary,
    .trustpilot-reviews {
        flex-basis: 100%;
    }

    #{$self}__need-help {
        display: flex;
        flex-grow: 1;
        margin-top: 10px;

        .need-help {
            display: none;
        }

        @include mq($screen-m) {
            justify-content: flex-end;
            margin: 45px 0 0 auto;

            .need-help {
                display: inline-block;
                height: fit-content;
            }
        }
    }

    .ais-ClearRefinements-button {
        margin-top: 30px;
    }

    #{$self}__static-block {
        margin: 0 0 10px 0;
        padding: 0 0 10px 0;

        @include mq($screen-m) {
            padding-right: 25px;
        }

        &--border {
            border-bottom: none;

            @include mq($screen-m) {
                border-bottom: 1px solid $gray-light;
            }
        }
    }
}
