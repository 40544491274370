$product-finder-banner__border-radius: $border-radius !default;
$product-finder-banner__background-color: $color-primary--accent-2 !default;
$product-finder-banner__text-color: $color-primary !default;


.product-finder-banner {
    @include mq($screen-m) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    background-color: $product-finder-banner__background-color;
    border-radius: $product-finder-banner__border-radius;
    padding: 15px 20px;
    margin-bottom: 20px;

    &__image {
        display: none;
        @include mq($screen-m) {
            display: block;
            img {
                max-height: 60px;
            }
        }
    }

    &__text {
        @include mq($screen-m) {
            display: flex;
            align-items: center;
        }
    }

    &__main-text {
        font-size: 25px;
        font-weight: $font-weight-bold;
        color: $product-finder-banner__text-color;

        @include mq($screen-m) {
            margin-right: 10px;
        }

        @include mq(false, $screen-m) {
            margin-bottom: 10px;
            font-size: 18px;
        }
    }

    &__sub-text {
        font-size: 25px;
        color: $product-finder-banner__text-color;

        @include mq(false, $screen-m) {
            margin-bottom: 10px;
            font-size: 18px;
        }
    }

    &__cta {
        @include mq(false, $screen-m) {
            width: 100%;

            .button {
                width: 100%;
                margin-top: 10px;
            }
        }
    }
}

.top-brands {
    margin-bottom: 20px;

    &__title {
        font-weight: bold;
        margin-bottom: 10px;
        color: $color-primary;
    }

    &__brands {
        @include mq($screen-m) {
            display: flex;
        }
    }

    &__brand {
        height: auto;
        width: 100%;
        max-height: 75px;
        overflow: hidden;

        img {
            max-height: 60px;
        }

        @include mq($screen-m) {
            flex-shrink: 0;
            flex-grow: 1;
            margin-right: 20px;
            width: auto;
            max-height: 125px;

            &:last-child {
                margin-right: 0;
            }

            img {
                max-height: 100px;
            }
        }

        @include mq(false, $screen-m) {
            margin: 0 0 10px 0;
            padding: 0;
            border-radius: 5px;
            box-shadow: 0 2px 10px -6px rgba(0, 0, 0, 0.5);

            &:after {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16"><path fill-rule="evenodd" fill="#{$color-primary}" d="M2.33 0L.45 1.88 6.55 8l-6.1 6.12L2.33 16l8-8z"/></svg>') no-repeat center;
                background-size: contain;
                margin-left: 3px;
                transform: translateY(1px);
                position: absolute;
                right: 25px;
            }
        }
    }
}
