.modal-request-success {

    .heading {

        .icon {
            margin: 5px auto;
            fill: #fff;
            background: $color-alt;
            border-radius: 50%;
            width: 38px;
            height: 38px;
        }

        h4 {
            margin-bottom: 0.5em;
            color: $color-alt;
        }

        p {
            text-align: center;
        }
    }

    .request {

        &__actions {

            .button {
                margin-top: 16px;
            }
        }
    }

    &.modal-popup {
        &.modal-slide {
            .modal-inner-wrap {
                height: auto;
            }
        }
    }
}

.modal-organisation-type-popup {
    width: 95vw;

    .modal-content {
        padding-left: 8px !important;
        padding-right: 8px !important;

        @include mq($screen-m) {
            padding-left: 32px !important;
            padding-right: 32px !important;
        }
    }

    @include mq($screen-m) {
        width: 684px;
    }

    &.modal-popup .modal-inner-wrap {
        width: 100%;
        bottom: 0;
        transform: none;

        @include mq($screen-m) {
            bottom: 50%;
        }
    }

    .heading-content {
        text-align: left;

        h4 {
            color: $color-primary;
        }

        p {
            text-align: left;
        }

        .org-types {
            display: flex;
            flex-flow: row wrap;
            gap: 4px;

            &__svg {
                stroke: $color-primary;
                stroke-width: 4;
                stroke-dasharray: none;
                stroke-linecap: butt;
                stroke-linejoin: miter;
                stroke-miterlimit: 10;
                fill: $color-primary;
                fill-rule: nonzero;
                opacity: 1;
            }
        }

        button {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            flex-basis: 100%;
            min-width: 200px;
            margin-top: 10px;
            padding: 12px 18px;
            gap: 8px;
            border: 1.5px solid #ebebeb;
            border-radius: 2px;
            font-weight: 800;
            font-size: 16px;
            color: $color-primary !important;
            background-color: rgb(239, 239, 239);
            background-size: cover;
            background-repeat: no-repeat;

            @include mq($screen-m) {
                flex-basis: auto;
            }

            &:hover {
                background-color: rgba(211, 211, 211, .15);
                cursor: pointer;
            }
        }
    }

    .modal__close-button {
        display: none;
    }
}
