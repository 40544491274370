.page-layout-checkout .modal-popup.transsmart-pickup-locations {

    .pickup-location-selector .topbar {
        display: flex;
        flex-direction: row;
    }

    .pickup-location-selector .topbar-filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .pickup-location-selector .main {
        position: relative;
        display: flex;
        flex-direction: row;
        min-height: 400px;
    }

    .pickup-location-selector .main.is-loading:after {
        position: absolute;
        display: block;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4);
    }

    .pickup-location-selector .main .main-map {
        flex-grow: 1;
        flex-shrink: 1;
        position: relative;
    }

    .pickup-location-selector .main .main-map .gmap {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .pickup-location-selector .main .main-list {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 200px;
        overflow-y: auto;
    }

    .modal-inner-wrap {
        @media(min-width: 1024px) {
            width: 950px;
            margin-left: -475px;
            margin-top: 25px;
        }
        @media(max-width: 768px) {
            width: calc(100% - 30px);
            margin: 15px auto;
        }
    }

    .modal-header {
        padding: 20px;

        h1 {
            margin: 0;
        }
    }

    .modal-content {
        padding-left: 0;
        padding-right: 0;
    }

    .modal-footer {
        border-top: 0;
        padding: 20px;
        display: flex;
        justify-content: flex-end;

        @include mq(false, $screen-l) {
            justify-content: center;
            position: sticky;
            bottom: 0;

            .button {
                width: 100%;
            }
        }
    }

    .modal-inner-wrap {
        .modal__close-button {
            display: block;
            background-color: $color-primary;
            position: absolute;
            right: 0;
            width: 40px;
            height: 40px;
            border-radius: 0;
            top: 0;
            min-width: unset;
        }
        .modal__close-button-icon {
            fill: #fff;
        }
    }

    .topbar {
        margin: 0 20px 20px;
        display: flex;
        justify-content: space-between;
        @media(max-width: 1200px) {
            flex-direction: column;
            flex-wrap: wrap;
        }
    }

    .topbar-search {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        @media(max-width: 1200px) {
            margin-bottom: 10px;
            flex-wrap: wrap;

            label,
            button {
                margin: 5px 0;
            }
        }

        label {
            margin-bottom: 0;

            > span { display: none; }
            > input {
                box-sizing: border-box;
                background: #EFEFEF;
                border-radius: 5px;
                border: none;
                color: black;
                height: 36px;
                padding: 0 10px;
                margin-right: 10px;
            }
        }

        button {
            height: 36px;
            padding: 7px 20px;
            background-color: $color-primary;
            border: none;
            color: #fff;
            border-radius: 5px;
        }
    }

    .topbar-filters {

        .filter {
            display: flex;
            margin-left: 10px;

            @media(max-width: 1200px) {
                margin: 5px 0;
                flex-basis: 50%;
            }

            span {
                font-size: 14px;
            }
        }

        label {
            font-size: 11px;
            color: $color-primary--accent-5;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            font-weight: bold;
            cursor: pointer;

            input {
                margin-right: 5px;
            }
        }

    }

    .main {
        @media(max-width: 1200px) {
            min-height: unset;
        }
    }

    .main-map {
        @media(max-width: 1200px) {
            display: none;
        }
    }

    .main-list {
        margin: 0;
        padding: 0 15px;
        list-style: none;
        flex-basis: 220px;
        @media(max-width: 1200px) {
            flex-grow: 1 !important;
        }
    }

    .main-list-location {
        border-bottom: 1px solid #E2E2E2;

        &:last-child {
            border-bottom: none;
        }

        label {
            padding: 14px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0;

            span {
                font-size: 14px;
                font-weight: bold;
                color: $color-primary--accent-5;
                display: inline-block;
                margin-right: 10px;
            }
        }

        &.is-active {
            label span {
                color: #0093d0;
            }
        }
    }

    .action-accept {
        height: 36px;
        padding: 7px 20px;
        background-color: $color-primary;
        border: none;
        color: #fff;
        border-radius: 5px;
    }

    .no-results {
        display: none;
        position: absolute;
        left: 50%;
        top: 50%;
        padding: 25px;
        transform: translate(-50%, -50%);
        background: $color-light;
    }

    .pickup-location-selector--no-results {

        .no-results {
            display: block;
        }

        .gmap {
            opacity: 0.4;
        }
    }
}

.pickuppoint-hint {
    display: inline-block;
    padding: 0 0 10px 0;
}

.pickuppoint-button {
    margin-bottom: 10px;

    span {
        background: transparent !important;
    }

    &:disabled {
        background: lighten($color-primary, 30%);
    }
}