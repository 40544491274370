.modal-tier-upgrade {
    margin: 0 auto;
    width: 90%;
    height: auto;
    max-height: 90%;

    @include mq($screen-m) {
        height: 100%;
        width: 35%;
        margin: 50px auto;
        box-shadow: none;
    }

    .heading {
        text-align: center;
        margin-bottom: 20px;
        font-size: $font-size-medium;
        color: $color-text-accent;
        font-weight: $font-weight-bold;

        &:before {
            content: '';
            display: block;
            margin: 0 auto 8px;
            width: 32px;
            height: 33px;
            background: svg-uri($percentage-icon-svg) no-repeat;
        }
    }

    .actions-wrapper {
        width: 100%;
        margin: 0 auto;

        @include mq($screen-m) {
            width: 50%;
        }
    }

    .button {
        margin: 5px 0;
    }

    &__wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        .button[data-role='closeBtn'] {
            width: 100%;
        }
    }

    &__title {
        text-align: center;
        margin-top: 15px;
        flex-basis: 100%;
        font-weight: $font-weight-bold;

        @include mq($screen-m) {
            text-align: left;
        }
    }

    &__suggestion {
        margin-top: 5px;
    }

    &.modal-popup,
    &.modal-slide {
        overflow-y: hidden !important;
        left: 0 !important;

        .modal-header {
            padding: 20px 50px 0;
        }

        .modal-inner-wrap {
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.35);
            border-radius: $border-radius;
        }
    }
}
