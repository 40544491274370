$self : '.blog';

.blog-post-view {

    .title-wrapper {
        border-bottom: $border-base;
        margin-bottom: 15px;

        .heading {
            text-transform: none;
            width: 100%;
            color: $color-primary;
            font-weight: $font-weight-bold;
            font-size: 24px;

            @include mq($screen-m) {
                font-size: 28px;
            }
        }
    }
}

#{$self} {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -#{$global-container-margin};

    &__preview {
        position: relative;
        flex-basis: $blog__preview-width;
        margin-bottom: $blog__preview-margin-bottom;

        @include mq($screen-m) {
            flex-basis: $blog__preview-width\@small;
            margin-bottom: $blog__preview-margin-bottom\@large;
            padding: 0 15px;
        }

        @include mq($screen-l) {
            flex-basis: $blog__preview-width\@large;
        }

        &:first-of-type {
            flex-basis: $blog__preview--first-width;
            height: $blog__preview--first-height;
            margin-bottom: $blog__preview--first-margin-bottom;

            @include mq($screen-m) {

                #{$self}__image {
                    height: 100%;
                }
            }

            #{$self}__link {
                position: relative;
                display: block;
                height: 100%;
            }

            #{$self}__title {
                position: absolute;
                bottom: 0;
                margin: 0;
                padding: $blog__preview--first-title-padding;
                background: $blog__preview--first-title-background;

                @include mq($screen-m) {
                    bottom: 30px;
                    max-width: $blog__preview--first-title-width\@medium;
                    padding: $blog__preview--first-title-padding\@medium;
                }

                .heading {
                    font-size: $blog__preview--first-title-heading-font-size;

                    @include mq($screen-m) {
                        font-size: $blog__preview--first-title-heading-font-size\@medium;
                    }
                }
            }

            #{$self}__read-more {
                display: block;
                flex-flow: row nowrap;
                text-align: center;
                line-height: 1em;
                font-size: $blog__preview--first-read-more-font-size;

                .icon {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: -15px;
                    fill: $blog__preview--first-read-more-icon-fill;
                }

                @include mq($screen-m) {
                    text-align: left;
                    font-size: $blog__preview--first-read-more-font-size\@medium;
                }
            }
        }
    }

    &__link {
        display: block;
    }

    &__title {
        width: 100%;
        margin-top: $blog__title-margin-top;

        .heading {
            line-height: 1em;
            color: $blog__title-color;
            font-weight: $blog__title-font-weight;
            font-size: $blog__title-font-size;

            @include mq($screen-m) {
                font-size: $blog__title-font-size\@medium;
            }
        }
    }

    &__read-more {
        display: none;
    }

    &__image {
        height: $blog__image-height;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__tag-wrapper {
        position: absolute;
        top: 0;
    }

    &__tag {
        display: inline-block;
        padding: $blog__tag-padding;
        border: $blog__tag-border;
        background: $blog__tag-background;
        font-weight: $blog__tag-font-weight;
        font-size: $blog__tag-font-size;
        line-height: $blog__tag-line-height;
    }

    &__post {
        margin-bottom: 25px;

        #{$self}__image {
            height: auto;
            max-height: 100%;
            margin: $spacer--medium 0;
        }

        #{$self}__tag-wrapper {
            position: unset;
            margin: $spacer--medium 0;
        }

        @include mq($screen-m) {

            #{$self}__image {
                height: 320px;
            }
        }
    }
}
