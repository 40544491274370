.popup-content {
    padding: 15px;

    &__column {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;

        @include mq($screen-m) {
            flex-basis: 50%;
            max-width: 50%;
        }

        &:first-child {
            padding-bottom: 30px;

            @include mq($screen-m) {
                padding-bottom: 0;
                padding-right: 30px;
            }
        }

        &:nth-child(2) {
            border-top: 1px solid $border-color-base;
            padding-top: 30px;

            &:before {
                content: attr(data-label);
                position: absolute;
                left: 0;
                top: -17px;
                right: 0;
                width: 36px;
                height: 36px;
                line-height: 34px;
                margin: 0 auto;
                background: #fff;
                border: 1px solid $border-color-base;
                border-radius: 50%;
                color: $border-color-base;
                text-align: center;
            }
            @include mq($screen-m) {
                padding-left: 30px;
                padding-top: 0;
                border-left: 1px solid $border-color-base;
                border-top: none;

                &:before {
                    margin: auto 0;
                    bottom: 0;
                    left: -17px;
                    top: 0;
                }
            }
        }
    }

    &__title {
        font-size: 26px;
    }

    &__link {
        display: block;
        margin-bottom: 10px;
    }
}
