$currency-switcher__currency-margin                     : 0 5px !default;
$currency-switcher__currency-button-padding             : 5px 3px 5px 0 !default;
$currency-switcher__currency-button-height              : 33px !default;
$currency-switcher__currency-button-border              : none !default;
$currency-switcher__currency-button-color               : $color-text-accent !default;
$currency-switcher__currency-button-color-hover         : initial !default;
$currency-switcher__currency-button-background          : transparent !default;
$currency-switcher__currency-button-background-hover    : none !default;
$currency-switcher__currency-button-opacity             : 0.6 !default;
$currency-switcher__currency-button-font-weight         : normal !default;
$currency-switcher__currency-button-font-size           : 12px !default;
$currency-switcher__currency-button-icon-margin         : 0 5px 0 0 !default;

.currency-switcher {
    font-size: 0;

    &__button {
        position: relative;
        opacity: $currency-switcher__currency-button-opacity;
        padding: $currency-switcher__currency-button-padding;
        height: $currency-switcher__currency-button-height;
        border: $currency-switcher__currency-button-border;
        color: $currency-switcher__currency-button-color;
        background: $currency-switcher__currency-button-background;
        font-weight: $currency-switcher__currency-button-font-weight;
        font-size: $currency-switcher__currency-button-font-size;

        &::after {
            content: '|';
            float: right;
            margin-left: 3px;
        }

        &:last-child {
            margin-left: -3px;
            &::after {
                content: none;
            }
        }

        &--active,
        &:focus,
        &:active,
        &:hover {
            color: $currency-switcher__currency-button-color-hover;
            background: $currency-switcher__currency-button-background-hover;
            text-decoration: underline;
        }

        &.active {
            opacity: 1;
        }
    }
}

//Legacy switcher SCSS
.currency {
    display: flex;
    position: relative;
    margin-right: 30px;

    &--secondary {
        margin-right: 0;
    }
}

.currency--secondary {
    button {
        color: $color-text-default;
        background: transparent;
        opacity: 0.6;
        margin-right: 3px;

        &:not(:last-child):after {
            content: '|';
            float: right;
            margin-left: 3px;
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        &.selected {
            opacity: 1;
        }
    }
}

.currency__button {
    position: relative;
    font-weight: 600;
    text-align: center;
    color: $white;
    background: none;
    border: 0;
    display: inline-block;
    cursor: pointer;
    z-index: 0;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -20px;
        height: 50px;
        width: calc(100% + 40px);
        right: 20px;
        background-color: transparent;
        z-index: -1;
        transition: background-color 0.2s ease-in-out;
    }

    &:hover:before,
    &:focus:before {
        background-color: rgba($color-primary--accent-2, 0.3);
    }
}

.currency__button--invert {
    padding: 5px 20px;
    color: $color-text-default;
    width: 100%;
    font-weight: 400;
    text-align: left;

    &:before {
        display: none;
    }

    &:hover,
    &:focus {
        background-color: $color-primary--accent-2;
    }
}

.currency__button--selected {
    font-weight: 600;
    background-color: $color-primary--accent-2;
}

.currency__popup {
    display: none;
    position: absolute;
    top: calc(100% + 20px);
    right: calc(100% - 140px);
    color: $color-text-default;
    background: $white;
    padding: 15px 0;
    border-radius: 5px;
    box-shadow: 0 5px 25px -5px rgba(0, 0, 0, 0.25);
    width: 165px;
    z-index: 1;

    &:before {
        content: '';
        position: absolute;
        left: 15px;
        top: -15px;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 25px solid $white;
    }
}

.currency__popup--visible {
    display: block;
}

.currency__valuta {
    margin-right: 15px;
    font-weight: 600;
    color: $color-primary;
}

.popup.currency-content {
    border-radius: 5px;
    box-shadow: 0 5px 25px -5px rgba(0, 0, 0, 0.25);
    width: 165px;
    top: 65px;
    &:before {
        content: '';
        position: absolute;
        right: 15px;
        top: -15px;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 25px solid $white;
    }
}
