$slider__transition                   : $transition-base !default;
$slider__item-width                   : calc(100% / 2.5) !default;
$slider__item-width\@small            : calc(100% / 3.5) !default;
$slider__item-width\@medium           : 25% !default;
$slider__navigation-margin            : $spacer !default;
$slider__navigation-icon-size         : 48px !default;
$slider__navigation-icon-margin       : $spacer !default;
$slider__navigation-icon-background   : $white !default;
$slider__icon-size                    : 25px !default;
$slider__icon-fill                    : $color-primary !default;
$slider__icon-fill-hover              : darken($color-primary, 10%) !default;
$slider__dot-margin                   : $spacer--medium !default;
$slider__dot-padding                  : $spacer--medium !default;
$slider__dot-inside-size              : $spacer--medium !default;
$slider__dot-inside-background        : $gray-dark !default;
$slider__dot-inside-border-radius     : $spacer !default;
$slider__dot-inside-background--active: $color-primary !default;

.slider {
    position: relative;

    &__handler {
        overflow: hidden;
    }

    &__slides {
        display: flex;
    }

    &__item {
        position: relative;
        border: 0;
        margin-right: 10px;
        min-width: $slider__item-width;

        @include mq($screen-s) {
            min-width: $slider__item-width\@small;
        }

        @include mq($screen-m) {
            min-width: $slider__item-width\@medium;
        }
    }

    &__navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: $slider__navigation-margin;
    }

    &__navigation-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        height: $slider__navigation-icon-size;
        width: $slider__navigation-icon-size;
        background-color: $slider__navigation-icon-background;

        &:first-child {
            margin-right: $slider__navigation-icon-margin;
        }

        &:last-child {
            margin-left: $slider__navigation-icon-margin;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: $slider__navigation-icon-background;

            .slider__icon {
                fill: $slider__icon-fill-hover;
            }
        }
    }

    &__icon {
        cursor: pointer;
        width: $slider__icon-size;
        height: $slider__icon-size;
        fill: $slider__icon-fill;
        transition: $slider__transition;
    }

    &__dot-navigation {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        margin-top: $spacer;
    }

    &__dot {
        display: block;
        cursor: pointer;
        padding: $slider__dot-padding;
        margin-right: $slider__dot-margin;

        &--active {
            .slider__dot-inside {
                background-color: $slider__dot-inside-background--active;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }


    &__dot-inside {
        display: block;
        width: $slider__dot-inside-size;
        height: $slider__dot-inside-size;
        background-color: $slider__dot-inside-background;
        border-radius: $slider__dot-inside-border-radius;
    }
}

.glider-contain {
    width: 100%;
    margin: 0 auto;
    position: relative;
}
.glider {
    margin: 0 auto;
    position: relative;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    transform: translateZ(0);
}
.glider-track {
    transform: translateZ(0);
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    z-index: 1;
}
.glider.draggable {
    user-select: none;
    cursor: -webkit-grab;
    cursor: grab;
}
.glider.draggable .glider-slide img {
    user-select: none;
    pointer-events: none;
}
.glider.drag {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}
.glider-slide {
    user-select: none;
    justify-content: center;
    align-content: center;
    width: 100%;
}
.glider-slide img {
    max-width: 100%;
}
.glider::-webkit-scrollbar {
    opacity: 0;
    height: 0;
}
.glider-prev,.glider-next {
    user-select: none;
    position: absolute;
    outline: none;
    background: none;
    padding: 0;
    z-index: 2;
    font-size: 40px;
    text-decoration: none;
    left: -23px;
    border: 0;
    top: 30%;
    cursor: pointer;
    color: #666;
    opacity: 1;
    line-height: 1;
    transition: opacity .5s cubic-bezier(.17,.67,.83,.67),
    color .5s cubic-bezier(.17,.67,.83,.67);
}
.glider-prev:hover,
.glider-next:hover,
.glider-prev:focus,
.glider-next:focus {
    color: #a89cc8;
}
.glider-next {
    right: -23px;
    left: auto;
}
.glider-next.disabled,
.glider-prev.disabled {
    opacity: .25;
    color: #666;
    cursor: default;
}
.glider-slide {
    min-width: 150px;
}
.glider-hide {
    opacity: 0;
}
.glider-dots {
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
}
.glider-dot {
    border: 0;
    padding: 0;
    user-select: none;
    outline: none;
    display: block;
    cursor: pointer;
    color: #ccc;
    border-radius: 999px;
    background: #ccc;
    width: 12px;
    height: 12px;
    margin: 7px;
}
.glider-dot:hover,
.glider-dot:focus,
.glider-dot.active {
    background: #a89cc8;
}
@media(max-width: 36em){
    .glider::-webkit-scrollbar {
        opacity: 1;
        -webkit-appearance: none;
        width: 7px;
        height: 3px;
    }
    .glider::-webkit-scrollbar-thumb {
        opacity: 1;
        border-radius: 99px;
        background-color: rgba(156, 156, 156, 0.25);
        box-shadow: 0 0 1px rgba(255,255,255,.25);
    }
}