.banner {
    display: block;
    position: relative;
    width: 100%;

    &:hover {
        text-decoration: none;
    }

    &__image {
        width: 100%;
    }
}
