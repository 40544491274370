$header-button__size                : 48px !default;
$header-button__height              : 40px !default;
$header-button__margin              : 0 0 0 $spacer !default;
$header-button__margin--extra-large : 0 0 0 $spacer--medium !default;
$header-button__padding             : 0 5px !default;
$header-button__padding\@large      : 0 10px !default;
$header-button__padding--minicart   : 0 0 0 0 !default;
$header-button__padding--minicart\@large: 0 5px 0 0 !default;

$header-button__icon-margin           : 0 0 0 0 !default;
$header-button__icon-size             : 22px !default;
$header-button__icon-size\@large      : 25px !default;
$header-button__icon-margin--medium   : 0 0 4px 0 !default;
$header-button__icon-padding          : 0 0 4px 0 !default;
$header-button__icon-color            : $color-primary !default;
$header-button__icon-color--hover     : $color-primary !default;
$header-button__transition            : $transition-base !default;
$header-button__icon-margin--with-text: 2px 7px 0 0 !default;
$header-button__icon-margin--with-text\@large: 0 10px 0 0 !default;
$header-button__icon-margin--minicart: 3px 5px 0 3px !default;
$header-button__icon-size--minicart-arrow: 11px !default;

$header-button__text-font-family    : $font-family-base !default;
$header-button__text-color          : $gray !default;
$header-button__text-font-size      : 10px !default;
$header-button__text-line-height    : 10px !default;

$header-button__counter-top         : -8px !default;
$header-button__counter-top--medium : -8px !default;
$header-button__counter-right       : -8px !default;
$header-button__counter-font-size   : $font-size-extra-small !default;
$header-button__counter-font-weight : $font-weight-bold !default;
$header-button__counter-background  : $color-tertiary !default;
$header-button__counter-color       : $white !default;
$header-button__counter-size        : 20px !default;
$header-button__counter-border      : 2px solid $white !default;

$header-button__counter-dot-size    : 2px !default;
$header-button__counter-dot-bg-color: $white !default;

.header-button {
    position: relative;
    min-width: inherit;
    height: $header-button__height;
    margin: $header-button__margin;
    padding: $header-button__padding;
    cursor: pointer;
    text-decoration: none;
    font-family: $header-button__text-font-family;

    @include mq($screen-s) {
        min-width: $header-button__size;
        padding: $header-button__padding\@large;
    }

    @include mq($screen-xl) {
        margin: $header-button__margin--extra-large;
    }

    &:focus,
    &:hover {
        text-decoration: none;
        .header-button__icon {
            fill: $header-button__icon-color--hover;
        }
    }

    &--reset-margin {
        margin: 0;
    }

    &--minicart {
        padding: $header-button__padding--minicart;
        justify-content: left;
        transition: border-color 0s, box-shadow 0s;

        .minicart-open & {
            border-color: #fff;
            background: #fff;
            box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.35);
            z-index: 1001;

            &:after {
                content: '';
                background: #FFF;
                width: calc(100% + 2px);
                position: absolute;
                height: 10px;
                bottom: -6px;
                left: -1px;
            }
        }

        @include mq ($screen-s) {
            padding: $header-button__padding--minicart\@large;
        }
    }

    &__icon {
        margin: $header-button__icon-margin;
        fill: $header-button__icon-color;
        transition: $header-button__transition;

        @include mq($screen-m) {
            margin: $header-button__icon-margin--medium;
            padding: $header-button__icon-padding;
        }

        &--with-text {
            padding: 0;
            width: $header-button__icon-size;
            height: $header-button__icon-size;

            @include mq($screen-l) {
                margin: $header-button__icon-margin--with-text\@large;
                width: $header-button__icon-size\@large;
                height: $header-button__icon-size\@large;
            }
        }

        &--minicart {
            margin: $header-button__icon-margin--minicart;

            & + .header-button__icon--minicart {
                position: absolute;
                opacity: 0;
            }
        }

        &--minicart-arrow {
            display: none;
            fill: $header-button__icon-color;
            width: $header-button__icon-size--minicart-arrow;
            height: $header-button__icon-size--minicart-arrow;
            margin: auto;
            padding: 0;

            @include mq($screen-s) {
                display: block;
            }
        }
    }

    &__text {
        display: none;
        font-size: $header-button__text-font-size;
        line-height: $header-button__text-line-height;
        text-align: center;
        color: $header-button__text-color;

        @include mq($screen-l) {
            display: block;
        }
    }

    &__counter {
        position: absolute;
        top: $header-button__counter-top;
        right: $header-button__counter-right;
        display: block;
        min-width: $header-button__counter-size;
        height: $header-button__counter-size;
        padding: $header-button__padding;
        border-radius: $header-button__counter-size;
        line-height: $header-button__counter-size;
        font-size: $header-button__counter-font-size;
        font-weight: $header-button__counter-font-weight;
        text-align: center;
        color: $header-button__counter-color;
        background-color: $header-button__counter-background;

        .header-button--account & {
            right: unset;
            left: 15px;
            top: 0;
            height: 16px;
            min-width: 16px;
            font-size: 8px;
            line-height: 16px;

            @include mq($screen-m) {
                left: 20px;
            }
        }

        &--hidden {
            display: none;
        }

        @include mq($screen-m) {
            top: $header-button__counter-top--medium;
        }

        &-dot {
            width: $header-button__counter-dot-size;
            height: $header-button__counter-dot-size;
            border-radius: $header-button__counter-dot-size;
            margin: auto;
            background-color: $header-button__counter-dot-bg-color;
        }
    }

    @include mq(false, $screen-m) {
        &__icon--minicart {
            height: 28px;
            width: 28px;
        }
    }

    @include mq($screen-m) {
        &__icon--minicart {
            height: 28px;
            width: 28px;
        }
    }

    @include mq($screen-l) {
        &__icon--minicart {
            height: 30px;
            width: 30px;
        }
    }
}

.header--alternative {

    .header__buttons {
        min-height: initial;
        justify-content: center;
    }

    .header__now-available {
        display: none;
        color: $color-alt;
        font-weight: $font-weight-bold;

        @include mq($screen-m) {
            display: block;
        }
    }

    .header-button {
        max-height: 20px;

        @include mq($screen-s) {
            padding: 0 5px;
        }

        &__text {
            display: none;

            @include mq($screen-m) {
                display: block;
            }
        }

        &__icon {
            margin: $header-button__icon-margin--medium;

            &--with-text {
                margin: $header-button__icon-margin--with-text\@large;
                width: $header-button__icon-size\@large;
                height: $header-button__icon-size\@large;
            }
        }
    }
}
