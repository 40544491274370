.quotations-overview {
    &__quotation {
        display: flex;
        border: 1px solid $gray-light;
        border-radius: $border-radius;
        width: 100%;
        padding: 20px;
        margin: 10px 0;

        &__info {
            flex-grow: 5;

            > div {
                margin-bottom: 10px;
            }

            &--id {
                font-weight: bold;
            }
        }

        &__actions {
            flex-grow: 1;

            .button {
                display: flex;
                margin-bottom: 5px;
                border-width: 1px;
                border-color: $gray-light;
                font-weight: $font-weight-semi-bold;
                font-size: $font-size-base;

                &--accept-quotation {
                    color: $color-primary;
                    &:hover, &:active {
                        background-color: $gray-lightest;
                    }
                }

                &--download-quotation {

                }

                &--reject-quotation {
                    color: $color-tertiary;
                    &:hover, &:active {
                        background-color: $gray-lightest;
                    }
                }
            }
        }
    }
}
