.pro-login__register-wrapper {
    margin-top: 50px;
    margin-bottom: 25px;

    @include mq($screen-m) {
        max-width: 50%;
        flex-grow: 1;
    }
}

.pro-login-suffix {
    margin-left: 5px;
    font-size: 12px;
    text-align: center;
    font-weight: normal;
    text-transform: capitalize;
}

.header-button__text--pro-login {
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    margin-top: 5px;
}

.pro-login-benefits {
    padding: 33px 34px 35px 32px;
    border-radius: 5px;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);

    &:empty {
        display: none !important;
    }

    &__usp-wrapper {
        flex-basis: 100%;

        .pro-login-usp {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            margin: 25px 0;
            padding: 8px 20px;
            background: $color-primary--accent-2;

            div[data-content-type="row"] div[data-element="inner"] {
                margin: 0;
            }

            p {
                margin: 0;
                color: $color-primary;
            }

            svg {
                width: 45px;
                height: 45px;
                fill: $color-primary;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @include mq ($screen-l) {
        padding: 33px 74px 35px 32px;
    }
}

.users {
    &__button {
        margin-bottom: 20px;
        width: 100%;

        @include mq($screen-l) {
            width: auto;
            min-width: 300px;
        }
    }
}

.users-overview {
    &__user {
        display: flex;
        border: 1px solid $gray-light;
        border-radius: $border-radius;
        width: 100%;
        padding: 20px;
        margin: 10px 0;

        &__info {
            flex-grow: 1;

            > div {
                margin-bottom: 10px;
            }
        }

        &__actions {
            flex-grow: 0;

            .button {
                display: flex;
                margin-bottom: 5px;
                border-width: 1px;
                border-color: $gray-light;
                font-weight: normal;
                font-size: $font-size-base;

                &--edit-user {
                    color: $color-primary;
                }

                &--delete-user {
                    color: $color-tertiary;
                }

                &:hover, &:active {
                    background-color: $gray-lightest;
                }
            }
        }
    }
}

.user-edit__form {
    .submit-user-edit {
        @include mq($screen-l) {
            width: auto;
        }
    }
}
