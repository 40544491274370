$dashboard-table-margin: $spacer--medium !default;

.dashboard-table {
    overflow: hidden;

    &--tab {
        margin: 20px 0;

        .dashboard-table__content {
            margin-bottom: 0;
        }
    }

    &__header {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
        margin-bottom: $dashboard-table-margin;
        padding-bottom: 10px;
        border-bottom: 1px solid $gray;
    }

    &__content {
        margin-bottom: $dashboard-table-margin;
        overflow-x: auto;
    }

    &__table {
        margin: 0;
        padding: 0;

        .price {
            font-size: $font-size-base;
        }
    }

    &__link {
        display: inline-flex;
        &:focus {
            @include focus-inline();
        }
    }

    &__delayed {
        color: $color-danger;
    }

    &__spacer {
        margin-right: $spacer;
    }

    &__title {
        margin-bottom: 0;
        margin-right: $dashboard-table-margin;
    }
}
