$cart-list-item__image-width               : 88px !default;
$cart-list-item__name-weight               : $font-weight-semi-bold !default;
$cart-list-item__name-color                : $color-text-accent !default;
$cart-list-item__price-margin              : 4px 0 !default;
$cart-list-item__price-margin\iOS          : 20px 0 0 0 !default;

$tier-background-color                     : $color-primary--accent-2 !default;
$tier-text-color                           : $color-primary--accent-5 !default;
$tier-text-decoration                      : none !default;
$tier-border                               : none !default;
$tier-box-shadow                           : none !default;
$tier-border-radius                        : 0 !default;

$self : '.cart-list-item';

#{$self} {
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    &__wrapper {
        display: flex;
        flex-flow: column nowrap;
        @media(min-width: 1025px) {
            flex-basis: 50%;
        }
    }

    &__remove {
        align-self: flex-start;
        cursor: pointer;
        padding: 0;
        margin-top: -6px;
        margin-right: -17px;
        width: 34px;
        height: 34px;
        background: transparent;
        border: none;

        @include mq($screen-m) {
            margin: 0;
        }

        &:hover {
            background-color: inherit;

            #{$self}__remove-icon {
                fill: $color-text-accent;
            }
        }
    }

    &__remove-icon {
        fill: $color-text-accent;
        width: 34px;
        height: 34px;
    }

    &__image {
        flex: 0 0 100px;

        .lazyload-wrapper {
            margin: 0 auto;
            width: $cart-list-item__image-width;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        @include mq($screen-l) {
            flex-direction: row;

            .show-lg {
                align-self: center;
            }
        }
    }

    &__name {
        text-decoration: none;
        line-height: $font-line-height;
        font-weight: $cart-list-item__name-weight;
        color: $cart-list-item__name-color;
        display: inline-block;
        margin-bottom: 10px;

        &:hover,
        &:focus {
            text-decoration: none;
            color: $cart-list-item__name-color;
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__sku {
        font-size: 13px;
    }

    &__qty {
        position: relative;

        .loading-mask {
            @extend .quantity-loading-mask;
        }
    }

    &__price {
        margin: $cart-list-item__price-margin;

        @include mq(false, $screen-m) {
            .eco-tax {
                display: block;
                flex-basis: 100%;
            }
        }

        .price-wrapper__eco {
            flex-basis: 100%;
            line-height: $font-size-extra-small;

            @include mq($screen-m) {
                flex-basis: initial;
                line-height: 22px;
            }
        }
    }

    &__price-total {

        .price-wrapper--compact {
            flex-wrap: wrap;
            justify-content: flex-end;
        }

        .price-wrapper__vat {
            text-align: right;
            flex-basis: 100%;
            font-size: $font-size-small;
            line-height: $font-size-small;
        }

        @include mq(false, $screen-m) {
            .tax-label {
                display: block;
            }
        }
    }

    &__dispatch-status {
        flex-basis: 100%;
        margin-top: 8px;
        text-align: right;

        .button {
            display: inline-block;
            vertical-align: middle;
            height: auto;
            padding: 6px 10px;
            font-weight: 600;
            font-size: 12px;
            margin-left: 5px;
        }

        @include mq($screen-m) {
            text-align: left;

            .button {
                font-size: 14px;
            }
        }
    }

    &__tier-price,
    &__led-alternative {
        margin-top: 8px;
        margin-bottom: 8px;
        width: 100%;

        @include mq($screen-m) {
            margin-bottom: 0;
        }

        .price {
            font-size: $font-size-base;
            font-weight: initial;
        }

        .link {
            margin-left: 0px;
            display: inline-block;
            font-weight: $font-weight-semi-bold;
            @include mq($screen-m) {
                margin-left: 30px;
            }
        }
    }

    &__tier-price {

        &.display-none {
            display: none;
        }

        .items {
            background-color: $tier-background-color;
            border: $tier-border;
            box-shadow: $tier-box-shadow;
            border-left: 5px $color-primary solid;
            color: $tier-text-color;
            padding: 14px 0 14px 35px;
            border-radius: $tier-border-radius;

            .link {
                color: $tier-text-color;
                text-decoration: $tier-text-decoration;
            }

            .icon {
                display: inline-block;
                margin-right: 2px;
                width: 14px;
                height: 14px;
                position: relative;
                top: 1px;
                fill: $tier-text-color;
            }
        }
    }

    &__led-alternative {

        &.display-none {display:none}

        .items {
            background-color: $color-alt--accent-3;
            color: $color-alt--accent-5;
            padding:11px 0 11px 35px;
            border-left:5px $color-alt--accent solid;
            font-weight: $font-weight-semi-bold;

            .link {
                background-color: $color-alt--accent-2;
                border: none;
                border-radius: 4px;
                color:white;
                cursor: pointer;
                display: inline-block;
                font-weight: bold;
                margin-left: 0px;
                padding: 2px 12px;
                @include mq($screen-m) {
                    margin-left: 40px;
                }
                &:hover {
                    background-color: $color-alt--accent;
                }
            }
        }
    }


    &__header,
    &__footer {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    &__footer {
        justify-content: space-between;
        flex-basis: 40%;
    }

    &--out-of-stock {

        #{$self}__info,
        #{$self}__image {
            opacity: 0.3;
        }
    }

    .ribbon {
        margin-top: 15px;
    }

    .message {
        margin-top: 15px;
    }
}
