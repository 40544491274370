.account .notifications-overview {
  display: flex;
  flex-flow: row wrap;

  &.action {
    margin-top: 10px;
  }

  &__notification {
    position: relative;
    padding: 20px;
    flex-basis: 100%;
    color: #fff;
    background: $color-primary;
    border-top: 1px solid #fff;

    &:first-child {
      border-radius: 5px 5px 0 0;
      border-top: none;
    }

    &:last-child {
      border-radius: 0 0 5px 5px;
    }

    &:after {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: $color-tertiary;
      content: '';
      position: absolute;
      right: 15px;
      top: calc(50% - 9px);
    }

    svg.close {
      position: absolute;
      top: 15px;
      right: 15px;
      fill: #fff;
    }

    &-header {
      margin-bottom: 10px;

      img {
        display: inline-block;
        vertical-align: middle;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;
        margin-right: 10px;
      }

      .from {
        font-weight: bold;
        font-size: 16px;
      }

      .subject {
                        margin: 20px  0;
        font-weight: $font-weight-semi-bold;
        font-size: 18px;
      }
    }

    &-footer {
      margin-top: 10px;
      text-align: right;
      font-style: italic;
    }

    &--opened {
      &:after {
        display: none;
      }
      background: $color-primary--accent-2;
      color: $color-primary;

      svg.close {
        fill: $color-primary;
      }
    }
  }
}
