//sass-lint:disable no-vendor-prefixes

@mixin isIE() {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}

@mixin isiOSDevice() {
    @supports (-webkit-touch-callout: none) {
        @content;
    }
}
