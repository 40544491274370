// Unfortunately we need to re-apply some CSS from the old site to make the migrated CMS pages compatible
// At this time we can't change the content off all the CMS blocks and pages to suit our new styling,
// so we revert some of the old styling instead
.blog-post-view,
.cms-page-view {
    .page-main {

        [data-content-type="row"][data-appearance="full-width"] {
            width: 100vw;
            margin-left: -15px;

            @include mq($screen-m) {
                margin-left: calc((100vw - #{$max-content-width}) / -2);
            }
        }

        [data-appearance="full-bleed"] {
            margin: 0;
            padding: 0;
            margin-left: -15px;
            width: 100vw;

            @include mq($screen-m) {
                margin-left: calc((100vw - #{$max-content-width}) / -2);
            }
        }

        div[class*="col-"] {
            float: left;

            + *:not([class*="col-"]) {
                clear: both;
            }
        }

        .container,
        .container-fluid {
            padding-left: 15px;
            padding-right: 15px;
        }

        .col-lg-1,
        .col-lg-10,
        .col-lg-11,
        .col-lg-12,
        .col-lg-2,
        .col-lg-3,
        .col-lg-4,
        .col-lg-5,
        .col-lg-6,
        .col-lg-7,
        .col-lg-8,
        .col-lg-9,
        .col-md-1,
        .col-md-10,
        .col-md-11,
        .col-md-12,
        .col-md-2,
        .col-md-3,
        .col-md-4,
        .col-md-5,
        .col-md-6,
        .col-md-7,
        .col-md-8,
        .col-md-9,
        .col-sm-1,
        .col-sm-10,
        .col-sm-11,
        .col-sm-12,
        .col-sm-2,
        .col-sm-3,
        .col-sm-4,
        .col-sm-5,
        .col-sm-6,
        .col-sm-7,
        .col-sm-8,
        .col-sm-9,
        .col-xs-1,
        .col-xs-10,
        .col-xs-11,
        .col-xs-12,
        .col-xs-2,
        .col-xs-3,
        .col-xs-4,
        .col-xs-5,
        .col-xs-6,
        .col-xs-7,
        .col-xs-8,
        .col-xs-9 {
            position: relative;
            min-height: 1px;
            padding-left: 15px;
            padding-right: 15px;
        }

        .col-xs-1 {
            width: 8.33333%;
        }

        .col-xs-2 {
            width: 16.66667%;
        }

        .col-xs-3 {
            width: 25%;
        }

        .col-xs-4 {
            width: 33.33333%;
        }

        .col-xs-5 {
            width: 41.66667%;
        }

        .col-xs-6 {
            width: 50%;
        }

        .col-xs-7 {
            width: 58.33333%;
        }

        .col-xs-8 {
            width: 66.66667%;
        }

        .col-xs-9 {
            width: 75%;
        }

        .col-xs-10 {
            width: 83.33333%;
        }

        .col-xs-11 {
            width: 91.66667%;
        }

        .col-xs-12 {
            width: 100%;
        }

        .col-xs-pull-0 {
            right: auto;
        }

        .col-xs-pull-1 {
            right: 8.33333%;
        }

        .col-xs-pull-2 {
            right: 16.66667%;
        }

        .col-xs-pull-3 {
            right: 25%;
        }

        .col-xs-pull-4 {
            right: 33.33333%;
        }

        .col-xs-pull-5 {
            right: 41.66667%;
        }

        .col-xs-pull-6 {
            right: 50%;
        }

        .col-xs-pull-7 {
            right: 58.33333%;
        }

        .col-xs-pull-8 {
            right: 66.66667%;
        }

        .col-xs-pull-9 {
            right: 75%;
        }

        .col-xs-pull-10 {
            right: 83.33333%;
        }

        .col-xs-pull-11 {
            right: 91.66667%;
        }

        .col-xs-pull-12 {
            right: 100%;
        }

        .col-xs-push-0 {
            left: auto;
        }

        .col-xs-push-1 {
            left: 8.33333%;
        }

        .col-xs-push-2 {
            left: 16.66667%;
        }

        .col-xs-push-3 {
            left: 25%;
        }

        .col-xs-push-4 {
            left: 33.33333%;
        }

        .col-xs-push-5 {
            left: 41.66667%;
        }

        .col-xs-push-6 {
            left: 50%;
        }

        .col-xs-push-7 {
            left: 58.33333%;
        }

        .col-xs-push-8 {
            left: 66.66667%;
        }

        .col-xs-push-9 {
            left: 75%;
        }

        .col-xs-push-10 {
            left: 83.33333%;
        }

        .col-xs-push-11 {
            left: 91.66667%;
        }

        .col-xs-push-12 {
            left: 100%;
        }

        .col-xs-offset-0 {
            margin-left: 0;
        }

        .col-xs-offset-1 {
            margin-left: 8.33333%;
        }

        .col-xs-offset-2 {
            margin-left: 16.66667%;
        }

        .col-xs-offset-3 {
            margin-left: 25%;
        }

        .col-xs-offset-4 {
            margin-left: 33.33333%;
        }

        .col-xs-offset-5 {
            margin-left: 41.66667%;
        }

        .col-xs-offset-6 {
            margin-left: 50%;
        }

        .col-xs-offset-7 {
            margin-left: 58.33333%;
        }

        .col-xs-offset-8 {
            margin-left: 66.66667%;
        }

        .col-xs-offset-9 {
            margin-left: 75%;
        }

        .col-xs-offset-10 {
            margin-left: 83.33333%;
        }

        .col-xs-offset-11 {
            margin-left: 91.66667%;
        }

        .col-xs-offset-12 {
            margin-left: 100%;
        }

        @media(min-width: 769px) {
            .col-sm-1 {
                width: 8.33333%;
            }

            .col-sm-2 {
                width: 16.66667%;
            }

            .col-sm-3 {
                width: 25%;
            }

            .col-sm-4 {
                width: 33.33333%;
            }

            .col-sm-5 {
                width: 41.66667%;
            }

            .col-sm-6 {
                width: 50%;
            }

            .col-sm-7 {
                width: 58.33333%;
            }

            .col-sm-8 {
                width: 66.66667%;
            }

            .col-sm-9 {
                width: 75%;
            }

            .col-sm-10 {
                width: 83.33333%;
            }

            .col-sm-11 {
                width: 91.66667%;
            }

            .col-sm-12 {
                width: 100%;
            }

            .col-sm-pull-0 {
                right: auto;
            }

            .col-sm-pull-1 {
                right: 8.33333%;
            }

            .col-sm-pull-2 {
                right: 16.66667%;
            }

            .col-sm-pull-3 {
                right: 25%;
            }

            .col-sm-pull-4 {
                right: 33.33333%;
            }

            .col-sm-pull-5 {
                right: 41.66667%;
            }

            .col-sm-pull-6 {
                right: 50%;
            }

            .col-sm-pull-7 {
                right: 58.33333%;
            }

            .col-sm-pull-8 {
                right: 66.66667%;
            }

            .col-sm-pull-9 {
                right: 75%;
            }

            .col-sm-pull-10 {
                right: 83.33333%;
            }

            .col-sm-pull-11 {
                right: 91.66667%;
            }

            .col-sm-pull-12 {
                right: 100%;
            }

            .col-sm-push-0 {
                left: auto;
            }

            .col-sm-push-1 {
                left: 8.33333%;
            }

            .col-sm-push-2 {
                left: 16.66667%;
            }

            .col-sm-push-3 {
                left: 25%;
            }

            .col-sm-push-4 {
                left: 33.33333%;
            }

            .col-sm-push-5 {
                left: 41.66667%;
            }

            .col-sm-push-6 {
                left: 50%;
            }

            .col-sm-push-7 {
                left: 58.33333%;
            }

            .col-sm-push-8 {
                left: 66.66667%;
            }

            .col-sm-push-9 {
                left: 75%;
            }

            .col-sm-push-10 {
                left: 83.33333%;
            }

            .col-sm-push-11 {
                left: 91.66667%;
            }

            .col-sm-push-12 {
                left: 100%;
            }

            .col-sm-offset-0 {
                margin-left: 0;
            }

            .col-sm-offset-1 {
                margin-left: 8.33333%;
            }

            .col-sm-offset-2 {
                margin-left: 16.66667%;
            }

            .col-sm-offset-3 {
                margin-left: 25%;
            }

            .col-sm-offset-4 {
                margin-left: 33.33333%;
            }

            .col-sm-offset-5 {
                margin-left: 41.66667%;
            }

            .col-sm-offset-6 {
                margin-left: 50%;
            }

            .col-sm-offset-7 {
                margin-left: 58.33333%;
            }

            .col-sm-offset-8 {
                margin-left: 66.66667%;
            }

            .col-sm-offset-9 {
                margin-left: 75%;
            }

            .col-sm-offset-10 {
                margin-left: 83.33333%;
            }

            .col-sm-offset-11 {
                margin-left: 91.66667%;
            }

            .col-sm-offset-12 {
                margin-left: 100%;
            }
        }

        @media(min-width: 992px) {
            .col-md-1 {
                width: 8.33333%;
            }

            .col-md-2 {
                width: 16.66667%;
            }

            .col-md-3 {
                width: 25%;
            }

            .col-md-4 {
                width: 33.33333%;
            }

            .col-md-5 {
                width: 41.66667%;
            }

            .col-md-6 {
                width: 50%;
            }

            .col-md-7 {
                width: 58.33333%;
            }

            .col-md-8 {
                width: 66.66667%;
            }

            .col-md-9 {
                width: 75%;
            }

            .col-md-10 {
                width: 83.33333%;
            }

            .col-md-11 {
                width: 91.66667%;
            }

            .col-md-12 {
                width: 100%;
            }

            .col-md-pull-0 {
                right: auto;
            }

            .col-md-pull-1 {
                right: 8.33333%;
            }

            .col-md-pull-2 {
                right: 16.66667%;
            }

            .col-md-pull-3 {
                right: 25%;
            }

            .col-md-pull-4 {
                right: 33.33333%;
            }

            .col-md-pull-5 {
                right: 41.66667%;
            }

            .col-md-pull-6 {
                right: 50%;
            }

            .col-md-pull-7 {
                right: 58.33333%;
            }

            .col-md-pull-8 {
                right: 66.66667%;
            }

            .col-md-pull-9 {
                right: 75%;
            }

            .col-md-pull-10 {
                right: 83.33333%;
            }

            .col-md-pull-11 {
                right: 91.66667%;
            }

            .col-md-pull-12 {
                right: 100%;
            }

            .col-md-push-0 {
                left: auto;
            }

            .col-md-push-1 {
                left: 8.33333%;
            }

            .col-md-push-2 {
                left: 16.66667%;
            }

            .col-md-push-3 {
                left: 25%;
            }

            .col-md-push-4 {
                left: 33.33333%;
            }

            .col-md-push-5 {
                left: 41.66667%;
            }

            .col-md-push-6 {
                left: 50%;
            }

            .col-md-push-7 {
                left: 58.33333%;
            }

            .col-md-push-8 {
                left: 66.66667%;
            }

            .col-md-push-9 {
                left: 75%;
            }

            .col-md-push-10 {
                left: 83.33333%;
            }

            .col-md-push-11 {
                left: 91.66667%;
            }

            .col-md-push-12 {
                left: 100%;
            }

            .col-md-offset-0 {
                margin-left: 0;
            }

            .col-md-offset-1 {
                margin-left: 8.33333%;
            }

            .col-md-offset-2 {
                margin-left: 16.66667%;
            }

            .col-md-offset-3 {
                margin-left: 25%;
            }

            .col-md-offset-4 {
                margin-left: 33.33333%;
            }

            .col-md-offset-5 {
                margin-left: 41.66667%;
            }

            .col-md-offset-6 {
                margin-left: 50%;
            }

            .col-md-offset-7 {
                margin-left: 58.33333%;
            }

            .col-md-offset-8 {
                margin-left: 66.66667%;
            }

            .col-md-offset-9 {
                margin-left: 75%;
            }

            .col-md-offset-10 {
                margin-left: 83.33333%;
            }

            .col-md-offset-11 {
                margin-left: 91.66667%;
            }

            .col-md-offset-12 {
                margin-left: 100%;
            }
        }

        @media(min-width: 1200px) {
            .col-lg-1 {
                width: 8.33333%;
            }

            .col-lg-2 {
                width: 16.66667%;
            }

            .col-lg-3 {
                width: 25%;
            }

            .col-lg-4 {
                width: 33.33333%;
            }

            .col-lg-5 {
                width: 41.66667%;
            }

            .col-lg-6 {
                width: 50%;
            }

            .col-lg-7 {
                width: 58.33333%;
            }

            .col-lg-8 {
                width: 66.66667%;
            }

            .col-lg-9 {
                width: 75%;
            }

            .col-lg-10 {
                width: 83.33333%;
            }

            .col-lg-11 {
                width: 91.66667%;
            }

            .col-lg-12 {
                width: 100%;
            }

            .col-lg-pull-0 {
                right: auto;
            }

            .col-lg-pull-1 {
                right: 8.33333%;
            }

            .col-lg-pull-2 {
                right: 16.66667%;
            }

            .col-lg-pull-3 {
                right: 25%;
            }

            .col-lg-pull-4 {
                right: 33.33333%;
            }

            .col-lg-pull-5 {
                right: 41.66667%;
            }

            .col-lg-pull-6 {
                right: 50%;
            }

            .col-lg-pull-7 {
                right: 58.33333%;
            }

            .col-lg-pull-8 {
                right: 66.66667%;
            }

            .col-lg-pull-9 {
                right: 75%;
            }

            .col-lg-pull-10 {
                right: 83.33333%;
            }

            .col-lg-pull-11 {
                right: 91.66667%;
            }

            .col-lg-pull-12 {
                right: 100%;
            }

            .col-lg-push-0 {
                left: auto;
            }

            .col-lg-push-1 {
                left: 8.33333%;
            }

            .col-lg-push-2 {
                left: 16.66667%;
            }

            .col-lg-push-3 {
                left: 25%;
            }

            .col-lg-push-4 {
                left: 33.33333%;
            }

            .col-lg-push-5 {
                left: 41.66667%;
            }

            .col-lg-push-6 {
                left: 50%;
            }

            .col-lg-push-7 {
                left: 58.33333%;
            }

            .col-lg-push-8 {
                left: 66.66667%;
            }

            .col-lg-push-9 {
                left: 75%;
            }

            .col-lg-push-10 {
                left: 83.33333%;
            }

            .col-lg-push-11 {
                left: 91.66667%;
            }

            .col-lg-push-12 {
                left: 100%;
            }

            .col-lg-offset-0 {
                margin-left: 0;
            }

            .col-lg-offset-1 {
                margin-left: 8.33333%;
            }

            .col-lg-offset-2 {
                margin-left: 16.66667%;
            }

            .col-lg-offset-3 {
                margin-left: 25%;
            }

            .col-lg-offset-4 {
                margin-left: 33.33333%;
            }

            .col-lg-offset-5 {
                margin-left: 41.66667%;
            }

            .col-lg-offset-6 {
                margin-left: 50%;
            }

            .col-lg-offset-7 {
                margin-left: 58.33333%;
            }

            .col-lg-offset-8 {
                margin-left: 66.66667%;
            }

            .col-lg-offset-9 {
                margin-left: 75%;
            }

            .col-lg-offset-10 {
                margin-left: 83.33333%;
            }

            .col-lg-offset-11 {
                margin-left: 91.66667%;
            }

            .col-lg-offset-12 {
                margin-left: 100%;
            }
        }

        img {
            width: unset;
            display: unset;
        }

        .blog_button_orange,
        .blog_button_orange {
            border: none;
            text-decoration: none;

            a {
                text-decoration: none;
            }
        }

        .blog_button_orange {
            background-color: $button__background--cta-secondary;

            &:hover {
                background-color: $button__background-hover--cta-secondary;
            }

            &:active {
                box-shadow: $button__box-shadow--cta-secondary;
                background-color: $button__background-hover--cta-secondary;
            }
        }

        .blog_button_green {
            background-color: $color-alt;

            &:hover {
                background-color: darken($color-alt, 5%);
            }

            &:active {
                box-shadow: $button__box-shadow--cta-secondary;
                background-color: darken($color-alt, 5%);
            }
        }

    }

    button {
        border: none;
    }

    .page-main.container {
        button.blog_button_current_page,
        button.blog_button_lightblue {
            background: #e7f6ff;
            color: #0b92e0;
            padding: 0 25px;
            line-height: 45px;
            border-radius: 6px;
            font-weight: 700;
            font-size: 14px;
            &:hover {
                background: #c0e7ff;
            }
            a {
                text-decoration: none;
                font-weight: 700;
            }
        }
        button.blog_button_current_page {
            background: #c0e7ff;
        }
        button.blog_button_blue {
            line-height: 45px;
            border-radius: 6px;
            font-weight: 700;
            padding: 0 25px;
            font-size: 14px;
            &:hover {
                background: #0e5178;
            }
            a {
                text-decoration: none;
                font-weight: 700;
            }
        }
    }

    &[class^="cms-videos"] {
        .page-main {
            .container {
                display: flex;
            }
        }
    }
}

body[class*='cms-'] {
    &.led-fixtures {
        .brands table {
            width: 100%;
        }
        .lights-blocks,
        .section-blocks {
            display: flex;
            flex-flow: row wrap;
            clear: both;

            a {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                flex-basis: 50%;

                @include mq($screen-m) {
                    flex-basis: calc(100% / 5 - 10px);
                }
            }
            img {
                height: 185px;
            }
            h3, h2 {
                text-align: center;
                font-size: $font-size-base;
                color: $color-text-accent;
            }
        }
    }
}