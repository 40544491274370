.datadetect-wrapper {
  .image-input-wrapper {
    @include mq(false, $screen-m) {
      flex-direction: column;

      label.camera-label {
        padding: 12px 25px;
        display: flex !important;
      }
    }

    .camera-label {
      display: none !important;
    }

    input {
      display: none !important;
    }

    & > label {
      cursor: pointer;
      border: 2px solid #f5f5f5;
      border-radius: 5px;
      padding: 12px 34px;
    }
  }

  .image-loader {
    display: none;
    text-align: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid $color-primary;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.liquidpixelsdata-search-index {

  .flex-center {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  svg {
    fill: $color-primary;
  }

  .main-color {
    color: $color-primary;
    font-weight: bold;
  }

  h3.main-color {
    font-size: 1.5rem;
  }

  .pt-20 {
    padding-top: 20px;
  }

  .pt-30 {
    padding-top: 30px;
  }

  .mt-20 {
    margin-top: 20px;
  }


  ul.product-attributes {
    padding-left: 20px;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;

    @include mq(false, $screen-m) {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
    }

    li {
      padding: 8px 0;
    }
  }

  .product-item-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 10px 40px;
    @include mq(false, $screen-l) {
      justify-content: center;
    }
  }

  .item-link {
    color: #333333;
    font-size: 19px;
  }

  .price-wrapper {
    line-height: 28px;
    font-size: 22px;
    font-weight: bold;
    @include mq(false, $screen-m) {
      margin-bottom: 20px;
    }
  }

  .price-box {
    margin-bottom: 20px;
  }

  .result {
    .add-to-cart-button__primary-button {
      padding: 0px 50px;
      width: 100%;

      span {
        display: inline-block;
      }
    }
  }

  .product-image-wrapper {

    margin: 10px 20px 10px 40px;
  }

  .product-details-wrapper {
    max-width: 550px;
  }

  .minimal-price-link {
    display: none;
  }

  #find-products-form {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='rgb(223, 223, 223)' stroke-width='4' stroke-dasharray='12%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px;
    padding: 100px 0;
    margin-top: 20px;
    margin-bottom: 80px;
  }

  .product-list {
    border: 1px solid #efefef;
    border-radius: 5px;
    margin-bottom: 20px;
    @include mq(false, $screen-m) {
      padding-bottom: 20px;
    }
  }

  .product-item-photo img {
    width: 200px;
  }

  .reset-form-wrapper {
    text-align: center;

    .reset-form-button {
      display: none;
      margin: 0 auto;
      padding: 15px 35px;
      background: $color-primary;
      border: none;
      border-radius: 7px;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .title-wrapper {
    visibility: hidden;
    height: 20px;
  }
}

.barcode-product-finder-button {
  position: absolute;
  top: -1px;
  right: 60px;
  height: calc(39px + 2px);

  svg {
    fill: $color-primary;

    path {
      fill: $color-primary;
    }
  }
}