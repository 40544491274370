$headings-margin                 : 0 0 0.5em 0 !default;
$headings-margin--page           : $spacer--medium 0 !default;

$headings-font-family            : $font-family-base !default;
$headings-font-weight            : $font-weight-semi-bold !default;
$headings-line-height            : $font-line-height !default;
$headings-color                  : $font-color-heading-default !default;

$headings-text-transform         : none !default;
$headings-text-transform--page   : uppercase !default;

$heading-font-size--page         : 24px !default;
$heading-font-size--first-level  : 18px !default;
$heading-font-size--second-level : 18px !default;
$heading-font-size--third-level  : $font-size-heading--third-level !default;
$heading-font-size--fourth-level : 14px !default;
$heading-font-size--fifth-level  : 12px !default;
$heading-font-size--sixth-level  : 12px !default;

.heading {
    margin: $headings-margin;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
    text-transform: $headings-text-transform;

    &--first-level {
        font-size: $heading-font-size--first-level;
    }

    &--second-level {
        font-size: $heading-font-size--second-level;
    }

    &--third-level {
        font-size: $heading-font-size--third-level;
    }

    &--fourth-level {
        font-size: $heading-font-size--fourth-level;
    }

    &--fifth-level {
        font-size: $heading-font-size--fifth-level;
    }

    &--sixth-level {
        font-size: $heading-font-size--sixth-level;
    }

    &--page {
        margin: $headings-margin--page;
        font-size: $heading-font-size--page;
        text-transform: $headings-text-transform--page;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @extend .heading;
}

h1 {
    @extend .heading--first-level;
}

h2 {
    @extend .heading--second-level;
}

h3 {
    @extend .heading--third-level;
}

h4 {
    @extend .heading--fourth-level;
}

h5 {
    @extend .heading--fifth-level;
}

h6 {
    @extend .heading--sixth-level;
}

div[data-content-type="collapsible-heading"] h1, div[data-content-type="text"] h1, h1[data-content-type=heading] {
    font-size: 30px;
    font-weight: bold;
    margin: 0 0 30px;
}
div[data-content-type="collapsible-heading"] h2, div[data-content-type="text"] h2, h2[data-content-type=heading] {
    font-size: 26px;
    font-weight: bold;
    margin: 0 0 26px;
}
div[data-content-type="collapsible-heading"] h3, div[data-content-type="text"] h3, h3[data-content-type=heading] {
    font-size: 22px;
    font-weight: bold;
    margin: 0 0 22px;
}
div[data-content-type="collapsible-heading"] h4, div[data-content-type="text"] h4, h4[data-content-type=heading] {
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 20px;
}
div[data-content-type="collapsible-heading"] h5, div[data-content-type="text"] h5, h5[data-content-type=heading] {
    font-size: 18px;
    font-weight: bold;
    margin: 0 0 18px;
}
div[data-content-type="collapsible-heading"] h6, div[data-content-type="text"] h6, h6[data-content-type=heading] {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 14px;
}
*[data-heading-style-italic=style-italic] {
    font-style: italic;
}
*[data-heading-style-underline=style-underline] {
    text-decoration: underline;
}
