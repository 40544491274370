$product-reviews__image-margin         : $spacer auto !default;
$product-reviews__image-max-width      : 300px !default;
$product-reviews__details-margin-bottom: $spacer--medium !default;
$product-reviews__product-name-margin  : 0 !default;

.product-reviews {
    &__image {
        display: block;
        margin: $product-reviews__image-margin;
        max-width: $product-reviews__image-max-width;
    }

    &__details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $product-reviews__details-margin-bottom;
    }

    &__name {
        margin: $product-reviews__product-name-margin;
    }
}
