$product-media-gallery-height\@xs                       : 210px;
$product-media-gallery-height\@screen-mobile-large      : 250px;
$product-media-gallery-height\@screen-m                 : 300px;
$product-media-gallery-height                           : 400px;
$gallery-color-primary                                  : $color-primary !default;
$gallery-color-gray                                     : $gray-light !default;
$gallery-color-white                                    : $white !default;

.gallery {
    display: flex;
    flex-flow: nowrap;
    justify-content: flex-start;
    list-style-type: none;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;

    .product-media-gallery {
        position: relative;
        overflow: hidden;
        height: $product-media-gallery-height\@xs;

        @include mq($screen-mobile-large) {
            height: $product-media-gallery-height\@screen-mobile-large;
        }

        @include mq($screen-m) {
            height: $product-media-gallery-height\@screen-m;
        }

        @include mq($screen-l) {
            height: $product-media-gallery-height;
        }

        &__images,
        &__enlarger-images {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            min-width: 100%;
            transition: left 0.3s ease-in-out;
        }

        &__image {
            cursor: zoom-in;
        }

        &__image {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            picture {
                position: absolute;
                left: 50%;
                top: 50%;
                width: $product-media-gallery-height\@xs;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                transform: translate(-50%, -50%);

                @include mq($screen-mobile-large) {
                    width: $product-media-gallery-height\@screen-mobile-large;
                    height: auto;
                }

                @include mq($screen-m) {
                    width: $product-media-gallery-height\@screen-m;
                    height: auto;
                }

                @include mq($screen-l) {
                    width: $product-media-gallery-height;
                    height: auto;
                }
            }
        }

        &__enlarger-image {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            picture {
                position: absolute;
                left: 50%;
                top: 50%;
                width: 100%;
                height: auto;
                max-width: 100vw;
                max-height: 100vh;
                transform: translate(-50%, -50%);

                img {
                    max-height: 100vh;
                    object-fit: contain;
                }
            }
        }

        &__navigation {
            &-button {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                opacity: 1;
                width: 40px;
                height: 40px;
                padding: 6px;
                cursor: pointer;
                border: 0;
                border-radius: 50%;
                fill: $gallery-color-white;
                background-color: $gallery-color-primary;

                svg {
                    fill: $gallery-color-white;
                    width: 25px;
                    height: 25px;
                }
            }

            &-prev {
                left: -100px;
                transition: left 0.3s ease-out;

                &.is-visible {
                    left: 0;
                }
            }

            &-next {
                right: -100px;
                transition: right 0.3s ease-out;

                &.is-visible {
                    right: 0;
                }
            }
        }

        &__dots {
            position: absolute;
            left: 50%;
            bottom: 0;
            padding: 10px;
            background-color: $gallery-color-white;
            border-radius: 3px 3px 0 0;
            transform: translateX(-50%);
            display: flex;
            flex-direction: row;

            &-dot {
                content: '';
                width: 7px;
                height: 7px;
                border-width: 1px;
                border-style: solid;
                background-color: $gallery-color-gray;
                border-color: $gallery-color-gray;
                border-radius: 50%;
                margin: 0 5px;
                cursor: pointer;
                transition: background-color 0.3s linear, border-color 0.3s linear;

                &.is-active {
                    background-color: $gallery-color-primary;
                    border-color: $gallery-color-primary;
                }
            }
        }

        &__enlarger {
            position: fixed;
            z-index: 1000000;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.7);

            &-content {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background-color: $gallery-color-white;

                @include mq($screen-m) {
                    left: 10px;
                    top: 10px;
                    right: 10px;
                    bottom: 10px;
                }

                @include mq($screen-l) {
                    left: 15px;
                    top: 15px;
                    right: 15px;
                    bottom: 15px;
                }
            }

            &-images-wrapper {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                overflow: hidden;

                @include mq($screen-m) {
                    left: 110px;
                }

                @include mq($screen-l) {
                    left: 224px;
                }
            }

            &-thumbnails {
                position: absolute;
                left: 0px;
                top: 0;
                width: 110px;
                bottom: 0px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: flex-start;
                overflow: auto;
                display: none;

                @include mq($screen-m) {
                    width: 110px;
                    display: flex;
                }

                @include mq($screen-l) {
                    width: 224px;
                }
            }

            &-thumbnail {
                margin: 10px;
                cursor: pointer;
                border: 1px solid $gallery-color-gray;
                transition: border-color 0.3s linear;

                &.is-active {
                    border-color: $gallery-color-primary;
                }
            }

            &__navigation {
                position: absolute;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: $gallery-color-primary;
                border-radius: 0 0 3px 3px;
                color: $gallery-color-white;
                padding: 5px;

                &-button {
                    padding: 3px;
                }

                &-position {
                    padding: 3px 20px;
                }

                svg {
                    fill: $gallery-color-white;
                    width: 25px;
                    height: 25px;
                }
            }

            &__buttons {
                position: absolute;
                top: 0;
                right: 0;
                background-color: $gallery-color-primary;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 5px;
                border-radius: 0 0 0 3px;

                svg {
                    fill: $gallery-color-white;
                    width: 25px;
                    height: 25px;
                }

                &-close {
                    display: block;
                    width: 31px;
                    height: 31px;
                    position: relative;
                    padding: 3px;

                    &:before {
                        display: block;
                        position: absolute;
                        left: 5px;
                        top: 14px;
                        content: '';
                        width: 23px;
                        height: 2px;
                        background-color: $gallery-color-white;
                        transform: rotate(45deg);
                    }

                    &:after {
                        display: block;
                        position: absolute;
                        left: 5px;
                        top: 14px;
                        content: '';
                        width: 23px;
                        height: 2px;
                        background-color: $gallery-color-white;
                        transform: rotate(135deg);
                    }
                }
            }
        }
    }
}
