.service-request {
    &.customer-identify {
        background: $color-primary--accent-2;
        padding: 15px 0;
        margin-bottom: 15px;
    }

    &.customer-identify .title{
        color: $color-primary;
    }

    &.customer-identify .login,
    &.customer-identify .guest-order {
        padding: 15px;
        margin-bottom: 0;
    }

    &.customer-identify .guest-order {
        @include mq($screen-m) {
            border-left: 1px solid #b3cddc;
        }
    }

    &.customer-identify .button {
        padding-right: 38px;
        padding-left: 38px;
        width: 100%;
        @include mq($screen-m) {
            width: 50%;
        }
    }

    .loading-overlay {
        pointer-events: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 200;
        background-color: rgba(0, 0, 0, 0.3);
        transition: opacity 0.1s linear;
        opacity: 0;

        &.active {
            pointer-events: initial;
            opacity: 1;
        }

        .loader {
            display: inline-block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .spinner:after {
            position: absolute;
            top: calc(50% - 32px);
            left: calc(50% - 32px);
            content: " ";
            display: block;
            width: 64px;
            height: 64px;
            border-radius: 50%;
            border: 6px solid $color-light;
            border-color: $color-light transparent $color-light transparent;
            animation: lds-dual-ring 1.2s linear infinite;
        }
    }

    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .block-title {
        color: $color-primary;
        margin-bottom: 10px;
    }

    .order-step {
        .block-title {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .non-eligible-link {
                padding-left: 30px;
                float: right;
            }
        }
    }

    .block-instructions {
        margin-bottom: 20px;
    }

    .block-content {
        .quotation-request {
            .input-customer-iban {
                margin-top: 5px;
            }
        }
    }

    .order-items-list {
        margin-bottom: 30px;
    }

    .step-navigator {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;

        .step {
            cursor: default;
            box-sizing: border-box;
            position: relative;
            margin: 0 1px;
            padding: 10px;
            flex-grow: 0;
            flex-shrink: 0;
            border: $border-base;
            color: $color-text-accent;

            @include mq($screen-m) {
                margin: 0 10px;
                padding: 10px 25px;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &.active {
                background-color: $color-primary;
                color: $color-light;
            }

            &.history {
                cursor: pointer;
            }
        }
    }

    .service-request-form {
        display: flex;
        flex-direction: column;

        .steps {
            flex-grow: 1;
            flex-shrink: 1;
        }

        .summary {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 100%;
            margin-top: 20px;
        }

        .summary-item {
            margin-bottom: 10px;
        }

        .action-toolbar {
            margin-top: 20px;
            margin-bottom: 20px;

            .action.primary {
                font-weight: normal;
                width: auto;
                height: auto;
                padding: 10px 25px;
                background-color: $color-primary;
                background-image: none;
                border-radius: 0;

                &:hover, &:focus, &:active {
                    background-image: none;
                    background-color: $color-primary;
                }

                &:disabled {
                    background-color: $gray-lighter;
                }
            }
        }

        .success-instructions {
            .product {
                display:flex;
                align-items: flex-start;
                padding: 20px;
                margin-top: 10px;
                margin-bottom: 10px;
                background: $color-primary--accent-2;

                .image {
                    width: 70px;
                    min-width: 70px;
                    margin-right: 20px;
                }

                .name {
                    margin-right: 20px;
                    max-width: calc(50% - 55px);

                    p {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .info {
                    text-align: right;
                    margin-left: auto;
                    max-width: calc(50% - 55px);

                    .item-image {
                        margin: 0 0 5px 5px;
                        width: calc(25% - 5px);

                        img {
                            margin: 0;
                            width: 100%;
                            min-width: 100%;
                        }
                    }
                }
            }
        }

        .success {
            .action-toolbar {
                padding-left: 40px;
            }
        }
    }

    .list-header {
        background: $color-primary--accent-2;
        color: $color-primary;
        font-weight: bold;
        margin-bottom: 10px;
        border-radius: 8px;

        span, strong {
            color: $color-primary;
            font-weight: bold;
        }
    }

    .orders-list {
        .order-item {
            font-size: 10px;
            line-height: 25px;
            display: flex;
            flex-flow: row;
            align-items: center;
            width: 100%;
            padding: 10px;
            margin-bottom: 5px;
            box-sizing: border-box;
            font-weight: 400;

            @include mq($screen-m) {
                font-size: 14px;
            }

            .order-id,
            .order-date,
            .order-total {
                flex: 0 0 25%;
            }

            .order-select {
                box-sizing: border-box;
                padding: 0 10px;
                flex-grow: 1;
                justify-content: center;
                display: flex;

                .tooltip {
                    .tooltip__icon {
                        line-height: 20px;
                        font-weight: bold;
                    }
                    .tooltip__content {
                        left: unset;
                        right: -140px;
                        bottom: 35px;

                        &::before {
                            left: unset;
                            right: 5px;
                            top: unset;
                            bottom: -15px;
                            border-top: 25px solid #e7f6ff;
                            border-bottom: none;
                        }
                    }
                }

                button {
                    width: 100%;
                }

                @include mq(false, $screen-m) {
                    ul {
                        line-height: 1.5;
                        padding-left: 0;

                        li {
                            &:not(:first-child) {
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    .table {
        padding: 0;

        thead {
            th {
                background: initial;
                border: none;

                .checkbox__label--checkbox {
                    cursor: pointer;
                }
            }
        }

        tbody {
            tr:not(:first-child) {
                td {
                    border-top: 1px solid $gray-light;
                }
            }

            td {
                background: initial;
                border: none;
                vertical-align: top;

                .checkbox__label--checkbox {
                    padding: 0 10px;
                    cursor: pointer;
                }
            }

            .item-name {
                display: flex;
            }

            .item-image {
                padding-left: 5px;
                padding-right: 5px;
                margin-bottom: 10px;
            }
        }

        .item-select {
            height: 60px;
        }
    }

    .option-list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-flow: row wrap;
        list-style: none;

        li {
            flex-basis: 33%;
            padding: 10px 20px;
            cursor: pointer;

            label {
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                justify-content: center;
                min-height: 75px;
                background: $gray-lightest;
                font-weight: $font-weight-semi-bold;

                span {
                    padding: 20px;
                }
            }

            input[type=radio] {
                display: none;
            }

            input[type=radio]:checked + label {
                background: $color-primary--accent-2;
            }
        }
    }

    .item-mobile {
        display: none;
    }

    .item-name {
        img {
            width: 80px;
            height: 80px;
        }

        .label {
            cursor: pointer;
            margin-left: 10px;
            word-break: break-word;
        }
    }

    .item-price {
        label {
            white-space: nowrap;
        }
    }

    .item-qty {
        input {
            margin-bottom: 5px;
        }

        p {
            margin-bottom: 0;
        }
    }

    .image-upload {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;
        max-width: 300px;
        width: 300px;
        margin-bottom: 15px;

        .button {
            background: $color-primary--accent-2;
            color: $color-primary;
            border-radius: 0;
            white-space: nowrap;
            text-align: center;
        }
    }

    .image-uploaded {
        .col-xs-3 {
            img {
                width: 100%;
            }
        }
    }

    .placeholder {
        display: block;
        text-align: center;
        padding: 5px 10px;
        background: $gray-light;
        color: $color-text-accent-2;
        cursor: pointer;

        p {
            margin-top: 8px;
            margin-bottom: 9px;
        }

        & > img {
            width: 100%;
            height: auto;
            margin: 10px auto 0;
        }

        .col-xs-6 {
            display: flex;
            flex-direction: column;
            padding: 2px;

            img {
                width: 100%;
                max-width: 200px;
                height: auto;
            }

            a {
                line-height: 1.2;
                margin-top: auto;
            }
        }
    }

    .item-pictures-step {

        .item-name {
            padding: 10px 0;
        }

        .item-picture-input {
            padding: 10px 0;

            img {
                padding: 10px 0;
            }

            input {
                padding: 10px 0;
            }
        }
    }

    textarea {
        width: 100%;
        border: $border-base;
        border-radius: $border-radius;
        padding: 5px;
        color: $color-text-accent;
        word-break: break-word;
    }

    select {
        cursor: pointer;
        line-height: 1.5;
        padding-right: 35px;
    }

    input:disabled,
    select:disabled,
    textarea:disabled {
        color: $color-text-accent-3;
        cursor: default;
    }
}

@include mq($screen-xl) {
    .service-request {
        .order-items-list {
            tbody {
                textarea {
                    max-width: 200px;
                }

                select {
                    min-width: 105px;
                }

                .item-name {
                    width: 300px;
                }

                .image-upload {
                    .placeholder {
                        .col-xs-6 {
                            &:nth-child(n+3) {
                                margin-top: 5px;
                            }
                        }
                    }

                    .col-xs-5 {
                        padding: 0;
                        flex-basis: 45%;
                        max-width: 45%;
                    }
                }

                .image-uploaded {
                    max-width: 300px;
                    width: 300px;
                }
            }
        }
    }
}

@include mq(false, $screen-xl) {
    .service-request {
        .order-items-list {
            display: flex;
            flex-direction: row;
            overflow: hidden;
            border: 1px solid $gray-light;

            thead,
            tbody {
                display: flex;
                flex-direction: column;

                tr {
                    display: flex;
                    flex-direction: column;

                    th, td {
                        position: relative;
                    }
                }
            }

            thead {
                position: absolute;
                width: 25vw;

                .list-header {
                    margin-bottom: 0;
                }

                th:not(.item-select) {
                    position: absolute;
                    top: -9999px;
                    left: -9999px;
                }

                .item-select {
                    position: relative;
                    top: 0;
                    left: 0;
                    padding: 0;
                    z-index: 1;

                    .checkbox__label--checkbox {
                        padding: 30px;
                        margin: 0;

                        &:before {
                            top: 11px;
                            left: auto;
                            right: 11px;
                        }

                        &:after {
                            top: 16px;
                            left: auto;
                            right: 15px;
                        }
                    }
                }
            }

            tbody {
                position: relative;
                width: 100%;
                padding-left: 25vw;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 25vw;
                    height: 100%;
                    background: $color-primary--accent-2;
                }

                tr {
                    td {
                        &:before {
                            content: '';
                            position: absolute;
                            top: -1px;
                            left: -100%;
                            width: 100%;
                            border-top: 1px solid $gray-light;
                        }

                        &:not(:first-child) {
                            border-top: 1px solid $gray-light;
                        }
                    }
                }

                textarea {
                    width: 100% !important;
                }

                .item-select {
                    padding: 0;

                    .checkbox__label--checkbox {
                        padding: 30px;
                        margin: 0;

                        &:before {
                            top: 11px;
                            left: 11px;
                        }

                        &:after {
                            top: 16px;
                            left: 15px;
                        }
                    }
                }

                .item-name {
                    align-items: center;
                }

                .image-upload {
                    max-width: none;
                    width: 100%;

                    .row {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                    }

                    .col-xs-6.placeholder {
                        flex-basis: 65%;
                        max-width: 65%;

                        .col-xs-6 {
                            flex-basis: 33%;
                            max-width: 33%;

                            &:nth-child(n+4) {
                                margin-top: 5px;
                            }
                        }
                    }

                    .col-xs-5 {
                        flex-basis: 30%;
                        max-width: 30%;
                    }
                }
            }

            .item-mobile {
                color: $color-primary;
                position: absolute;
                display: block;
                top: -1px;
                right: 100%;
                width: 25vw;
                padding: 10px;
                word-break: break-word;
            }
        }
    }
}

@include mq(false, $screen-m) {
    .service-request {
        .order-items-list {
            tbody {
                .image-upload {
                    .col-xs-6.placeholder {
                        .col-xs-6 {
                            flex-basis: 50%;
                            max-width: 50%;

                            &:nth-child(1),
                            &:nth-child(2) {
                                margin-top: 10px;
                            }

                            &:nth-child(n+3) {
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }
        }

        .service-request-form {
            .success-instructions {
                .product {
                    flex-direction: column;

                    .image {
                        margin: 0 auto;
                    }

                    .name {
                        text-align: center;
                        margin: 20px auto;
                        max-width: 100%;
                    }

                    .info {
                        text-align: center;
                        margin: 0 auto;
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

@include mq(false, $screen-s) {
    .service-request {
        .order-items-list {
            tbody {
                .item-name {
                    flex-direction: column;

                    label {
                        margin-left: 0;
                        margin-bottom: 0;
                    }
                }

                .image-upload {
                    .col-xs-6.placeholder {
                        flex-basis: 50%;
                        max-width: 50%;

                        .col-xs-6 {
                            flex-basis: 100%;
                            max-width: 100%;

                            &:nth-child(1) {
                                margin-top: 0;
                            }

                            &:nth-child(n+2) {
                                margin-top: 5px;
                            }
                        }
                    }

                    .col-xs-5 {
                        flex-basis: 45%;
                        max-width: 45%;
                    }
                }
            }
        }

        .service-request-form {
            .success-instructions {
                .product {
                    .info {
                        .item-image {
                            margin: 5px;
                            width: calc(50% - 10px);
                        }
                    }
                }
            }
        }
    }
}
