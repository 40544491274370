.grouped-filter {
    list-style: none;
    flex-grow: 1;
    margin: 0 10px 5px 0;

    &:last-child {
        margin-right: 0;
    }

    &__button {
        min-height: 27px;
        height: auto;
        padding: 5px 10px;
        min-width: 100%;
        font-weight: $grouped-filters__filter-label-font-weight;
        font-size: $grouped-filters__filter-label-font-size;
        background-color: $grouped-filter__button-background;

        &:hover {
            background-color: $grouped-filter__button-hover-background;
        }
    }

    &__label {
        color: $grouped-filter__label-color;
    }

    &--active {

        .grouped-filter__button {
            background-color: $grouped-filter__button-active-background;
        }
    }

    &--block {

        .grouped-filter__button {
            height: 100%;
            padding: 12px;
            background-color: $grouped-filter__button-block-background;
            color: $grouped-filter__button-block-color;
            border: $grouped-filters__mobile-border;
        }

        &-active {

            .grouped-filter__button {
                background-color: $grouped-filter__button-block-active-background;
                color: $grouped-filter__button-block-active-color;
            }
        }
    }

    &--disabled {

        .grouped-filter__button {
            background-color: $color-light--accent-2;
            color: $color-text-accent-4;
        }
    }
}
