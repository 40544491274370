$password-strength__margin                           : $spacer--medium 0 0 !default;
$password-strength__font-size                        : $font-size-small !default;
$password-strength__color                            : $gray !default;

$password-strength__indicator-width                  : 128px !default;
$password-strength__indicator-margin                 : 0 0 0 $spacer !default;
$password-strength__indicator-padding                : 4px 0 !default;
$password-strength__indicator-border-radius          : 32px !default;
$password-strength__indicator-font-size              : $font-size-base !default;

$password-strength__indicator-background--none       : $gray-lighter !default;
$password-strength__indicator-color--none            : $gray-darker !default;
$password-strength__indicator-background--weak       : $red !default;
$password-strength__indicator-color--weak            : $white !default;
$password-strength__indicator-background--medium     : $gray !default;
$password-strength__indicator-color--medium          : $white !default;
$password-strength__indicator-background--strong     : $blue !default;
$password-strength__indicator-color--strong          : $white !default;
$password-strength__indicator-background--very-strong: $green !default;
$password-strength__indicator-color--very-strong     : $gray-darker !default;

.password-strength {
    margin: $password-strength__margin;
    color: $password-strength__color;
    font-size: $password-strength__font-size;

    &__handle {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    &__indicator {
        position: relative;
        display: inline-block;
        min-width: $password-strength__indicator-width;
        margin: $password-strength__indicator-margin;
        padding: $password-strength__indicator-padding;
        border-radius: $password-strength__indicator-border-radius;
        background-color: transparent;
        font-size: $password-strength__indicator-font-size;
        text-align: center;
        overflow: hidden;

        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            z-index: -1;
            height: 100%;
            width: 100%;
        }

        .password-none & {
            color: $password-strength__indicator-color--none;
            &:before {
                background-color: $password-strength__indicator-background--none;
            }
        }

        .password-weak & {
            color: $password-strength__indicator-color--weak;
            &:before {
                background-color: $password-strength__indicator-background--weak;
            }
        }

        .password-medium & {
            color: $password-strength__indicator-color--medium;
            &:before {
                background-color: $password-strength__indicator-background--medium;
            }
        }

        .password-strong & {
            color: $password-strength__indicator-color--strong;
            &:before {
                background-color: $password-strength__indicator-background--strong;
            }
        }

        .password-very-strong & {
            color: $password-strength__indicator-color--very-strong;
            &:before {
                background-color: $password-strength__indicator-background--very-strong;
            }
        }
    }
}
