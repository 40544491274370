$logo__max-width               : 265px !default;
$logo__max-width\@medium       : 265px !default;
$logo__image-height            : inherit !default;
$logo__image-height\@medium    : 34px !default;

$logo__slogan-display-\@medium : block !default;
$logo__slogan-font-size        : $font-size-small !default;
$logo__slogan-letter-spacing   : 2px !default;
$logo__slogan-color            : $color-primary--accent-6 !default;

.logo {
    display: flex;

    @include mq($screen-m) {
        display: inline-block;
    }

    &__image {
        display: block;
        height: $logo__image-height;
        max-width: $logo__max-width;

        @include mq($screen-m) {
            width: $logo__max-width\@medium;
            height: $logo__image-height\@medium;
        }

        @include mq(false, $screen-m) {
            object-fit: contain;
            width: 100%;
        }

        @include isIE() {
            height: 100%;
        }
    }

    &__slogan {
        display: none;
        text-transform: uppercase;
        font-size: $logo__slogan-font-size;
        letter-spacing: $logo__slogan-letter-spacing;
        color: $logo__slogan-color;
        text-align: right;
        max-height: 40px;
        margin-top: 8px;
        transition: 0.3s ease;
        overflow: hidden;

        @include mq($screen-m) {
            display: $logo__slogan-display-\@medium;
        }
    }
}
