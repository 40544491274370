.modal-request-quote {
    text-align: center;
    min-width: 450px;
    margin: 0 auto;
    width: 90%;
    height: 600px;

    @include mq($screen-m) {
        height: 100%;
        width: 25%;
        margin: 50px auto;
        box-shadow: none;
    }

    .amlabel-position-wrapper {
        display: none !important;
    }

    .heading {

        .icon {
            margin: 0 auto;
            padding: 0;
            width: 25px;
            height: 25px;
            fill: $color-primary;

            @include mq($screen-m) {
                margin: 15px auto;
            }
        }

        h4 {
            margin-bottom: 0;
            font-weight: $font-weight-bold;
            font-size: $font-size-large;
            color: $color-text-accent;

            @include mq($screen-m) {
                margin-bottom: 0.5em;
            }
        }

        p {
            text-align: left;
            color: $color-text-accent-2;
        }
    }

    .request {
        margin: 20px 0 0;

        @include mq($screen-m) {
            margin: 30px 0 0;
        }

        span {
            display: block;
            text-align: left;
            margin: 10px 0;
            color: $color-text-accent;
            font-weight: $font-weight-semi-bold;
        }

        &__product {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
        }

        .lazyload-wrapper {
            flex-basis: 60px;
        }

        &__title {
            flex-basis: calc(100% - 60px - 20px);
            text-align: left;
            line-height: normal;

            @include mq(false, $screen-m) {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        &__qty {
            background: $color-primary--accent-2;
            border-radius: 10px;
            margin: 20px 0;
            padding: 15px;

            .label {
                font-weight: $font-weight-semi-bold;
                color: $color-text-accent;
            }

            .quantity-update {
                margin: 0 auto;
                background: #fff;
            }
        }

        &__multipack {
            display: inline-block;
            margin-top: 5px;
        }

        &__actions {

            .button {
                display: block;
                margin: 10px auto;
                width: 100%;

                @include mq($screen-m) {
                    width: 260px;
                }

                &--cta {
                    font-weight: $font-weight-bold;
                }

                &--light {
                    font-size: $font-size-base;
                }
            }
        }

        &__callback {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            margin: 20px 0 0;

            a {
                margin-left: 8px;
            }
        }
    }

    hr {
        margin: 25px -15px 0;
        border: none;
        height: 1px;
        background: $border-color-base;

        @include mq($screen-m) {
            margin: 25px -30px 0;
        }
    }

    &__wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
    }

    &__title {
        text-align: center;
        margin-top: 15px;
        flex-basis: 100%;
        font-weight: $font-weight-bold;

        @include mq($screen-m) {
            text-align: left;
        }
    }

    .input {
        &.required {
            & > {
                label,
                .label {
                    &:after {
                        content: '*';
                        font-size: 1.2rem;
                        margin: 0 0 0 5px;
                        color: $color-tertiary;
                    }
                }
            }
        }
    }

    &.modal-popup,
    &.modal-slide {
        left: 0 !important;

        .modal-header {
            padding: 20px 50px 0;
        }

        .modal-inner-wrap {
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.35);
            border-radius: $border-radius;

            @include mq($screen-m) {
                max-height: calc(100% - 100px) !important;
            }
        }

        .modal-content {
            padding-bottom: 21px;
            padding-left: 30px;
            padding-right: 30px;

            @include mq($screen-m) {
                padding-left: 40px;
                padding-right: 40px;
            }
        }
    }
}

.modals-wrapper div::-webkit-scrollbar {
    display: none;
}
