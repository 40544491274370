.checkout-onepage-success {

    .messages {
        display: none !important;
    }

    .title-wrapper {
        position: absolute;
        left: 0;
        width: 100%;
        margin: 50px 15px 0;

        @include mq($screen-l) {
            text-align: center;
            margin: 50px 0;
            z-index: 50;
        }

        .heading {
            text-transform: none;
            margin: 5px 0 0;
            line-height: $thank-you-page__title_font-size;
            font-size: $thank-you-page__title_font-size;
            color: $thank-you-page__title-color;
            font-weight: $thank-you-page_-title-font-weight;

            @include mq($screen-l) {
                width: 100%;
                font-size: $thank-you-page__title_font-size\@large;
            }
        }
    }

    @include mq($screen-m) {

        .page-wrapper main {
            margin-top: $thank-you-page__main-margin-top;
        }
    }
}

.thank-you-page {
    margin: 0 -#{$global-container-margin};
    overflow-y: hidden;

    &__header {
        background: $thank-you-page__header-background;
        height: $thank-you-page__header-height;
        padding: $thank-you-page__header-padding;
        color: $thank-you-page__header-color;

        @include mq($screen-l) {
            position: absolute;
            text-align: center;
            width: 100%;
            left: 0;
            padding: $thank-you-page__header-padding\@large;
            font-size: $thank-you-page__header-font-size\@large;
        }
    }

    &__content {
        background: $thank-you-page__content-background;
        box-shadow: $thank-you-page__content-box-shadow;
        border-radius: $thank-you-page__content-border-radius;
        margin: $thank-you-page__content-margin;
        padding: $thank-you-page__content-padding;

        @include mq($screen-l) {
            margin: $thank-you-page__content-margin\@large;
            position: relative;
            top: 160px;
            max-width: $thank-you-page__content-width\@large;
        }

        .static-text {
            margin: 0 -#{$global-container-margin};
            padding: $thank-you-page__static-padding;
            border-bottom: $thank-you-page__static-text-border;

            @include mq($screen-l) {
                padding: $thank-you-page__static-padding\@large;
            }

            &:last-child {
                border-bottom: none;
            }

            h4 {
                font-weight: $thank-you-page__static-heading-font-weight;
            }

            p {
                color: $thank-you-page__static-color;
                margin-bottom: $thank-you-page__static-margin;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .order-info li {
            margin-bottom: 20px;
        }

        .order-info p {

            img {
                width: 50px;
                height: 50px;
            }

            strong {
                display: block;
            }
        }
    }
}
