$range__padding               : $spacer 0 0 0 !default;
$range__margin                : 0 0 $spacer--medium 0 !default;
$range__height                : 8px !default;
$range__input-margin          : 0 !default;
$range__connect-border-radius : 0 !default;
$range__handler-width         : 24px !default;
$range__handler-height        : 24px !default;
$range__handler-bg-color      : $white !default;
$range__handler-border        : none !default;
$range__handler-border-radius : 50% !default;
$range__handler-box-shadow    : 0 2px 4px 0 rgba(51, 51, 51, 0.5) !default;
$range__handler-offset-top    : -1 * $range__height !default;
$range__track-bg-color        : $gray !default;
$range__track-bg-color--active: $color-primary !default;
$range__track-border-radius   : $border-radius;
$range__track-border          : none !default;
$range__track-box-shadow      : none !default;
$range__dash-padding          : 0 $spacer--medium 0 $spacer !default;
$range__suffix-padding        : 0 $spacer !default;
$range__button-padding        : 0 $spacer--extra-large !default;
$range__button-margin         : 0 0 $spacer--medium 0 !default;

.range-filter {
    padding: $range__padding;
    overflow: hidden;
    &__slider {
        margin: $range__margin;
    }
    &__inputs-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__dash {
        text-align: center;
        padding: $range__dash-padding;
    }
    &__suffix {
        padding: $range__suffix-padding;
    }
    &__label {
        @include visually-hidden();
    }
    &__input {
        width: auto;
        flex-grow: 1;
        margin: $range__input-margin;
    }
    &__button {
        margin: $range__button-margin;
        padding: $range__button-padding;
    }
}
.noUi-connect {
    background-color: $range__track-bg-color--active;
    border-radius: $range__connect-border-radius;
}
.noUi-tooltip {
    display: none;
}
.noUi-target {
    background-color: $range__track-bg-color;
    box-shadow: $range__track-box-shadow;
    border: $range__track-border;
    border-radius: $range__track-border-radius;
    height: $range__height;
}
.noUi-handle {
    &:hover {
        cursor: pointer;
    }
    .noUi-horizontal & {
        border-radius: $range__handler-border-radius;
        background: $range__handler-bg-color;
        width: $range__handler-width;
        height: $range__handler-height;
        border: $range__handler-border;
        box-shadow: $range__handler-box-shadow;
        top: $range__handler-offset-top;
        &:before,
        &:after {
            display: none;
        }
        &.focus-visible {
            outline: none;

            .noUi-tooltip {
                display: block;
            }
        }
    }
}
.noUi-extended {
    padding-right: 24px;
}
.noUi-extended .noUi-handle {
    left: 0;
}
.noUi-extended .noUi-origin  {
    right: -24px;
}
