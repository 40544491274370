@mixin visually-hidden($usePseudo: '') {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    @if $usePseudo != '' {
        &.focus-visible,
        &:active {
            clip:     auto;
            height:   auto;
            margin:   0;
            overflow: visible;
            position: static;
            width:    auto;
        }
    }
}
