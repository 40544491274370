#product-finder-modal-content {
    display: none;
}

.modal-product-finder.modal-popup.modal-slide {
    .modal-inner-wrap {
        max-width: 668px;
    }

    &._show {
        z-index: 999999;
        @include mq(false, $screen-l) {
            left: 0;
        }

        .modal-inner-wrap {
            top: 150px;
            @include mq($screen-l) {
                border-radius: 5px;
            }
            overflow-x: hidden;

            .modal-header {
                top: 0;
                right: 0;
                padding: 0;
                z-index: 1;

                .heading--page {
                    width: 100%;
                    background-color: $color-primary--accent-2;
                    color: $color-primary;
                    padding: 8px;
                    text-align: center;
                    font-size: 20px;

                    @include mq(false, $screen-l) {
                        display: none;
                    }
                }

                .modal__close-button {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: auto;
                    height: auto;
                    min-width: 0;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 30px;
                        height: 30px;
                        border: 1px solid transparent;
                        border-radius: 5px;
                        transition: $transition-base;
                        transform: translate(-50%, -50%);
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        background-color: transparent;
                    }

                    .modal__close-button-icon {
                        fill: $color-primary;
                        width: 50px;
                        height: 50px;

                        &:hover,
                        &:active,
                        &:focus {
                            fill: $gray;
                        }
                    }
                }
            }

            .modal-content {
                @include mq($screen-l) {
                    padding: 0 30px;
                }
                margin: 20px 0 90px 0;

                .ais-RefinementList-list {
                    @include mq($screen-l) {
                        grid-column: span 10/span 10;
                        column-count: 2;
                    }
                }

                @include mq(false, $screen-l) {
                    .ais-Panel {
                        border-bottom: 0;

                        .ais-RefinementList-showMore {
                            border-bottom: 0;
                        }
                    }

                    .ais-Panel-header {
                        &:after {
                            display: none;
                        }

                        .name {
                            font-size: 18px;
                            font-weight: 600;
                            color: $color-primary;
                            margin-bottom: 10px;
                            padding: 0 0 10px 0;
                            border-bottom: 1px solid $gray-light;
                        }
                    }

                    .ais-Panel-body {
                        display: block;
                        padding: 0;
                        border-bottom: 0;
                    }
                }

                .attribute-filter-title {
                    display: block;
                    font-weight: bold;
                    padding: 7px 0;
                }

                .progress-bar-wrapper {
                    width: 100%;

                    .progress-bar {
                        margin: 0;

                        .progress-bar-item {
                            cursor: pointer;

                            &:before {
                                height: 3px;
                                left: 38px;
                            }

                            &._active:before {
                                background: #e4e4e4;
                            }

                            &:last-child:before {
                                background: inherit;
                            }

                            & > span:after {
                                height: 32px;
                                margin-left: -16px;
                                top: 3px;
                                width: 32px;
                                color: #e4e4e4;
                            }

                            &._active > span:after {
                                color: $color-primary;
                            }
                        }
                    }
                }
            }

            .modal-footer {
                display: flex;
                position: fixed;
                bottom: 0;
                width: 100%;
                border-top: none;

                @include mq(false, $screen-l) {
                    position: sticky;
                    background: white;

                    &:before {
                        content: '';
                        left: 0;
                        height: 65px;
                        background: #fff;
                        width: 100%;
                        z-index: 999999999;
                        bottom: 90px;
                        opacity: 0.8;
                        pointer-events: none;
                        position: absolute;
                    }
                }

                .button {
                    flex-grow: 1;
                    flex-basis: 50%;
                    margin-right: 5px;
                    font-weight: 600;

                    &:last-child {
                        margin-left: 5px;
                        margin-right: 0;
                    }
                }

                #product-count {
                    margin-right: 4px;

                    .pull-left {
                        display: inline;

                        .ais-Stats {
                            display: inline;

                            &-text {
                                font-size: 0;

                                & > span {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .attribute-modal {
            &__title {
                display: none;
            }

            &__content {
                display: block;
                color: $color-text-default;
                font-weight: normal;
                font-size: $font-size-base;
                margin: 20px 0 10px 0;
            }
        }
    }
}
