$popup__padding                  : 0 !default;
$popup__content-top-margin       : 0 !default;
$popup__width                    : 320px !default;
$popup__width--screen-s          : 100% !default;
$popup__background               : $white !default;
$popup__z-index                  : 50 !default;
$popup__border                   : 0 !default;
$popup__box-shadow-color         : $black !default;
$popup__box-shadow               : 0 4px 6px 0 rgba($popup__box-shadow-color, 0.3) !default;
$popup__close-button-bg          : $white !default;
$popup__close-button-border      : 0 !default;
$popup__button-icon-fill         : $black !default;
$popup__button-icon-fill--actiive: $color-primary !default;
$popup__margin                   : 0 !default;

.popup {
    display: none;
    position: fixed;
    right: 0;
    left: auto;
    width: $popup__width--screen-s;
    padding: $popup__padding;
    margin: $popup__margin;
    z-index: $popup__z-index;
    box-shadow: $popup__box-shadow;
    background-color: $popup__background;
    border: $popup__border;
    @include mq($screen-s) {
        position: absolute;
        width: $popup__width;
        right: - $spacer;
    }

    &--active {
        display: block;
    }

    &__handler {
        position: relative;
        margin-top: $popup__content-top-margin;
    }

    &__close-button {
        position: absolute;
        top: -48px;
        right: -16px;
        background-color: $popup__close-button-bg;
        border: $popup__close-button-border;

        &:hover,
        &.focus-visible {
            background-color: $popup__close-button-bg;
        }

        &-icon {
            fill: $popup__button-icon-fill;

            &:hover,
            &.focus-visible {
                fill: $popup__button-icon-fill--actiive;
            }
        }
    }
}
