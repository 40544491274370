$rating__margin                  : $spacer--medium !default;
$review__info-column-width       : 20% !default;
$review__decsription-column-width: 80% !default;
$review__summary-max-width\@large: 200px !default;

.review {
    width: 100%;

    @include mq($screen-m) {
        display: flex;
        flex-flow: row nowrap;
    }

    &--add {
        @include mq($screen-m) {
            display: block;
        }
    }

    &--summary {
        @include mq($screen-m) {
            display: flex;
            justify-content: flex-end;
        }

        .rating {
            margin-right: $spacer;
        }
    }

    &__details {
        width: 100%;

        @include mq($screen-m) {
            &:first-child {
                width: $review__info-column-width;
            }

            &:last-child {
                width: $review__decsription-column-width;
            }
        }
    }

    &__author,
    &__date,
    &__title,
    &__rating,
    &__rating-legend {
        margin-bottom: $rating__margin;
    }

    &__title {
        font-size: $font-size-base;
        font-weight: $font-weight-base;
        text-transform: uppercase;
    }

    &__title-product {
        display: block;
        margin: $spacer 0;
        text-transform: none;
        font-weight: bold;
    }

    &__author {
        font-weight: $font-weight-base;
    }

    &__date {
        display: block;
        color: $gray;
        font-size: $font-size-small;
    }

    &__fieldset {
        max-width: 430px;
    }

    &__field {
        margin-bottom: $spacer--extra-large;
    }

    &__submit-btn {
        width: 100%;

        @include mq($screen-m) {
            width: auto;
        }
    }

    &__required-info {
        margin-top: $spacer--large;
        font-size: $font-size-small;
    }

    &__summary-handle {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        @include mq($screen-m) {
            flex-wrap: wrap;
            justify-content: flex-end;
            width: auto;
            max-width: $review__summary-max-width\@large;
        }
    }

    &__amount {
        font-size: $font-size-small;
        text-decoration: underline;
        letter-spacing: 2px;
        margin-right: $spacer;

        @include mq($screen-m) {
            margin-right: 0;
        }

        &.focus-visible {
            @include focus-inline();
        }

        &-text {
            display: none;
        }
    }

    &__summary-add {
        text-decoration: underline;
        text-align: right;

        &.focus-visible {
            @include focus-inline();
        }
    }
}
