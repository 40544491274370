.shipping-estimation {
    border-top: 1px solid #d9d9d9;
    &.active {
        .shipping-estimation__title-icon {
            transform: rotate(180deg);
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        cursor: pointer;

        &-text {
            font-weight: 500;
        }

        &-icon {
            width: 14px;
            height: 14px;
            transition: $transition-base;
        }
    }

    &__content {
        display: none;
        padding-bottom: 20px;
    }

    &__form {
        padding-top: 20px;
    }
}
