$algolia-search-bar-background: $color-primary--accent-2!default;
$algolia-pager-background: $color-primary--accent-2!default;
$algolia-pager-color: $color-primary !default;
$algolia-current-refinement-background: $color-primary--accent-2!default;
$algolia-current-refinement-delete-color: $color-primary !default;
$algolia-search-bar-label-color: $color-text-accent!default;
$algolia-autocomplete-border-color: $gray-light!default;
$algolia-autocomplete-hit-color: $color-primary--accent-7 !default;
$algolia-filter-title-border-color: $gray-light!default;
$algolia-product-separator-color: $gray-light!default;
$algolia-product-hover-border-color: $gray-light!default;
$algolia-text-accent: $color-text-accent!default;

$algolia-result-title-color: $color-primary !default;
$algolia-pagination-color: $color-primary !default;
$algolia-pagination-color-selected: $white !default;
$algolia-pagination-background: $white !default;
$algolia-pagination-background-selected: $color-primary !default;

$algolia-category-panel-name-color: $color-primary !default;
$algolia-category-panel-name-color-mobile: $color-text-accent !default;

.page-products {
    .heading--page {
        text-transform: none;
        font-weight: $font-weight-bold;
        color: $color-text-accent;
        margin: 0;
    }
}

.fixed-overlay {
    overflow: hidden;
    position: fixed;
    width: 100%;
    .page-wrapper {
        position: relative;
        z-index: 999999;
    }
}

.algolia-search-input {
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: transparent;
        width: 100%;
        height: $search-form__input-height;
        border: none;
        border-radius: 5px;
        font-size: $search-form__font-size;
        padding: 0 10px;
    }
    input:focus {
        @include focus-input();
    }
}
.aa-InputWrapperPrefix, .aa-InputWrapperSuffix{
    display: none;
}

#algolia-autocomplete-wrapper {
    z-index: 1000;
    position: relative !important;

    @include mq(false, $screen-m) {
        width: calc(100% + 83px);
        left: -83px;
    }
}

#algolia-autocomplete-container {
    position: absolute;
    background-color: $white;
    top: -1px;
    left: 1px;
    z-index: 100;
    display: block;
    right: 0;
    border-radius: 0 5px 5px 5px;
    margin-top: -1px;
    width: 690px;

    .category {
        padding: 4px;
        background: $color-light--accent;
        color: $color-primary--accent-6;
        text-align: left;
        font-size: 0.8em;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 5px;
    }
    ul{
        list-style: none;
        padding: 0;
        margin:0;

        .algoliasearch-autocomplete-hit {
            display: block;
            position: relative;
            padding: 5px 10px 5px 4px;
            color: $black;
            text-align: left;
            text-decoration: none;
        }
        mark {
            font-weight: 700;
            font-style: normal;
            color: $algolia-autocomplete-hit-color;
            background-color: transparent;
        }
    }
    #help-section-wrapper {
        float: left;
        width: 32%;
        margin-top: 10px;
        margin-left: 10px;
        @include mq(false, $screen-m) {
            width: 100%;
            display: block;
            padding: 10px;
            margin: 0;
        }
    }

    @include mq(false, $screen-m) {
        width: calc(100% - 2px);
    }
    [   aria-selected="true"] {
        background-color: $color-light--accent-2;
        transition: 0.2s background-color ease-in-out;
    }
    [data-autocomplete-source-id="categories"] {
        float: left;
        width: 32%;
        @include mq(false, $screen-m) {
            width: 100%;
            padding: 10px;
            margin: 0;
        }
        margin: 10px;
    }
    [data-autocomplete-source-id="products"] {
        width: 62%;
        @include mq(false, $screen-m) {
            width: 100%;
            padding: 10px;
            margin: 0;
        }
        float: right;
        margin: 10px;
        ul {
            border-top: 1px solid $gray-lighter;
            li {
                display: inline-block;
                width: 100%;
                border: 1px solid $gray-lighter;
                border-top: 0;
                margin: 0;

                .thumb {
                    float:left;
                    img {
                        width: 50px;
                        height: 50px;
                        overflow: hidden;
                    }
                }
                .info {
                    margin-left: 60px;
                    height: 55px;
                    overflow: hidden;

                }
            }
        }
    }

    #autocomplete-products-footer {
        display: none;
    }
}

#algolia-autocomplete-container:has(.aa-Panel) {
    border: 1px solid $algolia-autocomplete-border-color;
}

.algolia-instant-selector-results {
    .ambanner-9 {
        margin: 10px 0;

        @include mq($screen-l) {
            margin: 20px 0;
        }
    }

    .ambanner-5 {
        margin-bottom: 15px;
    }

    svg.expand-attribute {
        display: none;
    }

    #instant-search-facets-container {
        .ais-Panel:not(.ais-Panel-CurrentRefinements) {
            .ais-Panel-header {
                cursor: pointer;

                &--open {
                    svg.expand-attribute {
                        transform: rotate(180deg);
                    }
                }

                .name {
                    display: flex;
                    align-items: center;
                }

                svg.expand-attribute {
                    @include mq($screen-l) {
                        display: inline-flex;
                        width: 11px;
                        height: 11px;
                        margin: 0 0 0 auto;
                        fill: $color-primary;
                    }
                }
            }
            .ais-Panel-body {
                display: none;
                &--open {
                    display: block;
                }
            }
        }
    }

    .ais-Panel-header {

        .tooltip--attribute {
            .tooltip__icon {
                line-height: 20px;
                font-weight: bold;
            }
        }
    }

    @include mq($screen-l) {
        #algolia-left-container {
            margin-top: 37px;
        }
    }

    .product-finder {
        padding: 16px 25px;
        background: $color-primary--accent-2;
        border-left: 5px solid $color-primary;
        margin-bottom: 15px;
        cursor: pointer;

        &_title {
            color: $color-primary--accent-5;
            font-weight: bold;
            margin-bottom: 10px;
        }

        &_text {
            color: $color-primary--accent-5;
            font-weight: 600;
        }

        &_link {
            text-decoration: underline;
        }

        @include mq(false, $screen-l) {
            margin: 10px 0;

            &_title {
                margin-bottom: 0;
                display: inline;
            }

            &_link {
                display: inline;
                margin-left: 10px;
                text-decoration: none;
                position: relative;

                &:after {
                    @include mq(false, $screen-l) {
                        text-align: center;
                        position: absolute;
                        content: '';
                        right: -16px;
                        top: 2px;
                        width: 11px;
                        height: 16px;
                        display: inline-block;
                        background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16"><path fill="#{$color-primary}" fill-rule="evenodd" d="M2.33 0L.45 1.88 6.55 8l-6.1 6.12L2.33 16l8-8z"/></svg>') no-repeat center;
                        background-size: 6px auto;
                    }
                }
            }
        }
    }

    @include mq($screen-l) {
        #algolia-right-container {
            .product-finder {
                display: none;
            }
        }
    }

    @include mq(false, $screen-l) {
        #algolia-left-container {
            .product-finder {
                display: none;
            }
        }
    }

    #current-refinements {
        display: block;

        @include mq(false, $screen-l) {
            padding: 20px 0;
        }

        .ais-Panel {
            @include mq(false, $screen-l) {
                border-bottom: 0;
            }

            .ais-Panel-body {
                @include mq(false, $screen-l) {
                    display: block;
                    padding-bottom: 0;
                }
            }

            .ais-Panel-header:after {
                @include mq(false, $screen-l) {
                    display: none;
                }
            }
        }
    }

    #algolia_instant_selector {
        display: flex;
        flex-flow: row wrap;

        #algolia-left-container {
            display: none;

            @include mq($screen-l) {
                display: block;
            }

            @include mq(false, $screen-l) {
                &.show-filters {
                    display: block;
                    position: fixed;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    background: white;
                    z-index: 99999;
                }
            }
        }

        #algolia-right-container {
            @include mq($screen-l) {
                padding-left: 30px;
            }
        }

        #instant-search-bar-container {
            display: none;
            background-color: $algolia-search-bar-background;
            padding: 20px 35px 20px 20px;

            .instant-search-bar-label {
                color: $algolia-search-bar-label-color;
                font-weight: $font-weight-semi-bold;
                font-size: $font-size-base;
                flex-shrink: 0;
                align-self: center;
                margin-right: 15px;
            }

            #instant-search-bar {
                align-self: center;
                width: 100%;

                .ais-SearchBox-input {
                    width: 100%;
                    border: 0;
                    border-radius: 5px;
                    height: 42px;
                    padding: 0 13px;
                }

                .ais-SearchBox-reset {
                    display: none;
                }
            }

            .catalogsearch-result-index & {
                display: flex;
            }
        }

        .infos {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            border-bottom: 1px solid $algolia-filter-title-border-color;
            color: $algolia-text-accent;

            .pull-right {
                @include mq(false, $screen-l) {
                    width: 100%;
                }
            }

            .ais-Stats {
                display: none;

                @include mq($screen-l) {
                    display: block;
                }
            }
        }

        .pull-left {
            flex: 1;
        }

        .pull-right {
            display: flex;
            align-items: center;
        }

        .sort-by-label {
            display: none;
            margin-right: 8px;

            @include mq($screen-l) {
                display: block;
            }
        }

        #algolia-sorts {
            position: relative;
        }

        #refine-toggle {
            font-size: 16px;
            text-align: center;
            border: 1px solid $gray-light;
            margin: 10px 0 20px;
            cursor: pointer;
            background: $color-primary;
            color: $white;
            float: left;
            position: relative;
            height: 45px;
            line-height: 45px;
            border-radius: 23px;
            margin-left: -10px;
            padding: 0 25px 0 40px;
            width: 140px;
            box-shadow: 0 2px 10px -6px rgba(17, 17, 17, 0.5);

            &:before {
                content: '';
                position: absolute;
                left: 18px;
                top: 14px;
                display: inline-block;
                background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white"><path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"/></svg>') no-repeat center;
                background-size: cover;
                width: 15px;
                height: 15px;
            }

            &.floating {
                position: fixed;
                left: 50%;
                bottom: 75px;
                margin: 0;
                width: 160px;
                border-radius: 5px;
                z-index: 10;
                transform: translateX(-50%);
                font-weight: bold;
            }

            @include mq($screen-l) {
                display: none;
            }
        }

        .ais-SortBy {
            &:after {
                content: "";
                pointer-events: none;
                text-align: center;
                position: absolute;
                background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8"><path fill="#fff" d="M12 2L10.59.59 6 5.17 1.41.59 0 2l6 6z"/></svg>') no-repeat center;
                width: 20px;
                height: 8px;
                right: 4px;
                top: 30px;
                font-weight: 900;
                display: inline-block;
                z-index: 1;

                @include mq($screen-l) {
                    top: 20px;
                    background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8"><path fill="#2d2d2d" d="M12 2L10.59.59 6 5.17 1.41.59 0 2l6 6z"/></svg>');
                }
            }
        }

        .ais-SortBy-select {
            margin: 0;
            background: $white;
            color: $algolia-text-accent;
            border-radius: 5px;
            font-size: 14px;
            box-shadow: none;
            text-align: center;
            text-align-last: center;
            border: 1px solid $gray-light;
            cursor: pointer;
            outline: 0;
            float: right;
            position: relative;
            height: 45px;
            line-height: 45px;
            width: auto;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            padding: 0 30px 0 10px;
            -webkit-padding-end: 30px;
            -webkit-padding-start: 10px;
            font-weight: normal;

            @include mq(false, $screen-l) {
                font-size: 16px;
                border: 1px solid $gray-light;
                margin: 10px -10px 20px 0;
                cursor: pointer;
                background: $color-primary;
                color: $white;
                outline: 0;
                float: right;
                position: relative;
                line-height: 45px;
                border-radius: 22.5px;
                box-shadow: 0 2px 10px -6px rgba(17, 17, 17, 0.5);
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                padding: 0 30px 0 10px;
                -webkit-padding-end: 30px;
                -webkit-padding-start: 10px;
            }
        }

        .ais-Hits {
            &-item {
                float: left;
                width: 100%;
                border-bottom: 1px solid $algolia-product-separator-color;

                @media (min-width: 769px) {
                    width: 50%;
                }

                @media (min-width: 992px) {
                    width: 25%;
                }

                .result-content {
                    position: relative;

                    @include mq(false, $screen-m) {
                        display: flex;
                        flex-direction: row;
                    }

                    &:after {
                        @include mq(false, $screen-m) {
                            text-align: center;
                            position: absolute;
                            content: '';
                            background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16"><path fill="#2d2d2d" fill-rule="evenodd" d="M2.33 0L.45 1.88 6.55 8l-6.1 6.12L2.33 16l8-8z"/></svg>') no-repeat center;
                            right: -15px;
                            top: calc(50% - 8px);
                            width: 11px;
                            height: 16px;
                            display: inline-block;
                            font-weight: 900;
                        }
                    }
                }

                .result {
                    display: block;
                    margin: 15px 0;
                    padding: 15px 20px;
                    background-color: inherit;
                    border: 1px solid transparent;

                    @include mq(false, $screen-s) {
                        margin: 0;
                    }

                    &:hover {
                        box-shadow: 0 2px 13px -10px rgba(0, 0, 0, 0.5);
                        border-color: $algolia-product-hover-border-color;
                        text-decoration: none;
                    }

                    .result-thumbnail {
                        @include mq(false, $screen-m) {
                            flex-basis: 85px;

                            img {
                                max-width: 85px;
                                max-height: 85px;
                                object-fit: scale-down;
                            }
                        }

                        @include mq(false, $screen-s) {
                            flex-basis: 70px;
                        }

                        @include mq($screen-m) {
                            display: block;
                            height: 200px;
                            max-height: 200px;
                            width: 100%;
                            margin-bottom: 10px;
                            overflow: hidden;

                            img {
                                width: inherit;
                                max-width: 200px;
                                max-height: 200px;
                                margin: 0 auto;
                            }
                        }

                      .amlabel-div .labels {
                        visibility: hidden;
                        position: absolute;
                      }
                    }

                    .result-title {
                        font-weight: 600;
                        font-size: 14px;
                        text-align: left;
                        color: $algolia-result-title-color;
                        height: 62px;
                        line-height: 1.5;
                        overflow: hidden;
                        margin-bottom: 15px;

                        @include mq(false, $screen-m) {
                            color: $algolia-text-accent;
                            font-weight: 600;
                        }

                        @include mq(false, $screen-s) {
                            height: 42px;
                        }
                    }

                    .result-sub-content {
                        @include mq(false, $screen-m) {
                            padding-left: 20px;
                            flex-basis: 300px;
                        }

                        @include mq(false, $screen-s) {
                            padding-left: 10px;
                            flex-basis: calc(100% - 70px);
                        }
                    }

                    .price-box {
                        display: table;
                        margin-top: 5px;
                        text-align: right;
                        font-style: italic;
                        color: $algolia-text-accent;
                        min-height: 40px;

                        @include mq(false, $screen-s) {
                            min-height: initial;
                        }

                        .price-label {
                            float: left;
                            width: 100%;
                            text-align: left;
                            font-size: 12px;
                            line-height: 18px;
                            font-style: normal;

                            @include mq(false, $screen-m) {
                                font-size: 10px;
                                color: $color-text-accent-2;
                                line-height: 25px;
                                width: auto;
                            }

                            &.price__label--gross {
                                width: auto;
                                color: $color-text-accent-3;
                            }
                        }

                        .price-excluding-tax, .price-including-tax {
                            width: auto;
                            float: left;
                            clear: right;
                            white-space: nowrap;
                            text-align: left;
                            font-style: normal;
                            line-height: 1;
                            margin-left: 5px;

                            .label {
                                display: none;
                            }

                            &.price__value--old {
                                margin-top: 5px;
                            }

                            &.price__value--gross {
                                line-height: 18px;
                                margin: 0;
                                margin-left: 5px;
                                font-size: 12px;
                                clear: none;

                                @include mq(false, $screen-m) {
                                    margin-top: 2px;
                                }
                            }

                            @include mq(false, $screen-m) {
                                width: auto;
                                float: left;
                                text-align: left;
                            }
                        }
                    }
                }
                &__content {
                    display: block;
                    width: 100%;
                    clear: both;
                }
            }
        }
    }

    .algolia-clearfix {
        clear: both;
    }

    .ais-header {
        margin-bottom: 0;

        @include mq(false, $screen-l) {
            cursor: pointer;
            position: relative;
        }
    }

    #instant-search-pagination-container {
        display: flex;
        text-align: center;
    }

    .ais-Pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px auto;

        li {
            display: inline-block;
        }

        .ais-Pagination {
            &-item {
                &--disabled {
                    display: none;
                }

                a {
                    display: inline-block;
                    padding: 3px;
                    margin: 0 8px;
                    background-color: $algolia-pagination-background;
                    color: $algolia-pagination-color;
                }

                &--selected {
                    a {
                        width: 26px;
                        height: 26px;
                        font-weight: bolder;
                        color: $algolia-pagination-color-selected;
                        background: $algolia-pagination-background-selected;
                        border-radius: 50%;
                        padding: 2px;
                    }
                }

                &--nextPage, &--previousPage {
                    a {
                        position: relative;
                        font-weight: 400;
                        color: $algolia-pager-color;
                        background: $algolia-pager-background;
                        border-radius: 5px;
                        margin: 0;
                    }
                }

                &--nextPage {
                    a {
                        padding: 10px 5px 10px 13px;
                        margin-left: 25px;

                        @include mq(false, $screen-l) {
                            font-size: 0;
                            margin-left: 15px;
                        }

                        &:after {
                            display: inline-block;
                            position: relative;
                            top: 4px;
                            content: '';
                            background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16"><path fill="#{$algolia-pager-color}" fill-rule="evenodd" d="M2.33 0L.45 1.88 6.55 8l-6.1 6.12L2.33 16l8-8z"/></svg>') no-repeat center;
                            width: 11px;
                            height: 16px;
                            margin: 0 7px;

                            @include mq(false, $screen-l) {
                                margin: 0;
                                padding: 10px;
                                top: 0;
                                left: -2px;
                            }
                        }
                    }
                }

                &--previousPage {
                    a {
                        padding: 10px 13px 10px 5px;
                        margin-right: 25px;

                        @include mq(false, $screen-l) {
                            font-size: 0;
                            margin-right: 15px;
                        }

                        &:before {
                            display: inline-block;
                            position: relative;
                            top: 4px;
                            content: '';
                            background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16"><path fill="#{$algolia-pager-color}" fill-rule="evenodd" d="M2.33 0L.45 1.88 6.55 8l-6.1 6.12L2.33 16l8-8z"/></svg>') no-repeat center;
                            transform: rotate(180deg);
                            width: 11px;
                            height: 16px;
                            margin: 0 7px;

                            @include mq(false, $screen-l) {
                                margin: 0;
                                padding: 10px;
                                top: 0;
                                left: 3px;
                            }
                        }
                    }
                }
            }
        }
    }

    .ais-Panel {
        margin-bottom: 20px;

        @include mq(false, $screen-l) {
            position: relative;
            width: 100%;
            margin: 0;
            padding: 0;
            background-color: inherit;
            border: 0;
            border-bottom: 1px solid $algolia-filter-title-border-color;
        }

        .name {
            position: relative;
            font-size: 16px;
            font-weight: 600;
            color: $algolia-category-panel-name-color;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid $algolia-filter-title-border-color;

            @include mq(false, $screen-l) {
                font-size: 14px;
                font-weight: normal;
                color: $algolia-category-panel-name-color-mobile;
                padding: 13px 0 13px 35px;
                border-bottom: 0;
                margin-bottom: 0;
                border-bottom: none;
            }


            .tooltip {
                display: inline-block;
                margin-left: 10px;

                @include mq(false, $screen-l) {
                    display: none;
                }

                &__text {
                    color: $color-text-accent-3;
                    font-size: $font-size-base;
                    font-weight: normal;
                }
            }
        }

        &-header {
            &:after {
                @include mq(false, $screen-l) {
                    text-align: center;
                    position: absolute;
                    content: '';
                    right: 15px;
                    top: 13px;
                    width: 11px;
                    height: 16px;
                    display: inline-block;
                    background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16"><path fill="#2d2d2d" fill-rule="evenodd" d="M2.33 0L.45 1.88 6.55 8l-6.1 6.12L2.33 16l8-8z"/></svg>') no-repeat center;
                    background-size: 6px auto;
                }
            }
        }

        &-body {
            @include mq(false, $screen-l) {
                margin: 0;
                padding: 0 15px 25px 45px;
            }
        }

        .ais-RefinementList-showMore {
            margin-top: 10px;
            margin-bottom: 10px;
            color: $color-primary;
            background: transparent;
            border: 0;

            &-text {
                &:before {
                    content: '';
                    display: inline-block;
                    width: 12px;
                    height: 10px;
                    background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8"><path fill-rule="evenodd" fill="#{$color-primary}" d="M12 2L10.59.59 6 5.17 1.41.59 0 2l6 6z"/></svg>') no-repeat center;
                    margin-right: 10px;
                }

                &--active {
                    &:before {
                        content: '';
                        transform: rotate(180deg);
                    }
                }
            }

            &--disabled {
                display: none;
            }

            @include mq(false, $screen-l) {
                border-bottom: 1px solid $color-primary;
            }
        }

        .ais-RefinementList-count {
            margin-left: 5px;
            color: $color-text-accent-2;
            font-size: 0.8em;

            &:before {
                content: '(';
                position: relative;
            }

            &:after {
                content: ')';
                position: relative;
            }
        }

        &.ais-current-refined-values {
            position: relative;
        }
    }

    .ais-ClearRefinements-button {
        display: block;
        position: relative;
        margin: 10px 0;
        text-align: right;
        color: $algolia-text-accent;
        top: -20px;

        @include mq(false, $screen-l) {
            position: relative;
            bottom: 0;
            left: 0;
            margin-bottom: 10px;
            padding-right: 20px;
        }

        &--disabled {
            display: none;
        }
    }

    .ais-CurrentRefinements {
        &-item {
            display: block;
            align-items: center;
            position: relative;
            width: 100%;
            height: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            white-space: nowrap;
            padding: 12px 15px;
            color: $algolia-text-accent;
            border-radius: 20px;
            background-color: $algolia-current-refinement-background;
            margin-bottom: 10px;
            &:hover {
                text-decoration: line-through;
                cursor: pointer;
                button {
                    color: $algolia-text-accent;
                }
            }
        }

        &-label {
            margin-right: 5px;
        }

        &-category {
            &Label {
                font-weight: 700;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &-delete {
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            padding-right: 12px;
            color: $algolia-current-refinement-delete-color;
            font-weight: normal;
            font-size: $font-size-large;
            text-align: right;
            &:hover {
                color: $algolia-text-accent;
            }
        }
    }

    .ais-RefinementList {
        @include mq(false, $screen-l) {
            padding: 10px 0;
        }

        &-label {
            position: relative;
            display: flex;
            align-items: center;
            margin: 0;
            cursor: pointer;
            font-weight: 100;
            color: $algolia-text-accent;
            line-height: 2.14;

            @include mq(false, $screen-l) {
                margin-bottom: 15px;
            }

            &:before {
                display: inline-block;
                margin-top: 1px;
                content: '';
                width: 20px;
                height: 20px;
                margin-right: 10px;
                border: 1px solid $color-text-accent-3;
                border-radius: 3px;
            }

            &--no-checkbox {
                &:before {
                    content: none;
                }
            }

            &.checked {
                font-weight: bold;

                &:before {
                    background-color: $color-primary;
                    border-color: transparent;
                }

                &:after {
                    text-align: center;
                    content: '';
                    position: absolute;
                    color: $white;
                    left: -4px;
                    top: 1px;
                    width: 28px;
                    height: 28px;
                    display: inline-block;
                    background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"/></svg>') no-repeat center;
                    z-index: 1;

                    @include mq($screen-l) {
                        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"/></svg>');
                    }
                }
            }

            &:hover {
                text-decoration: underline;
            }

            @include mq(false, $screen-l) {
                &:before {
                    border-radius: 5px;
                }
            }
        }

        &-item {
            list-style: none;
        }

        &-list {
            padding: 0;
            margin: 0;
        }

        &-checkbox {
            visibility: hidden;
            width: 0;
            height: 0;
        }
    }

    .mobile-filter {
        &-header {
            position: fixed;
            top: 0;
            left: 0;
            height: 70px;
            background-color: $gray-lightest;
            width: 100%;
            z-index: 999999999;

            @include mq($screen-l) {
                display: none;
            }

            .close-filter {
                display: block;
                float: left;
                position: absolute;
                line-height: 70px;
                cursor: pointer;
                z-index: 9999999;
                left: 5px;
                top: 15px;

                .icon {
                    width: 42px;
                    height: 42px;
                    fill: $color-primary;
                }
            }

            .filter-title {
                font-size: 12px;
                color: $algolia-text-accent;
                font-weight: 400;
                position: absolute;
                width: 100%;
                text-align: center;
                margin-top: 7px;

                .filter-subtitle {
                    font-size: 16px;
                    color: $algolia-text-accent;
                    font-weight: 700;
                    position: absolute;
                    width: 150px;
                    max-width: 150px;
                    text-align: center;
                    margin-top: 15px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    left: calc(50% - 75px);
                    line-height: 1.8em;
                }
            }
        }

        &-footer {
            position: fixed;
            left: 0;
            height: 65px;
            background: $white;
            width: 100%;
            z-index: 999999999;
            bottom: 0;
            @include mq($screen-l) {
                display: none;
            }

            #apply-filters {
                cursor: pointer;
                border-radius: 4px;
                margin: 7px 15px 0;
                text-align: center;
                color: $white;
                font-size: 16px;
                padding: 13px;
                font-weight: 700;
                background-repeat: repeat-x;
                background-image: -webkit-linear-gradient(281deg, $color-cta, $color-cta--accent);
                background-image: -o-linear-gradient(281deg, $color-cta, $color-cta--accent);
                background-image: linear-gradient(281deg, $color-cta, $color-cta--accent);
            }
        }

        &-footer-overlay {
            position: fixed;
            left: 0;
            height: 65px;
            background: $white;
            width: 100%;
            z-index: 999999999;
            bottom: 65px;
            opacity: 0.8;
            pointer-events: none;

            @include mq($screen-l) {
                display: none;
            }
        }
    }

    #instant-search-facets-container {
        @media(max-width: 1024px) {
            width: 100%;
            height: auto;
            background-color: $white;
            display: block;
            position: fixed;
            top: 70px;
            bottom: 0;
            left: 0;
            z-index: 999999999;
            overflow-x: hidden;
            overflow-y: scroll;
            padding-bottom: 150px;
        }

        @include mq(false, $screen-m) {
            .ais-Panel-header {
                :hover {
                    cursor: pointer;
                }
            }
        }
    }

    .not-available-anymore {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        padding: 10px 0;
        border-top: 1px solid $gray-lighter;
        border-bottom: 1px solid $gray-lighter;
        color: $gray-dark;
        text-align: center;
        line-height: 17px;
        font-size: 10px;
        text-transform: uppercase;
        width: 100%;
        background: $color-light;
    }

    em {
        background-color: $color-primary--accent-2;
        font-style: normal;
    }

    .category-description {
        $sizes: 30, 40, 50, 60, 70;

        @each $size in $sizes {
            .align-left-#{$size} {
                float: left;
                padding-right: 25px;
                padding-top: 15px;
                padding-bottom: 15px;
                width: $size * 1%;
            }

            .align-right-#{$size} {
                float: right;
                padding-left: 25px;
                padding-top: 15px;
                padding-bottom: 15px;
                width: $size * 1%;

                & + * {
                    clear: both;
                }
            }
        }

        .image,
        img,
        picture {
            height: auto;
        }
    }
}

.catalog-category-view {
    @include mq($screen-l) {
        #algolia-left-container {
            margin-top: 15px;
        }
        .ambanner-4 > div[data-banner-id] {
            margin-bottom: 21px;

            &:empty {
                margin-bottom: 0;
            }
        }
        .infos {
            margin-top: -21px;
        }
    }
}

.ais-ToggleRefinement {
    .ais-ToggleRefinement-label {
        display: inline-block;
        height: 34px;
        position: relative;
        width: 60px;
        font-size: 0;

        .ais-ToggleRefinement-checkbox {
            display:none;
        }

        .ais-ToggleRefinement-labelText {
            background-color: #ccc;
            bottom: 0;
            cursor: pointer;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: .4s;
            border-radius: 34px;

            &:before {
                background-color: #fff;
                bottom: 4px;
                content: "";
                height: 26px;
                left: 4px;
                position: absolute;
                transition: .4s;
                width: 26px;
                border-radius: 50%;
            }
        }

        input:checked + .ais-ToggleRefinement-labelText {
            background-color: $color-primary;
        }

        input:checked + .ais-ToggleRefinement-labelText:before {
            transform: translateX(26px);
        }
    }
}
