[class*='account'],
[class*='sales-order'],
[class*='service-request'] {

    .heading--page {
        text-transform: none;
    }

    @include mq(false, $screen-l) {

        .title-wrapper {
            flex-flow: row wrap;
        }

        span.heading--page {
            flex-basis: 50%;
        }
    }

    .actions-toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;

        .primary + .secondary {
            margin-left: 10px;
        }

        .register-btn {
            flex-basis: 100%;

            @include mq($screen-m) {
                flex-basis: 50%;
            }
        }
    }

    .choice {
        label[for="change-email"],
        label[for="change-password"] {
            font-size: unset;
            color: $color-primary;
            text-decoration: underline;
        }
    }

    .action.primary.button {
        @extend .button;
        @extend .button--cta;
    }
}

$self : '.account-page';

#{$self} {

    .heading {
        color: $account-page__heading-color;
    }

    .organisation-type-select {
        @extend .input;

        .control {
            position: relative;

            select {
                position: relative;
                z-index: 2;
                background: 0 0;
            }

            .input__field {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                padding: 20px;
                height: 50px;

                label {
                    font-weight: 400;
                    margin: 0 0 0 8px;
                }

                &--active {
                    background: $color-primary--accent-2;
                    border: 1px solid $color-primary;

                    label {
                        color: $color-primary;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .multi-step-checkout__organisation-type-radio {
        .input {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
            grid-gap: 5px;

            flex-flow: row wrap;
            .input__field {
                margin: 0;
            }
        }
    }

    .paypal-account {
        img {
            width: auto;
        }
    }

    .password-strength-meter {
        margin-top: 7px;

        > span {
            font-style: italic;
        }
    }

    .field {
        width: 100%;

        &--half {
            display: inline-block;
            width: calc(50% - 4px);
            margin-right: 4px;
        }

        &--no-margin {
            margin: 0;
        }
    }

    &__label,
    label {
        font-weight: 600;
        color: $account-page__label-color;
    }

    &__input,
    input:not([type='radio']):not([type='checkbox']) {
        width: 100%;
        border-radius: $border-radius;
    }

    select {
        appearance: none;
        width: 100%;
        background: $color-light;
        border: $border-base;
        border-radius: $border-radius;
        color: $color-text-accent;
        padding: 0 10px;
        height: 40px;
    }

    &__newsletter,
    .e-invoice-form {
        margin-bottom: $spacer;
    }

    .choice {
        margin: $spacer 0;

        label {
            font-size: $account-page__label-font-size;

            span {
                vertical-align: middle;
            }
        }
    }

    .e-invoice-form {

        input:not([type='radio']):not([type='checkbox']) {
            @extend .input__field;
            border-radius: $border-radius;
        }

        .field {
            @extend .input;
        }
    }

    &__actions {

        .secondary {
            margin: $spacer--medium 0;
        }
    }

    &__forgot {
        color: $font-color-link-default;
    }

    &--create {
        &__wrapper {
            form {
                margin-top: 25px;
            }
            @include mq($screen-l) {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-content: flex-start;
            }
        }

        @include mq($screen-l) {
            max-width: $account-page__form-width;
            flex-grow: 1;
        }
    }

    &__explanation {
        display: block;
        margin-bottom: 20px;

        h1 {
            font-weight: bold;
            color: $color-primary;
        }

        p {
            font-size: 16px;
        }
    }

    .wishlists-overview {

        &__actions {
            display: flex;
            align-self: center;

            a {
                margin: 0;
            }

            .button--delete-wishlist{
                margin-left: 10px;
                border: none;
                background: none;
                cursor: pointer;
                &:after{
                    display: inline-block;
                    content: "\00d7";
                    font-size: 38px;
                    color: #292929;
                }
            }
        }
    }

    .wishlist {
        margin-top: 10px;
        position: relative;

        h1.heading {
            display: inline-block;
            margin-right: 8px;

            &[contenteditable=true] {
                border-radius: 5px;
                padding: 5px 10px;
                border: $border-base;
            }
        }

        span.button--download-booklet {
            position: relative;
            right: 0;
            top: auto;
            margin-bottom: 12px;
            display: table;
            font-weight: 700;
            text-decoration: underline;
            color: #000;
            .icon {
                display: inline;
                padding: 2px;
                margin-bottom: -7px;
                margin-left: 5px;
            }

            @include mq($screen-m) {
                position: absolute;
                top: 22px;
                margin-bottom: 0;
                display: inline;
            }
        }

        > .checkbox {
            border-bottom: $border-base;
        }

        > .input {
            margin: 20px 0;

            @include mq($screen-m) {
                width: 400px;
            }
        }

        &__item {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            padding: 8px 0;
            font-size: 12px;
            border-bottom: $border-base;

            @include mq($screen-m) {
                font-size: 14px;
            }

            .checkbox {
                padding: 0 30px 0 0;
            }

            label {
                font-weight: normal;
                color: $color-text-accent;

                &:hover {
                    cursor: pointer;
                }
            }

            .details {

                @include mq($screen-m) {
                    width: 360px;
                }

                strong {
                    @include line-clamp(2);
                }
            }

            img {
                display: none;

                @include mq($screen-m) {
                    display: block;
                    width: auto;
                }
            }
        }

        button svg {
            fill: #fff;
        }

        .wishlist-separator::after {
            content: '';
            border-left: 1px solid #5D5D5D;
            margin: 0 10px 0 5px;
        }

    }

    .price_agreements {
        margin-top: 10px;

        h1.heading {
            display: inline-block;
            margin-right: 8px;

            &[contenteditable=true] {
                border-radius: 5px;
                padding: 5px 10px;
                border: $border-base;
            }
        }

        > .checkbox {
            border-bottom: $border-base;
        }

        > .input {
            margin: 20px 0;

            @include mq($screen-m) {
                width: 400px;
            }
        }

        &__item {
            @include mq($screen-m) {
                display: flex;
            }
            flex-flow: row nowrap;
            align-items: center;
            column-gap: 10px;
            justify-content: space-between;
            padding: 8px 0;
            font-size: 12px;
            border-bottom: $border-base;

            @include mq($screen-m) {
                font-size: 14px;
            }

            .checkbox {
                padding: 0 30px 0 0;
            }

            label {
                @include mq($screen-m) {
                    flex: 2 1 240px;
                }
                font-weight: normal;
                color: $color-text-accent;

                &:hover {
                    cursor: pointer;
                }
            }

            .details {
                strong {
                    @include line-clamp(2);
                }
            }

            img {
                flex: 0 0 auto;
                display: none;

                @include mq($screen-m) {
                    display: block;
                    width: auto;
                }
            }

            &__wrapper {
                @include mq($screen-m) {
                    flex: 1 0 305px;
                }
            }

            &__actions {
                display: flex;
            }

            &__quantity-update {
                margin-right: 10px;
            }
        }

        button svg {
            fill: #fff;
        }
    }
}

.sales-guest-view,
.sales-order-view,
.payment-link-view,
.packingslips-packingslips-index {

    h1 {
        font-size: 18px;

        @include mq($screen-m) {
            font-size: 24px;
        }
    }

    .actions {
        display: flex;
        flex-flow: row nowrap;
        margin: 10px 0 15px;

        @include mq($screen-m) {
            margin: 20px 0 25px;
        }

        a {
            flex-basis: 50%;
            flex-shrink: 1;
            margin: 0 8px;
            font-size: 14px;

            @include mq($screen-m) {
                flex-basis: 25%;
                margin: 0 20px;
                font-size: 16px;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .sales-order-items {

        &__headers {
            display: none;

            @include mq($screen-m) {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                padding: 10px 0;
            }
        }

        &__item {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
            padding: 20px 0;
            border-top: $border-base;

            &:last-child {
                border-bottom: $border-base;
            }

            @include mq($screen-m) {
                flex-flow: row nowrap;
            }
        }

        .sales-order-item {

            &__details {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                flex-basis: 100%;

                img {
                    @include mq(false, $screen-m) {
                        width: 75px;
                        height: 75px;
                    }

                    @include mq($screen-m) {
                        flex-basis: 25%;
                        margin-right: 20px;
                    }
                }

                @include mq($screen-m) {
                    flex-basis: 40%;
                }
            }

            &__text {
                display: flex;
                flex-flow: row wrap;
                align-self: stretch;
                flex-basis: 70%;
                padding: 5px 0;

                span:first-child {
                    font-weight: 600;
                }

                span,
                .dispatch-status {
                    flex-basis: 100%;
                }

                .dispatch-status {

                    strong {
                        font-weight: 600;
                    }

                    svg {
                        display: none;
                    }
                }
            }

            &__totals {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                flex-basis: 100%;

                .tax-label {
                    display: block;
                    text-align: center;
                    margin-top: 5px;
                }

                @include mq($screen-m) {
                    flex-basis: 40%;
                }
            }
        }
    }

    .sales-order-totals {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;

        div {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            flex-basis: 100%;
            margin: 8px 0;

            &.border {
                padding-top: 10px;
                border-top: $border-base;
            }
        }
    }

    .sales-order-info {
        margin-bottom: 20px;

        .row {
            padding: 20px;
            border-radius: 5px;
            border: $border-base;

            @include mq($screen-m) {
                padding: 20px 40px;
            }

            .information {
                @include mq(false, $screen-m) {
                    margin-top: 20px;
                }

                @include mq($screen-m) {
                    h4 {
                        text-align: center;
                    }
                }

                svg {
                    display: block;
                    max-width: 40px;

                    @include mq($screen-m) {
                        margin: 0 auto;
                    }
                }

                img {
                    max-width: 40px;

                    @include mq($screen-m) {
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}

body.customer-invoicing-edit {
    .invoicing-information {
        &__limits {
            background: $color-primary--accent-2;
            border: none;
            margin-bottom: 20px;
            a {
                position: relative;
                top: 10px;
                text-decoration: underline;
                margin-left: -18px;
            }
            > fieldset.fieldset legend {
                span {
                    font-weight: 600;
                    font-size: 18px;
                }
            }
        }
    }
    .invoice-email {
        margin-top: 20px;
    }
    form.einvoice {
        &.active {
            border-top: 1px solid #ebebeb;
        }
        padding-top: 15px;
        margin-top: 15px;
        .e-invoice-form {
            > fieldset.fieldset legend {
                span.einvoicing-profile {
                    font-weight: 600;
                    font-size: 18px;
                }
            }
            .tooltip {
                width: 100%;
                padding-top: 10px;
            }
        }
        .e-invoicing-checkbox {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            input#einvoicing_use_einvoicing {
                height: 20px;
                width: unset;
                margin-right: 5px;
                position: relative;
                top: 1px;
                accent-color: $color-alt--accent;
            }
            label {
                font-size: 14px;
                margin-bottom: 0;
            }
        }
        input[type="radio"] {
            accent-color: $color-alt--accent;
        }
    }
    a.change_invoice_address {
        text-decoration: underline;
    }
}
