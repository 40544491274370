.quotation-page {
    .quotation-request {

        @include mq($screen-m) {
            flex-basis: 60%;

            &__contact-form {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
            }

            &__sidebar {
                flex-basis: 30%;
            }
        }

        h3 {
            color: $color-primary;
            font-weight: $font-weight-semi-bold;
            font-size: $font-size-medium;
            padding: 0 15px 16px;
            border-bottom: $border-base;

            @include mq($screen-m) {
                padding: 0 0 16px;
                font-size: $font-size-large;
            }
        }

        &__note {
            margin: 0 0 15px 0;
            font-size: 16px;
            color: $color-primary;
        }

        &__intro {
            margin-bottom: 40px;

            h1 {
                color: $color-primary;
                font-weight: $font-weight-bold;
                font-size: $font-size-large;

                @include mq($screen-m) {
                    font-size: $font-size-xxl;
                }
            }

            p {
                color: #111;
                line-height: 1.88;
                font-size: $font-size-base;

                @include mq($screen-m) {
                    font-size: $font-size-medium;
                }
            }
        }

        &__need-help {
            display: inline-block;
            position: relative;
            margin: 0 0 25px;
            padding: 20px 25px;
            border-radius: 5px;
            width: 100%;
            background: $color-primary;

            &--with-image {
                margin: 25px 0;

                img {
                    display: none;
                }

                @include mq($screen-m) {

                    img {
                        display: block;
                        position: absolute;
                        width: auto;
                        top: 50%;
                        right: 20px;
                        max-width: 150px;
                        transform: translateY(-50%);
                        border-radius: 50%;
                    }

                    p {
                        width: 70%;
                    }
                }
            }

            h2 {
                color: #fff;
            }

            p {
                color: #fff;
            }

            a {
                color: #fff;
            }
        }

        &__products {
            margin: 10px 0 0;
            @include mq(false, $screen-m) {
                margin: 10px -15px 0;
            }
        }

        &__product {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
            padding: 30px 15px 20px;
            border-bottom: $border-base;

            img {
                width: 60px;
                margin: 0 10px 0 0;
            }

            &-details {
                @include mq(false, $screen-m) {
                    flex-basis: calc(100% - 60px - 70px);
                }

                small {
                    display: block;
                    margin: 10px 0;
                    font-size: $font-size-small;
                    color: $color-text-accent-3;
                }
            }

            &-title {
                @include line-clamp(2);

                color: $color-text-accent;
                font-weight: $font-weight-semi-bold;

                @include mq($screen-m) {
                    width: 400px;
                }
            }

            &-qty {
                @include mq(false, $screen-m) {
                    flex-basis: 100%;
                    padding-left: 60px;
                    order: 4;
                }

                .quantity-update {
                    margin: 0;
                }

                .input {
                    margin-bottom: 0;
                }

                @include mq($screen-m) {
                    margin: 0 0 0 auto;
                }
            }
        }

        &__remove-product {
            flex-basis: 60px;
            margin-top: -5px;
            cursor: pointer;

            @include mq($screen-m) {
                padding: 0;
                margin: 5px 0 0 auto;
            }
        }

        &__contact-block {
            padding: 31px 40px 46px 37px;
            border-radius: 5px;
            box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
            margin-bottom: 25px;

            h2 {
                color: $color-primary;
                font-weight: $font-weight-bold;
                font-size: $font-size-large;

                @include mq($screen-m) {
                    font-size: 20px;
                }
            }

            .note {
                color: $color-text-accent-3;
            }
        }

        &__contact-form {

            .input {
                margin-bottom: 24px;

                &__field {
                    height: 45px;
                    border-radius: $border-radius;
                }

                & [type='submit'] {
                    font-weight: $font-weight-bold;
                }
            }

            label {
                text-align: left;
                color: $color-text-accent;
                font-weight: $font-weight-semi-bold;

                &:after {
                    margin-left: 5px;
                }

                &.error {
                    margin-top: 5px;
                    color: $message__color--error;
                }
            }
        }
    }

    .message {
        justify-content: center;
        padding: 20px 10px;
    }
}
