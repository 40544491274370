$usp-bar-background: $color-primary--accent-2 !default;
$usp-bar-icon-fill: $color-alt--accent-2 !default;
$usp-bar-font-size: $font-size-small !default;
$usp-bar-padding: 0 15px !default;
$usp-bar-font-color: $color-primary !default;

$usp-bar-background--single-item: $color-primary !default;
$usp-bar-font-size--single-item: $font-size-base !default;
$usp-bar-font-color--single-item: $color-light !default;

$self : '.usp-bar';

#{$self} {
    display: none;
    background: $usp-bar-background;
    transition: 0.3s ease;
    padding: $usp-bar-padding;

    @include mq($screen-xl) {
        display: block;
    }

    &__items-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
    }

    &__link {
        display: flex;
        padding: 15px 0;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        fill: $usp-bar-icon-fill;
    }

    &__title {
        font-size: $usp-bar-font-size;
        color: $usp-bar-font-color;
    }

    &--single-item {
        background: $usp-bar-background--single-item;

        .usp-bar {

            &__items-container {
                justify-content: center;
            }

            &__title {
                font-size: $usp-bar-font-size--single-item;
                color: $usp-bar-font-color--single-item;
            }
        }
    }

    ul:not(.usp-bar__items-container) {
        @extend #{$self}__items-container;
        @extend .container;
        @extend .list;

        li:not(.usp-bar__item) {
            @extend #{$self}__link;
            @extend #{$self}__title;

            display: block;
            position: relative;
            padding-left: 30px;

            &:before {
                position: absolute;
                top: 7px;
                left: 0;
                content: '';
                background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#57ac48" d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"/></svg>') no-repeat center;
                width: 28px;
                height: 28px;

                @include mq($screen-l) {
                    background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#57ac48" d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"/></svg>');
                }
            }
        }
    }

    ul:not(.usp-bar__items-container).module-usp{
        li:not(.usp-bar__item) {
            display: flex;
            align-items: center;
            position: relative;
            padding-left: 0;
            &:before {
                display: none;
            }
        }

    }

    .usp-image {
        display: inline;
        max-height: 23px;
        width: auto;
    }

    p {
        display: inline;
        margin: 0 0 0 10px;
    }

    .usp-trustpilot {
        display: flex;
        justify-content: center;
    }

    .trustpilot__logo {
        width: 85px;
    }

    .trustpilot__image {
        width: 120px;
        margin-left: 10px;
    }
}
