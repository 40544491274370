//
//  Default appearance styles
//  _____________________________________________

div[data-content-type='slider'] {
    display: none;

    &.slick-initialized {
        display: block;
        margin-bottom: $spacer--large !important; // sass-lint:disable-line no-important
    }

    .slick-list,
    .slick-track,
    .slick-slide {
        min-height: inherit;

        > div {
            min-height: inherit;
            overflow: hidden;
            width: 100%;
        }
    }
}
