$select__margin-bottom                   : $spacer--medium !default;
$select__padding                         : 0 $spacer--medium 0 $spacer--medium !default;
$select__border                          : 1px solid $form-elements-border-color !default;
$select__spacing                         : 40px !default;
$select__border-radius                   : 20px !default;
$select__field-background                : $white !default;
$select__input-border-color              : $form-elements-border-color !default;
$select__field-list-border-radius--single: 20px !default;

.select {
  margin-bottom: $select__margin-bottom;

  &__field {
    position: relative;
    background: $select__field-background;

    &--native {
      width: 100%;
      height: $select__spacing;
      padding: $select__padding;
      border: $select__border;
      border-radius: $select__border-radius;
      appearance: none;
      transition: $transition-base;

      &.focus-visible {
        @include focus-input();
      }
    }
  }

  &__field-item {
    position: relative;
    height: $select__spacing;
    line-height: $select__spacing;
    padding: $select__padding;
    cursor: pointer;
    font-size: $font-size-base;
  }

  &__field-list {
    &--single {
      height: $select__spacing;
      line-height: $select__spacing;
      padding: 0;
      border: $select__border;
      border-radius: $select__border-radius;
    }
  }

  &__field-input {
    transition: $transition-base;

    &.focus-visible {
      outline: none;
    }
  }

  &.dropdown-icon {
    appearance: none;
    background-image: svg-uri($account-page__select-svg-uri);
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 15px 10px;
  }
}

// Choices.js lib styles overwrites
.choices {
  &.is-open {
    .select__field-list {
      &--single {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: $select__field-list-border-radius--single;
        border-top-right-radius: $select__field-list-border-radius--single;
        border-bottom: 0;
      }
    }
  }

  &.is-focused {
    .select__field-list {
      transition: $transition-base;
      @include focus-input();
    }
  }

  &__list--dropdown {
    max-height: 300px;
    overflow: auto;

    .is-open & {
      border-radius: 0 0 $select__border-radius $select__border-radius;
      border-color: $select__input-border-color;
    }

    & .choices__item--selectable {
      padding-right: $spacer--medium;
    }
  }
}