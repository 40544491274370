$language-switcher__language-margin                     : 0 5px !default;
$language-switcher__language-button-padding             : 5px 10px !default;
$language-switcher__language-button-height              : 33px !default;
$language-switcher__language-button-border-radius       : 5px !default;
$language-switcher__language-button-border              : none !default;
$language-switcher__language-button-color               : initial !default;
$language-switcher__language-button-color-hover         : $color-primary !default;
$language-switcher__language-button-background          : transparent !default;
$language-switcher__language-button-background-hover    : $color-primary--accent-2 !default;
$language-switcher__language-button-font-weight         : normal !default;
$language-switcher__language-button-font-size           : 14px !default;
$language-switcher__language-button-icon-margin         : 0 5px 0 0 !default;

.language-switcher {

    &__language {
        display: inline-block;
        margin: $language-switcher__language-margin;
    }

    &__language-button {
        padding: $language-switcher__language-button-padding;
        height: $language-switcher__language-button-height;
        border-radius: $language-switcher__language-button-border-radius;
        border: $language-switcher__language-button-border;
        color: $language-switcher__language-button-color;
        background: $language-switcher__language-button-background;
        font-weight: $language-switcher__language-button-font-weight;
        font-size: $language-switcher__language-button-font-size;

        .button__icon {
            margin: $language-switcher__language-button-icon-margin;
        }

        &--active,
        &:focus,
        &:active,
        &:hover {
            color: $language-switcher__language-button-color-hover;
            background: $language-switcher__language-button-background-hover;
        }
    }
}
