$cart-actions__margin : 15px !default;

.cart__actions {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin: $cart-actions__margin 0;

    @include mq($screen-l) {
        display: none;
    }

    .button {
        padding: 0 15px;
    }

    &--continue {
        font-size: $font-size-base;

        .icon {
            width: 16px;
            height: 16px;
            padding: 4px;
            margin: 0 4px 0 -4px;
            fill: $color-primary;
        }

        &:hover {

            .icon {
                fill: $color-primary;
            }
        }
    }

    &--confirm {
        font-weight: $font-weight-bold;

        span {
            height: $font-size-medium;
            line-height: $font-size-base;
        }

        .icon {
            order: 2;
            width: 16px;
            height: 16px;
            padding: 3px;
            margin: 0 -4px 0 4px;
        }
    }
}
