$price__spacing                 : $spacer !default;
$price__color--special          : $color-tertiary !default;
$price__font-weight             : $font-weight-semi-bold !default;
$price__font-weight--old        : $font-weight-normal !default;
$price__font-weight--gross      : $font-weight-semi-bold !default;
$price__font-weight--special    : $font-weight-semi-bold !default;
$price__font-size               : 20px !default;
$price-color--old               : $color-text-accent-3 !default;
$price-color--gross             : $color-text-accent-3 !default;

$self : '.price';

#{$self} {
    font-size: $price__font-size;
    font-weight: $price__font-weight;

    &__label {
        &--gross {
            font-weight: $price__font-weight--gross;
            font-size: $font-size-base;
            color: $price-color--gross;

            .modal-popup & {
                display: none;
            }
        }
    }

    &__value {

        &--regular {
            display: block;
        }

        &--old {
            text-decoration: none;
            font-weight: $price__font-weight--old;
            font-size: $font-size-base;
            color: $price-color--old;
            margin-right: $price__spacing;
            position: relative;

            .tax-label {
                display: none !important;
            }

            &:before {
                font-weight: normal;
                top: 50%;
                background: $price-color--old;
                content: '';
                width: 110%;
                position: absolute;
                height: .1em;
                border-radius: .1em;
                white-space: nowrap;
                display: block;
                transform: rotate(
                        -15deg
                );
            }
        }

        &--special {
            display: block;
            color: $price__color--special;
            font-weight: $price__font-weight--special;

            & > ins {
                text-decoration: none;
            }
        }

        &--between {
            display: block;

            &:last-child {
                margin-top: $price__spacing;
            }
        }

        &--gross {
            text-decoration: none;
            font-weight: $price__font-weight--gross;
            font-size: $font-size-base;
            color: $price-color--gross;
            margin-right: $price__spacing;
            position: relative;

            .modal-popup & {
                display: none;
            }

            .tax-label {
                display: none !important;
            }

            &:before {
                font-weight: normal;
                top: 50%;
                background: $price-color--gross;
                content: '';
                width: 110%;
                position: absolute;
                height: .1em;
                border-radius: .1em;
                white-space: nowrap;
                transform: rotate(
                        -15deg
                );
            }
        }
    }

    &__text {
        font-weight: $price__font-weight--old;
    }

    &#{$self}--decimal {
        #{$self}__value:not(#{$self}__value--old):not(#{$self}__value--gross) {
            #{$self}__wrapper {
                display: inline-block;
            }

            #{$self}__prefix {
                font-size: 0.6em;
                margin-right: 5px;
            }

            #{$self}__decimal {
                position: relative;
                top: -0.5em;
                margin-left: -0.1em;
                font-size: 0.6em;
            }
        }
    }

    &--free {
        text-transform: capitalize;
        font-weight: $font-weight-bold;
        color: $color-alt;
    }
}
