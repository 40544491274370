.modal-base {
    overflow: initial !important;
    top: 50%;
    outline: none;
    border-radius: $border-radius;
    transform: translateY(-50%);

    .heading {
        text-transform: none;
    }

    .button {
        outline: none;
    }

    .modal__close-button {

        &:hover,
        &:focus,
        &:active {
            background: none;
            box-shadow: none;
        }
    }

    &.modal-popup,
    &.modal-slide {
        top: 50%;

        @include mq($screen-m) {
            min-width: 640px;
        }
    }
}

.modal-custom {
    &:focus {
        outline: none;
    }
}