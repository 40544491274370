.message__block {
    padding: 16px 25px;
    background: $color-primary--accent-2;
    border-left: 5px solid $color-primary;

    margin: 15px 0 0 0;

    &-title {
        font-size: $font-size-base;
        color: $color-primary--accent-5;
        font-weight: $font-weight-semi-bold;
        margin-bottom: 10px;
    }

    &-content {
        color: $color-primary--accent-5;
    }
}
