.e-invoice-method {
    margin: 20px 0 20px 15px;

    legend {
        margin:0;

        label {
            margin:0;

            span {
                padding-left: 5px;
            }
        }
    }

    > div {
        margin: 20px 0 0 15px;
    }

    .control > div.mage-error {
        display: none;
    }
}
