$quicksearch__top                              : calc(100% - #{$spacer} + 1px) !default;
$quicksearch__top\@medium                      : calc(100% + 1px) !default;
$quicksearch__left                             : 0 !default;
$quicksearch__left\@medium                     : auto !default;
$quicksearch__right\@medium                    : calc(50% - 375px) !default;
$quicksearch__left\@large                      : -$spacer--medium !default;
$quicksearch__width                            : 100% !default;
$quicksearch__width\@medium                    : 688px !default;
$quicksearch__padding                          : 0 $spacer !default;
$quicksearch__padding\@medium                  : 0 $spacer--medium !default;
$quicksearch__background                       : $white !default;
$quicksearch__box-shadow                       : 0 4px 6px 0 rgba(57, 50, 67, 0.3) !default;
$quicksearch__results-padding                  : $spacer--medium 0 !default;
$quicksearch__results-margin                   : 0 !default;
$quicksearch__results-border                   : 1px solid $gray-light !default;
$quicksearch__empty-margin                     : $spacer 0 !default;
$quicksearch__typed-font-weight                : $font-weight-bold !default;
$quicksearch__close-top                        : 0 !default;
$quicksearch__close-right                      : 0 !default;
$quicksearch__close-fill                       : $color-secondary !default;
$quicksearch__close-background                 : transparent !default;
$quicksearch__close-border                     : 0 !default;
$quicksearch__close-size                       : $spacer--medium !default;
$quicksearch__content-padding                  : $spacer 0 !default;
$quicksearch__list-margin                      : 0 !default;
$quicksearch__list-padding                     : 0 !default;
$quicksearch__list-padding-top--below          : $spacer--medium !default;
$quicksearch__category-padding                 : $spacer 0 !default;
$quicksearch__categories-column-padding        : $spacer 0 0 0 !default;
$quicksearch__categories-column-padding\@medium: 0 0 0 $spacer--medium !default;
$quicksearch__product-border                   : 1px solid $gray-light !default;
$quicksearch__product-border--last             : 0 !default;
$quicksearch__product-padding                  : $spacer 0 !default;
$quicksearch__product-image-size               : 72px !default;
$quicksearch__product-image-margin-right       : $spacer !default;
$quicksearch__name-margin                      : 0 !default;
$quicksearch__name-padding                     : $spacer 0 !default;
$quicksearch__name-color                       : inherit !default;
$quicksearch__name-font-size                   : 16px !default;
$quicksearch__name-font-weight                 : $font-weight-normal !default;
$quicksearch__more-link-padding                : $spacer--medium 0 !default;
$quicksearch__more-link-border-top             : 1px solid $gray-light !default;
$quicksearch__more-link-text-align             : center !default;
$quicksearch__more-link-font-weight            : $font-weight-bold !default;

.quicksearch {
    position: absolute;
    top: $quicksearch__top;
    left: $quicksearch__left;
    display: none;
    padding: $quicksearch__padding;
    width: $quicksearch__width;
    background: $quicksearch__background;
    box-shadow: $quicksearch__box-shadow;
    @include mq($screen-m) {
        top: $quicksearch__top\@medium;
        left: $quicksearch__left\@medium;
        right: $quicksearch__right\@medium;
        padding: $quicksearch__padding\@medium;
        width: $quicksearch__width\@medium;
    }
    @include mq($screen-l) {
        left: $quicksearch__left\@large;
    }
    &--visible {
        display: block;
    }

    &__wrapper {
        position: relative;
    }

    &__results {
        margin: $quicksearch__results-margin;
        padding: $quicksearch__results-padding;
        border-bottom: $quicksearch__results-border;
    }

    &__empty {
        margin: $quicksearch__empty-margin;
    }

    &__typed {
        font-weight: $quicksearch__typed-font-weight;
    }

    &__close-button {
        position: absolute;
        top: $quicksearch__close-top;
        right: $quicksearch__close-right;
        border: $quicksearch__close-border;
        background-color: $quicksearch__close-background;
        &:hover,
        &.focus-visible {
            border: $quicksearch__close-border;
            background-color: $quicksearch__close-background;
        }
    }

    &__close-icon {
        width: $quicksearch__close-size;
        height: $quicksearch__close-size;
        fill: $quicksearch__close-fill;
    }

    &__content {
        padding: $quicksearch__content-padding;
        @include mq($screen-m) {
            display: flex;
        }
    }

    &__list {
        margin: $quicksearch__list-margin;
        padding: $quicksearch__list-padding;
        list-style-type: none;
        &--column {
            flex: 2;
        }
        &--below {
            padding-top: $quicksearch__list-padding-top--below;
        }
    }

    &__categories-column {
        flex: 1;
        padding: $quicksearch__categories-column-padding;
        @include mq($screen-m) {
            padding: $quicksearch__categories-column-padding\@medium;
        }
    }

    &__product {
        display: flex;
        padding: $quicksearch__product-padding;
        border-bottom: $quicksearch__product-border;
        @include mq($screen-m) {
            &:last-child {
                border-bottom: $quicksearch__product-border--last;
            }
        }
    }

    &__product-image-wrapper {
        min-width: $quicksearch__product-image-size;
        min-height: $quicksearch__product-image-size;
        margin-right: $quicksearch__product-image-margin-right;
    }

    &__product-image {
        max-width: $quicksearch__product-image-size;
    }

    &__link {
        display: block;
        text-decoration: none;
    }

    &__name {
        color: $quicksearch__name-color;
        font-size: $quicksearch__name-font-size;
        &--thin {
            margin: $quicksearch__name-margin;
            padding: $quicksearch__name-padding;
            font-weight: $quicksearch__name-font-weight;
        }
    }

    &__category,
    &__manufacturer {
        padding: $quicksearch__category-padding;
    }

    &__more-link {
        display: block;
        padding: $quicksearch__more-link-padding;
        border-top: $quicksearch__more-link-border-top;
        text-align: $quicksearch__more-link-text-align;
        font-weight: $quicksearch__more-link-font-weight;
        text-decoration: none;
    }
}
