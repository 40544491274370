$base-page-content-margin-top           : 125px !default;
$base-page-content-margin-top-account   : 155px !default;
$base-page-content-margin-top-cart      : 145px !default;
$base-page-content-margin-top-\@large           : 175px !default;
$base-page-content-margin-top-account-\@large   : 205px !default;
$base-page-content-margin-top-cart-\@large      : 195px !default;

html,
body {
    -webkit-font-smoothing: antialiased;
}

body:not(.checkout-index-index):not(.checkout-cart-index):not(.paypal-express-review):not(.account) {
    .page-wrapper {
        overflow-x: hidden;

        main {
            @include mq($screen-m) {
                margin-top: $base-page-content-margin-top;
            }

            @include mq($screen-xl) {
                margin-top: $base-page-content-margin-top-\@large;
            }
        }
    }
}

body.checkout-cart-index {
    .page-wrapper {
        overflow-x: hidden;

        main {
            @include mq($screen-m) {
                margin-top: $base-page-content-margin-top-cart;
            }

            @include mq($screen-xl) {
                margin-top: $base-page-content-margin-top-cart-\@large;
            }
        }
    }
}

body.account {
    .page-wrapper {
        overflow-x: hidden;

        main {
            @include mq($screen-m) {
                margin-top: $base-page-content-margin-top-account;
            }

            @include mq($screen-xl) {
                margin-top: $base-page-content-margin-top-account-\@large;
            }
        }
    }
}

.cms-index-index .messages.cookie-messages {
    position: relative;
    top: 25px;
}

.quantity-loading-mask {
    position: absolute;
    z-index: 20;
    width: 34px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    .loader {
        display: block;
    }
}

.discount-code-loading-mask {
    position: absolute;
    z-index: 20;
    width: 34px;
    top: 7px;
    right: 110px;

    .loader {
        display: block;
    }
}

// Google page translation places <font> tags causing layout to break
[class^="price"] font {
    display: inline-block;
}

*:focus-visible {
    outline: none;
}

.cart-add-success-actions{
    min-width: 200px;
    text-align: center;
}