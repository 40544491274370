$bundle-option__title-margin               : 24px 0 $spacer !default;
$bundle-option__title-color--required      : $red !default;
$bundle-option__title-font-weight--required: $font-weight-normal !default;
$bundle-option__label-margin               : 0 !default;
$bundle-option__radio-margin-bottom        : $spacer !default;
$bundle-option__select-max-width           : 350px !default;
$bundle-option__select-margin-bottom       : $spacer--medium !default;
$bundle-option__qty-margin-bottom          : 0 !default;
$bundle-option__qty-input-max-width        : 100px !default;
$bundle-option__qty-input-margin-left      : $spacer !default;
$bundle-option__qty-input-color--disabled  : $gray !default;
$bundle-option__qty-input-cursor--disabled : default !default;

.bundle-option {
    &__title {
        margin: $bundle-option__title-margin;

        &--required {
            &:after {
                content: '*';
                color: $bundle-option__title-color--required;
                font-weight: $bundle-option__title-font-weight--required;
            }
        }
    }

    &__radio {
        margin-bottom: $spacer;
    }

    &__select {
        max-width: $bundle-option__select-max-width;
        margin-bottom: $bundle-option__select-margin-bottom;
    }

    &__qty {
        display: flex;
        align-items: center;
        margin-bottom: $bundle-option__qty-margin-bottom;
    }

    &__label {
        margin: $bundle-option__label-margin;
    }

    &__qty-input {
        max-width: $bundle-option__qty-input-max-width;
        margin-left: $bundle-option__qty-input-margin-left;

        &:disabled {
            color: $bundle-option__qty-input-color--disabled;

            &:hover,
            &.focus-visible {
                cursor: $bundle-option__qty-input-cursor--disabled;
            }
        }
    }
}
