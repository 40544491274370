$footer__padding                                : $spacer--medium !default;
$footer__bottom-last-handler-padding            : 0 0 56px 0 !default;
$footer__background-color                       : $gray-lightest !default;
$footer__background-color\@large                : $color-primary !default;
$footer__text-color                             : $color-light !default;

$footer__handler--with-top-separator-border     : 1px solid $color-primary--accent-4 !default;
$footer__handler--trustpilot-border             : 1px solid $gray-light !default;

$footer__handler--link-list-background-color               : $color-primary !default;
$footer__handler--link-list-background-color\@large        : $color-primary !default;

$footer__need-help-margin                       : 0 auto $spacer--medium !default;
$footer__need-help-padding                      : $spacer--medium 0 !default;
$footer__need-help-text-color                   : #fff !default;
$footer__newsletter-padding                     : $spacer--medium 0 !default;

$footer__trustpilot-background                  : none !default;
$footer__trustpilot-background-\@large          : $color-light !default;
$footer__trustpilot-padding-\@large             : 30px 0 !default;

$footer__link-list-padding                      : 0 0 30px 0 !default;
$footer__link-color                             : $color-light !default;
$footer__link-color-hover                       : $color-light !default;
$footer__link-text-decoration-hover             : underline !default;
$footer__link-inner-padding                     : $spacer--medium !default;

$footer__list__image-size                       : 32px !default;
$footer__list__image-size--wide                 : 77px !default;
$footer__list__image-size--wide-\@large         : 104px !default;

$footer__bottom-bar-background                  : $color-primary !default;
$footer__bottom-bar-background-\@large          : $color-light !default;
$footer__bottom-bar-color                       : $color-light !default;
$footer__bottom-bar-color-\@large               : $black !default;

$footer__bottom-logo                            : url('/images/logo/footer-logo.png') no-repeat center !default;
$footer__bottom-logo-margin                     : 30px auto 15px !default;
$footer__bottom-logo-width                      : 100% !default;
$footer__bottom-logo-height                     : 20px !default;

$footer__social-handler-margin                  : 15px 0 !default;
$footer__social-handler-margin-\@large          : 15px 0 0 !default;
$footer__social-icon-margin                     : 0 10px 0 0 !default;
$footer__social-icon-margin-\@large             : 0 10px 0 0 !default;
$footer__social-icon-extra-margin-\@large       : 0 20px 0 0 !default;
$footer__social-icon-background                 : $color-primary--accent-2 !default;
$footer__social-icon-border-radius              : 50% !default;
$footer__social-icon-color                      : $color-light !default;
$footer__social-icon-color-\@large              : $color-primary !default;
$footer__social-icon-opacity                    : 0.7 !default;
$footer__social-icon-opacity-\@large            : 1 !default;

$footer__currency-switcher-margin               : 0 0 0 auto !default;
$footer__vat-switcher-margin                    : 0 auto !default;
$footer__vat-switcher-padding                   : 20px 10px !default;

$footer__copyright-padding                      : $spacer--medium 0 $spacer--medium 0 !default;
$footer__copyright-margin                       : 0 !default;
$footer__copyright-margin-\@medium              : 0 10px 0 0 !default;

.footer {
    display: flex;
    flex-flow: row wrap;
    flex-shrink: 0;
    background-color: $footer__background-color;
    color: $footer__text-color;

    @include mq($screen-l) {
        background-color: $footer__background-color\@large;
    }

    &__handler {
        position: relative;
        width: 100%;

        &--bottom-bar,
        &--trustpilot {
            max-width: 100%;
        }

        &--switchers {
            align-items: center;
        }

        &--link-list {
            background: $footer__handler--link-list-background-color;
        }

        &--bottom-bar {

            @include mq($screen-l) {
                background: $footer__bottom-bar-background-\@large;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        @include mq($screen-m) {
            margin: 0 auto;
            padding-top: $footer__padding;

            .order-1 {
                order: 1;
            }
            .order-2 {
                order: 2;
            }
            .order-3 {
                order: 3;
            }
            .order-4 {
                order: 4;
            }
        }

        @include mq($screen-l) {
            display: flex;
            flex-flow: row wrap;

            &--link-list {
                display: block;
                background: $footer__handler--link-list-background-color\@large;
            }

            &--bottom-bar {
                max-width: initial;
            }

            &--trustpilot {
                order: -1;
                padding: 0;
                border-top: $footer__handler--trustpilot-border;
            }

            &--with-top-separator {
                border-top: $footer__handler--with-top-separator-border;
                max-width: 100%;
            }
        }

        @include mq ($screen-l, $screen-xl) {
            &--switchers {
                background: $gray-lightest;
            }
        }

        @include mq($screen-xl) {
            &--switchers {
                display: none;
            }

            &--trustpilot {
                padding: 0 0 $footer__padding 0;
            }
        }
    }

    > .footer__handler:last-child {

        @include mq($screen-l) {
            padding: $footer__bottom-last-handler-padding;
        }
    }

    &__trustpilot {
        background: $footer__trustpilot-background;

        @include mq($screen-l) {
            text-align: center;
            background: $footer__trustpilot-background-\@large;
            padding: $footer__trustpilot-padding-\@large;
        }
    }

    &__language-switcher {
        display: none;

        @include mq($screen-l) {
            display: block;
            order: 3;
            padding: $footer--padding 0 0 0;
        }
    }

    &__currency-switcher {
        display: block;
        margin: $footer__currency-switcher-margin;

        .currency-switcher {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__vat-switcher {
        margin: $footer__vat-switcher-margin;
        padding: $footer__vat-switcher-padding;

        .vat-switcher {
            color: $color-text-default;
            justify-content: flex-start;
            align-items: center;
        }
    }

    &__newsletter {
        display: none;

        @include mq($screen-l) {
            display: block;
            padding: $footer__newsletter-padding;
        }
    }

    &__need-help {
        hr {
            display: none;
        }
        @include mq($screen-l) {
            padding: $footer__need-help-padding;
        }
        p, .paragraph {
            @include mq($screen-l) {
                color: $footer__need-help-text-color;
            }
        }
    }

    &__newsletter,
    &__need-help {
        margin: $footer__need-help-margin;
    }

    &__links {
        padding: $footer__link-list-padding;
    }

    &__bottom-bar {
        width: 100%;
        color: $footer__bottom-bar-color;
        background-color: $footer__bottom-bar-background;

        @include mq($screen-l) {
            background-color: $footer__bottom-bar-background-\@large;
            color: $footer__bottom-bar-color-\@large;

            .link-list__link {
                color: $footer__bottom-bar-color-\@large;
            }
        }

        &--transparent {
            background-color: transparent;
        }
    }

    &__bottom-bar-handler {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        @include mq($screen-m) {
            justify-content: space-between;
            align-items: center;

            &--flex-end {
                flex-flow: column wrap;
                align-items: flex-end;
            }
        }
    }

    &__bottom-logo {
        background: $footer__bottom-logo;
        margin: $footer__bottom-logo-margin;
        width: $footer__bottom-logo-width;
        height: $footer__bottom-logo-height;
        display: block;
        background-size: contain;

        order: 1;
        @include mq($screen-l) {
            display: none;
        }
    }

    &__social-handler {
        order: 3;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        margin: $footer__social-handler-margin;

        @include mq($screen-l) {
            margin: $footer__social-handler-margin-\@large;
        }

        .list__item {
            margin: $footer__social-icon-margin;

            @include mq($screen-l) {
                margin: $footer__social-icon-margin-\@large;
            }

            .list__image {
                width: auto;
                height: auto;
                max-width: inherit;
                max-height: $footer__list__image-size;

                &--wide {
                    max-width: inherit;

                    @include mq($screen-l) {
                        max-width: inherit;
                    }
                }
            }
        }

        &--extra-margin {
            .list__item {
                @include mq($screen-l) {
                    margin: $footer__social-icon-extra-margin-\@large;
                }
            }

            a {
                @extend .list;
                @extend .list--with-image;
                @extend .list--horizontal;
                @extend .footer__social-handler--extra-margin;
            }
        }

        &--desktop {
            display: none;
        }

        @include mq($screen-l) {
            display: none;

            &--desktop {
                display: block;
                order: 2;
                justify-content: normal;
                width: auto;
            }
        }

        strong {
            @extend .list__item;
        }

        a {
            @extend .list__item;

            img {
                @extend .list__image;
            }
        }

        img {
            @extend .list__item;
            @extend .list__image;
        }

        .payment {
            display: block;
            width: 100%;
            text-align: center;

            img {
                max-width: 15%;
                display: inline-block;
                margin: 0;
            }
        }
    }

    &__bottom-links {
        order: 2;
        width: 100%;

        @include mq($screen-l) {
            display: flex;
            align-items: center;
            padding: 0 0 $footer--padding 0;

            .link-list {
                order: 2;
                width: auto;
                display: inline-block;
                padding-bottom: $footer--padding;
            }
        }

        .link-list {
            img {
                width: 150px;
                @include mq($screen-l) {
                    display: none;
                }
            }

            ul {
                display: flex;
                list-style-type: none;
                padding: 0;
                margin: 0;

                @include mq(false, $screen-l) {
                    display: block;
                }

                li {
                    display: inline-block;
                    margin: 10px;

                    @include mq($screen-l) {
                        margin: 0 10px;

                        &:first-child {
                            margin-left: 0;
                        }
                    }

                    a {
                        color: #2d2d2d;

                        @include mq(false, $screen-l) {
                            text-decoration: underline;
                        }

                        &:hover {
                            color: #2d2d2d;
                            text-decoration: underline;
                        }
                    }
                }
            }

            @include mq(false, $screen-m) {
                justify-content: center;
                padding: 25px 0 10px;

                ul {
                    text-align: center;

                    li a {
                        text-decoration: none;
                        color: $color-primary--accent-2;
                    }
                }
            }
        }
    }

    &__copyright {
        display: block;
        text-align: center;
        margin: $footer_-copyright-margin;
        padding: $footer__copyright-padding;

        @include mq($screen-l) {
            order: 1;
            display: inline-block;
            margin: $footer__copyright-margin-\@medium;
            white-space: nowrap;
        }
    }

    @include mq($screen-l) {
        .link-list li a {
            white-space: nowrap;
        }
    }

    &--border {
        border-top: $base-fat-border;

        @include mq($screen-l) {
            border: none;
        }
    }

    &--checkout {
        background-color: #f9f9f9;
        color: $multi-step-checkout__footer-usp-color;
    }

    @include mq(false, $screen-m) {
        div.link-list__list {
            border-bottom: 1px solid $footer-menu-border-color;
        }
    }

    .link-list__list {

        .icon {
            display: none;
        }

        @include mq(false, $screen-m) {
            position: relative;
            padding: 8px 0;
            flex-basis: 100%;
            font-size: 18px;

            .icon {
                position: absolute;
                display: block;
                top: 18px;
                right: 0;
                padding: 5px;
                fill: #fff;
            }
        }

        .block-title {
            @extend .link-list__label;
        }

        ul {
            @include mq(false, $screen-m) {
                display: none !important;
            }
        }

        &.active {

            ul {
                display: block !important;
            }

            .icon {
                transform: rotate(90deg);
            }
        }

        ul:not(.link-list__list) {
            @extend .link-list__list;

            li:not(.link-list__item) {
                @extend .link-list__item;

                @include mq(false, $screen-m) {
                    padding: 2px 0;
                    font-size: 18px;
                }

                a:not(.link-list__link) {
                    @extend .link-list__link;
                }
            }
        }
    }
}
