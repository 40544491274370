$cart__margin                       : $spacer--large !default;
$cart__heading-font-weight          : $font-weight-bold !default;
$cart__heading-color                : $color-text-accent !default;
$cart__terms-font-size              : $font-size-small !default;
$cart__terms-font-size\@medium      : $font-size-base !default;
$cart__bottom-margin                : 0 !default;
$cart__bottom-padding               : $spacer 0 0 0 !default;

$cartItemHeight: 119px !default;

.checkout-cart-index {
    .heading--page {
        text-transform: none;
        text-align: center;
        font-weight: $cart__heading-font-weight;
        flex-basis: 100%;
        color: $cart__heading-color;

        @include mq($screen-l) {
            text-align: left;
        }
    }
    @include mq(false, $screen-m) {
        .header__search-wrapper,
        .header__side-menu {
            display: none;
        }
    }
}

.cart-prev-next {
    @include mq($screen-m) {
        display: none;
    }

    display: flex;
    justify-content: space-between;
    position: relative;

    .minimum-order-popover {
        display: none;
    }

    &__continue-shopping {
        margin-right: 5px;
        
        a {
            @extend .button;
            @extend .button--light;
        }
    }

    .cart-summary__checkout-button {
        width: 50%;
    }
}

.cart {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: $cart__margin;

    &__wrapper {
        flex-basis: 100%;

        @include mq($screen-l) {
            position: relative;

            [class*='col-'] {
                &:first-child {
                    padding-right: 20px;
                }
            }
        }

        .block-general-conditions-confirmation {
            margin-top: 20px;
        }
    }

    &__products-list {
        margin: 0;

        .cart-list-item {
            padding: 15px 0;
            border-bottom: $border-base;
        }
    }

    &__link-wrapper {

        @include mq($screen-l) {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: flex-start;

            .dropdown-item__content {
                margin-bottom: 91px;
            }

            .discount-code {
                position: absolute;
                left: 0;
                width: 100%;
            }
        }
    }

    &__continue {
        display: none;

        @include mq($screen-l) {
            display: inline-block;
            padding: 13px 0;
        }
    }

    &__discount-code {
        .dropdown-item {
            position: static;
        }
        .dropdown-item__label > .dropdown-item__icon,
        .dropdown-item__label[aria-expanded="false"] > .dropdown-item__icon {
            transform: rotate(-90deg);
        }
        .dropdown-item__label[aria-expanded="true"] > .dropdown-item__icon {
            transform: rotate(0deg);
        }

        .icon {
            position: initial;
            display: inline-block;
            vertical-align: top;
        }

        &-label {
            text-decoration: none;

            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
            }
        }
    }

    &__shopping-cart-table {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
    }

    &__terms {
        margin-top: 15px;
        font-size: $cart__terms-font-size;

        @include mq($screen-m) {
            font-size: $cart__terms-font-size\@medium;
        }
    }

    &__payment-methods {
        display: none;

        @include mq($screen-l) {
            display: block;
            margin-top: 45px;
            padding: 20px;
            border: $border-base;
            border-radius: $border-radius;
        }

        .image {
            width: auto;
            height: auto;
        }

        h3 {
            color: $color-primary;
            font-size: $font-size-base;
            font-weight: $font-weight-semi-bold;
        }

        ul {
            @extend .list;

            li {
                color: $color-text-accent;
                display: block;
                position: relative;
                padding: 10px 0 14px 30px;

                &:before {
                    display: inline-block;
                    position: absolute;
                    top: 4px;
                    left: 0;
                    content: '';
                    background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#57ac48" d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"/></svg>') no-repeat center;
                    width: 28px;
                    height: 28px;

                    @include mq($screen-l) {
                        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#57ac48" d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"/></svg>');
                    }
                }
            }
        }

        p {
            color: $color-text-accent;
            margin-top: 10px;
        }
    }

    .block-paymentlogos {
        @extend .cart__payment-methods;
        margin-top: 0;
    }

    &__cross-sell-products {
        margin-top: 15px;
    }

    @include mq($screen-l) {
        .products.wrapper {
            padding-right: 20px;

            /**
            &.fill-gap--2 {
                margin-top: calc(#{$cartItemHeight} * -2);
            }

            &.fill-gap--1 {
                margin-top: calc(#{$cartItemHeight} * -1 + 58px);
            }
            **/
        }
    }

    .quotation-notice {
        background: #fafafa;
        padding: 15px;
        display: flex;
        flex-direction: column;
        @include mq($screen-m) {
            flex-direction: row;
            justify-content: space-between;
        }

        p {
            flex: 0 1 auto;
        }

        .actions {
            flex: 0 0 auto;
        }
    }
}


.checkout-cart-index .cart-summary__item .minimum-order-popover,
.cart-summary__checkout-button + .minimum-order-popover,
.minicart-content__checkout + .minimum-order-popover {
    display: none;
    background: $color-light;
    color: $color-primary--accent-6;
    font-size: $font-size-base;
    font-weight: $font-weight-semi-bold;
    border: 1px solid $color-primary--accent-6;
    border-radius: $border-radius;
    position: absolute;
    text-align: center;
    z-index: 200;
    top: 70px;
    left: 50%;
    width: 95%;
    padding: 10px 0;
    transform: translateX(-50%);

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 10px solid $color-light;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }
}

.checkout-cart-index .cart-summary__item .minimum-order-popover {
    position: relative !important;
    top: inherit !important;
    display: block !important;
    margin-bottom: 15px;
    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: inherit !important;
        bottom: -10px !important;
        transform: translateX(50%);
        left: 50%;
        rotate: 180deg !important;
        border-bottom: 10px solid $color-light;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }
}

.minicart-content__checkout + .minimum-order-popover {
    top: 102%;
}

.cart-summary__checkout-button.button--disabled:hover + .minimum-order-popover,
.minicart-content__checkout.button--disabled:hover + .minimum-order-popover {
    display: block;
}

.cart,
.cms-home {
    .block-paymentlogos {
        @extend .cart__payment-methods;
    }
}
