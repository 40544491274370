$product-view__action-elements-margin-top\@small       : 28px !default;
$product-view__action-elements-margin-top\@large       : 0 !default;

$product-view__action-icon-color                       : $gray-darker !default;
$product-view__action-icon-color-hover                 : $color-primary !default;
$product-view__action-icon-transition                  : $transition-base !default;
$product-view__action-item-size                        : 48px !default;
$product-view__action-item-spacing                     : $spacer !default;

$product-view__add-review-link-font-size               : $font-size-base !default;
$product-view__add-review-link-font-size\@medium       : $font-size-small !default;

$product-view__add-to-cart-margin-right                : $spacer--medium !default;

$product-view__base-elements-vertical-spacing          : $spacer !default;

$product-view__col-base-spacing                        : $spacer--medium !default;
$product-view__col-base-reduce-width                   : $product-view__col-base-spacing / 2 !default;
$product-view__col-left-width                          : calc(100% - 41.66666667% - #{$spacer--container}) !default;
$product-view__col-right-width                         : calc(41.66666667% - #{$spacer--container}) !default;

$product-view__title-font-size                         : $font-size-large !default;
$product-view__title-font-size-mobile                  : 14px !default;

$product-view__description-color                       : $gray-darker !default;
$product-view__description-margin                      : 0 $spacer--medium $spacer--medium !default;
$product-view__description-margin\@medium              : 0 0 $spacer--large !default;
$product-view__description-padding                     : $spacer--medium !default;
$product-view__description-padding\@medium             : $spacer--medium 0 !default;
$product-view__description-border-top                  : 1px solid $gray-light !default;
$product-view__description-border-bottom               : 1px solid $gray-light !default;
$product-view__description-line-height                 : 1.7 !default;
$product-view__description-title-font-weight           : $font-weight-bold !default;
$product-view__description-bundle-margin               : 0 !default;
$product-view__description-bundle-padding              : $spacer--medium 0 !default;

$product-view__customization-space                     : $spacer--medium !default;
$product-view__customization-border                    : 1px solid $gray-light !default;

$product-view__summary-padding                         : $spacer--medium 0 !default;
$product-view__summary-border                          : 1px solid $gray-light !default;
$product-view__summary-margin                          : 0 0 $spacer--large !default;
$product-view__summary-title-font-weight               : $font-weight-bold !default;
$product-view__summary-item-space                      : $spacer--medium !default;
$product-view__summary-price-margin-bottom             : $spacer--medium !default;
$product-view__summary-price-font-size                 : $font-size-large !default;
$product-view__summary-price-font-weight               : $font-weight-bold !default;
$product-view__summary-unit-padding-left               : 4px !default;
$product-view__summary-unit-font-size                  : $font-size-base !default;

$product-view__add-to-cart-margin                      : 0 0 0 0 !default;
$product-view__add-to-cart-margin\@large               : 0 $product-view__add-to-cart-margin-right 0 0 !default;
$product-view__add-to-cart-btn-min-width               : 150px !default;
$product-view__add-to-cart-btn-padding\@large          : 0 $spacer--large !default;

$product-view__gallery-max-width                       : 100% !default;
$product-view__gallery-margin-bottom                   : 0 !default;
$product-view__gallery-margin-bottom\@medium           : 0 !default;
$product-view__gallery-padding-bottom                  : $spacer !default;
$product-view__gallery-padding-bottom\@medium          : 0 !default;

$product-view__information-padding                     : 0 20px !default;
$product-view__information-padding\@medium             : 0 !default;

$product-view__main-actions-margin                     : 9.5px 0 0 0 !default;
$product-view__main-details-box-shadow                 : 0 0 0 0 !default;

$product-view__main-details-bottom-spacing             : $spacer--medium !default;

$product-view__order-gallery                           : 1 !default;
$product-view__order-main-details                      : 2 !default;
$product-view__order-secondary-details                 : 3 !default;

$product-view__price-color                             : $gray-darker !default;
$product-view__price-color--old                        : $color-text-accent-3 !default;
$product-view__price-color--special                    : $color-tertiary !default;
$product-view__price-font-size                         : $font-size-huge !default;
$product-view__price-font-weight                       : $font-weight-bold !default;
$product-view__price-label-font-weight                 : $font-weight-normal !default;
$product-view__price-font-size-modal\@mobile           : $font-size-modal-mobile !default;

$product-view__qty-margin-right\@small                 : $spacer--medium !default;

$product-view__slider-name-font-size                   : $font-size-medium !default;
$product-view__slider-margin-bottom                    : 25px !default;
$product-view__slider-margin-bottom\@medium            : 72px !default;
$product-view__slider-margin-bottom--last\@medium      : 56px !default;
$product-view__slider-margin-bottom--last\@large       : 64px !default;

$product-view__wrapper-margin                          : $spacer--medium 0 !default;
$product-view__wrapper-margin\@medium                  : 0 0 20px 0 !default;
$product-view__installer-contact-background            : $color-primary--accent-2 !default;
$product-view__installer-contact-color                 : $color-primary--accent-5 !default;
$attribute__title-color                                : $color-primary !default;

$product-view__stock-margin-top                        : 0px !default;

.product-view {
    display: flex;
    flex-direction: column;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: $product-view__wrapper-margin;

        @include mq($screen-m) {
            display: block;
            margin: $product-view__wrapper-margin\@medium;
        }

        @include mq(false, $screen-m) {
            margin-bottom: 0;
        }
    }

    &__row {
        margin: 0;
    }

    &__container {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .view-specifications-container {
            display: none;
            @include mq($screen-m) {
                display: block;
            }

            .wwl-view-specifications {
                color: $color-primary;
                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                    text-decoration-thickness: from-font;
                }
            }
        }

        .wwl-separator {
            margin: 0 15px;
            font-size: 18px;
            color: #5d5d5d;

            display: none;

            @include mq($screen-m) {
                display: block;
            }
        }
    }

    &__back-to-grouped-link {
        display: flex;
        align-items: center;

        .icon {
            width: 15px;
            height: 15px;
            margin-right: 5px;
            fill: $color-primary;
        }

         a {
             line-height: 1.1;
         }
    }

    &__trustpilot-summary {
        display: flex;

        .trustpilot {
            padding-left: 0;
        }
    }

    &__trustpilot-reviews {
        border-top: $base-border;
        margin: 0 -#{$global-container-margin} 15px -#{$global-container-margin};
        padding: 0 $global-container-margin;

        @include mq($screen-l) {
            margin: 0 0 15px 0;
            padding: 0;
        }
    }

    &__gallery {
        position: relative;
        order: $product-view__order-gallery;
        width: 100%;
        max-width: $product-view__gallery-max-width;
        padding-bottom: $product-view__gallery-padding-bottom;
        margin-bottom: $product-view__gallery-margin-bottom;

        .gallery-placeholder {

            &.gallery-not-available-anymore {
                img {
                    opacity: .3;
                }
                .loader--visible {
                    display: none;
                }
            }

            &._block-content-loading {
                @media(min-width: 425px) {
                    min-height: 270px;
                }

                @include mq($screen-l) {
                    min-height: 400px;
                }
            }

            @include isIE() {
                .loader--visible {
                    height: auto;
                }
            }
        }

        .not-available-anymore {
            position: absolute;
            z-index: 10;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            padding: 10px 0;
            text-align: center;
            border-radius: $border-radius;
            background: $color-primary--accent-2;
            width: 94%;
            left: 3%;
            margin-top: -15px;

            @include mq($screen-m) {
                width: 70%;
                left: 15%;
            }

            p {
                font-size: $font-size-base;
                margin: 0;
                padding: 3px 0 9px;
                color: $color-primary;

                @include mq($screen-m) {
                    font-size: $font-size-medium;
                }
            }

            a {
                height: 35px;
                line-height: 35px;
                padding: 0 35px;
                margin-bottom: 3px;
            }
        }

        @include mq($screen-m) {
            padding-bottom: $product-view__gallery-padding-bottom\@medium;
            margin-bottom: $product-view__gallery-margin-bottom\@medium;
            float: left;
            width: $product-view__col-left-width;
        }
    }

    &__featured-attributes {
        float: right;
        margin: 0 0 0 20px;

        .attribute {
            position: relative;
            width: 133px;
            height: 78px;
            margin-bottom: 10px;
            border: $border-base;
        }
    }

    &__brand-thumb {

        span {
            position: absolute;
            left: 50%;
            top: 50%;
        }

        &--philips span {
            width: 112px;
            height: 24px;
            margin: -12px 0 0 -56px;
            background: url('../images/sprite.png') -326px -1px no-repeat;
        }

        &--sylvania span {
            width: 98px;
            height: 25px;
            margin: -13px 0 0 -49px;
            background: url('../images/sprite.png') -156px -305px no-repeat;
        }

        &--osram span {
            width: 91px;
            height: 27px;
            margin: -14px 0 0 -45px;
            background: url('../images/sprite.png') 0 -309px no-repeat;
        }

        &--steinel span {
            width: 98px;
            height: 28px;
            margin: -15px 0 0 -47px;
            background: url('../images/sprite.png') 0 -370px no-repeat;
        }

        &--linden span {
            width: 96px;
            height: 28px;
            margin: -15px 0 0 -47px;
            background: url('../images/sprite.png') 0 -343px no-repeat;
        }

        &--ledvance span {
            width: 110px;
            height: 30px;
            margin: -12px 0 0 -57px;
            background: url('../images/sprite.png') -261px -338px no-repeat;
        }

        &--noxion span {
            width: 112px;
            height: 25px;
            margin: -13px 0 0 -52px;
            background: url('../images/sprite.png') -262px -366px no-repeat;
        }

        &--ge span {
            width: 72px;
            height: 34px;
            margin: -20px 0 0 -42px;
            background: url('../images/sprite.png') -303px -307px no-repeat;
        }

        &--ansell span {
            width: 91px;
            height: 34px;
            margin: -14px 0 0 -45px;
            background: url('../images/sprite.png') 0 -8px no-repeat;
        }

        &--alecto span {
            width: 91px;
            height: 34px;
            margin: -14px 0 0 -45px;
            background: url('../images/sprite.png') -80px -5px no-repeat;
        }

        &--wago span {
            width: 91px;
            height: 34px;
            margin: -14px 0 0 -45px;
            background: url('../images/sprite.png') -175px -5px no-repeat;
        }

        &--nordlux span {
            width: 100px;
            height: 34px;
            margin: -14px 0 0 -45px;
            background: url('../images/sprite.png') -10px -58px no-repeat;
        }

        &--calex span {
            width: 100px;
            height: 34px;
            margin: -14px 0 0 -45px;
            background: url("../images/sprite.png") -115px -52px no-repeat;
        }

        &--paulmann span {
            width: 100px;
            height: 34px;
            margin: -14px 0 0 -45px;
            background: url('../images/sprite.png') -10px -95px no-repeat;
        }

        &--busch-jaeger span {
            width: 110px;
            height: 34px;
            margin: -14px 0 0 -55px;
            background: url('../images/sprite.png') -120px -95px no-repeat;
        }
    }

    &__beam-angle {
        background: url('../images/sprite.png') -403px -220px no-repeat;

        span {
            position: absolute;
            left: 42px;
            top: 44px;
            width: 48px;
            height: 24px;
            font-size: 24px;
            line-height: 24px;
            font-weight: 700;
            text-align: center;
            color: #0a485d;
        }

        &--1 {
            background-position: -1px -220px;
        }

        &--2 {
            background-position: -135px -220px;
        }

        &--3 {
            background-position: -269px -220px;
        }
    }

    &__main-details {
        position: relative;
        display: block;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        order: $product-view__order-main-details;

        .product-elabel {
            @include mq($screen-m) {
                height: auto;
            }
        }

        &:empty {
            display: none;
        }

        @include mq($screen-m) {
            float: right;
            width: $product-view__col-right-width;
            margin-bottom: $product-view__main-details-bottom-spacing;
            border-radius: $border-radius;
            box-shadow: $product-view__main-details-box-shadow;
        }

        &--transparent {
            box-shadow: none;
        }
    }

    &__secondary-details {
        display: flex;
        flex-wrap: wrap;
        order: $product-view__order-secondary-details;
        width: 100%;

        .related-products.alternatives {
            width: 100%;
        }

        @include mq(false, $screen-m) {
            .product-view__volume-discount {
                margin-bottom: 0;
            }
        }

        @include mq($screen-m) {
            display: block;
            width: $product-view__col-right-width;
            float: right;
            clear: right;
        }

        .unsalable-product-alternatives {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__title {
        width: 100%;
        margin-bottom: $product-view__base-elements-vertical-spacing;

        .heading--page {
            margin: 0;
            text-transform: inherit;
            font-size: $product-view__title-font-size-mobile;
            @include mq($screen-m) {
                font-size: $product-view__title-font-size;
            }
            font-weight: $font-weight-bold;
        }
    }

    &__price {

        display: flex;
        flex-basis: 100%;
        padding: 0 0 11px;
        column-gap: 6px;
        align-items: baseline;

        @include mq(false, $screen-m) {
            padding: 0;
        }

        .price {
            font-size: $product-view__price-font-size;
            font-weight: $product-view__price-font-weight;
        }

        .price-wrapper {

            @include mq(false, $screen-mobile) {
                line-height: 17px;
                font-size: $product-view__price-font-size-modal\@mobile !important;
            }

            &__vat {
                @include mq($screen-m, $screen-l) {
                    display: block;
                    text-align: center;
                    flex-basis: 100%;
                }
            }

            @include mq($screen-m) {
                justify-content: center;
            }
        }

        .price-box {
            display: flex;

            @include mq(false, $screen-m) {
                .tax-label {
                    display: block;
                }
            }
        }

        .price-label {
            font-weight: $product-view__price-label-font-weight;
        }

        .price__value {

            &--old {
                font-size: $font-size-base;
                color: $product-view__price-color--old;
            }

            &--special {
                display: block;
                color: $product-view__price-color--special;
                font-weight: bold;
            }

            &.sly-old-price {
                display: none;
            }
        }
        .price__value .price-container {
            display: inline-flex;
            align-items: flex-end;
            column-gap: 6px;
        }
    }

    .request-quotation {
        margin-top: 20px;

        &.hidden {
            display: none;
        }
    }

    &__volume-discount {
        @include mq(false, $screen-m) {
            margin-top: 10px;
        }

        @include mq($screen-m) {
            margin-bottom: 27px;
        }

        @include mq(false, $screen-m) {
            .modal-popup & {
                margin-top: 10px;
            }
        }

        .dropdown-item {
          position: static;

            @include mq(false, $screen-m) {
                padding-top: 10px;
                padding-bottom: 20px;
                position: relative;
            }
        }

        .dropdown-item__label {
            position: absolute;
            background: transparent;
            top: 30px;
            right: 0;
            padding-right: 20px;

            .icon {
                right: unset;
            }

            .modal-popup & {
                top: 0;
                max-width: 100%;
            }

            @include mq($screen-xs) {
                top: -5px;
                padding: 5px 20px 5px 0;
            }

            @include mq($screen-mobile-large) {
                max-width: 50%;
                padding: 13px 20px 13px 0;
            }

            @include mq($screen-m) {
                display: none;
                .modal-popup & {
                  display: block;
                  position: relative;
                }
            }
        }

        .dropdown-item__title {
            @include mq(false, $screen-m) {
                display: inline-block;
            }
        }

        .dropdown-item__content {
            padding-top: 10px;
        }

        .icon {
            right: 0;
        }

        &--light {
            @include mq($screen-m) {
                display: none;
            }
        }
    }

    &__dispatch-status {
        margin: 15px 0;
    }

    &__wishlist-container {
        position: relative;
        width: 50px;
    }

    &__wishlist-container:hover {
        .wishlist-icon-full {
            opacity: 1;
        }
    }

    &__wishlist-add {
        position: absolute;
        width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;

        .tooltip__content {
            left: 50%;
            top: 175%;
            transform: translate(-50%, -50%);
            font-size: 14px;

            &:before {
                left: 50%;
                transform: translateX(-50%);
            }
            @include mq(false, $screen-l) {
                left: -150%;
                &:before {
                    left: 88.5%;
                }
            }

            @media screen and (max-width: $screen-m) {
                display: none !important;
                pointer-events: none;
                &:before {
                    content: none;
                }
            }
        }

        .wishlist-icon,
        .wishlist-icon-full {
            height: 26px;
            width: 26px;
            position: absolute;
            fill: $color-cta;
            transition: all .3s ease-in-out, outline-offset 1ms;
        }

        .wishlist-icon-full {
            opacity: 0;
        }
    }

    &__usp-list-container {
        margin-bottom: -6px;

        .usp-list {
            list-style: none;
            color: $color-text-default;
            padding-left: 0;
            margin-top: 7px;
        }
        .usp-item {
            display: flex;
            column-gap: 4px;
            align-items: center;
        }
        .usp-icon {
            width: 30px;
            height: 30px;
        }
    }

    &__description {
        order: 2;
        margin: $product-view__description-margin;
        color: $product-view__description-color;
        width: 100%;
        padding: $product-view__description-padding;
        border-top: $product-view__description-border-top;
        border-bottom: $product-view__description-border-bottom;
        line-height: $product-view__description-line-height;

        @include mq($screen-m) {
            padding: $product-view__description-padding\@medium;
            margin: $product-view__description-margin\@medium;
        }
    }

    .attribute {
        &__title {
            display: block;
            color: $color-text-default;
            font-weight: 700;
            font-size: 21px;
            margin: 0 0 20px;
        }

        &.description {
            display: inline-block;
        }
    }

    &__description-bundle {
        order: 0;
        margin: $product-view__description-bundle-margin;
        padding: $product-view__description-bundle-padding;
    }

    &__description-title {
        font-weight: $product-view__description-title-font-weight;
    }

    &__customization {
        width: 100%;
        margin-bottom: $product-view__customization-space;
        padding-bottom: $product-view__customization-space;
        border-bottom: $product-view__customization-border;
    }

    &__summary {
        width: 100%;
        padding: $product-view__summary-padding;
        margin: $product-view__summary-margin;
        border-bottom: $product-view__summary-border;
        border-top: $product-view__summary-border;

        @include mq($screen-m) {
            border-bottom: 0;
            margin: 0;
        }
    }

    &__summary-price {
        display: flex;
        align-items: center;
        margin-bottom: $product-view__summary-price-margin-bottom;
        font-weight: $product-view__summary-price-font-weight;
        font-size: $product-view__summary-price-font-size;
    }

    &__summary-unit {
        padding-left: $product-view__summary-unit-padding-left;
        font-size: $product-view__summary-unit-font-size;
    }

    &__summary-title {
        font-weight: $product-view__summary-title-font-weight;
        margin-bottom: $product-view__summary-item-space;
    }

    &__summary-list {
        padding-left: $product-view__summary-item-space;
    }

    &__summary-item-title {
        display: block;
        font-weight: $product-view__summary-title-font-weight;
    }

    &__information {
        order: 3;
        width: 100%;
        padding: $product-view__information-padding;

        @include mq($screen-m) {
            padding: $product-view__information-padding\@medium;
        }
    }

    &__main-actions {
        display: flex;
        justify-content: space-between;
        width: 100%;

        flex-direction: column;
        row-gap: 12px;
        order: 1;

        @include mq(false, $screen-m) {
            margin-top: 5px;
        }

        @include mq($screen-l) {
            margin: $product-view__main-actions-margin;
        }
    }

    &__actions {
        clear: both;
    }

    &__options {
        width: 100%;

        .label {
            color: $color-text-default;
            font-weight: $font-weight-semi-bold;
            font-size: $font-size-base;
            margin: 0;
        }

        .select {
            position: relative;
            appearance: none;
            background-color: #fff;
            margin: 10px 0;
            padding: 0 20px;
            border: $border-base;
            border-radius: 5px;
            width: 100%;
            height: 45px;
            font-size: $font-size-base;
        }
    }

    &__qty {
        margin-right: $product-view__qty-margin-right\@small;

        &--increments {
            font-size: 10px;
            text-align: center;
            padding-top: 6px;
            color: gray;
        }
    }

    &__action-elements {
        column-gap: 15px;
        width: 82%;
        margin-top: 4px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        flex: 1 0 auto;

        @include mq($screen-l) {
            flex-flow: row nowrap;
            justify-content: flex-start;
        }

        @include mq($screen-m, $screen-l) {
            flex-direction: row;
            width: 100%;
        }

        @include mq(false, $screen-m) {
            flex-flow: unset;
            width: 100%;
        }
    }

    &__add-to-cart,
    &__update-cart {
      flex-grow: 1;
      @include mq($screen-l) {
        width: 100%;
      }

      .braintree-paypal-logo {
          position: relative;
          z-index: 1;
          margin-top: 10px;
      }
    }

    &__action {
        margin-right: $product-view__action-item-spacing;
        width: $product-view__action-item-size;
        height: $product-view__action-item-size;
        padding: 0;

        &:hover {
            cursor: pointer;

            .icon {
                fill: $product-view__action-icon-color-hover;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        &--button {
            box-shadow: none;
            background: transparent;
            border: none;
        }

        .icon {
            fill: $product-view__action-icon-color;
            transition: $product-view__action-icon-transition;
        }
    }

    &__slider {
        margin-bottom: $product-view__slider-margin-bottom;
        overflow: hidden;

        @include mq($screen-l) {
            display: none;
        }

        .slider__handler {
            margin-right: -$spacer--medium;
        }
    }

    &__specifications {
        @include mq($screen-l) {
            padding: 0 $spacer--container 0 0;
        }
        .dropdown-item__label:hover,
        .dropdown-item__label:focus {
            text-decoration: none;
            cursor: default;
        }
    }

    &__attachments {
        margin-bottom: 25px;
        .dropdown-item__label:hover,
        .dropdown-item__label:focus {
            text-decoration: none;
            cursor: default;
        }
    }

    &__related {
        @include mq($screen-l) {
            display: block;
            padding: 0 0 0 $spacer--container;
        }

        .related-products {
            margin-bottom: $spacer--extra-large;

            .product-elabel {
                width: 35px;
                height: auto;
                margin-top: 10px;
                padding: 0;
                cursor: pointer;

                @include mq($screen-m) {
                    width: 50px;
                }
            }
        }
    }

    &__need-help {
        display: none;

        @include mq($screen-l) {
            display: block;
            padding: 0 0 0 $spacer--container;

            .need-help {
                border: $border-base;
                width: 100%;
                padding: 30px;
            }
        }
    }

    &__saving-tip {
        width: 100%;
        margin-top: 15px;
    }

    &__static-text {
        h2 {
            font-size: 14px;
        }
    }

    .related-products.led_alternatives {
        margin-bottom: 5px;
    }

    &__installer-contact-pdp {
        padding: 16px 25px;
        background: $white;
        border: 1px solid $gray-light;
        border-radius: 5px;

        @include mq(false, $screen-m) {
            display: none;
        }

        @include mq($screen-l) {
            margin: 0 0 25px 25px;
        }

        p {
            color: $product-view__installer-contact-color;
        }

        h4 {
            color: $product-view__installer-contact-color;
        }

        ul {
            list-style: none;
            padding-left: 0;

            li {
                margin-bottom: 8px;
                color: $product-view__installer-contact-color;

                &:before {
                    content: '';
                    width: 1em;
                    height: 1em;
                    margin-right: 8px;
                    display: inline-block;
                    vertical-align: middle;
                    background-repeat: no-repeat;
                    background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 215.33783 164.89999"><path fill="#{$color-alt}" d="M 214.2,19.3 196.6,1.2 C 195.9,0.4 194.8,0 193.8,0 192.7,0 191.7,0.4 191,1.2 L 69,124.1 24.6,79.7 c -0.8,-0.8 -1.8,-1.2 -2.8,-1.2 -1,0 -2,0.4 -2.8,1.2 L 1.2,97.5 c -1.6,1.6 -1.6,4.1 0,5.7 l 56,56 c 3.6,3.6 8,5.7 11.7,5.7 5.3,0 9.9,-3.9 11.6,-5.5 h 0.1 L 214.3,25 c 1.4,-1.7 1.4,-4.2 -0.1,-5.7 z" /></svg> ');
                }
            }
        }

        a {
            text-decoration: underline;
        }
    }

    .product-name-wrapper {
        @include mq(false, $screen-m) {
            display: none;
        }
    }

    .product-unavailable {
        display: inline-block;
        width: 100%;
    }

    &__stock {
        margin-top: $product-view__stock-margin-top;
    }
}

.catalog-product-view .product-view__qty, .sticky-product-header {
    .quantity-update {
        width: fit-content;
        height: 36px;
        column-gap: 6px;
        box-shadow: unset;

        &__input {
            height: 46px;
            color: $quantity-update__input-color-spp;
            border: 2px solid #ebebeb;
            font-size: 16px;
            padding: 0 18px;
            width: 69px;
            border-radius: 0;
            transition: all .3s ease-in-out, outline-offset 1ms;

            &:hover {
                border: 2px solid $color-text-default !important;
            }
        }

        &__button {
            background-color: white !important;
            border: 2px solid #ebebeb;
            border-radius: 0;
            height: 46px;
            padding: 0 18px;
            margin: 0;

            &--plus,
            &--plus:hover,
            &--plus:active {
                color: $quantity-update__button--plus-color-spp;
            }

            &--minus {
                border-radius: 5px 0 0 5px;
            }

            &--plus {
                border-radius: 0 5px 5px 0;
            }

            &--minus,
            &--minus:hover,
            &--minus:active {
                color: $quantity-update__button--minus-color-spp;
            }

            &--plus:hover,
            &--minus:hover {
                border: 2px solid $color-text-default !important;
            }
        }
    }
}

.sticky-product-header {
    display: none;

    @include mq($screen-m) {
        display: block;

        .product-elabel {
            width: 50px;
            height: auto;
            margin-left: 15px;
            cursor: pointer;
        }
    }
}

.product-elabel-block {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 0 8px;
    max-width: 100%;
}

.product-elabel {
    width: 50px;
    height: auto;
    cursor: pointer;
}

.product-elabel-sheet {
    color: #2d2d2d;
    white-space: nowrap;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
        color: $color-primary;
    }
    .modal-led-alternative & {
        white-space: normal;
        width: calc(100% - 40px);
        text-align: left;
        hyphens: auto;
        word-break: break-word;
    }
}

.video-buttons i {
    font-size: 24px;
    margin-bottom: 6px;
}
.video-button {
    float: left;
    margin: 3px;
}
.video-button__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 60px;
    min-width: 60px;
    border: 1px solid;
    border-radius: 5px;
    justify-content: center;
    color: #ffffff;
    background-color: $color-primary;
    cursor: pointer;
    transition: all 0.3s ease-in-out, outline-offset 1ms;
    &:hover {
        color: #fff;
        background-color: $color-primary--accent;
    }
}
.video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}
.video-button__text {
    font-size: .7em;
}
.video-wrapper iframe,
.video-wrapper object,
.video-wrapper embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.video-icon-play {
    width: 24px;
    height: 24px;
    display: block;
    margin: 0 auto 4px;
    background: svg-uri($product-page__play-video-svg-uri);
}

.quicklinks-mobile-wrapper {
    order: 3;
    width: 100%;
}

.product-videos {
    display: inline-block;
}

.category-links__list--quicklinks {
    margin-top: 20px;
}
#modal-elabel-content {

    img {
        width: 250px;
        height: auto;
        margin: 0 auto;

        @include mq($screen-m) {
            width: 200px;
        }
    }

    a {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
    }
}
