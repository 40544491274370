$breadcrumbs__margin                        : $spacer !default;
$breadcrumbs__padding                       : 0 $spacer !default;
$breadcrumbs__icon-margin--first            : $spacer--medium $spacer $spacer--medium 0 !default;
$breadcrumbs__color                         : $color-primary !default;
$breadcrumbs__font-size                     : $font-size-small !default;

.breadcrumbs {
    display: flex;
    padding-top: 20px;

    @include mq($screen-m) {
        min-height: 61px;
    }

    &__list {
        padding: 0;
        margin: 0;
        width: 100%;

        @include mq($screen-m) {
            display: block;
            overflow: hidden;
            text-wrap: nowrap;
        }
    }

    .icon {
        display: none;
        width: 14px;
        height: 14px;
        fill: $color-text-default;

        @include mq(false, $screen-m) {
            &.breadcrumbs__mobile {
                display: inline-block;
                vertical-align: middle;
                transform: rotate(180deg);
            }
        }

        @include mq($screen-m) {
            display: inline-block;
            vertical-align: middle;
        }

        &:first-child {
            margin: $breadcrumbs__icon-margin--first;
            width: 21px;
            height: 21px;
            fill: $color-primary;
        }
    }

    &__item {
        display: none;
        align-items: stretch;
        color: $breadcrumbs__color;
        font-size: $breadcrumbs__font-size;
        transition: all .3s ease-in-out,outline-offset 1ms;

        @include mq(false, $screen-m) {
            &.breadcrumbs__mobile {
                display: inline-block;
                max-width: 90%;

                span {
                    @include line-clamp(1);
                }
            }
        }

        @include mq($screen-m) {
            display: inline-block;
            vertical-align: middle;

            &:hover {
                color: $breadcrumbs__color;
                text-decoration: underline;
                text-decoration-color: $breadcrumbs__color;
                text-decoration-thickness: from-font;
                cursor:pointer;
            }
        }

        &:first-child {
            .breadcrumbs__link {
                padding-left: 0;
            }
        }

        &:last-child {
            color:$color-text-default;


            @include mq($screen-m) {
                &:hover {
                    text-decoration: none;
                    cursor: default !important;
                }
            }
        }

    }

    &__link {
        display: flex;
        align-items: center;
        padding: 5px;
        text-decoration: none;
        background-clip: content-box;
        color: inherit;

        @include mq($screen-m) {
            padding: $breadcrumbs__padding;

            &:hover {
                color: inherit;
                text-decoration: inherit;
                cursor: inherit;
            }
        }

    }

    & + .page-main {
        .heading--page {
            margin-top: 0;
        }
    }
}

.page-product-grouped .breadcrumbs {
    @include mq(false, $screen-m) {
        display: none;
    }
}