.new-visitor-block {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 110;
    width: 100%;
    background-color: $color-primary--accent-2;

    .content-inner {
        padding: 10px 0;
    }

    .heading {

        .heading-icon {
            display: none;
        }

        span:first-child {
            font-size: $font-size-medium;
            color: $color-primary--accent-5;
            margin-bottom: 0.5em;
            display: inherit;
            max-width: 85%;
        }

        ul {
            list-style: none;
            padding-left: 0;
            margin: 0;

            li {
                display: block;
                margin-right: 0;
                color: $color-primary--accent-5;

                &:before {
                    content: '';
                    width: 1em;
                    height: 1em;
                    margin-right: 8px;
                    display: inline-block;
                    vertical-align: middle;
                    background-repeat: no-repeat;
                    background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 215.33783 164.89999"><path fill="#{$color-alt}" d="M 214.2,19.3 196.6,1.2 C 195.9,0.4 194.8,0 193.8,0 192.7,0 191.7,0.4 191,1.2 L 69,124.1 24.6,79.7 c -0.8,-0.8 -1.8,-1.2 -2.8,-1.2 -1,0 -2,0.4 -2.8,1.2 L 1.2,97.5 c -1.6,1.6 -1.6,4.1 0,5.7 l 56,56 c 3.6,3.6 8,5.7 11.7,5.7 5.3,0 9.9,-3.9 11.6,-5.5 h 0.1 L 214.3,25 c 1.4,-1.7 1.4,-4.2 -0.1,-5.7 z" /></svg> ');
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .request {
        .request__actions--secondary {
            font-weight: normal;
            color: $color-primary--accent-5;

            a {
                text-decoration: underline;
            }
        }
    }

    .remove-icon {
        fill: $color-text-accent;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        width: 40px;
        height: 40px;

        @include mq($screen-m) {
            position: relative;
        }
    }
}

@include mq(false, $screen-m) {
    .new-visitor-block {

        .content-inner {
            position: relative;
            display: block;
        }

        .request {
            display: block;
            margin-top: 5px;
            margin-bottom: 5px;

            .request__actions--primary {
                width: 100%;
                margin-bottom: 10px;
            }

            .button {
                width: 100%;
            }
        }

        .action-wrap {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

}

@include mq($screen-m) {
    .new-visitor-block {

        .content-inner {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .heading {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-grow: 1;
            margin-bottom: 0;
        }

        .request {
            display: flex;
            flex-direction: row;
            align-items: center;

            .request__actions--primary {
                margin-right: 15px;
                width: 150px;
            }

            .request__actions--secondary {
                font-weight: normal;

                a {
                    display: block;
                    text-decoration: underline;
                }
            }

            .button {
                margin: 0 !important;
                width: 100%;
            }
        }
    }
}

@include mq($screen-l) {
    .new-visitor-block {

        .heading {
            .heading-icon {
                display: block;
                margin-right: 30px;
                max-width: 50px;
                max-height: 50px;
            }

            ul {
                li {
                    margin-right: 25px;
                    display: inline-block;
                    vertical-align: top;
                }
            }
        }

        .request {
            .request__actions--secondary {
                a {
                    display: inline-block;
                }
            }
        }
    }
}
