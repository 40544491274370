$featured-category-padding                 : 0 15px 0 30px !default;
$featured-category-min-height              : 95px !default;
$featured-category-min-height-\@large      : 130px !default;
$featured-category-border-radius           : 5px !default;
$featured-category-background-start        : $color-primary !default;
$featured-category-background-end          : $color-primary--accent-7 !default;
$featured-category--cta-background-start   : $color-cta !default;
$featured-category--cta-background-end     : $color-cta--accent !default;
$featured-category-text-color              : $color-light !default;
$featured-category-font-weight             : $font-weight-semi-bold !default;
$featured-category-heading-margin          : 0 0 5px 0 !default;
$featured-category-heading-color           : $color-light !default;
$featured-category-heading-font-weight     : $font-weight-bold !default;
$featured-category-icon-border             : 2px solid $color-light !default;
$featured-category-icon-outer-size         : 40px !default;
$featured-category-icon-size               : 16px !default;
$featured-category-icon-fill               : $color-light !default;

.featured-category {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 5px 0;
    min-height: $featured-category-min-height;
    background-color: $featured-category-background-start;
    border-radius: $featured-category-border-radius;

    @include gradient-directional($featured-category-background-start, $featured-category-background-end, 109deg);

    @include mq($screen-l) {
        min-height: $featured-category-min-height-\@large;
    }

    &__content {
        display: flex;
        flex-flow: column;
        justify-content: center;
        width: calc(100% - #{$featured-category-icon-outer-size} - 20px);
        padding: $featured-category-padding;
        color: $featured-category-text-color;
        font-weight: $featured-category-font-weight;

        h2 {
            margin: $featured-category-heading-margin;
            color: $featured-category-heading-color;
            font-weight: $featured-category-heading-font-weight;
        }

        span {
            @include line-clamp(2);
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: $featured-category-icon-border;
        width: $featured-category-icon-outer-size;
        height: $featured-category-icon-outer-size;

        .icon {
            width: $featured-category-icon-size;
            height: $featured-category-icon-size;
            fill: $featured-category-icon-fill;
        }
    }

    &--cta {
        background-color: $featured-category--cta-background-start;

        @include gradient-directional($featured-category--cta-background-start, $featured-category--cta-background-end, 289deg);
    }
}
