.checkbox {

    &[type='checkbox'] {
        display: none;

        &:checked + label:before {
            background: $checkbox-extend__-background;
            border-color: $color-primary;
        }

        &:checked + label:after {
            content: '';
        }
    }

    &__label {

        &--checkbox {
            position: relative;

            span {
                font-weight: normal;
                margin-left: 28px;
            }

            &:before {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                left: 0;
                border: $checkbox-extend__-border;
                border-radius: $checkbox-extend__-border-radius;
                background-color: $white;
            }

            &:after {
                content: none;
                position: absolute;
                display: inline-block;
                width: 12px;
                height: 7px;
                top: 5px;
                left: 4px;
                transform: rotate(-45deg);
                border-left: 3px solid $checkbox-extend__-border-color;
                border-bottom: 3px solid $checkbox-extend__-border-color;
            }
        }
    }
}
