.category-description-collapsable {
    &.expanded {
        .category-description {
            @include mq($screen-s) {
                display: block;
            }
        }
    }
    &:not(.expanded) {
        .category-description {
            @include mq($screen-s) {
                @include line-clamp(1);
            }
        }
    }

    .show-more {
        display: none;

        @include mq($screen-s) {
            display: block;
            text-decoration: underline;
            text-align: center;
            margin: -10px 0 10px;
            color: $color-primary;

            svg {
                width: 18px;
                height: 18px;
                padding: 3px;
                vertical-align: sub;
                fill: $color-primary;
            }
        }
    }
}

.category-description {
    margin-bottom: 20px;

    @include mq(false, $screen-s) {

        h2 {
            text-align: left !important;
            font-size: 16px !important;
            margin-bottom: 10px !important;

            &.dropdown-item__title {
                text-align: center !important;
            }
        }

        p {
            text-align: left !important;
        }
    }

    .dropdown-item__title {
        color: $color-primary;
    }

    &:not(.category-description-dropdown) {
        display: none;

        @include mq($screen-s) {
            display: block;
        }

        .show-more {
            display: none;

            @include mq($screen-s) {
                display: block;
            }
        }
    }

    &--mobile-short {
        display: inline-block;

        p {
            margin-bottom: 0;
        }

        &:not(.expanded) {
            @include line-clamp(11);
        }

        + .show-more {
            display: block;
            text-align: center;
            margin: -10px 0 10px;
            color: $color-primary;

            svg {
                width: 18px;
                height: 18px;
                padding: 3px;
                vertical-align: sub;
                fill: $color-primary;
            }
        }
    }

    > p {
        margin-bottom: 0;
    }
}
