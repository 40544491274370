$cart-totals__border            : 1px solid #c7d3db !default;

.cart-totals {

    &__totals {
        display: flex;
        flex-direction: column;
    }

    &__totals-block {
        display: flex;
        flex-direction: column;
        padding: 15px 0 8px 0;
        border-bottom: $cart-totals__border;

        &--grand-totals {
            border-bottom: none;
        }
    }

    &__row {
        display: flex;
        flex-direction: row;
        padding: 0 0 $spacer--input 0;

        .tooltip--surcharge {
            float: right;
            margin-left:10px;

            .tooltip__icon {
                display: block;
                line-height: 20px;
                font-weight: bold;
                margin: 0 10px 0 0;
                background: $product-tooltip-background-color;
                border: $product-tooltip-border;
                color: $product-tooltip-border;
            }

            .tooltip__content {
                background: $color-primary;
                border: $color-primary;
                color: $color-light;
                left:-100px;

                &:before {
                    color: $color-primary;
                    border: $color-primary;
                }
            }
        }

        .tooltip__content .price {
            font-size: 14px;
            font-weight: normal;
        }
    }

    &__row--grand-total {
        font-weight: $font-weight-bold;
    }

    &__total-label {
        flex: 1 50%;

        &--discount {
            flex: 1 75%;
            color: $color-alt;
            font-weight: $font-weight-semi-bold;

            a {
                display: none;

                @include mq($screen-l) {
                    display: inline-block;
                }
            }
        }

        &--grand-total-incl-tax {
            font-weight: $font-weight-bold;
        }
    }

    &__total-label-subtitle {
        font-weight: normal;
        font-size: $font-size-small;
    }

    &__total-value {
        flex: 1 50%;
        text-align: right;

        &--discount {
            color: $color-alt;
            font-weight: $font-weight-semi-bold;
        }

        &--grand-total-incl-tax {
            font-weight: $font-weight-bold;
        }
    }
}

.tax-display-excluding .cart-totals .grand.excl {
    padding-top: 20px;
    margin-top: 10px;
    border-top: 1px solid $color-light--accent;
}
