$dashboard-nav-link-item-filter: none !default;

.dashboard-nav {
  .dashboard-nav__title {
    display: none;
  }
  .nav.item > a, .nav.item.current {
    display: flex;
    flex-direction: row;
    align-items: center;
    > svg {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      filter: $dashboard-nav-link-item-filter;
    }
  }
}