//
//  Responsive Classes
//  _____________________________________________

@include mq($max-screen: $screen-m - 1) {
    .pagebuilder-column {
        background-attachment: scroll !important; // sass-lint:disable-line no-important
        flex-basis: 100%;
    }
}
