$small-category-items-per-row                               : 3 !default;
$small-category-items-per-row-\@medium                      : 4 !default;
$small-category-items-per-row-\@xl                          : 6 !default;
$small-category-items-per-row-\@xxl                         : 6 !default;
$small-category-list-item-background                        : $color-light !default;
$small-category-list-item-box-shadow                        : 0 2px 10px -6px rgba(0, 0, 0, 0.5) !default;
$small-category-list-item-box-shadow\ie                     : 0 2px 10px -2px rgba(0, 0, 0, 0.5) !default;
$small-category-list-item-border-radius                     : 5px !default;
$small-category-list-item-margin                            : 0 0 20px 0 !default;
$small-category-list-item-margin-\@medium                   : 0 10px 20px 10px !default;
$small-category-list-item-padding                           : 0 !default;
$small-category-list-item-wrapper-padding                   : 5px;
$small-category-list-item-wrapper-padding-\@medium          : 10px;
$small-category-list-item-wrapper-padding-\@large           : 20px;
$small-category-list-item-link-color                        : $font-color-link-accent;
$small-category-list-item-link-color-hover                  : $font-color-link-accent;
$small-category-list-item-image-size                        : 52px !default;
$small-category-list-item-image-size-\@large                : 90px !default;
$small-category-list-item-image-margin-left-right           : 11px !default;
$small-category-list-text-font-weight                       : $font-weight-semi-bold !default;
$small-category-list-text-font-size                         : 14px !default;
$small-category-list-text-font-size-\@medium                : 12px !default;
$small-category-list-text-font-size-\@large                 : 14px !default;
$small-category-list-text-icon-fill                         : $color-primary !default;
$small-category-list-text-icon-size                         : 13px !default;
$small-category-list-after-icon-fill                        : $color-primary !default;
$small-category-border-color                                : #e4e4e4 !default;

.small-category-list {

    .list,
    &.list {
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 40px;

        @for $i from 1 to 10 {

            &--show-#{$i} {

                .list__item:nth-child(n + #{$i + 1}) {
                    display: none;

                    @include mq($screen-m) {
                        display: block;
                    }
                }
            }
        }
    }

    .list__item {
        flex-basis: 100%;
        background: $small-category-list-item-background;
        margin: $small-category-list-item-margin;
        padding: $small-category-list-item-padding;
        border-radius: $small-category-list-item-border-radius;
        box-shadow: $small-category-list-item-box-shadow;

        @include isIE() {
            box-shadow: $small-category-list-item-box-shadow\ie;
        }

        @include  mq($screen-m) {
            margin: $small-category-list-item-margin-\@medium;
        }

        @include mq($screen-m, $screen-l) {
            $num-row-m : $small-category-items-per-row;
            flex-basis: calc((100% / #{$num-row-m}) - (#{$small-category-list-item-wrapper-padding-\@large} * (#{$num-row-m} - 1) / #{$num-row-m} ));

            &:nth-child(1),
            &:nth-child(#{$num-row-m}n + #{$num-row-m + 1}) {
                margin-left: 0;
            }

            &:nth-child(#{$num-row-m}n + #{$num-row-m}) {
                margin-right: 0;
            }
        }

        @include mq($screen-l, $screen-xl) {
            $num-row-l : $small-category-items-per-row-\@medium;
            flex-basis: calc((100% / #{$num-row-l}) - (#{$small-category-list-item-wrapper-padding-\@large} * (#{$num-row-l} - 1) / #{$num-row-l} ));

            &:nth-child(1),
            &:nth-child(#{$num-row-l}n + #{$num-row-l + 1}) {
                margin-left: 0;
            }

            &:nth-child(#{$num-row-l}n + #{$num-row-l}) {
                margin-right: 0;
            }
        }

        @include mq($screen-xl, $screen-xxl) {
            $num-row-xl : $small-category-items-per-row-\@xl;
            flex-basis: calc((100% / #{$num-row-xl}) - (#{$small-category-list-item-wrapper-padding-\@large} * (#{$num-row-xl} - 1) / #{$num-row-xl} ));

            &:nth-child(1),
            &:nth-child(#{$num-row-xl}n + #{$num-row-xl + 1}) {
                margin-left: 0;
            }

            &:nth-child(#{$num-row-xl}n + #{$num-row-xl}) {
                margin-right: 0;
            }
        }

        @include mq($screen-xxl) {
            $num-row-xxl : $small-category-items-per-row-\@xxl;
            flex-basis: calc((100% / #{$num-row-xxl}) - (#{$small-category-list-item-wrapper-padding-\@large} * (#{$num-row-xxl} - 1) / #{$num-row-xxl} ));

            &:nth-child(1),
            &:nth-child(#{$num-row-xxl}n + #{$num-row-xxl + 1}) {
                margin-left: 0;
            }

            &:nth-child(#{$num-row-xxl}n + #{$num-row-xxl}) {
                margin-right: 0;
            }
        }

        &:hover {
            opacity: 0.8;
        }

        &-link {
            display: block;
            color: $small-category-list-item-link-color;

            &:hover {
                color: $small-category-list-item-link-color-hover;
            }
        }

        &-content-wrapper {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: center;
            text-align: center;
            padding: $small-category-list-item-wrapper-padding;

            @include mq($screen-m) {
                flex-direction: column;
                padding: $small-category-list-item-wrapper-padding-\@medium;
            }

            @include mq($screen-l) {
                padding: $small-category-list-item-wrapper-padding-\@large;
            }
        }

        &-image {
            display: inline-block;
            width: $small-category-list-item-image-size;
            height: $small-category-list-item-image-size;
            margin: 0 $small-category-list-item-image-margin-left-right 0 $small-category-list-item-image-margin-left-right;
            object-fit: contain;

            @include mq($screen-m) {
                display: block;
                margin: 0;
                width: $small-category-list-item-image-size-\@large;
                height: $small-category-list-item-image-size-\@large;
            }
        }

        &-text-wrapper {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            width: calc(100% - #{$small-category-list-item-image-size} - #{2 * $small-category-list-item-image-margin-left-right});

            @include mq($screen-m) {
                width: 100%;
            }

            .icon {
                margin-right: 20px;
                fill: $small-category-list-text-icon-fill;
                width: $small-category-list-text-icon-size;
                height: $small-category-list-text-icon-size;

                @include mq($screen-m) {
                    margin: 0;
                }
            }
        }

        &-text {
            text-align: center;
            margin: 0 auto 0 0;
            font-weight: $small-category-list-text-font-weight;
            font-size: $small-category-list-text-font-size;

            @media(max-width: 1280px) {
                font-size: $small-category-list-text-font-size-\@medium;
            }

            @include mq($screen-m) {
                margin: 0 auto;
                font-size: $small-category-list-text-font-size-\@medium;
            }

            @media (min-width: 1281px) {
                font-size: $small-category-list-text-font-size-\@large;
            }
        }
    }

    // ---------------- Extends to style homepage content based on migrated data. ----------------

    @extend .list;
    @extend .list--show-6;

    li {
        @extend .list__item;
    }

    a {
        @extend .list__item-content-wrapper;
        @extend .list__item-link;

        @include mq(false, $screen-m) {
            &:after {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16"><path fill-rule="evenodd" fill="#{$small-category-list-after-icon-fill}" d="M2.33 0L.45 1.88 6.55 8l-6.1 6.12L2.33 16l8-8z"/></svg>') no-repeat center;
                background-size: contain;
                margin-left: 3px;
                transform: translateY(1px);
            }
        }
    }

    img {
        @extend .list__item-image;
    }

    span {
        @extend .list__item-text;

        word-break: break-word;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        @include mq($screen-m) {
            &:after {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16"><path fill-rule="evenodd" fill="#{$small-category-list-after-icon-fill}" d="M2.33 0L.45 1.88 6.55 8l-6.1 6.12L2.33 16l8-8z"/></svg>') no-repeat center;
                background-size: contain;
                margin-left: 3px;
                transform: translateY(1px);
            }
        }
    }
}

.glider-contain {
    margin-bottom: 20px;
    overflow: hidden;
    visibility: hidden;
    max-height: 300px;

    .block-products-list & {
        max-height: none;
    }

    .glider {
        width: calc(100% - 20px);
    }

    .glider-slide {
        margin: 0 10px 10px;
        padding: 20px;
        flex-basis: calc((100% / 6) - (20px * (6 - 1) / 6 ));
        background-color: $white;
        box-shadow: $small-category-list-item-box-shadow;
        border: 1px solid $small-category-border-color;
        border-radius: 5px;
        box-sizing: border-box;

        a {
            color: $color-text-accent;
            text-align: center;
            display: block;
            transition: $transition-base;
        }

        & > a {
            .block-products-list & {
                display: flex;
                flex-flow: column wrap;
                justify-content: space-between;
                align-items: center;
            }
        }

        img {
            display: block;
            margin: 0 auto;
            width: 90px;
            height: 90px;
        }

        strong {
            font-size: $small-category-list-text-font-size;
            font-weight: $small-category-list-text-font-weight;
            text-align: center;
            word-break: break-word;
            display: block;
            margin: 0 auto 0 0;

            .block-products-list & {
                @include line-clamp(2);
            }
        }

        .price-box {
            text-align: left;
            padding: 5px 0;

            .block-products-list & {
                .minimal-price-link,
                .price__value--gross,
                .price__label--gross {
                    display: none;
                }
            }

            @include mq($screen-m) {
                padding: 10px 0;
            }
        }

        button svg {
            @include mq($screen-m) {
                display: none;
            }
        }
    }

    .category-link,
    .show-more-link {
        color: $color-primary;
    }

    .category-link {
        font-weight: bold;
        margin-bottom: 10px;
        display: block;
    }

    .show-more-link {
        position: relative;
        padding-right: 15px;
        margin-right: 20px;
        float: right;
        background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16"><path fill-rule="evenodd" fill="#{$small-category-list-after-icon-fill}" d="M2.33 0L.45 1.88 6.55 8l-6.1 6.12L2.33 16l8-8z"/></svg>') no-repeat;
        background-position: right 0 bottom 5px;
        background-size: 6px auto;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: $small-category-list-after-icon-fill;
            transition: $transition-base;
        }

        &:hover,
        &:focus,
        &:active {
            &:after {
                background-color: transparent;
            }
        }
    }

    .glider-prev,
    .glider-next {
        top: 50%;
        padding: 20px 10px;
        background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16"><path fill-rule="evenodd" fill="#{$white}" d="M2.33 0L.45 1.88 6.55 8l-6.1 6.12L2.33 16l8-8z"/></svg>') no-repeat center;
        background-size: 11px auto;
        background-color: $color-primary;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .glider-prev {
        left: 0;
        transform: translateY(-50%) rotate(180deg);
    }

    .glider-next {
        right: 0;
        transform: translateY(-50%);
    }

    .glider-dots {
        display: none;
    }
}

@include mq(false, $screen-m) {
    .glider-contain {
        width: calc(100% + 15px);

        .glider {
            width: 100%;
        }

        .glider-slide {
            margin: 0 5px 10px;
        }

        .show-more-link {
            float: none;
            padding-left: 15px;
            padding-right: 0;
            margin-left: 5px;
            margin-right: 0;
            background-position: left 0 bottom 5px;

            &:after {
                left: auto;
                right: 0;
                width: calc(100% - 15px);
            }
        }

        .glider-prev,
        .glider-next {
            display: none;
        }
    }
}
