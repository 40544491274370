$search-form__border-radius: $border-radius !default;
$search-form__button-border-radius: 0 $border-radius $border-radius 0 !default;

.search-form {
    border-radius: $search-form__border-radius;
    &__input {
        border-radius: $search-form__border-radius;
    }

    &__button {
        border-radius: $search-form__button-border-radius;
    }
}
