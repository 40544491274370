$action-margin : 15px !default;
$action-padding: 15px !default;

.action {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__handler {
        flex-basis: 100%;
        margin-bottom: $action-margin;

        @include mq($screen-m) {
            flex-basis: auto;
            margin-bottom: 0;
        }
    }

    &__button {
        margin-right: $action-margin;
        height: 30px;
        padding: 0 20px;
        font-size: 14px;
        width: 100%;
        margin-bottom: $action-margin;

        @include mq($screen-m) {
            width: auto;
            margin-bottom: auto;
        }

        &:last-child {
            margin-right: 0;
        }

        &[type="submit"] {
            background-color: $color-cta;
        }
    }

    &__link {
        padding: $action-padding 0;
        margin-right: 15px;
        background-clip: content-box;

        &:last-child {
            margin-right: 0;
        }
    }
}
