$vat-switcher-title-margin                                  : 0 15px 0 0 !default;
$vat-switcher-slider-width                                  : 45px !default;
$vat-switcher-slider-height                                 : 18px !default;
$vat-switcher-slider-background                             : $gray-lighter !default;
$vat-switcher-slider-background--checked                    : $color-primary--accent-5 !default;
$vat-switcher-slider-border-radius                          : 22px !default;
$vat-switcher-slider-pseudo-transition                      : 0.4s !default;
$vat-switcher-slider-pseudo-size                            : 25px !default;
$vat-switcher-slider-pseudo-top                             : -4px !default;
$vat-switcher-slider-pseudo-border-radius                   : 50% !default;
$vat-switcher-slider-pseudo-box-shadow                      : 0 1px 5px -2px rgba(0, 0, 0, 0.75) !default;
$vat-switcher-slider-pseudo-background                      : svg-uri($ios-close-svg) $color-light no-repeat !default;
$vat-switcher-slider-pseudo-background--checked             : svg-uri($ios-checkmark-svg) $color-primary no-repeat !default;

$vat-switcher-value-margin                                  : 0 0 0 15px !default;
$vat-switcher-value-font-weight                             : $font-weight-semi-bold !default;

.vat-switcher {
    display: flex;
    justify-content: center;

    &__title {
        align-self: center;
        margin: $vat-switcher-title-margin;
    }

    label {
        align-self: center;
        margin: 0;
    }

    &__slider {
        display: inline-block;
        position: relative;
        cursor: pointer;
        top: 4px;
        right: 0;
        bottom: 0;
        width: $vat-switcher-slider-width;
        height: $vat-switcher-slider-height;
        background: $vat-switcher-slider-background;
        border-radius: $vat-switcher-slider-border-radius;

        &::before {
            content: "";
            position: absolute;
            background-size: contain;
            left: 0;
            transition: $vat-switcher-slider-pseudo-transition;
            height: $vat-switcher-slider-pseudo-size;
            width: $vat-switcher-slider-pseudo-size;
            top: $vat-switcher-slider-pseudo-top;
            background: $vat-switcher-slider-pseudo-background;
            border-radius: $vat-switcher-slider-pseudo-border-radius;
            box-shadow: $vat-switcher-slider-pseudo-box-shadow;
        }
    }

    &__value {
        align-self: center;
        margin: $vat-switcher-value-margin;
        font-weight: $vat-switcher-value-font-weight;

        &.hidden {
            display: none;
        }
    }

    &--checked {
        .vat-switcher__slider {
            background: $vat-switcher-slider-background--checked;

            &::before {
                transform: translateX(100%);
                background: $vat-switcher-slider-pseudo-background--checked;
            }
        }
    }
}
