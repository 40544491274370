.toast-notification{
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 110;
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.25);
  margin: 30px;

  .content-inner {
    padding: 20px 20px;
    overflow: hidden;
  }

  .heading {
    .heading-icon {
      display: none;
    }

    h4 {
      font-size: $font-size-medium;
      color: $gray-darkest;
      margin-bottom: 0.5em;
    }
  }

  .remove-icon {
    fill: $color-text-accent;
    position: absolute;
    top: 7px;
    right: 7px;
    padding: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  @include mq(false, $screen-m) {
    width: calc(100% - 20px);
    margin: 10px;
    .content-inner {
      width: 100%;
    }
  }

}
