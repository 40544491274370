//sass-lint:disable no-ids

$tp-green                           : #01b679 !default;
$tp-star-1-c                        : #ff3722 !default;
$tp-star-2-c                        : #fb963a !default;
$tp-star-3-c                        : #f9cd16 !default;
$tp-star-4-c                        : #73cf11 !default;
$tp-star-5-c                        : #00c47c !default;

.trustpilot-review-item {
    position: relative;
    transition: all 1s ease;
    color: #1b1b21;

    strong {
        font-weight: 600;
    }

    .tp-widget-review__source__information,
    .tp-widget-summary__message,
    .modal {
        position: relative;
    }

    .tp-widget-review__source__information::before,
    .tp-widget-summary__message::before,
    .modal::before {
        background-color: #fff;
        border-right: 1px solid #d6d6d6;
        border-top: 1px solid #d6d6d6;
        content: '';
        display: block;
        height: 16px;
        left: 50%;
        position: absolute;
        top: -13px;
        transform: rotate(-34deg) translateX(-50%) skewX(26deg);
        transform-origin: center;
        width: 16px;
    }

    .tp-widget-review__source__information,
    .tp-widget-summary__message {
        background-color: #fff;
        border: 1px solid rgba(151, 151, 151, 0.18);
        box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.1);
        color: #191919;
        display: none;
        right: 20px;
        line-height: 16px;
        padding: 16px 8px;
        position: absolute;
        margin-top: 30px;
        z-index: 10;
    }

    .tp-widget-review__source__information::before,
    .tp-widget-summary__message::before {
        border-color: rgba(151, 151, 151, 0.18);
    }

    @media screen and (min-width: 768px) {
        .tp-widget-review__source__information,
        .tp-widget-summary__message {
            padding: 16px;
        }
    }

    #fallback-logo {
        display: none;
    }

    .no-svg #fallback-logo {
        display: inline-block;
    }

    .tp-widget-review {
        .tp-widget-review__text {
            font-size: 14px;
            font-weight: 600;
            color: #2d2d2d;

            &::before {
                content: open-quote;
            }

            &::after {
                content: close-quote;
            }
        }

        .tp-widget-review__footer {
            color: #9b9b9b;
            margin: 16px 0 0;
            position: relative;
        }
    }

    .tp-widget-loader,
    .tp-widget-fallback {
        max-width: 50%;
        opacity: 0;
        text-align: center;
        width: 400vh;
    }

    .tp-widget-loader svg,
    .tp-widget-fallback svg {
        width: 100%;
    }

    @media screen and (min-width: 768px) {
        .tp-widget-loader,
        .tp-widget-fallback {
            max-width: 200px;
        }
    }

    .tp-widget-loader--loaded {
        opacity: 1;
    }

    .bold-underline {
        border-bottom: 1px solid rgba(25, 25, 25, 0.6);
    }

    .bold-underline:hover {
        border-color: #191919;
    }

    img {
        border: 0;
    }

    svg {
        display: inline-block;
        width: 100%;
    }

    a {
        color: #191919;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
            background: initial;
            color: initial;
            text-decoration: none;
        }
    }

    .tp-widget-review__rating__label {
        align-items: center;
        display: flex;
    }

    .tp-widget-review__rating__label::after {
        border-color: $tp-green transparent transparent transparent;
        border-style: solid;
        border-width: 4px 4px 0;
        content: '';
        display: block;
        height: 0;
        margin: 0 0 0 6px;
        width: 0;
    }

    .tp-widget-review__source {
        margin: 16px 0 0;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 21px;

        @media screen and (min-width: 768px) {
            margin-top: -18px;
        }

        .tp-widget-review__source-text {
            align-items: center;
            display: inline-flex;
            position: absolute;
            right: 20px;
            line-height: 18px;
            color: $tp-green;

            .tp-widget-review__sourcelogo {
                width: auto;
                height: 20px;
                margin-left: 3px;
            }
        }
    }

    .tp-widget-review__source__information {
        cursor: default;
        width: 100%;
    }

    .tp-widget-review__source__information::before {
        left: 75%;
    }

    .tp-widget-review__source__information span {
        display: block;
        margin: 0 0 6px;
    }

    .tp-widget-review__source__information p {
        margin: 0 0 8px;
    }

    .tp-widget-review__source__information p:last-of-type {
        margin: 0;
    }

    .tp-widget-review__source__information a {
        color: $tp-green;
    }

    .tp-widget-review__source:hover .tp-widget-review__source__information {
        display: block;
    }

    .tp-widget-review__source:hover::after {
        content: '';
        display: block;
        height: 20px;
        left: 0;
        position: absolute;
        top: 100%;
        width: 100%;
    }

    @media screen and (min-width: 768px) {
        .tp-widget-review__source__information {
            max-width: 335px;
            padding: 16px;
            width: 200%;
        }
    }

    .tp-widget-reviews-container {

        .tp-widget-review {
            border: 1px solid #ececec;
            border-radius: 8px;
            font-size: 13px;
            padding: 25px 20px;
            position: relative;
            margin-bottom: 10px;
        }

        .tp-widget-reviews {
            &__show-more,
            &__show-less {
                span {
                    color: $color-primary;
                    cursor: pointer;
                    padding-left: 15px;

                    &:before {
                        border-style: solid;
                        border-width: 0.1em 0.1em 0 0;
                        content: '';
                        display: inline-block;
                        height: 0.35em;
                        position: relative;
                        top: 0.5em;
                        vertical-align: top;
                        width: 0.35em;
                        left: -0.8em;
                        transform: rotate(45deg);
                    }
                }
            }

            &__show-less {
                display: none;

                span {
                    &:before {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }

    .tp-widget-review__date {
        color: #5c5c70;
        position: absolute;
        right: 0;
        top: 16px;
    }

    .tp-widget-review__heading {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 0 0 12px;
        position: relative;
    }

    .tp-widget-review__display-name {
        color: #5c5c70;
        margin: 0 0 16px;
        max-width: 80%;
    }

    .tp-widget-review__stars {
        margin: 0 16px 0 0;
        width: 100px;
    }

    .tp-widget-review__rating__label {
        color: $tp-green;
        cursor: pointer;
        font-size: 13px;
    }

    .tp-widget-review__rating__label--collapse {
        color: #5c5c70;
        display: none;
    }

    .tp-widget-review__rating--expanded .tp-widget-review__rating__label {
        display: none;
    }

    .tp-widget-review__rating--expanded .tp-widget-review__rating__label--collapse {
        display: flex;
    }

    .tp-widget-review__rating--expanded .tp-widget-review__rating__label--collapse::after {
        border-top-color: #d6d6d6;
        transform: rotate(180deg);
    }

    .tp-widget-review__rating--expanded .tp-widget-review__rating__modal {
        transform: scale(1);
    }

    .tp-widget-review__text {
        font-size: 14px;
        line-height: 24px;
    }

    .load-more-button {
        color: $tp-green;
        cursor: pointer;
        font-size: 13px;
        padding: 24px 0;
        text-align: center;
    }

    .tp-widget-wrapper-layout {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .tp-widget-wrapper-layout::after {
        content: '';
        display: block;
        height: 12px;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transition: opacity 0.2s ease;
        width: 100%;
        z-index: 20;
    }

    .tp-widget-wrapper-layout::after {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(182, 182, 182, 0.19) 100%);
        bottom: 0;
    }

    .tp-widget-wrapper-layout.content-shadow--show::after {
        opacity: 1;
    }

    @media screen and (max-width: 768px) {
        .tp-widget-empty-vertical__subtitle-wrapper {
            align-items: center;
            display: flex;
            flex-direction: column;
        }
        .tp-widget-empty-vertical__logo {
            margin: 5px 0 0;
        }
        .tp-widget-empty-horizontal {
            flex-direction: column;
        }
        .tp-widget-empty-horizontal__logo {
            margin: 0;
        }
        .tp-widget-summary {
            border: none;
        }

        .tp-widget-review__source__information {
            right: -1px;
            width: calc(100% + 1px);
        }
    }
}
