$dashboard-items-padding         : 15px !default;
$dashboard-items-margin          : 15px !default;
$dashboard-items__item-max-width : calc(50% - #{$spacer--medium}) !default;
$dashboard-items__item-margin    : 15px $spacer--medium 15px 0 !default;

.dashboard-items {
    &__title {
        margin-bottom: $dashboard-items-margin;
        padding-bottom: 10px;
        border-bottom: 1px solid $gray;
    }

    &__content {
        margin: 0;
    }

    &__address {
        margin-bottom: $dashboard-items-margin;
    }

    &__item {
        max-width: $dashboard-items__item-max-width;
        margin: $dashboard-items__item-margin;
        padding: $dashboard-items-padding 0;
    }
}
