//
//  Default appearance styles
//  _____________________________________________

div[data-content-type='video'] {
    .pagebuilder-video-inner {
        display: inline-block;
        width: 100%;
    }

    .pagebuilder-video-container {
        border-radius: inherit;
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}
