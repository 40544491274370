$self : '.volume-discount';

#{$self} {

    &__icon {
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        width: 14px;
        height: 14px;
        fill: $volume-discount__icon-fill;
    }

    &__table {
        border: none;
        border-radius: 0;
        color: $volume-discount__table-color;
        background: $volume-discount__table-background;

        & th {
            text-align: left;
            border: none;
            padding: 5px;
            border-bottom: $volume-discount__table-border;
            color: $volume-discount__table-heading-color;
            background: $volume-discount__table-background;
            font-weight: $volume-discount__table-heading-font-weight;
        }

        & td {
            border: none;
            background: $volume-discount__table-background;
            border-bottom: $volume-discount__table-border;
        }

        & tr {

            &[data-trigger='request-quotation'] {
                .modal-content & {
                    display: none;
                }
            }

            &:last-child {

                td {
                    border: none;
                }
            }
        }
    }

    &__text-accent {
        color: $volume-discount__text-accent-color;
        font-weight: $volume-discount__text-accent-font-weight;
    }

    &__price {

        .price-wrapper {
            line-height: normal;
        }

        .price {
            font-weight: $font-weight-normal;
            font-size: $font-size-base;

            @include mq($screen-m) {
                text-decoration: underline;
            }
        }
    }

    &--light {
        @include mq($screen-m) {
            #{$self}__table {
                background: $volume-discount--light__table-background;
                font-size: $font-size-small;

                @include mq($screen-l) {
                    font-size: $font-size-base;
                }
            }

            & tr {
                cursor: pointer;
                text-align: center;

                &:last-child {

                    td {
                        border-right: $volume-discount--light__table-border;

                        &:first-child {
                            border-left: $volume-discount--light__table-border;
                        }

                        &:nth-child(3) {
                            border: none;
                        }

                        &:last-child {
                            display: none;
                        }
                    }
                }
            }

            & th {
                border: none;
                border-right: $volume-discount--light__table-border;
                text-align: center;
                background: $volume-discount--light__table-background;
                color: $volume-discount--light__table-heading-color;
                font-weight: $volume-discount--light__table-heading-font-weight;

                &:first-child {
                    border-left: $volume-discount--light__table-border;
                }

                &:nth-child(3) {
                    border-right: none;
                }

                &:last-child {
                    display: none;
                }
            }

            & td {
                border: none;
                border-right: $volume-discount--light__table-border;
                padding: 2px;
                background: $volume-discount--light__table-background;

                &:first-child {
                    border-left: $volume-discount--light__table-border;
                }

                &:nth-child(3) {
                    border: none;
                }

                &:last-child {
                    display: none;
                }
            }

            & td:not(#{$self}__text-accent) {
                color: $volume-discount--light__table-color;
                text-decoration: $volume-discount--light__table-text-decoration;
            }
        }
    }

    &-blocks {
        display: none;
        margin-top: 25px;

        @include mq($screen-m) {
            display: block;
        }

        &__title {
            font-size: $font-size-base;
            font-weight: $font-weight-semi-bold;
            color: $color-text-accent;
        }

        &__wrapper {
            display: flex;
            position: relative;
            margin-top: 14px;

            @if $volume-discount__desktop-reverse {
                flex-flow: row-reverse;
                &.no-quotation {
                    justify-content: flex-end;
                }
            }
        }

        &__item-wrapper {
            position: relative;
            margin-right: 14px;
            min-width: 120px;
            flex-basis: calc(100% / 3);

            @if $volume-discount__desktop-reverse {
                &:first-of-type {
                    margin-right: 0;
                }
            } @else {
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        &__item {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            border-radius: $border-radius;
            max-height: 86px;
            height: 100%;
            padding: 12px 24px;
            border: solid 1px $gray-light;
            cursor: pointer;

            &:hover {
                border: solid 1px $gray;
                background: $color-light--accent;
            }
        }

        &__qty {
            display: block;
            font-weight: $font-weight-bold;
            color: $color-text-accent;
            text-align: center;
            margin-bottom: 5px;
        }

        &__price {
            display: block;
            flex-basis: 100%;
            color: $color-text-accent-2;
            text-align: center;
            font-size: $font-size-small;
            font-weight: $font-weight-semi-bold;
        }

        &__discount {
            display: block;
            margin-top: 10px;
            font-weight: $font-weight-semi-bold;
            color: $color-alt;
            text-align: center;

            .link--icon {
                justify-content: center;
                text-decoration: underline;
                line-height: 1;
            }
        }
    }
}
