$message__padding                 : $spacer--medium !default;
$message__margin-base             : 0 0 40px 0 !default;
$message__font-size               : $font-size-base !default;
$message__transition              : $transition-base !default;
$message__background              : $color-warning !default;
$message__background--primary     : $color-primary--accent-2 !default;
$message__background--success     : $color-alt--accent-3 !default;
$message__background--error       : $color-tertiary--accent-2 !default;
$message__color                   : $color-warning--accent !default;
$message__color--primary          : $color-primary !default;
$message__color--success          : $color-alt !default;
$message__color--error            : $color-tertiary--accent !default;
$message__border-radius           : $border-radius !default;
$message__icon-fill               : $message__color !default;
$message__icon-fill--primary      : $message__color--primary !default;
$message__icon-fill--success      : $message__color--success !default;
$message__icon-fill--error        : $message__color--error !default;

$free-shipping-notice__color      : #0b92e0;
$free-shipping-notice__background : #e7f6ff;
$free-shipping-notice__margin     : 20px 0 10px;
$free-shipping-notice__margin-desktop : 0 0 20px;

.message {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: $message__padding;
    margin: $message__margin-base;
    background-color: $message__background;
    border-radius: $message__border-radius;
    color: $message__color;
    font-size: $message__font-size;
    transition: $message__transition;

    .icon {
        fill: $message__icon-fill;
    }

    &__content {
        flex-basis: 80%;
        margin: 0 10px;
    }

    &--primary {
        background-color: $message__background--primary;
        color: $message__color--primary;

        .icon {
            fill: $message__icon-fill--primary;
        }
    }

    &--success {
        background-color: $message__background--success;
        color: $message__color--success;

        .icon {
            fill: $message__icon-fill--success;
        }
    }

    &--error {
        background-color: $message__background--error;
        color: $message__color--error;

        .icon {
            fill: $message__icon-fill--error;
        }
    }
}

.free-shipping-notice {
    width: 100%;
    border-radius: 5px;
    padding: 16px;
    color: $free-shipping-notice__color;
    margin: $free-shipping-notice__margin;
    background-color: $free-shipping-notice__background;

    &:before {
        content: '';
        display: inline-block;
        background: svg-uri('<svg width="36" height="26" xmlns="http://www.w3.org/2000/svg"><path d="M27.5 25.392c.836 0 1.621-.215 2.356-.646a4.819 4.819 0 001.748-1.748c.43-.735.646-1.52.646-2.356h3.192V12.7l-4.75-6.308h-4.75V.008H3.75c-.861 0-1.609.317-2.242.95S.558 2.338.558 3.2v17.442H3.75c0 .836.215 1.621.646 2.356a4.819 4.819 0 001.748 1.748c.735.43 1.52.646 2.356.646.836 0 1.621-.215 2.356-.646a4.819 4.819 0 001.748-1.748c.43-.735.646-1.52.646-2.356h9.5c0 .836.215 1.621.646 2.356a4.819 4.819 0 001.748 1.748c.735.43 1.52.646 2.356.646zM33.01 12.7h-7.068V8.748h3.952L33.01 12.7zM27.5 22.998a2.274 2.274 0 01-1.672-.684 2.274 2.274 0 01-.684-1.672c0-.659.228-1.222.684-1.691a2.243 2.243 0 011.672-.703c.659 0 1.222.234 1.691.703.469.469.703 1.032.703 1.691s-.234 1.216-.703 1.672a2.338 2.338 0 01-1.691.684zm-19 0a2.274 2.274 0 01-1.672-.684 2.274 2.274 0 01-.684-1.672c0-.659.228-1.222.684-1.691a2.243 2.243 0 011.672-.703c.659 0 1.222.234 1.691.703.469.469.703 1.032.703 1.691s-.234 1.216-.703 1.672a2.338 2.338 0 01-1.691.684z" fill="#0b92e0"/></svg>') no-repeat;
        background-size: contain;
        width: 17px;
        height: 12px;
        margin-right: 8px;
    }

    @include mq($screen-m) {
        margin: $free-shipping-notice__margin-desktop;
    }
}

#surcharge-message {
    .price {
        font-size: 14px;
        font-weight: normal;
    }
}

.checkout-cart-index {
    .messages .notice {
        display: none;
    }

    &.google-shopping-inactive {
        .messages .notice {
            display: block;
        }
    }
}
