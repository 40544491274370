$input__field-padding                : 0 $spacer--medium !default;
$input__field-padding--textarea      : $spacer--medium !default;
$input__field-spacing                : 40px !default;
$input__field-border                 : 1px solid $form-elements-border-color !default;
$input__field-border-radius          : $form-elements-radius !default;
$input__field-border-radius--textarea: $form-elements-radius--small !default;
$input__field-line-height--textarea  : $font-line-height !default;
$input__margin-bottom                : $spacer--medium !default;
$input__placeholder-color            : $gray !default;
$input__label-margin-right           : $spacer--medium !default;
$input__min-height--textarea         : 100px !default;
$input__date-background-image        : url("../images/icons/calendar.svg") !default;
$input__date-background-size         : 25px 25px !default;
$input__date-background-repeat       : no-repeat !default;
$input__date-background-position     : center center !default;
$input__date-background-color--hover : $color-primary !default;
$input__date-border-color--hover     : $input__date-background-color--hover !default;
$input__date-margin                  : 0 0 0 $spacer !default;

.input {
    margin-bottom: $input__margin-bottom;

    &--inline {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        .input__label {
            @extend .label--inline;
            margin-right: $input__label-margin-right;
        }
    }

    &__field {
        width: 100%;
        height: $input__field-spacing;
        line-height: normal;
        padding: $input__field-padding;
        border: $input__field-border;
        border-radius: $input__field-border-radius;
        transition: $transition-base;
        -webkit-appearance: initial;

        ._error &,
        ._vat-warning &,
        &.mage-error {
            border-color: $input__error-color !important;
        }

        &::placeholder {
            color: $input__placeholder-color;
        }

        &:focus {
            @include focus-input();
        }

        &--textarea {
            display: block;
            border-radius: $input__field-border-radius--textarea;
            min-width: 100%;
            max-width: 100%;
            min-height: $input__min-height--textarea;
            padding: $input__field-padding--textarea;
            line-height: $input__field-line-height--textarea;
        }
    }

    .ui-datepicker-trigger {
        @extend .button;
        @extend .button--icon;
        background-image: $input__date-background-image;
        background-size: $input__date-background-size;
        background-repeat: $input__date-background-repeat;
        background-position: $input__date-background-position;
        margin: $input__date-margin;

        &:hover,
        &:focus {
            background-image: $input__date-background-image;
            background-size: $input__date-background-size;
            background-repeat: $input__date-background-repeat;
            background-position: $input__date-background-position;
            background-color: $input__date-background-color--hover;
            border-color: $input__date-border-color--hover;
        }

        span {
            display: none;
        }
    }

    &--button {

        .input__field {
            border-radius: $border-radius 0 0 $border-radius;
            width: calc(100% - 98px);
            height: 48px;

            &:focus {
                box-shadow: none;
                border-color: $form-elements-border-color;
            }
        }

        .button {
            border-radius: 0 $border-radius $border-radius 0;
            flex-basis: 98px;

            .icon {
                fill: $color-light;
            }
        }

        .input__wrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }
    }
}
