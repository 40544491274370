.attribute-modal {
    position: relative;
    display: contents;

    &__title {
        @include mq(false, $screen-m) {
            display: none;
        }

        margin-left: 10px;
        font-weight: normal;
        font-size: $font-size-base;
        color: $color-text-accent-3;
        cursor: pointer;
    }

    &__content {
        display: none;
    }
}

.modal-attribute.modal-popup.modal-slide {
    .modal-inner-wrap {
        max-width: 668px;
    }
    &._show {
        .modal-inner-wrap {
            top: 150px;
            border-radius: 5px;
            overflow-x: hidden;

            .modal-header {
                position: absolute;
                top: 0;
                right: 0;
                padding: 0;
                z-index: 1;

                .modal__close-button {
                    position: relative;
                    top: 0;
                    right: 0;
                    width: auto;
                    height: auto;
                    min-width: 0;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 30px;
                        height: 30px;
                        border: 1px solid transparent;
                        border-radius: 5px;
                        transition: $transition-base;
                        transform: translate(-50%, -50%);
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        background-color: transparent;
                    }

                    .modal__close-button-icon {
                        fill: $color-primary;
                        width: 50px;
                        height: 50px;

                        &:hover,
                        &:active,
                        &:focus {
                            fill: $gray;
                        }
                    }
                }
            }

            .modal-content {
                padding: 0 30px;
                margin: 20px 0 90px 0;

                .ais-RefinementList-list {
                    grid-column: span 10/span 10;
                    column-count: 2;
                }

                .attribute-filter-title {
                    display: block;
                    font-weight: bold;
                    padding: 7px 0;
                }
            }

            .modal-footer {
                position: fixed;
                bottom: 0;
                width: 100%;
                border-top: none;
            }
        }
    }

    .attribute-modal {
        &__title {
            display: none;
        }

        &__content {
            display: block;
            color: $color-text-default;
            font-weight: normal;
            font-size: $font-size-base;
            margin: 20px 0 10px 0;
        }
    }
}
