$list-icon-size                     : 14px !default;
$list-icon-fill                     : $color-primary !default;

.list {

    &--horizontal {
        align-items: center;

        .list__item {
            padding: 0;

            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    &--with-icon {

        .list__item {
            display: flex;
            align-items: center;
            padding: 0;

            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
        .list__icon-link {
            padding: $list__padding--with-icon 0;

            .icon {
                display: inline-block;
                vertical-align: middle;
                width: $list-icon-size;
                height: $list-icon-size;
                fill: $list-icon-fill;
            }
        }
    }

    &--icon-with-text {

        .list__item {
            margin-right: 10px;
        }

        .icon {
            display: inline-block;
            fill: $list-icon-fill;
            margin-right: 15px;
        }

        .link {
            font-size: 0;
        }

        .list__icon-link {
            display: flex;
            align-items: center;
        }

        .list__text {
            display: inline-block;
            text-decoration: underline;
            font-size: $font-size-base;
        }
    }

    &--with-image {

        .list__image {
            width: auto;
            height: auto;
        }
    }
}
