$dashboard-form-margin: 30px !default;

.dashboard-form {

    @include mq($screen-m) {
        flex-basis: calc(50% - 10px);
    }

    &__title {
        margin-bottom: $dashboard-form-margin;
        padding-bottom: 10px;
        border-bottom: 1px solid $gray;
    }

    .fieldset--flex {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .address-input {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        flex-basis: 100%;
    }
    .street-input {
        flex-grow: 1;
        flex-basis: calc(60% - 10px);

        &--2 {
            flex-grow: 0;
        }
    }
    .house-number-input {
        flex-basis: 40%;
    }

    &__divider {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        &.direct-mailings {
            margin-top: -30px;
        }
    }

    &__divider,
    &__select {
        margin-bottom: $dashboard-form-margin;
    }

    &--hidden {
        display: none;
    }
}

.field-recaptcha {
    padding: 10px 0;
}
