.register {
    &__password {
        display: flex;
        flex-direction: column;

        .mage-error[generated] {
            order: 2;
        }
    }
}
