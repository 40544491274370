$badge__padding              : 0 5px !default;
$badge__background           : transparent !default;
$badge__border-radius        : 15px !default;
$badge__font-size            : $font-size-small !default;
$badge__font-family          : $font-family-base !default;
$badge__font-weight          : $font-weight-semi-bold !default;
$badge__text-transform       : uppercase !default;
$badge__border               : 1px solid $color-alt !default;
$badge__color                : $color-alt !default;
$badge__color--new           : $color-success !default;
$badge__background--new      : $color-light !default;
$badge__color--discount      : $color-danger !default;
$badge__background--discount : $color-light !default;

.badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: $badge__width;
    height: $badge__height;
    font-family: $badge__font-family;
    font-size: $badge__font-size;
    font-weight: $badge__font-weight;
    text-transform: $badge__text-transform;
    color: $badge__color;
    background: $badge__background;
    border-radius: $badge__border-radius;
    border: $badge__border;

    &--new {
        color: $badge__color--new;
        background: $badge__background--new;
    }

    &--discount {
        color: $badge__color--discount;
        background: $badge__background--discount;
    }

    &--modal {
        display: block;
    }
}
