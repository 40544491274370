$information-margin: 15px !default;

.information {
    margin-bottom: $information-margin * 2;

    &__title {
        margin-bottom: $information-margin;
    }

    &__text {
        margin-bottom: 0;
    }
}
