.knowledge-base, .knowledge-category-view, .knowledge-category{
  .section-wrapper-block-listing-title {
    h3 {
      color: $color-primary;
      border-bottom: 1px solid #ebebeb;
      padding-bottom: 10px;
      font-size: 18px;
      font-weight: 700;
      padding-top: 7px;
      a {
        color: $color-primary;
        text-decoration: none;
        background-color: transparent;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.knowledge-base {
  ul.row {
    padding-left: 0;
  }
  li.category-name {
    margin-bottom: 44px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    list-style-type: none;
    .category-wrapper-inner {
      border: 1px solid #e7f6ff;
      border-radius: 12px;
        .category-wrapper-title {
          background: #e7f6ff;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          padding: 0 13px 0 18px;
          height: 66px;
          display: flex;
          align-items: center;
          a {
            display: block;
            width: 100%;
            position: relative;
            span {
              display: inline-block;
              vertical-align: middle;
              line-height: 1;
            }
            span.category-wrapper-title-text {
              padding-left: 20px;
              font-size: 15px;
              font-weight: 600;
              color: $color-primary;
            }
            span.category-wrapper-title-icon-right {
              position: absolute;
              right: 0;
              top: 50%;
              margin-top: -7px;
              .button__icon {
                fill: $color-primary;
              }
            }
          }
          .category-wrapper-title-icon-img-360002160652, .category-wrapper-title-icon-img-360001032897 {
            background: url(/hc/theming_assets/01HZPZQZM2BW2SQMPE6DAV4V6C) no-repeat center;
            width: 37px;
            height: 25px;
          }
        }
      ul {
        padding-left: 0;
      }
      .category-wrapper-body {
        padding: 25px 13px 25px 18px;
        height: 176px;
        ul {
          margin-bottom: 0;
          li {
            list-style-type: disc;
            margin-left: 18px;
            color: $color-primary;
            margin-bottom: 10px;
            a {
              color: $color-primary;
              font-size: 13px;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .category-view-more {
          padding-left: 18px;
          font-size: 13px;
          font-weight: 600;
          font-style: italic;
          color: $color-primary;
          position: absolute;
          bottom: 16px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.knowledge-category {
  padding-top: 50px;
  .category-section-wrapper {
    .section-wrapper-block {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding-left: 30px;
      .section-wrapper-block-listing-body {
        ul {
          padding-top: 6px;
          li {
            list-style-type: none;
            padding: 7px 0;
            a {
              color: #2d2d2d;
              font-size: 14px;
              &:hover {
                color: $color-primary;
              }
            }
          }
        }
      }
      .article-list-more a {
        padding-left: 0;
        font-size: 13px;
        font-weight: 600;
        font-style: italic;
        color: $color-primary;
        padding-top: 18px;
        display: inline-block;
      }
    }
    .section-wrapper-block-listing {
      ul.article-list {
        padding-left: 0;
      }
      width: 100%;
      padding-right: 40px;
      flex-basis: 50%;
      padding-top: 45px;
      &:nth-child(1), &:nth-child(2) {
        padding-top: 0;
      }
    }
  }
}

.category-section-wrapper-left {
  .category-section-wrapper-title {
    background: $color-primary;
    border-radius: 4px;
    padding: 0 14px;
    height: 35px;
    align-items: center;
    display: flex;
    h3 {
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      margin: 0;
    }
  }
  .category-section-wrapper-list {
    margin-top: 18px;
    ul {
      padding-left: 0;
      margin-bottom: 0;
      li {
        list-style-type: none;
        a {
          padding: 7px 14px;
          width: 100%;
          display: block;
        }
        &.active {
          a {
            font-weight: 700;
          }
        }
      }
    }
  }
}

.knowledge-category-view {
  padding-top: 50px;
  .category-section-wrapper-inner {
    width: 100%;
  }
  .section-wrapper-block {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 30px;
    &.section-wrapper-block-single {
      padding-left: 0;
    }
    .section-wrapper-block-listing.section-wrapper-block-listing-single {
      flex-basis: 100%;
      padding-right: 0px;
    }
  }
  .section-wrapper-block-listing-body {
    width :100%;
  }
  h3 {
    color: $color-primary;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
    padding-top: 7px;
  }
  ul.article-list {
    padding-top: 6px;
    padding-left: 0;
    margin-bottom: 0;
    li {
      padding: 7px 0;
      list-style-type: none;
      a {
        color: #2d2d2d;
        font-size: 14px;
        &:hover {
          color: $color-primary;
        }
      }
    }
  }
}
.article-wrapper {
  .section-wrapper-block {
    .section-wrapper-block-listing-body {
      .main-column {
        padding-left: 30px;
        .section {
          .article-body {
            padding-top: 21px;
            padding-bottom: 26px;
            border-bottom: 1px solid #ebebeb;
          }
        }
      }
    }
  }
}
