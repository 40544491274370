.sales-label-wrapper {
    &--grouped {
        position: relative;
        margin-top: 5px;
    }

    .sales-label {
        display: inline-block;
        padding: 2px 5px;
        position: absolute;
        background: $white;
        border: 1px solid $color-tertiary;
        color: $color-tertiary;
        font-size: 14px;
        font-weight: bold;
        top: 5px;
        border-radius: 3px;

        @include mq(false, $screen-m) {
            font-size: 12px;
        }

        .ais-Hits & {
            top: 2px;
            right: 6px;

            @include mq(false, $screen-m) {
                position: relative;
                top: inherit;
                bottom: inherit;
                right: inherit;
                left: inherit;
            }
        }

        &--grouped {
            position: relative;
        }

        &--pdp {
            padding: 7px 10px;
            top: 10px;
            left: 0;
        }

        &--special-price {
            background: $white;
            color: $color-tertiary;
            border: 1px solid $color-tertiary;
        }
    }
}
