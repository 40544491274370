$table__padding                  : $spacer--medium !default;
$table__color                    : $color-text-accent !default;
$table__background               : $white !default;
$table__font-size                : $font-size-base !default;
$table__line-height              : 19px !default;
$table__border-radius            : 0 !default;
$table__border                   : 1px solid $gray-light !default;
$table__th-background            : $gray-lightest !default;
$table__th-color                 : $color-text-accent-2 !default;
$table__td-background            : $white !default;
$table__tfoot-background         : $gray-lightest !default;
$table__background-odd--odd-even : $white !default;
$table__background-even--odd-even: $color-primary--accent-8 !default;
$table__th-font-weight--clean    : $font-weight-bold !default;
$table__th-background--clean     : transparent !default;
$table__background-odd--clean    : #f9f9f9 !default;
$table__background-even--clean   : $white !default;

.table {
    border-collapse: separate;
    border-spacing: 0;
    padding: 10px 15px;
    width: 100%;
    background: $table__background;
    border: $table__border;
    border-radius: $table__border-radius;
    color: $table__color;
    font-size: $table__font-size;
    line-height: $table__line-height;

    &--odd-even {
        tbody {
            tr {
                &:nth-child(odd) {
                    background: $table__background-odd--odd-even;
                }

                &:nth-child(even) {
                    background: $table__background-even--odd-even;
                }
            }

            td {
                background-color: transparent;
            }
        }
    }

    &--clean {
        margin: 0;
        border: 0;
        border-radius: 0;

        thead {
            tr {
                padding: 0;
                border-bottom: 0;
            }
        }

        th {
            text-align: left;
            border-bottom: 0;
            border-right: 0;
            background: $table__th-background--clean;
            font-weight: $table__th-font-weight--clean;
        }

        td {
            border-bottom: 0;
            border-right: 0;
            background: $table__th-background--clean;
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    background: $table__background-odd--clean;
                }

                &:nth-child(even) {
                    background: $table__background-even--clean;
                }
            }
        }
    }

    &--scope-row {
        border-width: 1px 0;
        background: transparent;
        border: none;
        padding: 0;

        tr {
            padding: 0;

            @include mq($screen-m) {
                margin-bottom: 20px;
            }

            th {
                background: transparent;
                border: none;
                padding: 5px 0;
                text-align: left;
                color: $table__th-color;
                font-weight: $font-weight-normal;
                font-size: $font-size-small;


                @include mq($screen-m) {
                    font-size: $table__font-size;
                    padding: 10px 15px;
                }
            }

            td {
                background: transparent;
                border: none;
                padding: 5px 35px 5px 0;
                font-weight: $font-weight-semi-bold;
                color: $color-text-accent;

                @include mq($screen-m) {
                    padding: 10px 35px;
                }
            }

            @include mq($screen-m) {

                &:nth-child(even) {
                    th[scope="row"],
                    td {
                        background-color: $table__background-even--odd-even;
                    }
                }
            }
        }
    }

    &--compact {
        @extend  .table--scope-row;

        tr {

            @include mq($screen-m) {
                margin-bottom: 0;
            }

            th {
                padding: 0;

                @include mq($screen-m) {
                    padding: 0;
                }
            }

            td {
                padding: 0;

                @include mq($screen-m) {
                    padding: 0;
                }
            }

            @include mq($screen-m) {

                &:nth-child(even) {
                    th[scope="row"],
                    td {
                        background-color: initial;
                    }
                }
            }
        }
    }

    &__caption,
    &__visually-hidden {
        @include visually-hidden();
    }

    thead {
        tr {
            padding: 0;
            border-bottom: 0;
            border-top-left-radius: $table__border-radius;
            border-top-right-radius: $table__border-radius;
        }

        th {
            &:first-child {
                border-top-left-radius: $table__border-radius;
            }

            &:last-child {
                border-top-right-radius: $table__border-radius;
            }
        }
    }

    tr {
        padding: 0;
        border-bottom: 0;
        display: table-row;
    }

    th {
        display: table-cell;
        padding: $table__padding;
        background: $table__th-background;
        border-bottom: $table__border;
        border-right: $table__border;

        &:last-child {
            border-right: 0;
        }
    }

    td {
        display: table-cell;
        padding: $table__padding;
        background: $table__td-background;
        border-bottom: $table__border;
        border-right: $table__border;

        &:last-child {
            border-right: 0;
        }
    }

    tbody {
        tr {
            &:last-child {
                td {
                    &:first-child {
                        border-bottom-left-radius: $table__border-radius;
                    }

                    &:last-child {
                        border-bottom-right-radius: $table__border-radius;
                    }
                }
            }
        }
    }

    tfoot {
        tr {
            &:last-child {
                border-bottom: 0;

                td {
                    border-bottom: 0;

                    &:first-child {
                        border-bottom-left-radius: $table__border-radius;
                    }

                    &:last-child {
                        border-bottom-right-radius: $table__border-radius;
                    }
                }
            }
        }

        td {
            display: table-cell;
            padding: $table__padding;
            background: $table__tfoot-background;
            border-right: $table__border;
        }
    }
}
