.ribbon {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: $ribbon-background;
    border-left: $ribbon-border-left;
    border-right: $ribbon-border-right;
    border-top: $ribbon-border-top;
    border-bottom: $ribbon-border-bottom;
    border-radius: $ribbon-border-radius;

    &__content {
        line-height: normal;
        padding: 10px 10px 10px 25px;
        color: $ribbon-color;

        strong {
            font-weight: $ribbon-strong-font-weight;
        }
    }

    &__cta {
        padding: 10px;

        .button {
            min-width: 72px;
            font-size: $ribbon-font-size;
        }
    }

    &--alt {
        background: $ribbon--alt-background;
        border-color: $ribbon--alt-color;

        .ribbon__content {
            color: $ribbon--alt-color;
        }
    }
}
