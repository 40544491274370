.skip-nav {
    @include visually-hidden('focus');
    padding: 12px $spacer--extra-large;
    top: 0;
    left: 0;

    &.focus-visible,
    &:hover {
        position: absolute;
        margin: $spacer;
        background-color: $color-primary;
        color: $black;
    }

    &--relative {
        &.focus-visible,
        &:hover {
            display: block;
            position: relative;
            margin: $spacer--medium;
        }
    }
}
