$discount-code__background-\@large          : $color-primary--accent-2 !default;
$discount-code__label-color                 : $color-primary !default;
$discount-code__button-border-bottom        : none !default;

$self : '.discount-code';

#{$self} {

    @include mq($screen-l) {
        padding: 20px;
        margin: 7px 0;
        background: $discount-code__background-\@large;

        form {
            margin: 0;
        }

        .input {
            display: flex;
            align-items: center;
            flex-flow: row nowrap;
            justify-content: flex-end;
            margin: 0;
        }
    }

    .input__wrapper {
        position: relative;

        .loading-mask {
            @extend .discount-code-loading-mask;
        }
    }

    &__button {
        border-bottom: $discount-code__button-border-bottom;
    }

    &__label {
        color: $discount-code__label-color;
        font-weight: $font-weight-semi-bold;

        @include mq($screen-l) {
            margin-right: 20px;
            margin-bottom: 0;

            &:first-child {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    &__field {

        @include mq($screen-l) {
            max-width: 360px;
            min-width: 280px;
        }
    }

    &--success {

        #{$self}__label {
            color: $color-alt;

            .icon {
                display: inline-block;
                vertical-align: middle;
                padding: 0;
                margin-left: -6px;
                fill: $color-alt;
            }
        }
    }

    &--invalid {

        #{$self}__label {
            color: $color-tertiary;

            .icon {
                display: inline-block;
                vertical-align: middle;
                padding: 0;
                margin-left: -6px;
                fill: $color-tertiary;
            }
        }
    }
}
