.product-specifications {

    &__table {
        @include mq($screen-m) {
            margin-bottom: 15px;
        }
    }

    &__with-icons {
        margin-bottom: 15px;

        .list {
            @include mq($screen-m) {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: center;
            }
        }

        .list__text {
            text-decoration: none;
            font-size: $font-size-medium;
            font-weight: $font-weight-bold;

            @include mq($screen-m) {
                text-decoration: underline;
                font-size: $font-size-base;
                font-weight: $font-weight-normal;
            }
        }

        .list__item {
            padding: 10px;
            margin: 10px 0;
            border-radius: 5px;
            background: $product-specifications__with-icons-background;

            .icon {
                fill: $color-light;
            }

            .link {
                color: $color-light;
            }

            @include mq($screen-m) {
                padding: 0;
                background: $product-specifications__with-icons-background\@medium;

                .icon {
                    fill: $color-primary;
                }

                .link {
                    color: $font-color-link-default;
                }
            }
        }

        .icon:not(.dropdown-item__icon) {
            padding: 0;
            margin: 0 15px 0 10px;
            width: 16px;
            height: 16px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    &__title {
        display: block;
        padding: 15px $global-container-margin 15px $global-container-margin;
        margin: 15px -#{$global-container-margin} 0 -#{$global-container-margin};
        border-top: $base-border;
        font-weight: $product-specifications__title-font-weight;
        font-size: $font-size-medium;

        @include mq($screen-m) {
            border-top: none;
            padding: 0 $global-container-margin 15px $global-container-margin;
        }

        @include mq($screen-l) {
            padding: 0 0 0 0;
            margin: 0;
        }

        @include mq(false, $screen-m) {
            font-size: $font-size-base;
            padding-top: 15px;
            padding-bottom: 15px;
            margin-top: 0;
        }
    }

    &__item {
        padding-top: 15px;
        padding-left: 0;

        @include mq($screen-m) {
            padding-top: 0;
            overflow: visible;
        }

        th.label {
            width:35%;
        }

        td.tooltip {
            width:30px;
            padding:5px;
        }
    }

    &__label {
        display: inline-block;
        font-weight: $product-specifications__label-font-weight;
        border-bottom: $product-specifications__label-border;
        color: $product-specifications__title-color;

        padding: 15px #{$global-container-margin};
        margin: 0 -#{$global-container-margin};

        @include mq($screen-m) {
            font-size: $font-size-medium;
            margin-bottom: 15px;
            color: $headings-color;
        }

        @include mq($screen-l) {
            margin: 0 0 15px 0;
            padding: 15px 0;
            color: $headings-color;
        }

        .icon {
            fill: $product-specifications__icon-fill;

            @include mq($screen-m) {
                display: none;
            }
        }

        &[aria-expanded="true"],
        &:hover,
        &:focus {
            .icon {
                fill: $product-specifications__icon-fill;
            }
        }
    }

    &__button {
        justify-content: flex-start;
        width: 100%;
        padding: 0 15px;

        .icon {
            margin-right: 15px;
        }
    }

    &__attachments {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        .attachments {

            &__item {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                flex-basis: 50%;
                margin: 10px 0;

                @include mq($screen-m) {
                    flex-basis: 25%;
                    margin: 10px 0 0;
                }
            }

            &__icon {
                width: 16px;
                margin-right: 15px;
            }

            &__label {
                text-decoration: underline;
            }
        }
    }

    &__specifications-input-container {
        display: flex;
        padding-top: 15px;
        padding-bottom: 10px;
        @include mq(false, $screen-l) {
            padding-top: 0;
        }

        &__input {
            height: 41px;
            width: calc(49% + 2px);
            padding: 10px;
            border-radius: 0 5px 5px 0;
            border: 1px solid $gray-light;

            @include mq(false, $screen-s) {
                width: calc(100% - 105px);
            }
            @include mq(false, $screen-xs) {
                font-size: smaller;
            }
        }

        button {
            position:relative;
            left:-42px;
            height: 41px;
            margin: 0;
            border: none;
            background-color: $color-primary;
            box-shadow: none;
            border-radius: 0 5px 5px 0;
            z-index: 1;
        }

        .icon {
            width: 30px;
            height: 30px;
        }

        #specifications-clear {
            display: flex;
            align-items: center;
            position: relative;
            left: -35px;
            background: white;
            color: $color-primary;
            border:none;
            padding: 0 8px;
            cursor:pointer;
        }
    }
}

body.isFiltering {
    .product-view__specifications {
        .dropdown-item:has(tr.matching) {
            display:block;
        }
        .dropdown-item:not(:has(tr.matching)) {
            display:none;
        }
    }

    tr.matching {
        display: table-row;
    }

    tr:not(.matching) {
        display:none;
    }

    #specifications-clear {
        display:flex;
    }
}

body:not(.isFiltering) {
    .product-specifications__table {
        > tr {
            display:table-row;

        }
    }
    #specifications-clear {
        display:none;
    }
}
