$modal-led-alternative__heading-color                   : $color-alt !default;
$modal-led-alternative__saving-background               : $color-alt !default;
$modal-led-alternative__saving-color                    : $color-light !default;

.modal-led-alternative {
    text-align: center;
    margin: 0 auto;
    width: 90%;
    height: 100%;
    max-height: 90%;

    @include mq($screen-m) {
        height: 100%;
        max-width: 1200px;
        box-shadow: none;
    }

    .tab__title {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .heading {
        font-size: $font-size-medium;
        color: $modal-led-alternative__heading-color !important;
        font-weight: $font-weight-normal !important;
        text-align: center !important;
    }

    &.stock-alternative .heading {
        color: $color-text-accent !important;
    }

    .price-box {
        margin-top: 10px;
    }

    .price__value--old {
        display: none;
    }

    .dispatch-status {
        text-align: left;
        font-weight: bold;
        padding: 0;

        &__text {
            display: inline;
        }
    }

    #led-dropdown {
        display: flex;

        .row__content {
            padding: 0 15px;

            @include mq($screen-m) {
                padding: 0 30px;
            }
            &:first-child {
                border-right: 1px solid $gray-light;

                &:after {
                    position: absolute;
                    content: "";
                    left: calc(50% - 10px);
                    top: calc(50% + 50px);
                    width: 20px;
                    transform: rotate(
                            45deg
                    );
                    height: 20px;
                    background: #fff;
                    border-top: 1px solid $gray-light;
                    border-right: 1px solid $gray-light;
                }
            }
        }
    }

    .cart-led-alternative-modal-conventional {
        #led-dropdown {
            .row__content.dropdown-list__content {
                padding-bottom: 60px;
            }

            .row__content {
                &:first-child {
                    border-right: none;

                    &:after {
                        content: '';
                    }
                }
            }
        }
    }

    .led-button {
        padding: 10px 20px 12px;
        height: auto;
        line-height: 22px;
        font-size: 16px;
        font-weight: bold;
    }

    .dropdown-list__item {
        .led-button {
            width: 90%;
            left: 5%;
            bottom: 10px;
            position: absolute;
        }

        .modal-led-alternative__specifications .table tr th {
            text-align: right;
            width: 50%;
        }

        .modal-led-alternative__image-wrapper {
            flex-basis: 100%;
            padding: 10px 20px;

            img {
                max-width: 100px;
                max-height: 100px;
                margin: 5px auto 0;
            }
        }

        .savings-ribbon {
            width: calc(100% + 2px);
            margin-left: -1px;
            margin-right: -1px;
            margin-top: 4px;
        }
    }

    .alt-width {
        .modal-header {
            padding-top: 80px !important;
        }
    }

    .cart-led-alternative-modal {
        padding-bottom: 30px;

        .product-elabel {
            width: 40px !important;
        }

        .cancel-button,
        .led-button {
            width: 100%;
            margin: 15px auto 0;
        }

        .savings-ribbon {
            width: calc(100% + 60px);
            margin-left: -30px;
            margin-right: -30px;
            margin-top: 15px;
            margin-bottom: 25px;
        }

        .option-badge {
            text-align: center;
            width: 100%;
            padding: 10px;

            span {
                border-radius: 8px;
                background-color: #e7f6ff;
                font-size: 10px;
                color: $color-primary;
                padding: 2px 8px;
            }

            &--led-alternative {
                span {
                    background-color: #e7ffee;
                    color: $color-alt;
                }
            }
        }

        .modal-led-alternative__image-wrapper {
            width: 100%;

            img {
                max-height: 75px;
                margin: 0 auto;
                width: auto;
            }
        }
    }

    .dropdown-list__icon {
        fill: $modal-led-alternative__heading-color;
    }

    &__text {
        display: none;

        @include mq($screen-m) {
            display: block;
            text-align: left;
            padding: 0 20px 10px;

            p {
                line-height: normal;
                margin: 0;
            }
        }
    }

    &__saving {
        text-align: center;
        height: 32px;
        line-height: 32px;
        margin: 0 -15px;
        background-color: $modal-led-alternative__saving-background;
        color: $modal-led-alternative__saving-color;
        font-size: $font-size-base;

        @include mq($screen-m) {
            margin: 0 -30px;
        }
    }

    &__wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        margin: 8px 20px;
        border: 1px solid $modal-led-alternative__heading-color;
        border-radius: 15px;
        width: initial;
    }

    .dropdown-list__item {
        position: relative;
    }

    .dropdown-list__content {
        padding-bottom: 60px;
    }

    .product-specifications__table {
        margin-bottom: 15px;
    }

    .dropdown-list__label[aria-expanded="true"] > .dropdown-list__icon {
        fill: $modal-led-alternative__heading-color;
    }

    .dropdown-list__label[aria-expanded="true"] {
        color: $black;
    }

    &__link {
        color: initial;
        line-height: 1.15;
        clear: both;
        .modal-led-alternative__title span {
            max-height: 110px;
        }

        &:hover {
            color: initial;
        }
    }

    &__details {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        flex-basis: 100%;
        padding: 10px 0 0 0;
        max-width: 100%;
    }

    &__specifications {
        width: 100%;
        margin-top: 15px;

        .table tr {
            td, th {
                padding-top: 5px;
                padding-bottom: 5px;
                font-size: 13px;
            }
            th {
                padding-right: 5px;
                font-size: 13px;
            }
            td {
                text-align: left;
                padding-left: 5px;
            }
        }

        @include mq($screen-m) {
            .table tr th {
                padding-left: 0;
            }
        }
    }

    &__title {
        text-align: center;
        flex-basis: 100%;
        font-weight: $font-weight-bold;
        height: 100px;

        span {
            @include line-clamp(5);
        }

        @include mq($screen-m) {
            text-align: left;
            height: 55px;

            span {
                @include line-clamp(3);
            }
        }
    }

    &.modal-popup,
    &.modal-slide {
        left: 0 !important;

        .modal-header {
            padding: 20px 50px 10px;
            .modal__close-button {
                top: 0;
            }
            .button__icon {
                width: 35px;
                height: 35px;
            }
        }

        .modal-content {
            padding-bottom: 10px;
        }

        .modal-inner-wrap {
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.35);
            border-radius: $border-radius;
            height: 100% !important;
            overflow-x: hidden;

            @media (max-width: $screen-l) and (min-height: $screen-m) {
                height: auto !important;
            }

            @media (min-width: $screen-l) and (min-height: 685px) {
                height: auto !important;
            }
        }
    }

    .savings-ribbon {
        color: #fff;
        background: $modal-led-alternative__heading-color;
        line-height: 1.2;
        font-weight: 100;
        padding: 9px 10px 7px;

        img {
            display: inline;
            vertical-align: middle;
            margin-top: -3px;
            margin-right: 4px;
            height: 16px;
            width: 16px;
        }

        .price {
            font-size: 14px;
        }

        b, strong {
            font-weight: 700;
        }
    }

    .bulb-icon {
        width: 35px;
        height: 35px;
        position: absolute;
        top: 30px;
        left: 50%;
        margin-left: -18px;
    }

    @include mq(1025px) {
        .dropdown-list__label {
            text-align: center;
        }
    }

    .list--led-specifications {
        text-align: left;

        li {
            color: $color-text-accent-2;
            margin: 5px 0;

            &:before {
                content: "\2022";
                color: $color-text-accent-2;
                display: inline-block;
                width: 1em;
            }

            &.list--item-checkmark {

                &:before {
                    display: inline-block;
                    position: relative;
                    left: -4px;
                    content: '';
                    background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fff" d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"/></svg>') no-repeat center $color-alt;
                    background-size: 1.5em;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                }
            }
        }
    }

    .list--led-alternatives {
        display: flex;
        justify-content: center;
         li {
             margin: 0 15px;
             position: relative;
             padding: 0 0 0 22px;
             font-size: 14px;
             font-weight: bold;

             &:before {
                 display: inline-block;
                 position: absolute;
                 top: 0;
                 left: -1px;
                 content: '';
                 background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fff" d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"/></svg>') no-repeat center;
                 width: 16px;
                 height: 16px;
                 border-radius: 50%;
                 border: 1px solid $white;

                 @include mq($screen-l) {
                     background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fff" d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"/></svg>');
                 }
             }
         }
    }

    @include mq(false, $screen-l) {
        .desktop-button {
            display: none;
        }
    }

    @include mq($screen-l) {
        .desktop-button {
            display: block;
        }

        .mobile-buttons {
            display: none;
        }
    }
}

.modal-popup.modal-led-alternative .modal-inner-wrap,
.modal-slide.modal-led-alternative .modal-inner-wrap {
    .modal__close-button-icon {
        fill: $color-primary;
        &:hover {
            fill: $gray;
        }
    }
}

.modal-popup {
    &.modal-slide.modal-accessories {
        &._show {
            .modal-inner-wrap {
                max-width: 668px !important;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 5px;
                overflow-x: hidden;
            }
        }
    }

    &.modal-accessories {
        .modal-header {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0;
            z-index: 1;

            .modal__close-button {
                position: relative;
                top: 0;
                right: 0;
                width: auto;
                height: auto;
                min-width: 0;

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 30px;
                    height: 30px;
                    border: 1px solid transparent;
                    border-radius: 5px;
                    transition: $transition-base;
                    transform: translate(-50%, -50%);
                }

                &:hover,
                &:active,
                &:focus {
                    background-color: transparent;

                    &:after {
                        border: 1px solid $color-primary;
                    }
                }

                .modal__close-button-icon {
                    fill: $color-primary;
                    width: 50px;
                    height: 50px;
                }
            }
        }

        .modal-content {
            padding-left: 40px;
            padding-right: 40px;
        }

        .cart-add-success-info {
            position: relative;
            background-color: $color-alt--accent-3;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-left: -40px;
            margin-right: -40px;
            padding: 40px 40px 25px;

            &:after {
                content: '';
                position: absolute;
                left: 40px;
                bottom: -15px;
                width: 30px;
                height: 30px;
                background-color: $color-alt--accent-3;
                transform: rotate(-45deg);
            }

            .cart-add-success-text {
                position: relative;
                padding-left: 25px;
                margin-right: 45px;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fff" d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"/></svg>') $color-alt;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 20px auto;
                }

                .cart-add-success-product-status {
                    font-size: 16px;
                    color: $color-alt;
                    font-weight: bold;
                    display: block;
                }

                .cart-add-success-product-name {
                    font-size: 14px;
                    margin-top: 10px;
                    display: block;
              }
            }

            .cart-add-success-actions {
                .button {
                    &:not(:first-of-type) {
                        margin-top: 10px;
                        font-size: 14px;

                    }

                    &.action {
                        width: auto;
                        min-width: auto;
                        height: auto;
                        padding: 0;
                        border: none;
                        background-color: transparent;

                        &:hover,
                        &:active,
                        &:focus {
                            box-shadow: none;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .product-accessories-modal-info {
            margin-top: 35px;
            margin-bottom: 25px;

            p {
                &:first-child {
                    color: $color-primary;
                    font-size: 17px;
                    font-weight: bold;
                }

                &:not(:first-of-type) {
                    margin-top: 10px;
                }
            }
        }

        .product-elabel {
            width: 50px;
            height: auto;
            margin-top: 10px;
            cursor: pointer;

            @include mq($screen-m) {
                margin-top: 5px;
            }
        }

        .led-dropdown {
            display: flex;
            margin: 0 -8px;

            .row__content {
                padding: 20px;
                margin: 0 8px;
                max-width: 33%;
                border: 1px solid $gray-light;
                border-radius: 5px;

                .row {
                    flex-direction: column;
                    flex-wrap: nowrap;
                }
            }

            .grid-container {
                display: flex;
                height: 100%;
            }
        }

        .modal-led-alternative__image-wrapper {
            height: 150px;
            min-height: 150px;
            max-height: 150px;
            overflow: hidden;

            img {
                max-height: 100%;
            }
        }

        .modal-led-alternative__details {
            padding-top: 0;
            margin: 20px 0 10px;
            align-items: initial;

            .modal-led-alternative__title {
                height: auto;
            }

            .accessory-short-name {
                padding-right: 15px;

                &:after {
                    content: '....';
                    position: absolute;
                    bottom: 2px;
                    right: 0;
                }
            }

            a {
                color: $color-text-accent;
                font-weight: $font-weight-semi-bold;
                position: relative;
                display: block;
                height: 44px;
                overflow: hidden;
                word-break: break-all;
            }
        }

        .modal-led-alternative-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            margin-top: auto;
        }

        .modal-led-alternative__price-wrapper {
            width: calc(100% - 50px);
            padding-right: 5px;

            .price-box {
                .price.price--decimal {
                    .price__value {
                        .price__prefix {
                            margin-right: 2px;
                        }

                        .tax-label {
                            color: $color-text-accent-2;
                        }
                    }
                }

                .price__value--old {
                    display: inline-block;

                    &:before {
                        width: 100%;
                    }

                    .price-excluding-tax {
                        font-size: 12px;
                        color: $color-text-accent-3;

                        .tax-label {
                            display: none;
                        }
                    }
                }

                .price__value--regular {
                    .price-container {
                        display: flex;
                        flex-direction: column;
                    }

                    .price-including-tax {
                        order: 1;
                    }

                    .price-excluding-tax {
                        order: 2;

                        &.price-wrapper {
                            margin-top: 5px;
                        }
                    }

                    .price-excluding-tax,
                    .price-including-tax {
                        color: $color-text-accent;
                        line-height: 1.1;
                        display: inline-block;
                    }
                }

                .price__value--special {
                    .price-excluding-tax {
                        line-height: 1.1;
                        display: inline-block;
                    }
                }
            }
        }

        .modal-add-to-cart {
            width: 45px;

            .product-view__main-actions {
                margin: 0;
            }

            .add-to-cart-button {
                display: flex;

                &.btn-add-to-cart-success {
                    padding: 11px;
                    background-color: rgba($color-alt--accent, 0.2);
                    border: 1px solid $color-alt;
                    border-radius: 5px;

                    .button {
                        border-radius: 50%;
                        background-size: 28px auto;
                        background: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fff" d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"/></svg>') $color-alt;
                        background-position: center;
                        background-repeat: no-repeat;
                    }

                    .button__icon {
                        display: none;
                    }
                }
            }

            .button {
                height: auto;
                width: auto;
                min-width: auto;
                margin: auto;
                padding: 10px 11px 11px 10px;
                background-color: $button__background--cta;
                font-weight: $button__font-weight--cta;
                box-shadow: $button__box-shadow--cta;
                @include gradient-directional($button__background--cta, $button__background-hover--cta, 281deg);

                .button__icon {
                    margin: 0;
                }

                span {
                    display: none;
                }
            }
        }
    }

    &.stock-alternative {
        [data-price-type="basePrice"],
        [data-price-type="finalPrice"] {
            color: $color-text-accent;
        }
    }
}

@include mq(false, $screen-m) {
    .modal-popup {
        &.modal-slide.modal-accessories {
            left: 0;

            &._show {
                .modal-inner-wrap {
                    max-width: 100% !important;
                    top: 0;
                    left: 0;
                    transform: translateY(0);
                    border-radius: 0;
                }
            }
        }

        &.modal-accessories {
            .modal-content {
                padding-left: 15px;
                padding-right: 15px;
            }

            .cart-add-success-info {
                padding-top: 60px;
            }

            .modal-led-alternative__image-wrapper {
                height: auto;
            }

            .led-dropdown {
                flex-direction: column;
                margin: 0;

                .row__content {
                    margin: 10px 0;
                    max-width: none;
                }

                .row {
                    justify-content: center;
                    width: 100%;
                    margin: 0;
                }

                .modal-led-alternative__image-wrapper {
                    max-width: 100px;
                    min-height: 100px;
                    max-height: 100px;
                    margin: 0 auto;
                }

                .modal-led-alternative__details {
                    a {
                        padding-right: 0;
                        height: auto;
                        word-break: initial;

                        &:after {
                            display: none;
                        }
                    }
                }

                .modal-led-alternative__price-wrapper {
                    .price--decimal {
                        display: flex;
                        flex-direction: row;
                    }

                    .price-box {
                        .price__value--special {
                            .price-including-tax,
                            .price-excluding-tax {
                                margin-right: 8px;
                            }
                        }

                        .price__value--regular {
                            .price-including-tax,
                            .price-excluding-tax {
                                margin-right: 8px;
                            }
                        }

                        .price-container {
                            display: flex;
                            flex-direction: row;
                            align-items: baseline;
                        }
                    }
                }

                .modal-add-to-cart {
                    width: auto;
                }
            }
        }
    }
}

@include mq(false, $screen-s) {
    .modal-popup {
        &.modal-accessories {
            .cart-add-success-info {
                flex-direction: column;

                .cart-add-success-text {
                    margin-right: 0;
                    margin-bottom: 20px;
                }

                .cart-add-success-actions {
                    text-align: center;
                }
            }
        }
    }
}
